(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsCargoController', WmsCargoController);

    WmsCargoController.$inject = ['$scope', '$rootScope', '$stateParams', '$translate', '$uibModal', '$timeout', '$http',
    'WmsCargo', 'WmsWarehouse', 'Principal', 'OpsFiles', 'DataUtils', 'AccountBind',
    'uiGridConstants', 'uiGridGroupingConstants', 'GridState', 'DateUtils'];

    function WmsCargoController($scope, $rootScope, $stateParams, $translate, $uibModal, $timeout, $http,
        WmsCargo, WmsWarehouse, Principal, OpsFiles, DataUtils, AccountBind,
        uiGridConstants, uiGridGroupingConstants, GridState, DateUtils) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;
        $scope.filter = {
            byInTime:true,
            showInventory: true,
            showInTransit: true,
            showAvailable: true,
            showForOutbound: true,
            exLocked:true
        };
        vm.getContetns = getContetns;
        vm._onLocationSelected = _onLocationSelected;
        vm._onAreaSelected = _onAreaSelected;
        vm.warehouseSelected = warehouseSelected;
        vm.clientSelected = clientSelected;
        vm.warehouses = [];
        vm.locations = [];
        vm.areas = [];
        vm.getBarcode = getBarcode;

        vm.reportavailalbe = false;

        vm.getAllWarehouses = getAllWarehouses;
        vm.getAllAreas = getAllAreas;
        vm.getAllLocations = getAllLocations;
        vm.showChange = showChange;
        vm.wmsCargoImport = wmsCargoImport;
        vm.fifoRegistIn = fifoRegistIn;
        vm.saveWmsCargoList = saveWmsCargoList;
        vm.inventoryForceOut = inventoryForceOut;
        vm.createWmsIn = createWmsIn;
        vm.delAll = delAll;

        function showChange(isImport) {
            if(isImport){
                $scope.gridOptions.data = resultsCopy;
                return;
            }
            $scope.gridOptions.data = [];
            if(!resultsCopy || resultsCopy.length == 0){
                return;
            }
            angular.forEach(resultsCopy, function(data){
                if($scope.filter.showInTransit && data.inTransitAmt){
                    $scope.gridOptions.data.push(data);
                }
                else if($scope.filter.showInventory && data.inventoryAmt){
                    $scope.gridOptions.data.push(data);
                } 
                else if($scope.filter.showAvailable && data.availableAmt){
                    $scope.gridOptions.data.push(data);
                }
                else if($scope.filter.showForOutbound && data.forOutbound){
                    $scope.gridOptions.data.push(data);
                }
            })
            initContent();
            resize();
        }

        var initContent = function() {
            angular.forEach($scope.gridOptions.columnDefs, function(col, index){
                if(index == 1){
                    if($rootScope.account.companyId){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;                        
                    }
                }
                if(col.field == "availableAmt"){
                    col.visible = $scope.filter.showAvailable;
                }
                else if(col.field == "inventoryAmt"){
                    col.visible = $scope.filter.showInventory;
                }
                else if(col.field == "pkgs"){
                    col.visible = $scope.filter.showInTransit;
                }
                else if(col.field == "forOutbound"){
                    col.visible = $scope.filter.showForOutbound;
                }
            })
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }
        
        function getBarcode (fd) {
            if("location" == fd){
                getAllLocations();
            }            
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(code) {
                if("location" == fd){
                    angular.forEach(vm.locations, function(data){
                        if(data.code == code){
                            $scope.filter.location = data.code;
                            $scope.filter.filterType = 'location';
                            getContetns()
                        }
                    });
                }
                else {
                    $scope.filter[fd] = code;
                }
            }, function() {
            });
        }

        $scope.isSaving = false;
        function getAllWarehouses () {
            if ($scope.isSaving || (vm.warehouses && vm.warehouses.length > 0)){
                return;
            }
            $scope.isSaving = true;
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: false
                }, function (result) {
                $scope.isSaving = false;
                if(!result || result.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                vm.warehouses = result;
            });
        }


        function getAllLocations () {
            if ($scope.isSaving || (vm.locations && vm.locations.length > 0)){
                return;
            }
            $scope.isSaving = true;
            WmsWarehouse.getAllLocations({companyId: $rootScope.account.companyId}, function (data) {
                vm.locations = data;
                $scope.isSaving = false;
            });
        }
        function getAllAreas () {
            if ($scope.isSaving || (vm.areas && vm.areas.length > 0)){
                return;
            }
            $scope.isSaving = true;
            WmsWarehouse.getAllAreas({companyId: $rootScope.account.companyId}, function (data) {
                vm.areas = data;
                $scope.isSaving = false;
            });
        }

        function warehouseSelected (warehouse) {
            $scope.filter.warehouseId = warehouse.id;
            $scope.filter.companyId = warehouse.companyId;
        }
        function _onLocationSelected (location) {
        }
        function _onAreaSelected (area) {
            $scope.filter.areaId = area.areaId;
        }
        function clientSelected (client) {
            $scope.filter.clientId = client.id;
        }
        var resultsCopy;
        function getContetns () {
            if(!$scope.filter.warehouseName){
                $scope.filter.warehouseId = null;
                $scope.filter.companyId = null;
            }
            if(!$scope.filter.companyId){
                $scope.filter.companyId = $rootScope.account.companyId;
            }                        
            if($rootScope.account.companyId 
                && $rootScope.account.companyId != $scope.filter.companyId
                && !$scope.filter.clientId){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseClientAlert'));     
                return;
            }   
            if(!$scope.filter.locationName){
                $scope.filter.locationId = null;
            }
            if(!$scope.filter.areaName){
                $scope.filter.areaId = null;
            }
            if(!$scope.filter.clientName){
                $scope.filter.clientId = null;
            }            
            $scope.filter.exactSearch = true;
            layer.load(1, {shade: 0.3});
            WmsCargo.inventoryQuery($scope.filter, function(result){
                layer.closeAll(); 
                if(!result || result.length == 0){
                    resultsCopy = [];
                    showChange(false);
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                else{
                    vm.reportavailalbe = true;                
                    layer.msg($translate.instant('autopsApp.wmsCargo.resultSize', {param : result.length})); 
                }
                afterLoadData(result, false);
            }, function(){
                layer.closeAll(); 
            });
        }
        function afterLoadData(result, isImport) {
            resultsCopy = angular.copy(result);
            var nowDate = new Date();
            angular.forEach(resultsCopy, function(data){
                data.ttlCost = (data.shareCost?data.shareCost:0) + (data.goodsValue?data.goodsValue:0);
                if(data.availableAmt){
                    data.gwTtl = data.availableAmt * data.gw;
                    data.volTtl = data.availableAmt * data.vol;
                }
                else if(!data.allOut && data.appAmt){
                    data.gwTtl = data.appAmt * data.gw;
                    data.volTtl = data.appAmt * data.vol;
                }
                data.forOutbound = data.inventoryAmt - data.availableAmt;
                data.inventoryDays = DateUtils.getDaysDiff(data.gateInTime, nowDate);
                data.chargeDays = DateUtils.getDaysDiff(data.billDate, nowDate);                    
            })
            showChange(isImport);
        }


        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        $scope.gridOptions = {
            enableSorting: true,//排序

            enableGridMenu : true,//表格 菜单  
            showGridFooter: true,//表格的footer  
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'wms_inventory.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],         

            minimumColumnSize: 70,
            gridFooterTemplate:'<div class="ml-5 mt-5"><strong><span translate="stats.stat.totalText" class="text-muted"></span><span class="label label-primary mr-8 f-13">{{grid.appScope.gridOptions.data.length}}</span><span translate="stats.stat.choosed" class="text-muted" ng-if="grid.appScope.selectedRows"></span><span class="label label-primary mr-8 f-13" ng-if="grid.appScope.selectedRows">{{grid.appScope.selectedRows}}</span></strong><span ng-if="grid.appScope.selectedRows"><button type="button" class="btn btn-primary btn-xs ml-10 mr-5" ng-click="grid.appScope.delAllChecked()"><span class="glyphicon glyphicon-remove"></span><span translate="entity.action.delAllChecked">delAllChecked</span></button><button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.delAllUnChecked()"><span class="glyphicon glyphicon-remove"></span><span translate="entity.action.delAllUnChecked">delAllUnChecked</span></button></span></div>',
            columnDefs: [
                { field: 'id', 
                    width: 120,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container"><div class="btn-group" uib-dropdown dropdown-append-to-body><button id="btn-append-to-body" type="button" class="btn btn-danger btn-grid" uib-dropdown-toggle ng-disabled="isSaving || !row.entity.id || row.entity.isVoid"><span class="mr-4 glyphicon glyphicon-wrench"></span><span translate="entity.action.opEnter">opEnter</span><span class="caret"></span></button><ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="btn-append-to-body"><li><a href="" ng-click="grid.appScope.editCargo(row.entity)"><span class="mr-4 glyphicon glyphicon-edit"></span><span translate="entity.action.amendation">amendation</span></a></li><li role="separator" class="divider"></li><li><a href="" ng-click="grid.appScope.WMS_LOGS_POP(row.entity.companyId, row.entity.id)"><span class="mr-4 glyphicon glyphicon-eye-open"></span><span translate="autopsApp.wmsCargo.viewLog">viewLog</span></a></li><li role="separator" class="divider"></li><li><a href="" ng-click="grid.appScope.antiIn(row.entity)"><span class="mr-4 glyphicon glyphicon-log-out"></span><span translate="autopsApp.wmsCargo.antiIn">antiIn</span></a></li><li role="separator" class="divider"></li><li><a href="" ng-click="grid.appScope.cargoMove(row.entity)"><span class="mr-4 glyphicon glyphicon-transfer"></span><span translate="global.menu.entities.moveShelf">movement</span></a></li><li role="separator" class="divider"></li><li><a href="" ng-click="grid.appScope.delCargo(row.entity)"><span class="mr-4 glyphicon glyphicon-remove"></span><span translate="entity.action.delete">delete</span></a></li></ul></div><button type="button" ng-click="grid.appScope.removeCargo(row.entity)" class="btn btn-default btn-grid"><span class="glyphicon glyphicon-remove"></span></button></div>'},
                { field: 'clientName', 
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.clientName')},
                { field: 'sku', 
                    displayName: "SKU", 
                    minWidth:150},
                { field: 'cargoName', 
                    displayName: $translate.instant('autopsApp.wmsIn.cargoName'), 
                    minWidth:100},
                { field: 'cargoNameEn', 
                    displayName: $translate.instant('autopsApp.wmsIn.cargoNameEn'), 
                    minWidth:100},
                { field: 'mark', 
                    displayName: $translate.instant('autopsApp.wmsIn.mark'), 
                    minWidth:100},
                { field: 'id', 
                    displayName: $translate.instant('global.cloudFiles'), 
                    width:100,
                    enableSorting: false,
                    enableFiltering: false,
                    cellTemplate: '<div id="btnsId"class="btn-group flex-btn-group-container mr-5"ng-if="row.entity.id"><div class="btn-group"uib-dropdown dropdown-append-to-body><button id="btn-append-to-body"type="button"class="btn btn-link btn-grid"uib-dropdown-toggle ng-click="row.entity.files=[];grid.appScope.LOADFILES(row.entity.files, \'/wmsCargo/\' + row.entity.id + \'/\', row.entity.companyId)"><span class="glyphicon glyphicon-cloud"></span><span translate="global.cloudFiles">cloudFiles</span><span class="caret"></span></button><ul class="dropdown-menu"uib-dropdown-menu role="menu"aria-labelledby="btn-append-to-body"><li><a href=""ngf-select ngf-change="grid.appScope.UPLOADFILE($file, \'/wmsCargo/\' + row.entity.id + \'/\', row.entity.files, row.entity.companyId)"><span class="glyphicon glyphicon-cloud-upload"></span>&nbsp;<span translate="global.uploadFiles">uploadFiles</span></a></li><li role="separator"class="divider"ng-if="row.entity.files"></li><li ng-repeat="file in row.entity.files"><a href=""ng-click="grid.appScope.PDFPOP(file.fileurl,file.filename)"><span class="glyphicon glyphicon-cloud-download"></span>&nbsp;{{file.filename}}</a></li></ul></div></div>'},
                { field: 'status', 
                    displayName: $translate.instant('autopsApp.wmsIn.status'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [ 
                        { value: 'OK', label: 'OK'}, 
                        { value: 'NO', label: 'NO'}, 
                        { value: 'HD', label: 'HD'}]
                    },
                    minWidth:100},  
                { field: 'locationType', 
                    displayName: $translate.instant('autopsApp.wmsIn.locationType'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.LOCATION_TYPES
                    },
                    cellTemplate: '<span translate="global.locationTypes.{{row.entity.locationType}}" ng-if="row.entity.locationType"></span>',
                    minWidth:100}, 
                { field: 'inAction',
                    displayName: $translate.instant('autopsApp.wmsCargo.actionType'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: 'moveIn', label: $translate.instant('autopsApp.wmsCargo.action.moveIn')},
                        { value: 'registerIn', label: $translate.instant('autopsApp.wmsCargo.action.registerIn')}]
                    },
                    minWidth:100,
                    cellTemplate: '<span class="ml-5" translate="autopsApp.wmsCargo.action.{{row.entity.inAction}}" ng-if="row.entity.inAction"></span>'},
                { field: 'appAmt', 
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    cellClass:'grid-primary',
                    displayName: $translate.instant('autopsApp.wmsIn.inNum'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'availableAmt', 
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    cellClass:'grid-primary',
                    displayName: $translate.instant('autopsApp.wmsIn.availableAmt'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'inventoryAmt', 
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    cellClass:'grid-primary',
                    displayName: $translate.instant('autopsApp.wmsIn.inventoryAmt'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'inTransitAmt', 
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    cellClass:'grid-primary',
                    displayName: $translate.instant('autopsApp.wmsIn.inTransitAmt'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},   
                { field: 'forOutbound', 
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    cellClass:'grid-primary',
                    displayName: $translate.instant('autopsApp.wmsIn.forOutbound'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'wmsInNum', 
                    displayName: $translate.instant('autopsApp.wmsIn.warehousingNumber'), 
                    minWidth:130},
                { field: 'gateInTime', 
                    displayName: $translate.instant('autopsApp.wmsIn.gateInTime'), 
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.gateInTime | date:\'medium\'}}">{{row.entity.gateInTime | date:\'mediumDate\'}}</span>',
                    minWidth:130},                  
                { field: 'inventoryDays', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.inventoryDays'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'chargeDays', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.chargeDays'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'code', 
                    displayName: $translate.instant('autopsApp.wmsIn.code'), 
                    minWidth:100},
                { field: 'palletizedAt', 
                    displayName: $translate.instant('autopsApp.wmsIn.BoxNo'), 
                    minWidth:100},                         
                { field: 'fnSku', 
                    displayName: "FnSku", 
                    minWidth:100},            
                { field: 'asin', 
                    displayName: "Asin", 
                    minWidth:100},          
                { field: 'upc', 
                    displayName: "upc", 
                    minWidth:100},
                { field: 'packageType', 
                    displayName: $translate.instant('autopsApp.wmsCargo.pkgsType'), 
                    minWidth:100},
                { field: 'warehouseName', 
                    displayName: $translate.instant('autopsApp.wmsIn.warehouseName'), 
                    minWidth:100},    
                { field: 'location', 
                    displayName: $translate.instant('autopsApp.wmsIn.location'), 
                    minWidth:100},                    
                { field: 'truckNum', 
                    displayName: $translate.instant('autopsApp.wmsIn.truckNum'), 
                    minWidth:100},
                { field: 'ctnNum', 
                    displayName: $translate.instant('autopsApp.wmsIn.ctnNum'), 
                    minWidth:100},
                { field: 'poNum', 
                    displayName: $translate.instant('autopsApp.wmsIn.poNum'), 
                    minWidth:100},
                { field: 'podName', 
                    displayName: $translate.instant('autopsApp.wmsIn.podName'), 
                    minWidth:100},                
                { field: 'batchOne', 
                    displayName: $translate.instant('autopsApp.wmsIn.batch') + 1, 
                    minWidth:100},
                { field: 'batchTwo', 
                    displayName: $translate.instant('autopsApp.wmsIn.batch') + 2, 
                    minWidth:100},
                { field: 'batchThree', 
                    displayName: $translate.instant('autopsApp.wmsIn.batch') + 3, 
                    minWidth:100},
                { field: 'batchFour', 
                    displayName: $translate.instant('autopsApp.wmsIn.batch') + 4, 
                    minWidth:100},
                { field: 'gw', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.gw'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'length', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.length'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'width', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.width'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'height', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.height'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'vol', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.vol'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'gwTtl', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.gwTtl'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'volTtl', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.volTtl'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'piece', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.piece'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'pieceTtl', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.pieceTtl'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'pieceAvaTtl', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.pieceAvaTtl'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'pieceBalTtl', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.pieceBalTtl'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'billDate', 
                    displayName: $translate.instant('autopsApp.wmsCargo.billDate'), 
                    minWidth:100},
                { field: 'keeper', 
                    displayName: $translate.instant('autopsApp.wmsCargo.keeper'), 
                    minWidth:100},
                { field: 'returnTracking', 
                    displayName: $translate.instant('autopsApp.wmsIn.returnTracking'), 
                    minWidth:100},
                { field: 'goodsValue', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.goodsValue'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'declareValue', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.declareValuePh'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'shareCost', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.shareCost'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'ttlCost', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.ttlCost'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},                    
                { field: 'chargeType', 
                    displayName: $translate.instant('autopsApp.wmsIn.chargeType'), 
                    minWidth:100},
                { field: 'chargePrice', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.chargePrice'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'remarks', 
                    displayName: $translate.instant('autopsApp.wmsCargo.remarks'), 
                    minWidth:100}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 123;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "inventory";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "inventory";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi; 
                // $scope.gridApi.grouping.on.groupingChanged($scope, function(col){
                //     initContent();
                // });
                gridApi.selection.on.rowSelectionChanged($scope,function(row){
                    onSelectionChanged();
                });
                gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                    onSelectionChanged();
                });
             }
        }

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 123
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                initContent();
            })
        });
        $scope.selectedRows;
        function onSelectionChanged(){
            $scope.selectedRows = $scope.gridApi.selection.getSelectedRows().length;
        }
        $scope.delAllChecked = function() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            for(var i = $scope.gridOptions.data.length -1; i>= 0; i--){
                var isSelected = false;
                angular.forEach(selectedRows, function(data){
                    if(angular.equals(data, $scope.gridOptions.data[i])){
                        isSelected = true;
                    }
                })
                if(isSelected){
                    $scope.gridOptions.data.splice(i, 1);
                }
            }
            $timeout(function (){
                onSelectionChanged();
            });
            
        }

        $scope.delAllUnChecked = function() {
            $scope.gridOptions.data = $scope.gridApi.selection.getSelectedRows();
            onSelectionChanged();
        }

        $scope.cargoMove = function(cargo) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(!cargo && selectedRows.length == 0){
                layer.msg($translate.instant('autopsApp.wmsIn.noInventorySelected'));
                return;
            }
            $rootScope.CARGO_MOVE_SHELF(cargo, selectedRows, vm.locations);
        }

        $scope.editCargo = function(cargo) {  
            if ($rootScope.account.authorities.indexOf("AU_WMS_AMENDMENT") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AMENDMENT');    
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-cargo/wms-cargo-detail.html',
                controller: 'WmsCargoDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return angular.copy(cargo);
                    }],
                }
            }).result.then(function(result) {
                DataUtils.afterSaveWmsCargo(cargo, result);
                initContent();
            }, function(result) {
            });
        }

        $scope.antiIn = function(cargo) {  
            if($rootScope.HAVENOAUTH('AU_WMS_ANTI')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_ANTI');    
                return;
            }
            layer.confirm($translate.instant('autopsApp.wmsCargo.antiInTips'), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    WmsCargo.antiIn({id:cargo.id}, function(result) {
                        var index = $scope.gridOptions.data.indexOf(cargo);
                        if (index > -1) {
                            $scope.gridOptions.data.splice(index, 1);
                        };
                        $translate.refresh();  
                    }, function(result){
                        if(result.status == 409){
                            layer.msg($translate.instant('autopsApp.wmsCargo.lockedAlert'));  
                        }
                        else{
                            layer.msg($translate.instant('global.refreshAlert'));   
                        }                  
                    });          
                    layer.close(index);
                }, function() {
            });
        }

        $scope.excelExport = function(dataList) {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.dadianWmsInventory({
                enCn: $translate.instant('global.enCn')
            }, $scope.gridOptions.data, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('global.menu.admin.profitStats') + ".xlsx");
            })
        }    
        
        function wmsCargoImport(file, saveRequired) {
            if (!file || !$rootScope.account.companyId) { return; }
            var fd = new FormData();
            fd.append('file', file);
            fd.append('saveRequired', saveRequired);            
            fd.append('companyId', $rootScope.account.companyId);
            $http.post(
                'api/wms-cargos/wmsCargoImport', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                data = angular.fromJson(data);
                if (!data || data.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                afterLoadData(data, true);
                layer.msg($translate.instant('global.importSuccess'));
            }).error(function(error) {
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });
        };


        function saveWmsCargoList() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('autopsApp.wmsIn.noInventorySelected'));
                return;
            }
            WmsCargo.saveWmsCargoList({outDto: true}, selectedRows, function(data) {
                afterLoadData(data, true);
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(){
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        }

        function notSaved() {
            var notSave = false;
            angular.forEach($scope.gridOptions.data, function(data){
                if(!data.id){
                    notSave = true;
                }
            });
            return notSave;
        }

        function fifoRegistIn() {
            if(notSaved()){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('autopsApp.wmsIn.noInventorySelected'));
                return;
            }
            for(var i = 0; i < selectedRows.length; i++){
                if(selectedRows[i].allOut){
                    $rootScope.OPS_ALERT(selectedRows[i].wmsInNum + ": " + $translate.instant('autopsApp.wmsIn.cargoInAlert'));
                    return;
                }
                if(!selectedRows[i].location){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.locationNullAlert'));
                    return;
                }
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.fiAlert'), {
                icon: 3,
                title: "Alert",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                WmsCargo.fifoRegistIn(selectedRows, function(data) {
                    afterLoadData(data, true);
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function(){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                })
            }, function() {});
        }
        
        function inventoryForceOut() {
            if(notSaved()){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('autopsApp.wmsIn.noInventorySelected'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.inventoryForceOutAlert', {param: selectedRows.length}), {
                icon: 3,
                title: "Alert",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                WmsCargo.inventoryForceOut(selectedRows, function(data) {
                    afterLoadData(data, true);
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function(){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                })
            }, function() {});
        }


        function createWmsIn(isOut) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('autopsApp.wmsIn.noInventorySelected'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.createJobTips'), {
                icon: 3,
                title: "Alert",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                WmsCargo.createWmsIn({isOut: isOut}, selectedRows, function(data) {
                    afterLoadData(data, true);
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function(){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                })
            }, function() {});
        }

        $scope.delCargo = function (cargo) {
            if (cargo.id) {
                layer.confirm($translate.instant('global.confirmToDelete'), {
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.confirm'),$translate.instant('global.cancel')],
                    icon: 3
                }, function(index) {
                    layer.close(index);
                    WmsCargo.delete({
                        id: cargo.id
                    }, function(result) {
                        removeOne(cargo);
                        layer.msg($translate.instant('global.delete_success'));
                    }, $rootScope.ALERT_ERROR);
                },function(index){
                    layer.close(index);
                });                
            }
            else {
                removeOne(cargo);
            }
        };

        $scope.removeCargo = function(cargo) {  
            var index = $scope.gridOptions.data.indexOf(cargo);
            if (index > -1) {
                $scope.gridOptions.data.splice(index, 1);
            };
        }       

        function removeOne(cargo){
            var index = resultsCopy.indexOf(cargo);
            if (index > -1) {
                resultsCopy.splice(index, 1);
            };
            showChange(false);
        }

        function delAll() {          
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('autopsApp.wmsIn.noInventorySelected'));
                return;
            }
            layer.confirm($translate.instant('global.delSelectedAlert', {ttlAmt: selectedRows.length}), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    WmsCargo.delAll(selectedRows, function(result){
                        angular.forEach(selectedRows, function (cargo) {
                            if(!cargo.id || result.failedIdList.indexOf(cargo.id) == -1){
                                var index = resultsCopy.indexOf(cargo);
                                if (index > -1) {
                                    resultsCopy.splice(index, 1);
                                };
                            }                            
                        })
                        showChange(false);  
                        $rootScope.OPS_ALERT($translate.instant('global.delListResult', {
                            successAmt: result.successAmt, 
                            failedAmt: result.failedIdList.length
                        })); 
                        $translate.refresh();
                    }, function(result){
                        layer.msg($translate.instant('global.delete_fail'));
                    })
                    layer.close(index);
                }, function() {
            });
        }

    }
})();
