(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCrmsController', OpsCrmsController);

    OpsCrmsController.$inject = ['$scope', '$state', '$rootScope', '$translate', '$uibModal', '$http', '$timeout',
    'WinAES', 'AlertService', 'OpsFiles', 'Principal','OpsVender','OpsVenderSearch', 'uiGridConstants', 'GridState', 'DataUtils'];

    function OpsCrmsController($scope, $state, $rootScope, $translate, $uibModal, $http, $timeout,
        WinAES, AlertService, OpsFiles, Principal, OpsVender, OpsVenderSearch, uiGridConstants, GridState, DataUtils) {


        if (!$rootScope.filterType){
            $rootScope.filterType = "self";
        }

        var vm = this;
        vm.total = {};

        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.page = 1;
        vm.itemsPerPage = 20;

        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = null;
        vm.currentSearch = null;

        vm.isAdvanceSearch = false;

        $scope.setFilter = function(type) {
            if (type == "all" && $rootScope.account.authorities.indexOf("FUN_VIEW_ALL_CLIENTS") == -1
                && $rootScope.account.authorities.indexOf("ROLE_SALES") == -1){
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_ALL_CLIENTS');
                return;
            }
            $rootScope.filterType = type;
            vm.clear();
        };

        var gridCopy;
        var pageLoaded = false;
        var filterTimer;
        function loadAll() {
            if (vm.searchQuery) {
                OpsVenderSearch.query({
                    venderType: 'crm',
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                filter.venderType = "crm";
                filter.filterType = $rootScope.filterType;
                if($rootScope.filterType == "pub" && $rootScope.HAVEAUTH("FB_PUB_CLIENT")){
                    layer.msg($translate.instant('global.forbidden'));
                    return;
                }
                if(gridCopy){
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term != null && angular.isDefined(cf.term)){
                                if(cf.condition){
                                    var fd = column.field;
                                    if(fd.endWith("Str")){
                                        fd = fd.replace("Str", "");
                                    }
                                    if(cf.condition == 128 || cf.condition == 256){ //" < ",
                                        if(column.field == "checkDate"){
                                            filter[fd + "TO"] = DateUtils.convertLocalDateToServer(cf.term);
                                        }
                                        else {
                                            filter[fd + "TO"] = cf.term;
                                        }
                                    }
                                    else if(cf.condition == 32 || cf.condition == 64){ //" > ",
                                        if(column.field == "checkDate"){
                                            filter[fd + "FM"] = DateUtils.convertLocalDateToServer(cf.term);
                                        }
                                        else{
                                            filter[fd + "FM"] = cf.term;
                                        }
                                    }
                                }
                                else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                OpsVender.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [(vm.predicate.endWith('TAG')?vm.predicate.replace('TAG', ''):vm.predicate) + ',' + vm.direction];
                return result;
            }

            function onSuccess(data, headers) {
                vm.isAdvanceSearch = false;
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                initData();
                resize();
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
            }

            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            loadAll();
        }
        function clear() {
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction =  'desc';
            vm.searchQuery = vm.currentSearch = null;
            vm.isAdvanceSearch = false;
            loadAll();
        }
        loadAll();


        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 600){
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function initData() {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = vm.isAdvanceSearch;

            angular.forEach($scope.gridOptions.data, function(data){
                data.encodeId = WinAES.encode(data.id.toString());
                data.isPub = !data.creater;
            })
            DataUtils.addVenderTag($scope.gridOptions.data);
        }
        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuExcel : false,

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'id',
                    width: 150,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container ml-5"><button type="button"ng-click="grid.appScope.edit(row.entity)"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-modal-window"></span><span class="hidden-xs hidden-sm"translate="entity.action.edit"></span></button><button type="button "ng-click="grid.appScope.delete(row.entity) "class="btn btn-danger btn-grid"ng-disabled="grid.appScope.account.auth>2"><span class="glyphicon glyphicon-remove-circle"></span><span class="hidden-xs hidden-sm"translate="entity.action.delete"></span></button></div>'},
                { field: 'code',
                    displayName: $translate.instant('autopsApp.opsClient.code'),
                    minWidth:150,
                    cellTemplate: '<a class="ml-5"href=""ng-click="grid.appScope.edit(row.entity)">{{row.entity.code}}</a><span class="label label-success"ng-hide="row.entity.creater"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.opsClient.pubClient\' | translate}}">PUB</span>&nbsp;<span class="label label-primary"ng-if="row.entity.cooperated"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.opsClient.developedRecords\' | translate}}">CO</span>&nbsp;<span class="label label-danger"ng-if="row.entity.companyId != grid.appScope.account.companyId"data-toggle="tooltip"data-placement="top"title="{{row.entity.branch}}">G</span>'},
                { field: 'companyName',
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.opsClient.companyName')},
                { field: 'companyNameEn',
                    minWidth:180,
                    visible:false,
                    displayName: $translate.instant('autopsApp.opsClient.companyNameEn')},
                { field: 'creater',
                    displayName: $translate.instant('autopsApp.opsClient.creater'),
                    minWidth:150},
                { field: 'branch',
                    displayName: $translate.instant('autopsApp.opsClient.branch'),
                    minWidth:150},
                { field: 'team',
                    displayName: $translate.instant('autopsApp.opsClient.team'),
                    minWidth:150},
                { field: 'createdTime',
                    minWidth:200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsClient.createdTime'),
                    cellTemplate: '<span>{{row.entity.createdTime | date:\'medium\'}}</span>'},
                { field: 'audited',
                    minWidth:130,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: true, label: $translate.instant('autopsApp.opsClient.audited')},
                        { value: false, label: $translate.instant('autopsApp.opsClient.notAudited')}]
                    },
                    displayName: $translate.instant('autopsApp.opsClient.checkStatus'),
                    cellTemplate: '<span class="ml-5"><span class="label label-primary"translate="autopsApp.opsClient.audited"ng-if="row.entity.audited"ng-click="grid.appScope.setAudited(row.entity)">audited</span><span class="label label-info"translate="autopsApp.opsClient.notAudited"ng-hide="row.entity.audited"ng-click="grid.appScope.setAudited(row.entity)">notAudited</span></span>'},
                { field: 'auditor',
                    displayName: $translate.instant('autopsApp.opsClient.auditor'),
                    minWidth:150},
                { field: 'isPub',
                    minWidth:60,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isPublic'),
                    cellTemplate: '<span ng-class="row.entity.isPub?\'label-danger\':\' label-primary\'"translate="{{row.entity.isPub?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'userIdTAG',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.openAccount'),
                    cellTemplate: '<span ng-class="row.entity.userIdTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.userIdTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isLocked',
                    minWidth:130,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: true, label: $translate.instant('autopsApp.opsClient.isLocked')},
                        { value: false, label: $translate.instant('autopsApp.opsClient.notLocked')}]
                    },
                    displayName: $translate.instant('autopsApp.opsClient.lockStatus'),
                    cellTemplate: '<span class="ml-5"><span class="label label-primary"translate="autopsApp.opsClient.isLocked"ng-if="row.entity.isLocked"ng-click="grid.appScope.setlocked(row.entity)">isLocked</span><span class="label label-info"translate="autopsApp.opsClient.notLocked"ng-hide="row.entity.isLocked"ng-click="grid.appScope.setlocked(row.entity)">notLocked</span></span>'},
                { field: 'dontRoll',
                    minWidth:60,
                    visible:false,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.dontRoll'),
                    cellTemplate: '<span ng-class="row.entity.dontRoll?\'label-primary\':\' label-danger\'"translate="{{row.entity.dontRoll?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'nextAction',
                    displayName: $translate.instant('autopsApp.opsCrm.nextAction'),
                    minWidth:150},
                { field: 'nextTime',
                    minWidth:200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsClient.nextTime'),
                    cellTemplate: '<span>{{row.entity.nextTime | date:\'medium\'}}</span>'},
                { field: 'saleSchedule',
                    displayName: $translate.instant('autopsApp.opsClient.saleSchedule'),
                    minWidth:150,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: '25', label: "25%"},
                        { value: '50', label: "50%"},
                        { value: '75', label: "75%"},
                        { value: '100', label: "100%"}]
                    },
                    cellTemplate: '<strong class="ml-5" ng-if="row.entity.saleSchedule">{{row.entity.saleSchedule}}%</strong>'},
                { field: 'role',
                    displayName: $translate.instant('autopsApp.opsClient.role'),
                    minWidth:120,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CLIENT_TYPES
                    },
                    cellTemplate: '<span class="ml-5" translate="autopsApp.opsClient.{{row.entity.role}}" ng-if="row.entity.role">role</span>'},
                { field: 'bizVol',
                    displayName: $translate.instant('autopsApp.opsClient.bizVol'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: 'small', label: $translate.instant('autopsApp.opsClient.small')},
                        { value: 'middle', label: $translate.instant('autopsApp.opsClient.middle')},
                        { value: 'big', label: $translate.instant('autopsApp.opsClient.big')}]
                    },
                    cellTemplate: '<span class="ml-5" ng-if="row.entity.bizVol" translate="autopsApp.opsClient.{{row.entity.bizVol}}"></span>'},
                { field: 'clientSource',
                    minWidth:180,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CLIENT_SOURCES
                    },
                    displayName: $translate.instant('autopsApp.opsClient.clientSource'),
                    cellTemplate: '<span class="ml-5" ng-if="row.entity.clientSource" translate="autopsApp.opsClient.{{row.entity.clientSource}}"></span>'},
                { field: 'industry',
                    displayName: $translate.instant('autopsApp.opsClient.industry'),
                    minWidth:150},
                { field: 'location',
                    displayName: $translate.instant('autopsApp.opsClient.location'),
                    minWidth:150},
                { field: 'lastModifiedTime',
                    minWidth:200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsClient.lastModifiedTime'),
                    cellTemplate: '<span>{{row.entity.lastModifiedTime | date:\'medium\'}}</span>'},
                { field: 'lastModifiedBy',
                    displayName: $translate.instant('autopsApp.opsClient.lastModifiedBy'),
                    minWidth:150},
                { field: 'fcl',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: "FCL",
                    cellTemplate: '<span ng-class="row.entity.fcl?\'label-primary\':\' label-danger\'"translate="{{row.entity.fcl?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'lcl',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: "LCL",
                    cellTemplate: '<span ng-class="row.entity.lcl?\'label-primary\':\' label-danger\'"translate="{{row.entity.lcl?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'air',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: "AIR",
                    cellTemplate: '<span ng-class="row.entity.air?\'label-primary\':\' label-danger\'"translate="{{row.entity.air?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'local',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.localBiz'),
                    cellTemplate: '<span ng-class="row.entity.local?\'label-primary\':\' label-danger\'"translate="{{row.entity.local?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'booking',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.bookingBiz'),
                    cellTemplate: '<span ng-class="row.entity.booking?\'label-primary\':\' label-danger\'"translate="{{row.entity.booking?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'fba',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.fbaBiz'),
                    cellTemplate: '<span ng-class="row.entity.fba?\'label-primary\':\' label-danger\'"translate="{{row.entity.fba?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'specialCtn',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.specialCtn'),
                    cellTemplate: '<span ng-class="row.entity.specialCtn?\'label-primary\':\' label-danger\'"translate="{{row.entity.specialCtn?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'bulk',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.bulk'),
                    cellTemplate: '<span ng-class="row.entity.bulk?\'label-primary\':\' label-danger\'"translate="{{row.entity.bulk?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'truck',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.truck'),
                    cellTemplate: '<span ng-class="row.entity.truck?\'label-primary\':\' label-danger\'"translate="{{row.entity.truck?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'importBiz',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.importBiz'),
                    cellTemplate: '<span ng-class="row.entity.importBiz?\'label-primary\':\' label-danger\'"translate="{{row.entity.importBiz?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'parcel',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.parcel'),
                    cellTemplate: '<span ng-class="row.entity.parcel?\'label-primary\':\' label-danger\'"translate="{{row.entity.parcel?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'wms',
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.wms'),
                    cellTemplate: '<span ng-class="row.entity.wms?\'label-primary\':\' label-danger\'"translate="{{row.entity.wms?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 75;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }

                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if(pageLoaded){
                        loadAll();
                    }
                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            if($rootScope.HAVENOAUTH('CRM')){
                $state.go('home');
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 75
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if(gridCopy){
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term || cf.term == false){
                                filterRequired = true;
                            }
                        })
                    })
                    if(filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });

        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        $scope.quotation = function () {
            $rootScope.id = 1;
            $state.go('opsCrm.quotation');
        }
        var onDeleteSuccess = function (result) {
            layer.msg($translate.instant('global.delete_success'));
            loadAll();
        };

        var onDeleteError = function (result) {
            if (result.status === 403){
                layer.msg($translate.instant('global.delete_forbidden'));
            }
            else if (result.status === 409){
                layer.msg($translate.instant('autopsApp.opsClient.lockedAlert'));
            }
            else if (result.status === 406){
                layer.msg($translate.instant('autopsApp.opsClient.delContactForbidden'));
            }
            else{
                layer.msg($translate.instant('global.delete_fail'));
            }
        };

        $scope.delete = function (obj) {
            if ($rootScope.account.companyId !=  obj.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }
            if ($rootScope.HAVENOAUTH('DELETE_STMT_OBJ')){
                $rootScope.FORBIDDEN_BY_ALERT('DELETE_STMT_OBJ');
                return;
            }
            layer.confirm(obj.companyName + $translate.instant('autopsApp.opsClient.delete.question'), {
                title: $translate.instant('autopsApp.opsClient.delete.title'),
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
            }, function(index) {
                layer.close(index);
                OpsVender.delete({id: obj.id}, onDeleteSuccess, onDeleteError);
            }, function(index) {
                layer.close(index);
            });
        }

        $scope.edit = function (client) {
            if (!client){
                var opsVender = {};
                opsVender.cooperated = false;
                opsVender.companyName = $scope.newName;
                opsVender.isClient = true;
                opsVender.opsCrms = [{}];
                opsVender.opsConcernLanes = [];
                opsVender.contactList = [];
                openClientEditPop(opsVender);
            }
            else {
                OpsVender.getCrm({id: client.id}, function(result) {
                    if (!result.opsCrms || result.opsCrms.length == 0){
                        result.opsCrms = [{}];
                    }
                    if (!result.opsConcernLanes){
                        result.opsConcernLanes = [];
                    }
                    if (!result.contactList){
                        result.contactList = [];
                    }
                    openClientEditPop(result);
                });
            }
        }

        var openClientEditPop = function(opsVender) {
            $uibModal.open({
                templateUrl: 'app/entities/opsClient/opsClient-detail.html',
                controller: 'OpsClientDetailController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return opsVender;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        $translatePartialLoader.addPart('opsCrm');
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('accountInvoice');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                var isNew = true;
                angular.forEach($scope.gridOptions.data, function(data){
                    if (data.id == result.id){
                        angular.copy(result, data);
                        isNew = false;
                    }
                })
                if (isNew){
                    $scope.gridOptions.data.splice(0, 0, result);
                }

            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        $scope.deleteAll = function (obj) {
            if ($rootScope.HAVENOAUTH('DELETE_STMT_OBJ')){
                $rootScope.FORBIDDEN_BY_ALERT('DELETE_STMT_OBJ');
                return;
            }
            layer.confirm($translate.instant('autopsApp.opsClient.delete.deleteAllQuestion'), {
                title: $translate.instant('autopsApp.opsClient.delete.title'),
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
            }, function(index) {
                layer.close(index);
                OpsVender.deleteAll({}, $scope.gridOptions.data, onDeleteSuccess, onDeleteError);
            }, function(index) {
                layer.close(index);
            });
        }

        $scope.repeatNames = {};
        $scope.newName = "";
        var inputPopIndex = 0;
        $scope.createNew = function () {
            if ($rootScope.HAVENOAUTH('CREATE_STMT_OBJ')){
                $rootScope.FORBIDDEN_BY_ALERT('CREATE_STMT_OBJ');
                return;
            }
            $scope.newName = "";
            inputPopIndex = layer.open({
                type: 1,
                area: ['380px', '170px'],
                title: $translate.instant("autopsApp.opsClient.checkRepeat"),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#checkRepeatPop")
            });
            document.getElementById("checkRepeat_field").focus();
        }

        $scope.checkRepeat = function () {
            $scope.isSaving = true;
            var queryString = "";
            var reg = /^[\u4e00-\u9fa5]/;
            var queryString = angular.copy($scope.newName);
            if(!reg.test($scope.newName)){
                queryString = "*"+angular.copy($scope.newName)+"*";
            }
            queryString = queryString.replace(/\s+/g, "%");

            OpsVender.checkRepeat({
                companyId: $rootScope.account.companyId,
                newName: queryString
            }, function(result) {
                layer.closeAll();
                $scope.isSaving = false;
                if (!result || result.length == 0){
                    layer.closeAll();
                    layer.msg($translate.instant('autopsApp.opsClient.checkPassed'));
                    $scope.edit();
                }
                else {
                    $scope.repeatNames = result;
                    layer.open({
                        type: 1,
                        area: ['780px', '380px'],
                        moveOut: false,
                        title: $scope.newName + ": " + $translate.instant("autopsApp.opsClient.checkResultTips"),
                        shade: 0.3,
                        moveType: 0,
                        shift: 0,
                        content: $("#checkResultPop"),
                        btn: [$translate.instant('entity.action.ignore'), $translate.instant('entity.action.back')],
                        yes: function(index, layero) {
                            layer.close(index);
                            $scope.edit();
                        },
                        cancel: function(index) {
                            layer.close(index);
                        }
                    });
                }
            }, function(response) {
                $scope.isSaving = false;
                if (response.status === 403){
                    layer.msg($translate.instant('autopsApp.opsClient.invalidInput'));
                }

            });
        }

        var isReverse = true;
        $scope.sortClients = function(title) {
            isReverse = !isReverse;
            $scope.gridOptions.data.sort(function(a,b){
                if (isReverse){
                    return a[title]<b[title]?1:-1;
                }
                else {
                    return a[title]>b[title]?1:-1;
                }
            });
        }

        $scope.advanceSearch = function() {
            var searchParams = {
                isFromCrm: true,
                isClient: true,
                venderType: "crm"
            };
            $uibModal.open({
                templateUrl: 'app/entities/ops-crm/ops-crm-dialog.html',
                controller: 'OpsCrmDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                $scope.gridOptions.data = results;
                vm.isAdvanceSearch = vm.currentSearch = true;
                $scope.gridOptions.totalItems = results.length;
                initData();
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                resize();
                getTtl();
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        var getTtl= function() {
            vm.total = {};
            vm.total.ttl = $scope.gridOptions.data.length;
            vm.total.coTtl = 0;
            vm.total.devTtl = 0;
            vm.total.percent = 0;
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.cooperated){
                    vm.total.coTtl++;
                }
                else {
                    vm.total.devTtl++;
                }
            })
            vm.total.percent = vm.total.coTtl/vm.total.ttl*100
            vm.total.percent = vm.total.percent.toFixed(2)-0;
        }
        $scope.setlocked= function(opsClient) {
            if ($rootScope.account.companyId !=  opsClient.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }
            if ($rootScope.HAVENOAUTH('AU_OBJ_LOCK')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_OBJ_AUDIT');
                return;
            }
            var lockedCopy = opsClient.isLocked;
            if(!opsClient.isLocked){
                opsClient.isLocked = true;
            }
            else {
                opsClient.isLocked = false;
            }
            OpsVender.setlocked(opsClient, function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
            },function(result) {
                opsClient.isLocked = lockedCopy;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        };

        $scope.setAudited= function(opsClient) {
            if ($rootScope.account.companyId !=  opsClient.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }
            if ($rootScope.HAVENOAUTH('AU_OBJ_AUDIT')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_OBJ_AUDIT');
                return;
            }
            var lockedCopy = opsClient.audited;
            if(!opsClient.audited){
                opsClient.audited = true;
                opsClient.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            else {
                opsClient.audited = false;
                opsClient.auditor = null;
            }
            OpsVender.setAudited(opsClient, function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
            },function(result) {
                opsClient.audited = lockedCopy;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        };

        $scope.excelImport = function(file) {
            if ($rootScope.HAVENOAUTH('CREATE_STMT_OBJ')){
                $rootScope.FORBIDDEN_BY_ALERT('CREATE_STMT_OBJ');
                return;
            }
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            fd.append('cooperated', false);
            fd.append('venderRole', "client");

            $http.post(
                'api/opsVenders/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    data = angular.fromJson(data);
                    if (data.newCount == 0 && data.repeatCount == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));
                        return;
                    }
                    if(data.newCount>0){
                        loadAll();
                    }
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.opsClient.uploadResult', {
                        repeatCount: data.repeatCount,
                        newCount: data.newCount}));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };

        $scope.shiftAll = function() {
            if ($rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.forbiddenBoss'));
                return;
            }

            $uibModal.open({
                templateUrl: 'app/entities/share-client/share-client-delete-dialog.html',
                controller: 'ShareClientDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return "";
                    }]
                }
            }).result.then(function() {
                loadAll();
            }, function() {

            });
        }
        $scope.excelExport = function() {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = "CRMClients.xlsx";
            OpsFiles.excelOpsVender({
                title: "CRM Clients",
                enCn: $translate.instant('global.enCn')
            }, $scope.gridOptions.data, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }
        var unsubscribe = $rootScope.$on('autopsApp:opsVenderUpdate', function(event, result) {
            if(result.id){
                angular.forEach($scope.gridOptions.data, function(guide){
                    if(guide.id == result.id){
                        angular.copy(result, guide);
                    }
                })
            }
            else {
                loadAll();
            }
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
