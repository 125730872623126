(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressPackageDataController', ExpressPackageDataController);

    ExpressPackageDataController.$inject = ['$scope', '$state', '$timeout', '$translate', '$rootScope', '$uibModal',
        'DataUtils', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WinAES', 'uiGridConstants',
        'GridState', 'ExpressPackage'];

    function ExpressPackageDataController($scope, $state, $timeout, $translate, $rootScope, $uibModal,
                                           DataUtils, ParseLinks, AlertService, pagingParams, paginationConstants, WinAES, uiGridConstants,
                                          GridState, ExpressPackage) {
        var vm = this;
        vm.exportExcel = exportExcel;
        vm.loadPage = loadPage;
        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.page = 1;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAdvanceSearch = false;
        var gridCopy;
        var pageLoaded = true;
        var filterTimer;
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function () {
          //  initGrid();
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 171
            }, function (result) {
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if (!gridStateObj || !gridStateObj.id) {
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if (gridCopy) {
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function (column) {
                        angular.forEach(column.filters, function (cf) {
                            if (cf.term) {
                                filterRequired = true;
                            }
                        })
                    })
                    if (filterRequired) {
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                        return;
                    }
                }
                loadAll();
            })
        });

        function loadAll() {
            var filter = {};
            filter.express = {};
            if (gridCopy) {
                angular.forEach(gridCopy.columns, function (column) {
                    angular.forEach(column.filters, function (cf) {
                        if (cf.term != null && angular.isDefined(cf.term)) {
                            var fd = column.field;
                            if (fd.indexOf("express.") == 0) {
                                fd = fd.replace("express.", "");
                                if (cf.condition) {
                                    if (cf.condition == 128 || cf.condition == 256) {
                                        filter.express[fd + "TO"] = cf.term;
                                    } else if (cf.condition == 32 || cf.condition == 64) {
                                        filter.express[fd + "FM"] = cf.term;
                                    }
                                } else {
                                    filter.express[fd] = cf.term;
                                }
                            } else {
                                if (cf.condition) {
                                    if (cf.condition == 128 || cf.condition == 256) {
                                        filter[fd + "TO"] = cf.term;
                                    } else if (cf.condition == 32 || cf.condition == 64) {
                                        filter[fd + "FM"] = cf.term;
                                    }
                                } else {
                                    filter[fd] = cf.term;
                                }
                            }
                        }
                    })
                })
            }
            ExpressPackage.getFilterPage({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, filter, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + vm.direction];
                return result;
            }

            function onSuccess(data, headers) {
                if (headers('X-Total-Count')) {
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                } else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                vm.isAdvanceSearch = false;
                initData();
                resize();
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        window.onresize = function () {
            resize();
        }

        function resize() {
            if (!$("#gridId") || !$("#gridId").offset()) {
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if (tabHeight > winHeight) {
                tabHeight = winHeight;
            }
            if (tabHeight < 600) {
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function initData() {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.enablePagination = !vm.isAdvanceSearch;
            $scope.gridOptions.enablePaginationControls = !vm.isAdvanceSearch;
         //   initGrid();
        }

        // function initGrid() {
        //     if (!$rootScope.account.companyId) {
        //         for (var index = $scope.gridOptions.columnDefs.length - 1; index >= 0; index--) {
        //             var col = $scope.gridOptions.columnDefs[index];
        //             if (col.field == "guide.customerName" || col.field == "guide.register") {
        //                 $scope.gridOptions.columnDefs.splice(index, 1);
        //             }
        //         }
        //     }
        // }

        $scope.gridOptions = {
            enableSorting: true, //排序
            useExternalSorting: true,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: true, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            paginationPageSizes: [20, 35, 50, 100],
            paginationPageSize: 20,
            useExternalPagination: true,
            enablePagination: true,
            enablePaginationControls: true,

            minimumColumnSize: 70,
            exporterExcelFilename: "ExpressPackage Data",
            columnDefs: [{
                field: 'express.jobNum',
                minWidth: 150,
                displayName: $translate.instant('autopsApp.warehousingData.jobNum') + "(JOB)",
                aggregationType: uiGridConstants.aggregationTypes.count,
                cellTemplate: '<span class="grid-text"><a href="" ng-click="grid.appScope.parcelRapidInByPackage(row.entity.express.shipmentType, row.entity.express.id)">{{row.entity.express.jobNum}}</a></span>'
            }, {
                field: 'express.poNum',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.booking.poNum') + "(JOB)"
            },{
                field: 'express.soNum',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.booking.soNum') + "(JOB)"
            }, {
                field: 'express.warehouseName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.wmsIn.warehouseName') + "(JOB)"
            }, {
                field: 'express.shipperName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.wmsIn.clientName') + "(JOB)"
            }, {
                field: 'express.remarks',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.expressParcel.remarks')
            }, {
                field: 'cargoName',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.wmsIn.cargoName')
            }, {
                field: 'sku',
                minWidth: 100,
                displayName: "SKU"
            }, {
                field: 'pkgs',
                minWidth: 70,
                type: 'number',
                aggregationType: uiGridConstants.aggregationTypes.sum,
                filters: [
                    {
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                        placeholder: ' >= '
                    },
                    {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                        placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.wmsIn.pkgs')
            }, {
                field: 'gw',
                type: 'number',
                filters: [
                    {
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                        placeholder: ' >= '
                    },
                    {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                        placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.gw'),
                minWidth: 100
            }, {
                field: 'vol',
                type: 'number',
                minWidth: 100,
                filters: [
                    {
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                        placeholder: ' >= '
                    },
                    {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                        placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.vol')
            }, {
                field: 'length',
                type: 'number',
                minWidth: 70,
                filters: [
                    {
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                        placeholder: ' >= '
                    },
                    {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                        placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.length')
            }, {
                field: 'width',
                type: 'number',
                minWidth: 70,
                filters: [
                    {
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                        placeholder: ' >= '
                    },
                    {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                        placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.width')
            },
                {
                    field: 'height',
                    type: 'number',
                    minWidth: 70,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.warehousingData.height')
                },  {
                    field: 'gwTtl',
                    minWidth: 100,
                    type: 'number',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.warehousingData.gwTtl')
                }, {
                    field: 'volTtl',
                    minWidth: 100,
                    type: 'number',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.warehousingData.volTtl')
                }],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function ($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 171;
                    GridState.update(gridStateObj, function (result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function (error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function ($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function ($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function (row) {
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "warehousingData";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "warehousingData";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                    if (!$scope.gridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function (a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function () {
                    gridCopy = this.grid;
                    if (pageLoaded && $scope.gridOptions.useExternalFiltering) {
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function () {
                            loadAll();
                        }, 1000); //查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if (pageLoaded) {
                        loadAll();
                    }
                });
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + 'desc',
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction = 'desc';
            vm.currentSearch = null;
            vm.isAdvanceSearch = false;
            vm.transition();
            loadAll();
        }

        $scope.searchRequestPop = function () {
            var searchParams = {};
            searchParams.express = {};
            $uibModal.open({
                templateUrl: 'app/entities/express-package/express-package-data-search.html',
                controller: 'ExpressPackageDataSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function () {
                        return searchParams;
                    }]
                }
            }).result.then(function (results) {
                $scope.gridOptions.data = results;
                vm.isAdvanceSearch = vm.currentSearch = true;
                initData();
                $scope.gridOptions.totalItems = results.length;
                $timeout(function () {
                    resize();
                });

            }, function () {
            });
        }

        function exportExcel() {
            if (!$scope.gridOptions.data || $scope.gridOptions.data.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = "expressPackageData.xlsx";
            ExpressPackage.exportExpressPackages($scope.gridOptions.data, function (result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }

        $scope.deleteOne = function (row) {
            var index = $scope.gridOptions.data.indexOf(row);
            $scope.gridOptions.data.splice(index, 1);
        }
    }

})();
