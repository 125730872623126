(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('ChargesReportController', ChargesReportController);

	ChargesReportController.$inject = ['$scope', '$rootScope', '$translate', '$timeout', '$uibModal', 
    'Principal',  'OpsVender', 'GuideRates', 'OpsFiles', 'GridState', 'uiGridConstants', 'uiGridGroupingConstants', 
    'DateUtils', 'DataUtils', 'AsyncTask'];

	function ChargesReportController($scope, $rootScope, $translate, $timeout, $uibModal,
        Principal, OpsVender, GuideRates, OpsFiles, GridState, uiGridConstants, uiGridGroupingConstants, 
        DateUtils, DataUtils, AsyncTask) {
		
		$scope.statParams = {byItemName: true};
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
		//----------统计报表
        $scope.files = [];
		$scope.dayFormat = "yyyy-MM-dd";
		var getStatParams = function() {
			if (!$scope.statParams.payer){
				$scope.statParams.venderId = null;
			}
			if (!$scope.statParams.item){
				$scope.statParams.itemId = null;
			}
			if (!$scope.statParams.agentName){
				$scope.statParams.deliveryId = null;
			}
			if (!$scope.statParams.coloaderName){
				$scope.statParams.bookingToId = null;	
			}
			if (!$scope.statParams.clientName){
				$scope.statParams.shipperId = null;
			}
			if (!$scope.statParams.customerName){
				$scope.statParams.customerId = null;
			}
            if (!$scope.statParams.salesNamePH){
                $scope.statParams.salesId = null;
            }
            $scope.statParams.companyId = $rootScope.account.companyId;
		}
		$scope.isSaving = false;

		$scope.getContents = function() {			
            var diffDays = DateUtils.getDaysDiff($scope.statParams.etdFM, $scope.statParams.etdTO);
            if (diffDays < 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            // if (diffDays > 92) {
            //     layer.msg($translate.instant('global.messages.etdPeriodQuarterAlert'));
            //     return;
            // }
			getStatParams();			
            layer.load(1, {shade: 0.3});
			GuideRates.chargesReport($scope.statParams, onLoadDataSuccess, $rootScope.ALERT_ERROR);
		}

        var onLoadDataSuccess = function(result){
            $scope.isSaving = false;
            layer.closeAll(); 
            if (!result || result.length == 0) {
                $scope.gridOptions.data = [];
                initContent();
                layer.msg($translate.instant('stats.stat.noData'));
                return;
            }
            if (result.length > 10000) {
                layer.confirm($translate.instant('global.dataSizeAlert', {amt: result.length}), {
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    $scope.excelExport(result);
                    layer.close(index);
                }, function() {
                    $scope.gridOptions.data = result;   
                    resize();           
                    initContent(); 
                });
            }
            else {
                $scope.gridOptions.data = result;   
                resize();           
                initContent(); 
            }
        }

        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 680){
                tabHeight = 680;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        
        $scope.totalStr = "";
        $scope.payTtlStr = "";
        $scope.paidTtlStr = "";
        $scope.baseTtlStr = "";
        $scope.notPaidAmtStr = "";
        $scope.notRevAmtStr = "";

        $scope.totalObj = {};
		var initContent = function() {            
            getTtl();
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
		}

        var getTtl = function() {
            $scope.totalStr = $scope.payTtlStr = $scope.paidTtlStr = $scope.baseTtlStr = $scope.notPaidAmtStr = $scope.notRevAmtStr = "";
            var totalExp = 0, payTtlExp = 0, paidTtlExp = 0;
            var totalRev = 0, payTtlRev = 0, paidTtlRev = 0, notPaidAmt = 0, notRevAmt = 0;
            var baseTtl = 0;
            var curSet = new Set();
            var payCurSet = new Set();


            $scope.totalObj.bastRev = 0;
            $scope.totalObj.baseExp = 0;
            $scope.totalObj.totalList = [];

            angular.forEach($scope.gridOptions.data, function(rate){
                rate.isPayTxt = rate.isPay == true?$translate.instant('stats.stat.isPay'):$translate.instant('stats.stat.isnotpay');
                if(rate.baseAmt){
                    if(rate.isPay){
                        baseTtl -= rate.baseAmt;
                        $scope.totalObj.baseExp += rate.baseAmt;
                    }
                    else{
                        baseTtl += rate.baseAmt;
                        $scope.totalObj.bastRev += rate.baseAmt;
                    }                    
                }
                if(rate.currency){
                    curSet.add(rate.currency);
                }
                if(rate.payCurrency){
                    payCurSet.add(rate.payCurrency);
                }
            })
            $scope.baseTtlStr = $rootScope.account.baseCurrency + DataUtils.round(baseTtl, 2);
            curSet.forEach(function(cur){
                totalExp = totalRev = 0;
                angular.forEach($scope.gridOptions.data, function(rate){
                    if(rate.currency == cur){
                        if(rate.isPay){
                            totalExp += rate.totalDec;
                        }
                        else {
                            totalRev += rate.totalDec;
                        }
                    }
                })
                $scope.totalStr += cur + ": " + DataUtils.round(totalRev, 2) + "(Rev), " + DataUtils.round(totalExp, 2) + "(Exp); ";
            })

            payCurSet.forEach(function(cur){
                payTtlExp = 0;
                paidTtlExp = 0;
                payTtlRev = 0;
                paidTtlRev = 0;
                notPaidAmt = 0;
                notRevAmt = 0;
                angular.forEach($scope.gridOptions.data, function(rate){
                    if(rate.payCurrency == cur){
                        if(rate.isPay){
                            payTtlExp += rate.payAmount;
                            if(rate.actual){
                                paidTtlExp += rate.actual;
                            }
                            if(rate.notPaidAmt){
                                notPaidAmt += rate.notPaidAmt;
                            }
                        }
                        else {
                            payTtlRev += rate.payAmount;
                            if(rate.actual){
                                paidTtlRev += rate.actual;
                            }
                            if(rate.notRevAmt){
                                notRevAmt += rate.notRevAmt;
                            }
                        }
                    }
                })
                $scope.payTtlStr += cur + ": " + DataUtils.round(payTtlRev, 2) + "(Rev), " + DataUtils.round(payTtlExp, 2) + "(Exp); ";
                $scope.paidTtlStr += cur + ": " + DataUtils.round(paidTtlRev, 2) + "(Rev), " + DataUtils.round(paidTtlExp, 2) + "(Exp); ";
                $scope.notPaidAmtStr += cur + DataUtils.round(notPaidAmt, 2) + "; ";
                $scope.notRevAmtStr += cur + DataUtils.round(notRevAmt, 2) + "; ";

                var newRate = {};
                newRate.currency = cur;
                newRate.rev = payTtlRev;
                newRate.exp = payTtlExp;
                newRate.revBal = notRevAmt;
                newRate.expBal = notPaidAmt;
                $scope.totalObj.totalList.push(newRate);
            })
        }
        $scope.checkedObj = {};
        var getCheckedTotalList = function() {            
            $scope.checkedObj = getTotalList($scope.gridApi.selection.getSelectedRows());
            $translate.refresh();            
        }
        $scope.visibleObj = {};
        var getVisibelTtl = function() {       
            var visibleRows = $scope.gridApi.core.getVisibleRows(gridCopy);
            var visibleDatas = [];
            angular.forEach(visibleRows, function(data){
                visibleDatas.push(data.entity);
            })
            $scope.visibleObj = getTotalList(visibleDatas);
            $translate.refresh();            
        }
        var getTotalList = function(dataList) {
            var totalObj = {};     
            totalObj.bastRev = 0;
            totalObj.baseExp = 0;
            totalObj.totalList = [];

            if(dataList.length == 0){
                $translate.refresh();
                return totalObj;
            }
            var payCurSet = new Set();
            angular.forEach(dataList, function(rate){
                if(rate.baseAmt){
                    if(rate.isPay){
                        totalObj.baseExp += rate.baseAmt;
                    }
                    else{
                        totalObj.bastRev += rate.baseAmt;
                    }                    
                }
                if(rate.payCurrency){
                    payCurSet.add(rate.payCurrency);
                }
            })
            var payTtlExp = 0, payTtlRev = 0, notPaidAmt = 0, notRevAmt = 0;
            payCurSet.forEach(function(cur){
                payTtlExp = 0;
                payTtlRev = 0;
                notPaidAmt = 0;
                notRevAmt = 0;
                angular.forEach(dataList, function(rate){
                    if(rate.payCurrency == cur){
                        if(rate.isPay){
                            payTtlExp += rate.payAmount;
                            if(rate.notPaidAmt){
                                notPaidAmt += rate.notPaidAmt;
                            }
                        }
                        else {
                            payTtlRev += rate.payAmount;
                            if(rate.notRevAmt){
                                notRevAmt += rate.notRevAmt;
                            }
                        }
                    }
                })
                var newRate = {};
                newRate.currency = cur;
                newRate.rev = payTtlRev;
                newRate.exp = payTtlExp;
                newRate.revBal = notRevAmt;
                newRate.expBal = notPaidAmt;
                totalObj.totalList.push(newRate);
            })
            return totalObj;
        }

        var filterTimer, gridCopy;
        $scope.gridOptions = {
            enableSorting: true,//排序
            enableRowHeaderSelection : true,  //行选中
            enableGridMenu : true,//表格 菜单  
            showGridFooter: true,//表格的footer  
            enableFiltering: true,//高级过滤
            showColumnFooter: true,//每列footer

            minRowsToShow: 20,
            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('global.menu.admin.chargesReport') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],     
            columnDefs: [
                { field: 'jobNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.jobNum'),
                    cellTemplate: '<a  class="grid-text" href="" ng-click="grid.appScope.VIEWGE(row.entity)" ng-if="row.entity.id">{{row.entity.jobNum}}</a>'},
                { field: 'mblNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.mblNum')},
                { field: 'soNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.soNum')},
                { field: 'clientSoNum', 
                    minWidth:150, 
                    displayName: $translate.instant('autopsApp.guide.clientWmsInNum')},
                { field: 'poNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.poNum')},
                { field: 'hblNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.hblNum')},
                { field: 'ctnMawb',
                    minWidth:150,
                    displayName: $translate.instant('stats.stat.ctnNumsStr')},
                { field: 'refNum', 
                    minWidth:120,
                    displayName: "EDI Ref#"},
                { field: 'thirdpartyPlatformRef', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.thirdpartyPlatformRef')},       
                { field: 'item',
                    minWidth:100,
                    displayName: $translate.instant('stats.stat.item')},
                { field: 'bizDate', 
                    minWidth:120, 
                    displayName: $translate.instant('stats.stat.bizDate')}, 
                { field: 'etd',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.etd')},
                { field: 'atd',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.atd')},
                { field: 'eta',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.eta')},
                { field: 'accountDate',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.accountDate')},
                { field: 'inputBy',
                    minWidth:100,
                    displayName: $translate.instant('stats.stat.inputBy')},
                { field: 'inputTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.inputTime | date:\'medium\'}}">{{row.entity.inputTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.feeInput')},
                { field: 'serviceTerm',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.serviceTerm'), 
                    cellTemplate: '<span class="grid-text" translate="global.{{row.entity.serviceTerm}}" ng-if="row.entity.serviceTerm"></span>'},
                { field: 'payer',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.settlementObj')},
                { field: 'isPayTxt',
                    minWidth:80, 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [ 
                        { value: $translate.instant('stats.stat.isPay'), label: $translate.instant('stats.stat.isPay')}, 
                        { value: $translate.instant('stats.stat.isnotpay'), label: $translate.instant('stats.stat.isnotpay')}]
                    },
                    displayName: $translate.instant('stats.stat.guideRates.isPay')},
                { field: 'currency',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.currency')},
                { field: 'unit',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.unit')},
                { field: 'priceDec',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.price')},
                { field: 'countDec',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.count')},     
                { field: 'shouldCount',
                    type:'number',
                    minWidth:100, 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.guideRates.shouldCount')},    
                { field: 'diffCount',
                    minWidth:100, 
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('stats.stat.guideRates.diffCount'), 
                    cellTemplate: '<span class="grid-text"><span ng-if="row.entity.diffCount"translate="global.yes_answer" class="label label-danger">yes_answer</span><span ng-hide="row.entity.diffCount"translate="global.no_answer" class="label label-info">no_answer</span></span>'},
                { field: 'totalDec',
                    minWidth:100, 
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    footerCellTemplate: '<div>{{grid.appScope.totalStr}}</div>',
                    displayName: $translate.instant('stats.stat.guideRates.total')},
                { field: 'payExchange',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.payExchange')},
                { field: 'payCurrency',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.payCurrency')},
                { field: 'payAmount',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    footerCellTemplate: '<div >{{grid.appScope.payTtlStr}}</div>',
                    displayName: $translate.instant('stats.stat.guideRates.payAmount')},
                { field: 'actual',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    footerCellTemplate: '<div >{{grid.appScope.paidTtlStr}}</div>',
                    displayName: $translate.instant('stats.stat.guideRates.actual')},
                { field: 'notPaidAmt',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    footerCellTemplate: '<div >{{grid.appScope.notPaidAmtStr}}</div>',
                    displayName: $translate.instant('stats.stat.paymentNotPaid')},
                { field: 'notRevAmt',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    footerCellTemplate: '<div >{{grid.appScope.notRevAmtStr}}</div>',
                    displayName: $translate.instant('stats.stat.paymentNotReceived')},
                { field: 'baseAmt',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    footerCellTemplate: '<div >{{grid.appScope.baseTtlStr}}</div>',
                    displayName: $translate.instant('stats.stat.guideRates.baseAmt')}, 
                { field: 'paidTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.paidTime | date:\'medium\'}}">{{row.entity.paidTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.paymentClearTime')},    
                { field: 'hiddenRate',
                    minWidth:100, 
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('stats.stat.guideRates.hiddenRate'), 
                    cellTemplate: '<span class="grid-text"><span ng-if="row.entity.hiddenRate"translate="global.yes_answer" class="label label-danger">yes_answer</span><span ng-hide="row.entity.hiddenRate"translate="global.no_answer" class="label label-info">no_answer</span></span>'},
                { field: 'pkgNum',
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.pkgs')}, 
                { field: 'gw',
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.gw')},
                { field: 'vol',
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.vol')},
                { field: 'chargeWeight',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.chargeWeight')},
                { field: 'chargeWeightKg', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.chargeWeight') + "(KG)", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'chargeWeightCbm', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.chargeWeight') + "(CBM)", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'volumes',
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.volumes')},
                { field: 'paymentTerm',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.paymentTerm')},
                { field: 'billNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.billNum')},
                { field: 'mcNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.mcNum')},
                { field: 'billMakeDate', 
                    displayName: $translate.instant('stats.stat.billMakeDate'), 
                    width:120},
                { field: 'dnNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.dnNum')},
                { field: 'exchangeRate',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.exchangeRate')},
                { field: 'actualExchangeRate',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.actualExchangeRate')},
                { field: 'invoiceNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.invoiceNum')},
                { field: 'invoiceTime', 
                    width:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.invoiceTime | date:\'medium\'}}">{{row.entity.invoiceTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('autopsApp.autoCheck.invoiceTime')},   
                { field: 'writeOffCode',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.writeOffCode')},
                { field: 'deductBillIdTAG',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.deductBillIdTAG'), 
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="grid-text"><span ng-if="row.entity.deductBillIdTAG"translate="global.yes_answer" class="label label-danger">yes_answer</span><span ng-hide="row.entity.deductBillIdTAG"translate="global.no_answer" class="label label-info">no_answer</span></span>'},
                { field: 'remarks',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.remarks')},
                { field: 'salesName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterBySales')},
                { field: 'cusName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByCs')},                    
                { field: 'opName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByOperator')},
                { field: 'docName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByDocument')},
                { field: 'customerCode', 
                    displayName: $translate.instant('stats.stat.customerCode'), 
                    minWidth:120},
                { field: 'customerName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByClient')},
                { field: 'shipperName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByShiperCnee')},
                { field: 'bookingToName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByColoader')},
                { field: 'deliveryName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByAgent')},
                { field: 'clearanceAgent',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.clearanceAgent')},
                { field: 'venderIdCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.clientIdCode')},
                { field: 'venderCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.clientCode')},
                { field: 'venderPicName',
                    minWidth:100, 
                    displayName: $translate.instant('global.attn')},
                { field: 'venderPicTel',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.mailMob')},
                { field: 'venderPaymentTerm',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.paymentTerm')},
                { field: 'venderCreditDateType',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.dateType')},                    
                { field: 'venderCreditDays',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.venderCreditDays')},
                { field: 'venderCreditRemarks',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.venderCreditRemarks')},                    
                { field: 'creditBalance',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.creditBalance')},
                { field: 'clientRole',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.clientRole')},
                { field: 'creditRating',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.creditRating')},
                { field: 'itemCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.itemCode')},
                { field: 'destCountryCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.destCountryCode')}, 
                { field: 'porName', 
                    displayName: $translate.instant('stats.stat.porName'),
                    minWidth:100},
                { field: 'polName', 
                    displayName: $translate.instant('stats.stat.polName'),
                    minWidth:100},
                { field: 'podName', 
                    displayName: $translate.instant('stats.stat.podName'),
                    minWidth:100},
                { field: 'destName', 
                    displayName: $translate.instant('stats.stat.destName'), 
                    minWidth:100},
                { field: 'deliveryTo', 
                    displayName: $translate.instant('stats.stat.deliveryto'), 
                    minWidth:100},
                { field: 'deliveryTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.deliveryTime | date:\'medium\'}}">{{row.entity.deliveryTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.deliveryTime')},
                { field: 'wmsInTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.wmsInTime')},
                { field: 'ataTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.ataTime | date:\'medium\'}}">{{row.entity.ataTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.ataTime')},
                { field: 'productName', 
                    displayName: $translate.instant('stats.stat.productName'), 
                    minWidth:120},
                { field: 'mjobNum', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.mjobNum')},
                { field: 'carrier',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.bcarrier')},
                { field: 'vsl', 
                    displayName: $translate.instant('stats.stat.vsl'), 
                    minWidth:100},
                { field: 'voy', 
                    displayName: $translate.instant('stats.stat.voy'), 
                    minWidth:100},
                { field: 'team', 
                    displayName: $translate.instant('stats.stat.bteam'), 
                    minWidth:100},
                { field: 'branch',
                    displayName: $translate.instant('stats.stat.bbranch'), 
                    minWidth:100},
                { field: 'handleType',
                    displayName: $translate.instant('stats.stat.handleType'), 
                    minWidth:100},
                { field: 'orderRemarks',
                    displayName: $translate.instant('stats.stat.orderRemarks'), 
                    minWidth:100}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 64;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "chargesReport";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "chargesReport";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.grid.registerColumnsProcessor(setGroupValues, 410);
                gridApi.selection.on.rowSelectionChanged($scope,function(row){
                    getCheckedTotalList();
                });
                gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                    getCheckedTotalList();
                });
                gridApi.core.on.filterChanged($scope, function() {  
                    gridCopy = this.grid;
                    clearTimeout(filterTimer);
                    filterTimer = setTimeout(function(){                        
                        getVisibelTtl();                            
                    }, 1000);//查询延时时间，单位ms 
                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 64
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });
        var setGroupValues = function(columns, rows) {
            columns.forEach(function(column) {
                if (column.grouping && column.grouping.groupPriority > -1) {
                    // Put the balance next to all group labels.
                    column.treeAggregationFn = function(aggregation, fieldValue, numValue, row) {
                        if (typeof(aggregation.value) === 'undefined') {
                            aggregation.value = 0;
                            aggregation.count = 0;
                        }
                        aggregation.count++;

                        if(column.field == "currency"){
                            if(row.entity.isPay){
                                aggregation.value = aggregation.value - row.entity.totalDec;
                            }
                            else {
                                aggregation.value = aggregation.value + row.entity.totalDec;
                            }                            
                        }
                        else if(column.field == "payCurrency"){
                            if(row.entity.isPay){
                                aggregation.value = aggregation.value - row.entity.payAmount;
                            }
                            else {
                                aggregation.value = aggregation.value + row.entity.payAmount;
                            }                            
                        }
                        else {
                            if(row.entity.isPay){
                                aggregation.value = aggregation.value - row.entity.baseAmt;
                            }
                            else {
                                aggregation.value = aggregation.value + row.entity.baseAmt;
                            }                            
                        }
                    };
                    column.customTreeAggregationFinalizerFn = function(aggregation) {
                        if (typeof(aggregation.groupVal) !== 'undefined') {
                            aggregation.value = $rootScope.FORMAT_NUM(DataUtils.round(aggregation.value, 2));
                            if(column.field == "currency" || column.field == "payCurrency"){
                                aggregation.rendered = aggregation.groupVal + ' (' + aggregation.count + "_" + aggregation.value + ')';
                            }
                            else {
                                aggregation.rendered = aggregation.groupVal  + ' (' + aggregation.count + "_" + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency) + aggregation.value + ')';
                            }                            
                        } else {
                            aggregation.rendered = null;
                        }
                    };
                }
            });
            return columns;
        };

        $scope._onClientSelected = function(data) {
        	$scope.statParams.shipperId = data.id;
        }
        $scope._onCustomerSelected = function(data) {
        	$scope.statParams.customerId = data.id;
        }
        $scope._onColoaderSelected = function(data) {
        	$scope.statParams.bookingToId = data.id;
        }
        $scope._onAgentSelected = function(data) {
        	$scope.statParams.deliveryId = data.id;
        }
        $scope._onPayerSelected = function(data) {
        	$scope.statParams.venderId = data.id;
        }
        $scope._onItemSelected = function(data) {
            $scope.statParams.itemId = data.id;
        }

        var isReverse = false;
        $scope.sortByTitle = function(title) {
        	isReverse = !isReverse;
    		$scope.gridOptions.data.sort(function(a,b){
    			if (isReverse){
    				return a[title]<b[title]?1:-1;
    			}
    			else {
    				return a[title]>b[title]?1:-1;
    			}        			
    		});
        }
        $scope.setFdValue = function(fd, val) {
            $scope.statParams[fd]= val;
        }
        
        $scope.delShipmentType = function(index) {            
            $scope.statParams.shipmentTypes.splice(index, 1);
        };
        $scope.newShipmentType = null;
        $scope.shipmentTypeSelected = function(item) {
            if(!$scope.statParams.shipmentTypes){
                $scope.statParams.shipmentTypes = [];
            }
            $scope.statParams.shipmentTypes.push(item);
        }

        $scope.excelExport = function(resultList) {
            if(!resultList || resultList.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = $translate.instant('global.menu.admin.chargesReport') + ".xlsx";           
            OpsFiles.excelSynGuideRatesDTO({
                enCn: $translate.instant('global.enCn')
            }, resultList, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }    
        $scope.searchType = 'BillNum';
        $scope.setSearchType = function(type) {
            $scope.searchType = type;
        }
        $scope.searchStr = null;
        $scope.clearAll = function () {
            $scope.gridOptions.data = [];   
            initContent();
            $translate.refresh();
        }
        $scope.noBizType =function(){      
            return $scope.searchType == 'billNum' || $scope.searchType == 'dnNum' 
                    || $scope.searchType == 'statementCode' || $scope.searchType == 'invoiceNum';  
        }
        $scope.getRatesByRefNum =function(bizType){
            if($scope.isSaving){return;}            
            if(!$scope.searchStr){
                return;
            }
            if(!bizType && $scope.searchType !== 'BillNum'){
                return;
            }
            layer.load(1, {shade: 0.3});
            var paramCopy = angular.copy($scope.statParams);
            paramCopy.searchType = $scope.searchType;
            paramCopy.companyId = $rootScope.account.companyId;
            paramCopy.searchStr = $scope.searchStr;
            paramCopy.bizType = bizType;
            $scope.isSaving = true;
            GuideRates.getRatesByRefNum(paramCopy, onLoadDataSuccess, $rootScope.ALERT_ERROR);
        }        
        // “费用项目统计”定制的统一导出
        $scope.customizedChargesReport = function(resultList) {
            if(!resultList || resultList.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = $translate.instant('global.menu.admin.chargesReport') + ".xlsx";
            if ($rootScope.account.companyId == 643) {
                fileName = $translate.instant('global.menu.admin.chargesReport') + ".xls";
            }
            var loadIndex = layer.load(1, {shade: 0.3});
            OpsFiles.customizedChargesReport({
                companyId: $rootScope.account.companyId
            }, resultList, function(result) {
                layer.close(loadIndex);
                $rootScope.DOWNLOADEXCEL(result, fileName);
            }, $rootScope.ALERT_ERROR)
        } 

        $scope.searchGuidePop = function() {            
            if($rootScope.HAVENOAUTH('FMS') && $rootScope.HAVENOAUTH('FBA') && $rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }

            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return getEtdPeriod();
                    }]
                }
            }).result.then(function(result) {
                onLoadDataSuccess(result);
            }, function() {
            });
        }
        function getEtdPeriod(){
            var etdPeriod = {
                forChargesReport: true,
                companyId: $rootScope.account.companyId,
                statParams: angular.copy($scope.statParams)
            };
            etdPeriod.etdFM = $scope.statParams.etdFM;
            etdPeriod.etdTO = $scope.statParams.etdTO;
            etdPeriod.shipperId = $scope.statParams.shipperId;
            etdPeriod.clientNamePH = $scope.statParams.clientName;            
            etdPeriod.customerId = $scope.statParams.customerId;
            etdPeriod.customerNamePH = $scope.statParams.customerName;
            etdPeriod.bookingToId = $scope.statParams.bookingToId;
            etdPeriod.coloaderNamePH = $scope.statParams.coloaderName;
            etdPeriod.deliveryId = $scope.statParams.deliveryId;
            etdPeriod.agentNamePH = $scope.statParams.agentName;
            return etdPeriod;
        }

        $scope.searchParcelsPop = function(shipmentType) {    
            if($rootScope.HAVENOAUTH('TMS') && shipmentType == "TMS"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($rootScope.HAVENOAUTH('PARCEL') && shipmentType == "PARCEL"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var etdPeriod = getEtdPeriod();
            etdPeriod.shipmentType = shipmentType;
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return etdPeriod;
                    }]
                }
            }).result.then(function(result) {
                onLoadDataSuccess(result);
            }, function() {
            });
        }
        $scope.searchWmsInPop = function(isOut) {    
            if($rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                companyId: $rootScope.account.companyId,
                autoCheck: $scope.autoCheck
            };
            var wmsFilter = getEtdPeriod();
            if(isOut == "TD"){
                wmsFilter.filterType = "TD";
            }
            else {
                wmsFilter.isOut = isOut;
            }
            wmsFilter.venderId = $scope.statParams.shipperId;
            wmsFilter.clientNamePh = $scope.statParams.clientName;
            
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-search.html',
                controller: 'WmsInSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return wmsFilter;
                    }]
                }
            }).result.then(function(result) {
                onLoadDataSuccess(result);
            }, function() {
            });
        }
        $scope.delList = function() {          
            if ($rootScope.HAVENOAUTH('AU_DEL_RATES_BATCH')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_RATES_BATCH');
                return;
            }
            var selectedRows = $scope.gridApi.selection.getSelectedRows();  
            if(selectedRows.length == 0){                            
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.confirm($translate.instant('global.delSelectedAlert', {ttlAmt: selectedRows.length}), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    GuideRates.delList(selectedRows, function(result){
                        for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                            if(result.successIds.indexOf($scope.gridOptions.data[i].id) !== -1){
                                $scope.gridOptions.data.splice(i, 1);
                            }
                        }
                        $rootScope.OPS_ALERT($translate.instant('global.delListResult', {
                            successAmt: result.successAmt, 
                            failedAmt: result.failedAmt
                        })); 
                        $translate.refresh();
                    }, function(result){
                        layer.msg($translate.instant('global.delete_fail'));
                    })
                    layer.close(index);
                }, function() {
            });
        }

        $scope.asyncTasks = function(allCols) {
            getStatParams();
            if((!$scope.statParams.etdFM || !$scope.statParams.etdTO) 
                && (!$scope.statParams.inputTimeFM || !$scope.statParams.inputTimeTO) 
                && (!$scope.statParams.accountDateFM || !$scope.statParams.accountDateTO)
                && (!$scope.statParams.paidTimeFM || !$scope.statParams.paidTimeTO)){
                layer.msg($translate.instant('stats.stat.dateNullAlert'));              
                return;
            }
            var gridState = $scope.gridApi.saveState.save();
            DataUtils.fillGridDisplayName(gridState, $scope.gridOptions.columnDefs, allCols);            

            var body = {};
            body.statParams = $scope.statParams;
            body.gridState = gridState;
            body.taskType = "CHARGES";   
            var loadIndex = layer.load(1, {shade: 0.3});
            AsyncTask.submitAsyncTask(body, function(result){
                layer.close(loadIndex);
                $rootScope.OPS_ALERT($translate.instant('autopsApp.asyncTask.tips.taskSubmitSuccess'));
            }, $rootScope.ALERT_ERROR);
        }
        $scope.batchSubmit = function() {
            if ($rootScope.HAVENOAUTH('ROLE_ACCOUNT')) {
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
                return;
            }
            var selectedRows = $scope.gridApi.selection.getSelectedRows();  
            if(selectedRows.length == 0){                            
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var result = {};
            result.guideRateDtos = selectedRows;
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/batchRatesSubmit.html',
                controller: 'BatchRatesSubmitController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return result;
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }

        

	}
})();