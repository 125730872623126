(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmEnquiryController', CrmEnquiryController);

    CrmEnquiryController.$inject = ['$scope', '$state', '$rootScope', '$timeout', '$translate',
        'CrmEnquiry', 'CrmEnquirySearch', 'ParseLinks', 'pagingParams', 'paginationConstants', 'uiGridConstants', 'WinAES', 'GridState', 'DataUtils'
    ];

    function CrmEnquiryController($scope, $state, $rootScope, $timeout, $translate,
        CrmEnquiry, CrmEnquirySearch, ParseLinks, pagingParams, paginationConstants, uiGridConstants, WinAES, GridState, DataUtils) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.page = 1;
        loadAll();
        vm.isAdvanceSearch = false;
        var gridCopy;
        var pageLoaded = true;
        var filterTimer;
        $scope.viewAllChange = function() {
            if ($rootScope.HAVENOAUTH('AU_VIEW_ALL_ENQUIRY')) {
                vm.viewAll = !vm.viewAll;
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_ALL_ENQUIRY');
                return;
            }
            loadAll();
        }
        function loadAll() {
            if (vm.searchQuery) {
                CrmEnquirySearch.query({
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

            } else {
                var filter = {};
                filter.searchType = vm.viewAll?"all":"self";
                if (gridCopy) {
                    angular.forEach(gridCopy.columns, function(column) {
                        angular.forEach(column.filters, function(cf) {
                            if (cf.term != null && angular.isDefined(cf.term)) {
                                if (cf.condition) {
                                    var fd = column.field;
                                    if (cf.condition == 128 || cf.condition == 256) {
                                        filter[fd + "TO"] = cf.term;
                                    } else if (cf.condition == 32 || cf.condition == 64) {
                                        filter[fd + "FM"] = cf.term;
                                    }
                                } else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                CrmEnquiry.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + vm.direction];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                if (headers('X-Total-Count')) {
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                } else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                angular.forEach($scope.gridOptions.data, function(data) {
                    data.encodeId = WinAES.encode(data.id.toString())
                })
                vm.isAdvanceSearch = false;
                initData();

                resize();
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
            }

            function onError(error) {
                console.log("onError");
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + vm.direction,
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction = 'desc';
            vm.currentSearch = null;
            vm.transition();
        }
        window.onresize = function() {
            resize();
        }

        function resize() {
            if (!$("#gridId") || !$("#gridId").offset()) {
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if (tabHeight > winHeight) {
                tabHeight = winHeight;
            }
            if (tabHeight < 600) {
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function initData() {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = vm.isAdvanceSearch;
            angular.forEach($scope.gridOptions.data, function(data){
                angular.forEach($rootScope.CARGOTYPES, function(opt) {
                    if (opt.value == data.shipmentName) {
                        data.cargoTypeLabel = opt.label;
                    }
                })
                DataUtils.fillFdLabel(data);
            })

        }
        var enquiryStatusList = [{
            value: "",
            label: ""
        }, {
            value: 'new',
            label: $translate.instant('autopsApp.crmEnquiry.sts.new')
        }, {
            value: 'replied',
            label: $translate.instant('autopsApp.crmEnquiry.sts.replied')
        }, {
            value: 'booked',
            label: $translate.instant('autopsApp.crmEnquiry.sts.booked')
        }];

        $scope.gridOptions = {
            enableSorting: true, //排序
            useExternalSorting: true,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            exporterExcelFilename: "crm_enquiry",
            columnDefs: [{
                    field: 'id',
                    minWidth: 180,
                    displayName: "",
                    pinnedLeft: true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container grid-text"><button type="submit"ui-sref="crm-enquiry.edit({id:row.entity.encodeId})"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-modal-window"></span><span class="hidden-xs hidden-sm"translate="entity.action.edit"></span></button><button type="button"ui-sref="crm-enquiry.delete({id:row.entity.encodeId})"class="btn btn-danger btn-grid"><span class="glyphicon glyphicon-remove-circle"></span><span class="hidden-xs hidden-sm"translate="entity.action.delete"></span></button></div>'
                },
                {
                    field: 'enquiryNum',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.crmEnquiry.enquiryNum'),
                    cellTemplate: '<a class="grid-text" href="" ui-sref="crm-enquiry.edit({id:row.entity.encodeId})">{{row.entity.enquiryNum}}</span>'
                },
                {
                    field: 'status',
                    minWidth: 80,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: enquiryStatusList
                    },
                    displayName: $translate.instant('autopsApp.crmEnquiry.status'),
                    cellTemplate: '<span class="grid-text label label-info" ng-if="row.entity.status" translate="autopsApp.crmEnquiry.sts.{{row.entity.status}}"></span>'
                },
                {
                    field: 'creater',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.crmEnquiry.creater')
                },
                {
                    field: 'inquirerName',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.crmEnquiry.inquirerName')
                },
                {
                    field: 'inquirerCompany',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.crmEnquiry.inquirerCompany')
                },
                {
                    field: 'inquirerEmail',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.crmEnquiry.inquirerEmail')
                },
                {
                    field: 'enquiryTime',
                    minWidth: 200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [{
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                        placeholder: ' >= '
                    }, {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                        placeholder: ' <= '
                    }],
                    displayName: $translate.instant('autopsApp.crmEnquiry.enquiryTime'),
                    cellTemplate: '<span class="grid-text">{{row.entity.enquiryTime | date:\'medium\'}}</span>'
                },
                {
                    field: 'volume',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.crmEnquiry.volume')
                },
                {
                    field: 'pkgs',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    minWidth: 70,
                    displayName: $translate.instant('autopsApp.crmEnquiry.pkgs')
                },
                {
                    field: 'gw',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    minWidth: 70,
                    displayName: $translate.instant('autopsApp.crmEnquiry.gw')
                },
                {
                    field: 'vol',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    minWidth: 70,
                    displayName: $translate.instant('autopsApp.crmEnquiry.vol')
                },
                {
                    field: 'shipmentName',
                    minWidth: 100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CARGOTYPES
                    },
                    cellTemplate: '<span class="label label-info ml-5">{{row.entity.cargoTypeLabel}}</span>',
                    displayName: $translate.instant('autopsApp.crmEnquiry.cargoType')
                },
                {
                    field: 'cargoValue',
                    minWidth: 110,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellTemplate: '<span class="grid-text" ng-show="row.entity.cargoValue">USD {{row.entity.cargoValue}}</span>',
                    displayName: $translate.instant('autopsApp.crmEnquiry.cargoValue')
                },
                {
                    field: 'cargoName',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.crmEnquiry.cargoName')
                },
                {
                    field: 'hsCode',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.crmEnquiry.hsCode')
                },
                {
                    field: 'pol',
                    minWidth: 80,
                    displayName: $translate.instant('autopsApp.crmEnquiry.pol')
                },
                {
                    field: 'pod',
                    minWidth: 80,
                    displayName: $translate.instant('autopsApp.crmEnquiry.pod')
                },
                {
                    field: 'porAddress',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.crmEnquiry.porAddress')
                },
                {
                    field: 'podAddress',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.crmEnquiry.podAddress')
                },
                {
                    field: 'pod',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.crmEnquiry.pod')
                },
                { field: 'pickupRequired',
                    displayName: $translate.instant('autopsApp.crmEnquiry.pickupRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    minWidth: 80,
                    type: "boolean",
                    cellTemplate: '<span class="ml-5 label" ng-class="row.entity.pickupRequired?\'label-primary\':\'label-success\'"  translate="{{row.entity.pickupRequired?\'global.yes_answer\':\'global.no_answer\'}}"></span>'
                },
                { field: 'deliveryRequired',
                    displayName: $translate.instant('autopsApp.crmEnquiry.deliveryRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    minWidth: 80,
                    type: "boolean",
                    cellTemplate: '<span class="ml-5 label" ng-class="row.entity.deliveryRequired?\'label-primary\':\'label-success\'"  translate="{{row.entity.deliveryRequired?\'global.yes_answer\':\'global.no_answer\'}}"></span>'
                },
                // { field: 'declarationRequired',
                //     displayName: $translate.instant('autopsApp.crmEnquiry.declarationRequired'),
                //     filter: {
                //       type: uiGridConstants.filter.SELECT,
                //       selectOptions: $rootScope.YES_NO_OPTIONS
                //     },
                //     minWidth: 80,
                //     type: "boolean",
                //     cellTemplate: '<span class="ml-5 label" ng-class="row.entity.declarationRequired?\'label-primary\':\'label-success\'"  translate="{{row.entity.declarationRequired?\'global.yes_answer\':\'global.no_answer\'}}"></span>'
                // },
                // { field: 'clearanceRequired',
                //     displayName: $translate.instant('autopsApp.crmEnquiry.clearanceRequired'),
                //     filter: {
                //       type: uiGridConstants.filter.SELECT,
                //       selectOptions: $rootScope.YES_NO_OPTIONS
                //     },
                //     minWidth: 80,
                //     type: "boolean",
                //     cellTemplate: '<span class="ml-5 label" ng-class="row.entity.clearanceRequired?\'label-primary\':\'label-success\'"  translate="{{row.entity.clearanceRequired?\'global.yes_answer\':\'global.no_answer\'}}"></span>'
                // },
                // { field: 'exportDocRequired',
                //     displayName: $translate.instant('autopsApp.crmEnquiry.exportDocRequired'),
                //     filter: {
                //       type: uiGridConstants.filter.SELECT,
                //       selectOptions: $rootScope.YES_NO_OPTIONS
                //     },
                //     minWidth: 80,
                //     type: "boolean",
                //     cellTemplate: '<span class="ml-5 label" ng-class="row.entity.exportDocRequired?\'label-primary\':\'label-success\'"  translate="{{row.entity.exportDocRequired?\'global.yes_answer\':\'global.no_answer\'}}"></span>'
                // },
                // { field: 'importDocRequired',
                //     displayName: $translate.instant('autopsApp.crmEnquiry.exportDocRequired'),
                //     filter: {
                //       type: uiGridConstants.filter.SELECT,
                //       selectOptions: $rootScope.YES_NO_OPTIONS
                //     },
                //     minWidth: 80,
                //     type: "boolean",
                //     cellTemplate: '<span class="ml-5 label" ng-class="row.entity.importDocRequired?\'label-primary\':\'label-success\'"  translate="{{row.entity.importDocRequired?\'global.yes_answer\':\'global.no_answer\'}}"></span>'
                // },
                { field: 'dutyRequired',
                    displayName: $translate.instant('autopsApp.crmEnquiry.dutyRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    minWidth: 80,
                    type: "boolean",
                    cellTemplate: '<span class="ml-5 label" ng-class="row.entity.dutyRequired?\'label-primary\':\'label-success\'"  translate="{{row.entity.dutyRequired?\'global.yes_answer\':\'global.no_answer\'}}"></span>'
                },
                { field: 'insuranceRequired',
                    displayName: $translate.instant('autopsApp.crmEnquiry.insuranceRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    minWidth: 80,
                    type: "boolean",
                    cellTemplate: '<span class="ml-5 label" ng-class="row.entity.insuranceRequired?\'label-primary\':\'label-success\'"  translate="{{row.entity.insuranceRequired?\'global.yes_answer\':\'global.no_answer\'}}"></span>'
                },
                { field: 'enRequired',
                    displayName: $translate.instant('autopsApp.crmEnquiry.enRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    minWidth: 80,
                    type: "boolean",
                    cellTemplate: '<span class="ml-5 label" ng-class="row.entity.enRequired?\'label-primary\':\'label-success\'"  translate="{{row.entity.enRequired?\'global.yes_answer\':\'global.no_answer\'}}"></span>'
                }
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 124;
                    GridState.update(gridStateObj, function(result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "vgmHead";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "vgmHead";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if (!$scope.gridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if (pageLoaded && $scope.gridOptions.useExternalFiltering) {
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function() {
                            loadAll();
                        }, 1000); //查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function(newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if (pageLoaded) {
                        loadAll();
                    }
                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function() {
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 124
            }, function(result) {
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if (!gridStateObj || !gridStateObj.id) {
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if (gridCopy) {
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column) {
                        angular.forEach(column.filters, function(cf) {
                            if (cf.term) {
                                filterRequired = true;
                            }
                        })
                    })
                    if (filterRequired) {
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });
        var unsubscribe = $rootScope.$on('autopsApp:crmEnquiryUpdate', function(event, result) {
            if(result.id){
                var isNew = true;
                angular.forEach($scope.gridOptions.data, function(guide){
                    if(guide.id == result.id){
                        angular.copy(result, guide);
                        guide.encodeId = WinAES.encode(guide.id.toString());
                        angular.forEach($rootScope.CARGOTYPES, function(opt) {
                            if (opt.value == guide.shipmentName) {
                                guide.cargoTypeLabel = opt.label;
                            }
                        })
                        isNew = false;
                    }
                })
                if(isNew){
                    loadAll();
                }
            }
            else {
                loadAll();
            }
        });
        $scope.$on('$destroy', unsubscribe);


    }
})();
