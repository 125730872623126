(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ComWriteOffController', ComWriteOffController);
    
    ComWriteOffController.$inject = ['$rootScope', '$scope', '$state', '$translate', '$window', '$uibModal', '$uibModalInstance', '$timeout', 'Principal', 'AccountTitle', 'JhiLanguageService','GuideRates',
    'AccountInvoice','AccountCashier', 'OpsExchangeRate', 'AccountPayment', 'OpsVender', 'AutoCheck', 'DateUtils', 
    'DataUtils', 'entity', 'GridState', 'uiGridConstants', 'WriteOff'];
    
    function ComWriteOffController($rootScope, $scope, $state, $translate, $window, $uibModal, $uibModalInstance, $timeout, Principal, AccountTitle, JhiLanguageService, GuideRates, 
        AccountInvoice, AccountCashier, OpsExchangeRate, AccountPayment, OpsVender, AutoCheck, DateUtils, 
        DataUtils, entity, GridState, uiGridConstants, WriteOff) {

        var autoCheckPayments = [];
        var autoCheckInvoices = [];
        var autoCheckCurrencys = [];
        var autoCheck;
        $scope.currencyMaps = [];

        var accountInvoice = entity.accountInvoice;
        var accountPayment = entity.accountPayment;
        var autoCheck = entity.autoCheck;
        var accountCashier = entity.accountCashier;
        $scope.accountCashier = {};
        $scope.accountCashier.transactionDate = new Date();

        $scope.searchType = "S";
        $scope.searchFd = "dnNum";

        $scope.isMix = false;
        var correncyCopy = null;
        $timeout(function (){
            if ($rootScope.account.authorities.indexOf("FUN_WRITE_OFF") === -1) {                
                $uibModalInstance.dismiss('cancel');
                $rootScope.FORBIDDEN_BY_ALERT('FUN_WRITE_OFF');
                return;
            }
            // if ($rootScope.HAVEAUTH('FMIS') && $rootScope.HAVENOAUTH('ROLE_ACCOUNT')) {
            //     $uibModalInstance.dismiss('cancel');
            //     $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
            //     return;
            // }

            if (accountCashier){
                $scope.accountCashier = angular.copy(accountCashier);
                $scope.accountCashier.writeOffRemarks = accountCashier.remarks;
            }
            else if(autoCheck && autoCheck.id){
                if(autoCheck.currency){                    
                    $scope.getListByCurrency(autoCheck.currency);
                }
                else {
                    $scope.isSaving = true;
                    AutoCheck.getAllPayCurrencys(autoCheck, function(result) {
                        $scope.isSaving = false;
                        if(!result || result.length == 0){
                            return;
                        }
                        $scope.getListByCurrency(result[0].key);
                        $scope.currencyMaps = result;  
                    }, function(response) {
                        $scope.isSaving = false;
                    });
                }
                $rootScope.AUTOCHECK=null;
            }
            else if (accountInvoice){
                correncyCopy = accountInvoice.currency;
                $scope.accountCashier.originalCurrency = correncyCopy;
                $scope.accountCashier.originalAmt = accountInvoice.invoiceAmount;
                if(accountInvoice.paidAmount){
                    $scope.accountCashier.originalAmt = $scope.accountCashier.originalAmt - accountInvoice.paidAmount;
                    $scope.accountCashier.originalAmt = DataUtils.round($scope.accountCashier.originalAmt, 2);
                }
                AccountInvoice.getUnpaidList({id: accountInvoice.id}, function(result){
                    angular.forEach(result.accountInvoices, function(data){
                        $scope.addToInvoiceList(data);
                    })
                    angular.forEach(result.accountPayments, function(data){
                        $scope.addToPaymentList(data);
                    })                    
                });

            }
            else if (accountPayment){
                correncyCopy = accountPayment.currency;
                $scope.accountCashier.originalCurrency = correncyCopy;
                $scope.accountCashier.originalAmt = -accountPayment.invoiceAmount;
                if(accountPayment.paidAmount){
                    $scope.accountCashier.originalAmt = $scope.accountCashier.originalAmt + accountPayment.paidAmount;
                }
                AccountPayment.getUnpaidListTwo({id: accountPayment.id}, function(result){
                    angular.forEach(result.accountInvoices, function(data){
                        $scope.addToInvoiceList(data);
                    })
                    angular.forEach(result.accountPayments, function(data){
                        $scope.addToPaymentList(data);
                    })
                })
            }            
        });

        $scope.getListByCurrency = function(currency) {
            correncyCopy = currency;
            autoCheck.currency = currency;
            $scope.isSaving = true;
            var originalAmt = 0;
            layer.load(1, {shade: 0.3});
            AutoCheck.getDnCn(autoCheck, function(result) {
                layer.closeAll();
                $scope.isSaving = false;
                $scope.gridOptions_2.data = [];
                $scope.gridOptions_1.data = [];
                if (result.invoicesList || result.invoicesList.length > 0) {
                    angular.forEach(result.invoicesList, function(data){
                        $scope.gridOptions_1.data.push(data);
                        originalAmt += data.actualAmount;
                        if(!$scope.accountCashier.payerName){
                            $scope.accountCashier.payerName = data.payer;
                            $scope.accountCashier.payerVid = data.venderId;
                        }
                    })
                }
                if (result.paymentsList || result.paymentsList.length > 0) {
                    angular.forEach(result.paymentsList, function(data){
                        $scope.gridOptions_2.data.push(data);
                        originalAmt -= data.actualAmount;
                        if(!$scope.accountCashier.payerName){
                            $scope.accountCashier.payerName = data.payer;
                            $scope.accountCashier.payerVid = data.venderId;
                        }
                    })
                }
                $scope.accountCashier.originalCurrency = correncyCopy;
                $scope.accountCashier.originalAmt = DataUtils.round(originalAmt, 2);
                $scope.accountCashier.exchangeRate = null;
                updateWriteOffExchangeRate();
                updateList(true);
                updateCheckedTtl();
            }, function(response) {
                layer.closeAll();
                $scope.isSaving = false;
            });
        }

        $scope.statementCode = null;
        $scope.getDnCnByStatementCode = function() {
            if(!$scope.statementCode){
                return;
            }
            if(!$scope.isMix && !$scope.accountCashier.originalCurrency){
                layer.msg($translate.instant('autopsApp.accountInvoice.home.noAccountChoosed'));
                return;
            }
            $scope.isSaving = true;
            AutoCheck.getDnCnByStatementCode({
                companyId: $rootScope.account.companyId,
                currency: $scope.isMix? null : $scope.accountCashier.originalCurrency,
                code: $scope.statementCode
            }, function(result) {
                $scope.isSaving = false;
                if (result.invoicesList || result.invoicesList.length > 0) {
                    angular.forEach(result.invoicesList, function(data){
                        if($scope.isMix || data.currency == $scope.accountCashier.originalCurrency){
                            $scope.addToInvoiceList(data);
                        }                        
                    })
                }
                if (result.paymentsList || result.paymentsList.length > 0) {
                    angular.forEach(result.paymentsList, function(data){
                        if($scope.isMix || data.currency == $scope.accountCashier.originalCurrency){
                            $scope.addToPaymentList(data);
                        }                        
                    })
                }
            }, function(response) {
                layer.msg($translate.instant('global.messages.resultNull'));
                $scope.isSaving = false;
            });
        }

        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });        
        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };
        $scope.datePickerForEta = {};
        $scope.datePickerForEta.status = {
            opened: false
        };
        $scope.datePickerForEtaOpen = function($event) {
            $scope.datePickerForEta.status.opened = true;
        };
        $scope.ledgers = [];
        $scope.cashLedgers = [];
        function generateShowLedger(type) {
            for (var i = 0; i < $scope.ledgers.length; i++) {
                var titleTemp = "";
                if ($scope.ledgers[i].level > 1) {
                    var subLen = 0;
                    for (var j = 1; j < $scope.ledgers[i].level; j++) {
                        if (j == 2) {
                            subLen = subLen + 2;
                        } else {
                            subLen = subLen + 4;
                        }
                        var ledger = $scope.ledgers[i].code.substr(0, subLen);

                        for (var ii = 0; ii < $scope.ledgers.length; ii++) {
                            if ($scope.ledgers[ii].code == ledger) {
                                if (lang == "en") {
                                    titleTemp = titleTemp + $scope.ledgers[ii].enName + "/ ";
                                } else {
                                    titleTemp = titleTemp + $scope.ledgers[ii].cnName + "/ ";
                                }
                            }
                        }
                    }
                }
                if (lang == "en") {
                    $scope.ledgers[i].showLedger = titleTemp + $scope.ledgers[i].enName;
                } else {
                    $scope.ledgers[i].showLedger = titleTemp + $scope.ledgers[i].cnName;
                }
            }
            for (var i = $scope.ledgers.length - 1; i >= 0; i--) {
                if (!$scope.ledgers[i].isLast) {
                    $scope.ledgers.splice(i, 1);
                }
            }
            angular.forEach($scope.ledgers, function(data) {
                if (type == "all"){
                    $scope.cashLedgers.push(data);
                }
                else if (data.code.substring(0,4) == "1001" || data.code.substring(0,4) == "1002"){ 
                    $scope.cashLedgers.push(data);              
                }                
            })
            var nullOpposite = {
                oppositeId: null,
                code: '0000',
                showLedger: $translate.instant('autopsApp.accountInvoice.partWriteOff')
            }
            $scope.ledgers.splice(0, 0, nullOpposite);  
        }
        $scope.loadAllLedgers = function() {
            AccountTitle.getAll({
                type: "all"
            }, function(result) {
                $scope.ledgers = result;
                $scope.cashLedgers = [];
                generateShowLedger("all");
            });
        }
        $scope._onLedgerSelected = function(ledger) {
            $scope.accountCashier.accountId = ledger.id;
            $scope.accountCashier.accountCode = ledger.code;
            $scope.accountCashier.accountName = ledger.showLedger;
            $scope.accountCashier.exchangeRate = null;
            if (ledger.currency){
                $scope.accountCashier.originalCurrency = ledger.currency;            
            }
            else {
                $scope.accountCashier.originalCurrency = $rootScope.account.baseCurrency;
            }    
            if(correncyCopy && correncyCopy != $scope.accountCashier.originalCurrency){
                $scope.isMix = true;     
                correncyCopy = $scope.accountCashier.originalCurrency;
            }
            updateList(true);
            // $scope.clearDiff();
        }

        $scope.allTransactions = [];
        var exchangeRates = [];
        var loadTransactions = function() {
            if ($rootScope.HAVENOAUTH('FMIS')){
                $rootScope.loadCurrencys();
                if(!exchangeRates || exchangeRates.length == 0){
                    OpsExchangeRate.getExchangeRatesMap(function(result) {
                        exchangeRates = result;
                        updateWriteOffExchangeRate();
                    })
                }
                return;
            }
            else {
                if(!exchangeRates || exchangeRates.length == 0){
                    OpsExchangeRate.getExchangeRatesMap(function(result) {
                        exchangeRates = result;
                        updateWriteOffExchangeRate();
                    })
                }
            }

            AccountCashier.getAllTransaction({
                companyId: $rootScope.account.companyId
            }, function(result) {
                $scope.allTransactions = result;
                angular.forEach($scope.allTransactions, function(data){
                    data.originalAmt = data.originalAmt.toString();
                })
            });

            AccountTitle.getAll({
                type: "part"
            }, function(result) {
                $scope.ledgers = result;
                generateShowLedger();
            });
        }
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                loadTransactions();
            });
        }
        else {
            loadTransactions();
        }
        $scope._onTransactionSelected = function(transaction) {
            if(correncyCopy && correncyCopy != transaction.originalCurrency){
                $scope.isMix = true;          
            }
            $scope.accountCashier = angular.copy(transaction);
            $scope.accountCashier.originalAmt = $scope.accountCashier.originalAmt - 0;
            $scope.accountCashier.writeOffRemarks = transaction.remarks;
            updateWriteOffExchangeRate();
        }
        $scope.clearTransaction = function() {
            $scope.accountCashier= {};
            updateList(true);  
        }
        $scope.isMixChange = function() {
            if(!$scope.isMix && !$scope.accountCashier.originalCurrency){
                $scope.gridOptions_2.data = [];
                $scope.gridOptions_1.data = [];
            }
            else if($scope.accountCashier.originalCurrency && !$scope.isMix){
                for(var i = $scope.gridOptions_1.data.length -1; i >= 0; i--){
                    if($scope.gridOptions_1.data[i].currency != $scope.accountCashier.originalCurrency){
                        $scope.delFromInvoiceList($scope.gridOptions_1.data[i]);
                    }
                }
                for(var i = $scope.gridOptions_2.data.length -1; i >= 0; i--){
                    if($scope.gridOptions_2.data[i].currency != $scope.accountCashier.originalCurrency){
                        $scope.delFromPaymentList($scope.gridOptions_2.data[i]);
                    }
                }
            }
            else if ($scope.isMix && autoCheck && autoCheck.id){
                var autoCheckCopy = angular.copy(autoCheck);
                autoCheckCopy.currency = null;
                AutoCheck.getDnCn(autoCheckCopy, function(result) {
                    $scope.isSaving = false;
                    $scope.gridOptions_2.data = [];
                    $scope.gridOptions_1.data = [];
                    if (result.invoicesList || result.invoicesList.length > 0) {
                        angular.forEach(result.invoicesList, function(data){
                            $scope.addToInvoiceList(data);
                        })
                    }
                    if (result.paymentsList || result.paymentsList.length > 0) {
                        angular.forEach(result.paymentsList, function(data){
                            $scope.addToPaymentList(data);
                        })
                    }
                }, function(response) {
                    $scope.isSaving = false;
                });
            }
            updateList(true);
        }

        $scope.onOppositeLedgerChoosed = function(data) {
            if((data.code.substring(0,4) == "2205" && $scope.accountCashier.diffAmt < 0)  
                || (data.code.substring(0,4) == "1123" && $scope.accountCashier.diffAmt > 0)){
                layer.msg($translate.instant('autopsApp.accountInvoice.advanceAccountAmtAlert'));
                $scope.accountCashier.oppositeName = null;
                return;
            }
            $scope.accountCashier.oppositeId = data.id;
            $scope.accountCashier.oppositeName = data.showLedger;
            $scope.accountCashier.oppositeCode = data.code;
        }
        
        $scope.clearOposite =  function () {            
            if(!$scope.accountCashier.oppositeName && $scope.accountCashier.oppositeCode){
                $scope.accountCashier.oppositeId = null;
                $scope.accountCashier.oppositeName = null;
                $scope.accountCashier.oppositeCode = null;
            }
        }

        $scope.writOffNumCheck = function() {
            if (!$scope.accountCashier.transactionRef) {
                return;
            } else {
                GuideRates.checkWritOffNum({
                    companyId: $rootScope.account.companyId,
                    writeOffCode: $scope.accountCashier.transactionRef
                }, function(result) {
                    if (result.isRepeated) {
                        layer.msg($translate.instant('autopsApp.accountInvoice.writeOffCodeRepeated', {param: $scope.accountCashier.transactionRef}));
                    }
                });
            }
        };

        $scope.dnNumForSearch = null;
        $scope.searchByDnNum = function() {
            if(!$scope.dnNumForSearch){
                return;
            }
            if (!$scope.accountCashier.originalCurrency){
                return;
            }
            layer.load(1, {shade: 0.3});
            AccountInvoice.searchByDnNum({
                searchType: $scope.searchType,
                searchFd: $scope.searchFd,
                companyId: $rootScope.account.companyId,
                currency: $scope.isMix?null:$scope.accountCashier.originalCurrency
            }, $scope.dnNumForSearch, function(result) {
                layer.closeAll();
                if (result.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }              
                for(var i = result.length -1; i >=0; i--){      
                    $scope.addToInvoiceList(result[i]);
                }
            }, function(error){
                layer.closeAll();
            });
        }       

        $scope.cnNumForSearch = null;
        $scope.searchByCnNum = function() {
            if(!$scope.cnNumForSearch){
                return;
            }
            if (!$scope.accountCashier.originalCurrency){
                return;
            }
            layer.load(1, {shade: 0.3});
            AccountPayment.searchByDnNum({
                searchType: $scope.searchType,
                searchFd: $scope.searchFd,
                companyId: $rootScope.account.companyId,
                currency: $scope.isMix?null:$scope.accountCashier.originalCurrency
            }, $scope.cnNumForSearch, function(result) {           
                layer.closeAll();
                if (result.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }              
                for(var i = result.length -1; i >=0; i--){      
                    $scope.addToPaymentList(result[i]);
                }
            }, function(error){
                layer.closeAll();
            });
        }

        $scope.addToInvoiceList = function (invoice) {
            var isRepeated = false;
            for(var j = $scope.gridOptions_1.data.length -1; j >=0; j--){
                if (invoice.id == $scope.gridOptions_1.data[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                if(!invoice.writeOffRates){
                    invoice.actualAmount = null;
                }                
                $scope.gridOptions_1.data.push(invoice);
                $scope.dnNumForSearch = null;
                updateList(true);  
                updateCheckedTtl();
                if(!$scope.accountCashier.payerName){
                    $scope.accountCashier.payerName = invoice.payer;
                    $scope.accountCashier.payerVid = invoice.venderId;
                }
            }                  
        }
 
        $scope.addToPaymentList = function (payment) {
            var isRepeated = false;
            for(var j = $scope.gridOptions_2.data.length -1; j >=0; j--){
                if (payment.id ==$scope.gridOptions_2.data[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                if(!payment.writeOffRates){
                    payment.actualAmount = null;
                }
                $scope.gridOptions_2.data.push(payment);
                $scope.cnNumForSearch = null;
                updateList(true);  
                updateCheckedTtl();
                if(!$scope.accountCashier.payerName){
                    $scope.accountCashier.payerName = payment.invoiceTitle;
                    $scope.accountCashier.payerVid = payment.venderId;
                }
            }
                  
        }

        $scope.delFromInvoiceList = function (invoice) {
            var index = $scope.gridOptions_1.data.indexOf(invoice);
            if (index > -1) {
                $scope.gridOptions_1.data.splice(index, 1);
                updateList();
                updateCheckedTtl();
            };            
        }
        $scope.delFromPaymentList = function (payment) {
            var index = $scope.gridOptions_2.data.indexOf(payment);
            if (index > -1) {
                $scope.gridOptions_2.data.splice(index, 1);
                updateList();
                updateCheckedTtl();
            };            
        }

        $scope.writeOffExchangeRateChange = function (payment) {
            updateList();        
        }
        
        $scope.currencyChange = function(currency) {
            if(!currency){
                return;
            }
            $scope.accountCashier.exchangeRate = exchangeRates[$scope.accountCashier.originalCurrency];   
            updateWriteOffExchangeRate();      
            // $scope.clearDiff();       
        }

        var updateWriteOffExchangeRate = function() {
            if(!$scope.accountCashier.exchangeRate){
                $scope.accountCashier.exchangeRate = exchangeRates[$scope.accountCashier.originalCurrency];   
            }
            angular.forEach($scope.gridOptions_1.data, function(data){
                if(data.currency == $scope.accountCashier.originalCurrency){
                    data.writeOffExchangeRate = $scope.accountCashier.exchangeRate;
                }
                else {
                    data.writeOffExchangeRate = data.exchangeRate;
                } 
            });
            angular.forEach($scope.gridOptions_2.data, function(data){
                if(data.currency == $scope.accountCashier.originalCurrency){
                    data.writeOffExchangeRate = $scope.accountCashier.exchangeRate;
                }
                else {
                    data.writeOffExchangeRate = data.exchangeRate;
                }                
            });
            updateList(true); 
        }

        var updateList = function(resetWriteOffExchangeRate) {
            if(!$scope.accountCashier.exchangeRate){
                $scope.accountCashier.exchangeRate = exchangeRates[$scope.accountCashier.originalCurrency];   
            }
            $scope.accountCashier.diffAmt = $scope.accountCashier.baseAmt = $scope.accountCashier.originalAmt * $scope.accountCashier.exchangeRate;

            var currencies = new Set();
            var currencyTtl = 0;

            var isSameEx = true;
            var isMix = false;
            $scope.invoiceBaseAmtTtl = 0;
            $scope.invoiceTtlList = [];
            angular.forEach($scope.gridOptions_1.data, function(data){
                currencies.add(data.currency);
                if (!data.paidAmount){
                    data.paidAmount = 0;
                }
                data.balance = DataUtils.round(data.invoiceAmount - data.paidAmount, 2);
                if (null == data.actualAmount){
                    data.actualAmount = data.balance;
                }   
                if(null == data.writeOffExchangeRate || resetWriteOffExchangeRate){
                    if(data.currency == $scope.accountCashier.originalCurrency){
                        data.writeOffExchangeRate = $scope.accountCashier.exchangeRate;
                    }
                    else {
                        data.writeOffExchangeRate = data.exchangeRate;
                    }                    
                }       
                data.writeOffBaseAmt = DataUtils.round(data.writeOffExchangeRate * data.actualAmount, 2);            
                $scope.accountCashier.diffAmt -= data.writeOffBaseAmt;                   
                $scope.invoiceBaseAmtTtl += data.writeOffBaseAmt;     
                if(data.writeOffExchangeRate != $scope.accountCashier.exchangeRate){
                    isSameEx = false;
                }
            });
            if(currencies.size > 1){
                isMix = true;
            }          
            else if(currencies.size == 1 && !currencies.has($scope.accountCashier.originalCurrency)){
                isMix = true;
            }         
            currencies.forEach(function(currency){
                currencyTtl = 0;
                angular.forEach($scope.gridOptions_1.data, function(data){
                    if (data.currency == currency){
                        currencyTtl = currencyTtl + data.actualAmount;
                    }
                })
                $scope.invoiceTtlList.push({
                    currency: currency,
                    total: DataUtils.round(currencyTtl, 2)
                });      
            });

            $scope.paymentTtlList = [];
            $scope.paymentBaseAmtTtl = 0;
            currencies = new Set();
            angular.forEach($scope.gridOptions_2.data, function(data){
                currencies.add(data.currency);
                if (!data.paidAmount){
                    data.paidAmount = 0;
                }
                data.balance = DataUtils.round(data.invoiceAmount - data.paidAmount, 2);
                if (null == data.actualAmount){
                    data.actualAmount = data.balance;
                }   
                if(null == data.writeOffExchangeRate || resetWriteOffExchangeRate){
                    if(data.currency == $scope.accountCashier.originalCurrency){
                        data.writeOffExchangeRate = $scope.accountCashier.exchangeRate;
                    }
                    else {
                        data.writeOffExchangeRate = data.exchangeRate;
                    }
                }
                data.writeOffBaseAmt = DataUtils.round(data.writeOffExchangeRate * data.actualAmount, 2);
                $scope.accountCashier.diffAmt += data.writeOffBaseAmt;
                $scope.paymentBaseAmtTtl += data.writeOffBaseAmt;          
                if(data.writeOffExchangeRate != $scope.accountCashier.exchangeRate){
                    isSameEx = false;
                }     
            });
            if(currencies.size > 1){
                isMix = true;
            }          
            else if(currencies.size == 1 && !currencies.has($scope.accountCashier.originalCurrency)){
                isMix = true;
            }
            currencies.forEach(function(currency){
                currencyTtl = 0;
                angular.forEach($scope.gridOptions_2.data, function(data){
                    if (data.currency == currency){
                        currencyTtl = currencyTtl + data.actualAmount;
                    }
                })
                $scope.paymentTtlList.push({
                    currency: currency,
                    total: DataUtils.round(currencyTtl, 2)
                });      
            });
            $scope.invoiceBaseAmtTtl = DataUtils.round($scope.invoiceBaseAmtTtl, 2);
            $scope.paymentBaseAmtTtl = DataUtils.round($scope.paymentBaseAmtTtl, 2);
            $scope.accountCashier.diffAmt = DataUtils.round($scope.accountCashier.diffAmt, 2);
            $scope.accountCashier.invoiceAmt = DataUtils.round($scope.invoiceBaseAmtTtl - $scope.paymentBaseAmtTtl, 2);
            $scope.accountCashier.baseAmt = DataUtils.round($scope.accountCashier.baseAmt, 4);

            $scope.originalBalance = 0;
            if($scope.accountCashier && $scope.accountCashier.originalCurrency && !isMix){
                angular.forEach($scope.invoiceTtlList, function(data){
                    if (data.currency == $scope.accountCashier.originalCurrency){
                        $scope.originalBalance += data.total;
                    }
                })
                angular.forEach($scope.paymentTtlList, function(data){
                    if (data.currency == $scope.accountCashier.originalCurrency){
                        $scope.originalBalance -= data.total;
                    }
                })      
                $scope.originalBalance = (!$scope.accountCashier.originalAmt?0:$scope.accountCashier.originalAmt) - $scope.originalBalance;
                $scope.originalBalance = DataUtils.round($scope.originalBalance, 2);

                if($scope.originalBalance == 0 && isSameEx){
                    $scope.accountCashier.diffAmt = 0;
                    $scope.accountCashier.invoiceAmt = 0;
                }
            }
            $translate.refresh();
        }

        $scope.clearDiff = function(){
            if(!$scope.accountCashier.diffAmt || !$scope.accountCashier.exchangeRate){
                return;
            }
            var diffOrgAmt = $scope.accountCashier.diffAmt/$scope.accountCashier.exchangeRate;
            $scope.accountCashier.originalAmt -= diffOrgAmt;
            $scope.accountCashier.originalAmt = DataUtils.round($scope.accountCashier.originalAmt, 2);
            updateList();
        }


        function autoAdjustAmt(){
            if(!autoCheck || !autoCheck.id){
                return;
            }
            var diffAmt = $scope.originalBalance;
            if(diffAmt < 0){
                diffAmt = -diffAmt;
                angular.forEach($scope.gridOptions_1.data, function(invoice){
                    if(invoice && invoice.writeOffRates && !invoice.manual
                        && invoice.writeOffRates.length > 0 && diffAmt > 0){
                        //核销最低金额
                        invoice.checkBal = 0;
                        angular.forEach(invoice.writeOffRates, function(data){
                            if (data.isPay) {
                                invoice.checkBal += data.diffAmount;
                            }
                            else if (data.totalAmount < 0) {
                                invoice.checkBal -= data.diffAmount;
                            }
                        })
                        if (!invoice.actualAmount){
                            invoice.actualAmount = 0;
                        }
                        //可以扣减的金额
                        var reduceAmt = invoice.actualAmount - invoice.checkBal;

                        if(diffAmt > reduceAmt){
                            diffAmt = diffAmt - reduceAmt;
                            invoice.actualAmount = DataUtils.round(invoice.checkBal, 2);
                        }
                        else {
                            invoice.actualAmount = DataUtils.round(invoice.actualAmount - diffAmt, 2);
                            diffAmt = 0;
                        }
                        var thisAmount = invoice.actualAmount.toFixed(2) - 0;
                        //清空记录
                        angular.forEach(invoice.writeOffRates, function(data){
                            data.thisAmount = 0;
                        });

                        //更新费用列表
                        angular.forEach(invoice.writeOffRates, function(data){
                            if (data.isPay || data.totalAmount < 0) {
                                data.thisAmount = data.diffAmount.toFixed(2) - 0;
                                thisAmount = thisAmount - data.diffAmount;
                            }
                        });
                        angular.forEach(invoice.writeOffRates, function(data){
                            if (!data.isPay && data.totalAmount >= 0) {     
                                if (thisAmount >= data.diffAmount) {
                                    data.thisAmount = data.diffAmount.toFixed(2) - 0;
                                    thisAmount = thisAmount - data.diffAmount;
                                } 
                                else {
                                    data.thisAmount = thisAmount.toFixed(2) - 0;
                                    thisAmount = 0;
                                }
                            }
                        });
                    }
                })
            }
            else {
                angular.forEach($scope.gridOptions_2.data, function(payment){
                    if(payment && payment.writeOffRates && !payment.manual
                        && payment.writeOffRates.length > 0 && diffAmt > 0){
                        //核销最低金额
                        payment.checkBal = 0;
                        angular.forEach(payment.writeOffRates, function(data){
                            if (!data.isPay) {
                                payment.checkBal += data.diffAmount;
                            }
                            else if (data.totalAmount < 0) {
                                payment.checkBal -= data.diffAmount;
                            }
                        })
                        
                        if (null == payment.actualAmount){
                            payment.actualAmount = 0;
                        }
                        //可以扣减的金额
                        var reduceAmt = payment.actualAmount - payment.checkBal;

                        if(diffAmt > reduceAmt){
                            diffAmt = diffAmt - reduceAmt;
                            payment.actualAmount = DataUtils.round(payment.checkBal, 2);
                        }
                        else {
                            payment.actualAmount = DataUtils.round(payment.actualAmount - diffAmt, 2);
                            diffAmt = 0;
                        }
                        var thisAmount = payment.actualAmount.toFixed(2) - 0;
                        //清空记录
                        angular.forEach(payment.writeOffRates, function(data){
                            data.thisAmount = 0;
                        });
                        //更新费用列表
                        angular.forEach(payment.writeOffRates, function(data){
                            if (!data.isPay || data.totalAmount < 0) {
                                data.thisAmount = data.diffAmount.toFixed(2) - 0;
                                thisAmount = thisAmount - data.diffAmount;
                            }
                        });
                        angular.forEach(payment.writeOffRates, function(data){
                            if (data.isPay && data.totalAmount >= 0) {
                                if (thisAmount >= data.diffAmount) {
                                    data.thisAmount = data.diffAmount.toFixed(2) - 0;
                                    thisAmount = thisAmount - data.diffAmount;
                                }
                                else {
                                    data.thisAmount = thisAmount.toFixed(2) - 0;
                                    thisAmount = 0;
                                }
                            }
                        });
                    }
                })
            }
        }

        $scope.isSaving = false;
        $scope.submitWriteOff = function () {
            $scope.isSaving = true;
            if($scope.accountCashier.invoiceAmt > 0 && $scope.accountCashier.originalAmt < 0 && !$scope.accountCashier.oppositeId){
                layer.msg($translate.instant('autopsApp.accountInvoice.dnCnWrong'));
                $scope.isSaving = false;
                return;
            }
            if($scope.accountCashier.invoiceAmt < 0 && $scope.accountCashier.originalAmt > 0 && !$scope.accountCashier.oppositeId){
                layer.msg($translate.instant('autopsApp.accountInvoice.dnCnWrong'));
                $scope.isSaving = false;
                return;
            }

            if (!$scope.accountCashier.accountName && $rootScope.HAVEAUTH('FMIS')) {
                layer.msg($translate.instant('autopsApp.accountInvoice.home.noAccountChoosed'));
                $scope.isSaving = false;
                return;
            }
            if (!$scope.accountCashier.payerName && $rootScope.HAVEAUTH('FMIS') && $rootScope.account.companyId == 153) {
                layer.msg($translate.instant('autopsApp.accountInvoice.home.noPayerChoosed'));
                $scope.isSaving = false;
                return;
            }
            if(!$scope.isMix){
                var notOk = false;
                angular.forEach($scope.gridOptions_2.data, function(data){  
                    if(data.currency != $scope.accountCashier.originalCurrency){
                        notOk = true;
                    }
                });    
                angular.forEach($scope.gridOptions_1.data, function(data){                
                    if(data.currency != $scope.accountCashier.originalCurrency){
                        notOk = true;
                    }
                });    
                if(notOk){
                    layer.msg($translate.instant('autopsApp.accountInvoice.mixCurrenyAlert'));
                    $scope.isSaving = false;
                    return;
                }
            }
            if (!$scope.accountCashier.transactionRef) {
                layer.msg($translate.instant('autopsApp.accountInvoice.noWriteOffCodeAlert'));
                $scope.isSaving = false;
                return;
            }
            if(!$scope.accountCashier.transactionDate && $rootScope.HAVEAUTH('FMIS')){
                layer.msg($translate.instant('autopsApp.accountInvoice.accountingDateNullAlert'));
                $scope.isSaving = false;
                return;
            }
            if ((!$scope.gridOptions_2.data || $scope.gridOptions_2.data.length == 0) && (!$scope.gridOptions_1.data || $scope.gridOptions_1.data.length == 0)) {
                layer.msg($translate.instant('autopsApp.accountInvoice.home.nothingChoosed'));
                $scope.isSaving = false;
                return;
            }

            if (null == $scope.accountCashier.originalAmt) {
                layer.msg($translate.instant('autopsApp.accountInvoice.home.noPayAmount'));
                $scope.isSaving = false;
                return;
            }

            //账单付款合计与发票金额对比
            if ($scope.accountCashier.diffAmt){
                if ($rootScope.HAVEAUTH('FMIS')){
                    if (null == $scope.accountCashier.oppositeName){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.accountInvoice.diffAccountNullAlert', {ref: $scope.accountCashier.transactionRef}));
                        $scope.isSaving = false;
                        return;
                    }                    
                    if (($scope.accountCashier.baseAmt > 0 && $scope.accountCashier.diffAmt > 0)
                        || ($scope.accountCashier.baseAmt < 0 && $scope.accountCashier.diffAmt < 0)){
                        if (!$scope.accountCashier.oppositeId){
                            $scope.accountCashier.oppositeName = null;
                            $rootScope.OPS_ALERT($translate.instant('autopsApp.accountInvoice.diffAccountNullAlert', {ref: $scope.accountCashier.transactionRef}));
                            $scope.isSaving = false;
                            return;
                        }
                    }
                    else if (!$scope.accountCashier.oppositeId){
                        autoAdjustAmt();
                    }
                }
                else {
                    if (($scope.accountCashier.baseAmt > 0 && $scope.accountCashier.diffAmt > 0)
                        || ($scope.accountCashier.baseAmt < 0 && $scope.accountCashier.diffAmt < 0)){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.accountInvoice.bankAmtBiger'));
                        return;
                    }   
                    autoAdjustAmt();
                }        
                   
                // if(amended){
                //     $rootScope.OPS_ALERT($translate.instant('autopsApp.accountInvoice.home.notEqual'));
                //     $scope.isSaving = false;
                //     return;     
                // }    
            }
            else {
                $scope.accountCashier.oppositeId = null;
                $scope.accountCashier.oppositeName = null;
                $scope.accountCashier.oppositeCode = null;
            }

            var paymentType = "";
            if ($scope.accountCashier.accountCode){
                if ($scope.accountCashier.accountCode.substring(0,4) == "1001"){
                    paymentType = "cash";
                }
                else if ($scope.accountCashier.accountCode.substring(0,4) == "1002") {
                    paymentType = "deposit";
                }
                else {
                    paymentType = "advance";
                }
            }
            //付款方式
            angular.forEach($scope.gridOptions_2.data, function(data){                
                data.paymentType = paymentType;
            });    
            angular.forEach($scope.gridOptions_1.data, function(data){                
                data.paymentType = paymentType;
            });    
            
            if (!$scope.accountCashier.id){
                if($scope.gridOptions_2.data.length > 0){
                    if(!$scope.accountCashier.payerName){
                        $scope.accountCashier.payerName = $scope.gridOptions_2.data[0].invoiceTitle;
                        $scope.accountCashier.payerVid = $scope.gridOptions_2.data[0].venderId;
                    }
                }
                else {
                    if(!$scope.accountCashier.payerName){
                        $scope.accountCashier.payerName = $scope.gridOptions_1.data[0].invoiceTitle;
                        $scope.accountCashier.payerVid = $scope.gridOptions_1.data[0].venderId;
                    }                    
                }
            }

            layer.load(1, {shade: 0.3});
            AccountCashier.comWriteOff(getWriteOffDTO(), onSaveSuccess, onSaveError);   
        }

        function getWriteOffDTO(){
            var accountWriteOffDTO = {};
            accountWriteOffDTO.accountCashier = $scope.accountCashier;
            accountWriteOffDTO.accountPaymentDTOs = [];
            accountWriteOffDTO.accountInvoiceDTOs = [];
            accountWriteOffDTO.writeOffRemarks = $scope.accountCashier.writeOffRemarks;

            angular.forEach($scope.gridOptions_2.data, function(data){
                var accountPaymentDTO = {};
                accountPaymentDTO.accountPayment = data;
                accountPaymentDTO.writeOffExchangeRate = data.writeOffExchangeRate;
                accountPaymentDTO.writeOffRates = data.writeOffRates;
                accountWriteOffDTO.accountPaymentDTOs.push(accountPaymentDTO);
            });

            angular.forEach($scope.gridOptions_1.data, function(data){
                var accountInvoiceDTO = {};
                accountInvoiceDTO.accountInvoice = data;
                accountInvoiceDTO.writeOffExchangeRate = data.writeOffExchangeRate;
                accountInvoiceDTO.writeRates = data.writeOffRates;
                accountWriteOffDTO.accountInvoiceDTOs.push(accountInvoiceDTO);
            });
            return accountWriteOffDTO;
        }

        var onSaveSuccess = function(result) {            
            $scope.isSaving = false;
            layer.closeAll(); 
            if(result && result.id && $rootScope.HAVEAUTH('ROLE_ACCOUNT')){
                $rootScope.VIEWVOUCHER(null, null, result);
            }
            if (accountCashier || autoCheck || accountInvoice || accountPayment){
                $scope.clear();
                return;
            }
            $scope.gridOptions_2.data = [];
            $scope.gridOptions_1.data = [];  
            $scope.accountCashier = {};
            $scope.dnpayer = "";
            $scope.cnpayer = "";
            $scope.cnChecked = false;
            $scope.dnChecked = false;
            updateCheckedTtl();
            updateList();
            $scope.accountCashier.transactionDate = new Date();            
            loadTransactions();
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false; 
            layer.closeAll(); 
            $rootScope.ALERT_ERROR(result);
        };
        
        $scope.originalAmtChange = function() {
            updateList();
        }
        $scope.dnpayer = "";
        $scope.cnpayer = "";
        $scope.etdEndDate = null;
        $scope._onpayerselected = function(data, dncn) {
            if (dncn == 'dn'){
                AccountInvoice.getByDnNumOrVender({
                    companyId: $rootScope.account.companyId,
                    etdEndDate: DateUtils.convertLocalDateToServer($scope.etdEndDate),
                    venderId: data.id
                }, function(result) {
                    if (result.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));
                        $scope.dnpayer = "";
                        return;
                    }                
                    for(var i = result.length -1; i >=0; i--){   
                        if ($scope.isMix || result[i].currency == $scope.accountCashier.originalCurrency){
                            $scope.addToInvoiceList(result[i]);
                        }                        
                    }
                });
            }
            else {
                AccountPayment.getByDnNumOrVender({
                    companyId: $rootScope.account.companyId,
                    etdEndDate: DateUtils.convertLocalDateToServer($scope.etdEndDate),
                    venderId: data.id
                }, function(result) {     
                    if (result.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));
                        $scope.cnpayer = "";
                        return;
                    }                
                    for(var i = result.length -1; i >=0; i--){   
                        if ($scope.isMix || result[i].currency == $scope.accountCashier.originalCurrency){
                            $scope.addToPaymentList(result[i]);
                        }                        
                    }
                });
            }
        }

        $scope.setDiffZero = function(request, isPay) {
            if(isPay){
                angular.forEach($scope.gridOptions_2.data, function(data){
                    if(data.currency == request.currency){
                        data.writeOffExchangeRate = request.writeOffExchangeRate;
                    }
                })
            }
            else {
                angular.forEach($scope.gridOptions_1.data, function(data){
                    if(data.currency == request.currency){
                        data.writeOffExchangeRate = request.writeOffExchangeRate;
                    }
                })
            }
            updateList();    
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.changeInvoiceAmount = function (invoice) {
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountant/writeoff-invoice-pop.html',
                controller: 'WriteoffInvoicePopController',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return invoice;
                    }]
                }
            }).result.then(function(result) {
                updateList();
            }, function() {
                updateList();
            });
        }
        $scope.changePaymentAmount = function (accountPayment) {
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountant/writeoff-payment-pop.html',
                controller: 'WriteoffPaymentPopController',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return accountPayment;
                    }]
                }
            }).result.then(function(result) {
                updateList();
            }, function() {
                updateList();
            });
        }

        $scope.searchDn = function() {
            var searchParams = {
                isPaid: false,
                isVoided: false,
                isTtl: false,
                companyId: $rootScope.account.companyId
            };
            if($scope.accountCashier.originalCurrency && !$scope.isMix){
                searchParams.currency = $scope.accountCashier.originalCurrency;
            }
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountInvoice/accountInvoice-dialog.html',
                controller: 'AccountInvoiceDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                angular.forEach(results, function(data){
                    if(!data.isTtl && data.id){
                        $scope.addToInvoiceList(data);
                    }
                })
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        $scope.searchCn = function() {
            var searchParams = {
                isPaid: false,
                isVoided: false,
                isTtl: false,
                companyId: $rootScope.account.companyId
            };
            if($scope.accountCashier.originalCurrency && !$scope.isMix){
                searchParams.currency = $scope.accountCashier.originalCurrency;
            }
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountPayment/accountPayment-dialog.html',
                controller: 'AccountPaymentDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountPayment');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                angular.forEach(results, function(data){
                    if(!data.isTtl && data.id){
                        $scope.addToPaymentList(data);
                    }                    
                })
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        var updateCheckedTtl = function() {
            $scope.choosedInvoiceTtl = {};
            $scope.choosedInvoiceTtl.curList = [];
            var ttlAmt = 0;
            var currencies = new Set();
            var currencyTtl;
            angular.forEach($scope.gridOptions_1.data, function(data){
                if(data.actualAmount && data.isChecked){
                    currencies.add(data.currency);
                    ttlAmt += data.writeOffBaseAmt;
                }      
            })
            $scope.choosedInvoiceTtl.ttlAmt = DataUtils.round(ttlAmt, 2);              
            currencies.forEach(function(currency){
                currencyTtl = 0;
                angular.forEach($scope.gridOptions_1.data, function(data){
                    if (data.currency == currency && data.isChecked){
                        currencyTtl = currencyTtl + data.actualAmount;
                    }
                })
                $scope.choosedInvoiceTtl.curList.push({
                    currency: currency,
                    total: DataUtils.round(currencyTtl, 2)
                });      
            });

            $scope.unchoosedInvoiceTtl = {};
            $scope.unchoosedInvoiceTtl.curList = [];
            ttlAmt = 0;
            currencies = new Set();
            currencyTtl;
            angular.forEach($scope.gridOptions_1.data, function(data){
                if(data.actualAmount && !data.isChecked){
                    currencies.add(data.currency);
                    ttlAmt += data.writeOffBaseAmt;
                }      
            })
            $scope.unchoosedInvoiceTtl.ttlAmt = DataUtils.round(ttlAmt, 2);              
            currencies.forEach(function(currency){
                currencyTtl = 0;
                angular.forEach($scope.gridOptions_1.data, function(data){
                    if (data.currency == currency && !data.isChecked){
                        currencyTtl = currencyTtl + data.actualAmount;
                    }
                })
                $scope.unchoosedInvoiceTtl.curList.push({
                    currency: currency,
                    total: DataUtils.round(currencyTtl, 2)
                });      
            });
            $scope.choosedPaymentTtl = {};
            $scope.choosedPaymentTtl.curList = [];
            ttlAmt = 0;
            currencies = new Set();
            angular.forEach($scope.gridOptions_2.data, function(data){
                if(data.actualAmount && data.isChecked){
                    currencies.add(data.currency);
                    ttlAmt += data.writeOffBaseAmt;
                }      
            })
            $scope.choosedPaymentTtl.ttlAmt = DataUtils.round(ttlAmt, 2);              
            currencies.forEach(function(currency){
                currencyTtl = 0;
                angular.forEach($scope.gridOptions_2.data, function(data){
                    if (data.currency == currency && data.isChecked){
                        currencyTtl = currencyTtl + data.actualAmount;
                    }
                })
                $scope.choosedPaymentTtl.curList.push({
                    currency: currency,
                    total: DataUtils.round(currencyTtl, 2)
                });      
            });
            $scope.unchoosedPaymentTtl = {};
            $scope.unchoosedPaymentTtl.curList = [];
            ttlAmt = 0;
            currencies = new Set();
            angular.forEach($scope.gridOptions_2.data, function(data){
                if(data.actualAmount && !data.isChecked){
                    currencies.add(data.currency);
                    ttlAmt += data.writeOffBaseAmt;
                }      
            })
            $scope.unchoosedPaymentTtl.ttlAmt = DataUtils.round(ttlAmt, 2);              
            currencies.forEach(function(currency){
                currencyTtl = 0;
                angular.forEach($scope.gridOptions_2.data, function(data){
                    if (data.currency == currency && !data.isChecked){
                        currencyTtl = currencyTtl + data.actualAmount;
                    }
                })
                $scope.unchoosedPaymentTtl.curList.push({
                    currency: currency,
                    total: DataUtils.round(currencyTtl, 2)
                });      
            });
        }

        $scope.dnChecked = false;
        function setDnChecked () {
            $scope.dnChecked = false;
            angular.forEach($scope.gridOptions_1.data, function(data){
                data.isChecked = false;               
            })
            angular.forEach($scope.gridApi_1.selection.getSelectedRows(), function(data){
                data.isChecked = true;
                $scope.dnChecked = true;
            })
            updateCheckedTtl();
        }
        $scope.clearDnChecked = function() {
            $scope.gridApi_1.selection.clearSelectedRows();
            $scope.dnChecked = false;
            updateCheckedTtl();
        } 

        $scope.clearCnChecked = function() {
            $scope.gridApi_2.selection.clearSelectedRows();
            $scope.cnChecked = false;
            updateCheckedTtl();
        }      

        $scope.delAllDnChecked = function() {
            for(var i = $scope.gridOptions_1.data.length - 1; i >= 0; i--){
                if($scope.gridOptions_1.data[i].isChecked == true){
                    $scope.gridOptions_1.data.splice(i, 1);
                }
            }
            updateList(); 
            updateCheckedTtl();
        }
        $scope.delAllDnUnChecked = function() {
            for(var i = $scope.gridOptions_1.data.length - 1; i >= 0; i--){
                if($scope.gridOptions_1.data[i].isChecked == false){
                    $scope.gridOptions_1.data.splice(i, 1);
                }
            }
            updateList(); 
            updateCheckedTtl();
        }

        $scope.cnChecked = false;
        function setCnChecked () {
            $scope.cnChecked = false;
            angular.forEach($scope.gridOptions_2.data, function(data){
                data.isChecked = false;               
            })
            angular.forEach($scope.gridApi_2.selection.getSelectedRows(), function(data){
                data.isChecked = true;
                $scope.cnChecked = true;
            })
            updateCheckedTtl();
        }

        $scope.delAllCnChecked = function() {
            for(var i = $scope.gridOptions_2.data.length - 1; i >= 0; i--){
                if($scope.gridOptions_2.data[i].isChecked == true){
                    $scope.gridOptions_2.data.splice(i, 1);
                }
            }
            updateList(); 
            updateCheckedTtl();
        }
        $scope.delAllCnUnChecked = function() {
            for(var i = $scope.gridOptions_2.data.length - 1; i >= 0; i--){
                if($scope.gridOptions_2.data[i].isChecked == false){
                    $scope.gridOptions_2.data.splice(i, 1);
                }
            }
            updateList(); 
            updateCheckedTtl();
        }
        $scope.accountingDateChange = function() {
            if(!$scope.accountCashier.transactionDate){
                return;
            }
            OpsExchangeRate.getExchangeRatesMap({
                date: DateUtils.convertLocalDateToServer($scope.accountCashier.transactionDate)
            }, function(result) {
                exchangeRates = result;
                $scope.currencyChange($scope.accountCashier.originalCurrency);
            })
        }

        $scope.gridOptions_1 = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: true,//表格的footer
            showColumnFooter: false,//每列footer
            gridFooterTemplate:'<div class="ml-5 mt-5"><strong><span translate="autopsApp.accountInvoice.baseTtl">baseTtl</span>: <span class="label label-danger" ng-if="grid.appScope.invoiceBaseAmtTtl" style="margin-right: 18px;">{{grid.appScope.account.baseCurrency}}{{grid.appScope.invoiceBaseAmtTtl}}</span><span ng-if="grid.appScope.invoiceTtlList && grid.appScope.invoiceTtlList.length > 0"><span translate="autopsApp.accountInvoice.originalTtl">originalTtl</span>: <span ng-repeat="ttl in grid.appScope.invoiceTtlList track by $index" ng-class="{true: \'label label-info\', false: \'label label-primary\'}[$index%2 ==0]" style="margin-right: 8px;">{{ttl.currency}}{{ttl.total}}</span></span></strong></div>',

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'dn.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 50,
            columnDefs: [
                { field: 'dnNum', 
                    width: 130,
                    cellTemplate: '<span class="grid-text"><a href="" ng-click="grid.appScope.PREVIEW_DCN(row.entity)">{{row.entity.dnNum}}</a><span class="glyphicon glyphicon-remove ml-4 text-danger" ng-click="grid.appScope.delFromInvoiceList(row.entity)"></span></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.dnNum')},
                { field: 'actualAmount', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text"><strong ng-if="row.entity.actualAmount != null" class="text-danger">{{row.entity.actualAmount}}</strong><span ng-click="grid.appScope.changeInvoiceAmount(row.entity)" class="text-danger ml-4"><span class="glyphicon glyphicon-pencil"></span></span></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.home.writeOffAmount')},

                { field: 'currency', 
                    width: 50,
                    displayName: $translate.instant('autopsApp.accountInvoice.currency')},
                { field: 'writeOffExchangeRate', 
                    width: 80,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<input type="number" class="grid-input" ng-model="row.entity.writeOffExchangeRate" ng-dblclick="grid.appScope.setDiffZero(row.entity, false)" ng-change="grid.appScope.writeOffExchangeRateChange()"/>',
                    displayName: $translate.instant('autopsApp.accountInvoice.writeOffExchangeRate')},
                { field: 'writeOffBaseAmt', 
                    width: 120,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text"><span>{{grid.appScope.account.baseCurrency}}{{row.entity.writeOffBaseAmt}}</span></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.originalAmount')},
                { field: 'paidAmount', 
                    width: 80,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.paidAmount}}</span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.paidAmount')},
                { field: 'invoiceAmount', 
                    width: 80,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.invoiceAmount}}</span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.invoiceAmount')},
                { field: 'exchangeRate', 
                    width: 80,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.accountInvoice.exchangeRate')},
                { field: 'payer', 
                    width: 120,
                    displayName: $translate.instant('autopsApp.accountInvoice.payer')},
                { field: 'economicDate', 
                    width: 120,
                    displayName: $translate.instant('autopsApp.accountInvoice.economicDate')},
                { field: 'invoiceNum', 
                    width: 120,
                    displayName: $translate.instant('autopsApp.accountInvoice.invoiceNum')},
                { field: 'blNums', 
                    width: 120,
                    displayName: $translate.instant('autopsApp.accountInvoice.blNums')},
                { field: 'jobNums', 
                    width: 120,
                    displayName: $translate.instant('autopsApp.accountInvoice.jobNums')},
                { field: 'sales', 
                    width: 80,
                    displayName: $translate.instant('autopsApp.accountInvoice.salesName')},
                { field: 'creater', 
                    width: 80,
                    displayName: $translate.instant('autopsApp.accountInvoice.creater')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_1.gridState = angular.toJson($scope.gridApi_1.saveState.save());
                    gridStateObj_1.userId = $rootScope.account.id;
                    gridStateObj_1.gridId = 107;
                    GridState.update(gridStateObj_1, function(result){
                        gridStateObj_1 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_1.gridState){
                        $scope.gridApi_1.saveState.restore($scope, angular.fromJson(gridStateObj_1.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi_1.saveState.restore($scope, gridStateCopy_1);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi_1 = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope,function(row){
                    setDnChecked();
                });
                gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                    setDnChecked();
                });
            }
        }
        $scope.gridOptions_2 = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: true,//表格的footer
            showColumnFooter: false,//每列footer
            gridFooterTemplate:'<div class="ml-5 mt-5"><strong><span translate="autopsApp.accountInvoice.baseTtl">baseTtl</span>: <span class="label label-danger" ng-if="grid.appScope.paymentBaseAmtTtl" style="margin-right: 18px;">{{grid.appScope.account.baseCurrency}}{{grid.appScope.paymentBaseAmtTtl}}</span><span ng-if="grid.appScope.paymentTtlList && grid.appScope.paymentTtlList.length > 0"><span translate="autopsApp.accountInvoice.originalTtl">originalTtl</span>: <span ng-repeat="ttl in grid.appScope.paymentTtlList track by $index" ng-class="{true: \'label label-info\', false: \'label label-primary\'}[$index%2 ==0]" style="margin-right: 8px;">{{ttl.currency}}{{ttl.total}}</span></span></strong></div>',

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'cn.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 50,
            columnDefs: [
                { field: 'dnNum', 
                    width: 150,
                    cellTemplate: '<span class="grid-text"><a href="" ng-click="grid.appScope.PREVIEW_DCN(row.entity)"><span class="glyphicon glyphicon-ok-circle" ng-if="row.entity.isApproved"></span> {{row.entity.dnNum}}</a><span class="glyphicon glyphicon-remove ml-4 text-danger" ng-click="grid.appScope.delFromPaymentList(row.entity)"></span></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.dnNum')},
                { field: 'actualAmount', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text"><strong ng-if="row.entity.actualAmount != null" class="text-danger">{{row.entity.actualAmount}}</strong><span ng-click="grid.appScope.changePaymentAmount(row.entity)" class="text-danger ml-4"><span class="glyphicon glyphicon-pencil"></span></span></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.home.writeOffAmount')},
                { field: 'currency', 
                    width: 50,
                    displayName: $translate.instant('autopsApp.accountInvoice.currency')},
                { field: 'writeOffExchangeRate', 
                    width: 80,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<input type="number" class="grid-input" ng-model="row.entity.writeOffExchangeRate" ng-dblclick="grid.appScope.setDiffZero(row.entity, true)" ng-change="grid.appScope.writeOffExchangeRateChange()"/>',
                    displayName: $translate.instant('autopsApp.accountInvoice.writeOffExchangeRate')},
                { field: 'writeOffBaseAmt', 
                    width: 120,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text"><span>{{grid.appScope.account.baseCurrency}}{{row.entity.writeOffBaseAmt}}</span></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.originalAmount')},
                { field: 'paidAmount', 
                    width: 80,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.paidAmount}}</span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.paidAmount')},
                { field: 'invoiceAmount', 
                    width: 80,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.invoiceAmount}}</span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.invoiceAmount')},
                { field: 'exchangeRate', 
                    width: 80,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.accountInvoice.exchangeRate')},
                { field: 'invoiceTitle', 
                    width: 120,
                    displayName: $translate.instant('autopsApp.accountInvoice.payee')},
                { field: 'economicDate', 
                    width: 120,
                    displayName: $translate.instant('autopsApp.accountInvoice.economicDate')},
                { field: 'invoiceNum', 
                    width: 120,
                    displayName: $translate.instant('autopsApp.accountInvoice.invoiceNum')},
                { field: 'blNums', 
                    width: 120,
                    displayName: $translate.instant('autopsApp.accountInvoice.blNums')},
                { field: 'jobNums', 
                    width: 120,
                    displayName: $translate.instant('autopsApp.accountInvoice.jobNums')},
                { field: 'sales', 
                    width: 80,
                    displayName: $translate.instant('autopsApp.accountInvoice.salesName')},
                { field: 'creater', 
                    width: 80,
                    displayName: $translate.instant('autopsApp.accountInvoice.creater')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_2.gridState = angular.toJson($scope.gridApi_2.saveState.save());
                    gridStateObj_2.userId = $rootScope.account.id;
                    gridStateObj_2.gridId = 108;
                    GridState.update(gridStateObj_2, function(result){
                        gridStateObj_2 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_2.gridState){
                        $scope.gridApi_2.saveState.restore($scope, angular.fromJson(gridStateObj_2.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi_2.saveState.restore($scope, gridStateCopy_2);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi_2 = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope,function(row){
                    setCnChecked();
                });
                gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                    setCnChecked();
                });
            }
        }

        var gridStateObj_1 = {};
        var gridStateCopy_1 = null;
        var gridStateObj_2 = {};
        var gridStateCopy_2 = null;

        $timeout(function (){
            gridStateCopy_1 = $scope.gridApi_1.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 107
            }, function(result){
                gridStateObj_1 = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions_1);
                if(!gridStateObj_1 || !gridStateObj_1.id){
                    $scope.gridApi_1.saveState.restore($scope, gridStateCopy_1);
                    return;
                }   
                $scope.gridApi_1.saveState.restore($scope, angular.fromJson(gridStateObj_1.gridState));
            })

            gridStateCopy_2 = $scope.gridApi_2.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 108
            }, function(result){
                gridStateObj_2 = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions_2);
                if(!gridStateObj_2 || !gridStateObj_2.id){
                    $scope.gridApi_2.saveState.restore($scope, gridStateCopy_2);
                    return;
                }   
                $scope.gridApi_2.saveState.restore($scope, angular.fromJson(gridStateObj_2.gridState));
            })

        });
        $scope.gridOptions_1.data = [];
        $scope.gridOptions_2.data = [];

        $scope.getWriteOffCode = function () {
            WriteOff.getWriteOffCode({companyId: $rootScope.account.companyId}, function(data){
                $scope.accountCashier.transactionRef = data.value;
            })
        }
        $scope.refreshDnGuideRates = function () {
            layer.confirm($translate.instant('autopsApp.accountInvoice.refreshDnGuideRatesAlert'), {
                icon: 3,
                title: "Alert",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                GuideRates.refreshDnGuideRates({companyId: $rootScope.account.companyId}, getWriteOffDTO(), 
                    onSaveSuccess, onSaveError);
            }, function() {});
        }

    }
})();