(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideRatesDeleteController', GuideRatesDeleteController);

    GuideRatesDeleteController.$inject = ['$rootScope', '$scope', '$timeout', '$uibModalInstance', '$translatePartialLoader', '$translate', 
    'entity', 'GuideRates', 'GridState', 'uiGridConstants'];
    
	function GuideRatesDeleteController($rootScope, $scope, $timeout, $uibModalInstance, $translatePartialLoader, $translate, 
        entity, GuideRates, GridState, uiGridConstants) {
        $scope.request = entity;
        $translatePartialLoader.addPart('accountPayment');
        $translate.refresh();
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };


        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,   

            enableGridMenu : true,//表格 菜单  
            showGridFooter: false,//表格的footer  
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterExcelFilename: $translate.instant('entity.action.viewDetails') + "-" + $scope.request.dnNum,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'jobNum', 
                    width: 150,
                    displayName: $translate.instant('autopsApp.accountPayment.jobNums'),
                    cellTemplate: '<span class="grid-text"><a href=""ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity.jobNum}}</a></span>'},
                { field: 'mblNum', 
                    width:150,
                    displayName: $translate.instant('autopsApp.accountPayment.blNums')},
                { field: 'item', 
                    width:120,
                    displayName: $translate.instant('autopsApp.accountPayment.item')},
                { field: 'currency', 
                    width: 60,
                    displayName: $translate.instant('autopsApp.accountPayment.currency'),
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.currency}}</strong>'},
                { field: 'diffAmount', 
                    width: 100,
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],        
                    aggregationType: uiGridConstants.aggregationTypes.sum,  
                    displayName: $translate.instant('autopsApp.accountPayment.balance'),
                    cellTemplate: '<strong class="grid-text text-danger" ng-class="{true: \'info\', false: \'danger\'}[row.entity.diffAmount > 0]">{{row.entity.diffAmount}}</strong>'},
                { field: 'totalAmount', 
                    width: 100,
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],        
                    aggregationType: uiGridConstants.aggregationTypes.sum,  
                    displayName: $translate.instant('autopsApp.accountPayment.total'),
                    cellTemplate: '<strong class="grid-text text-danger" ng-class="{true: \'info\', false: \'danger\'}[row.entity.totalAmount > 0]">{{row.entity.totalAmount}}</strong>'},
                { field: 'etd', 
                    displayName: $translate.instant('autopsApp.accountPayment.economicDate'), 
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.etd | date:\'mediumDate\'}}</span>'},
                { field: 'billNum', 
                    width:120,
                    displayName: $translate.instant('autopsApp.accountPayment.billNum')},
                { field: 'salesName', 
                    width:120,
                    displayName: $translate.instant('autopsApp.accountPayment.salesName')},
                { field: 'payer', 
                    width:150,
                    displayName: $translate.instant('autopsApp.accountPayment.payer')},
               { field: 'vslVoy', 
                    width:100,
                    displayName: $translate.instant('autopsApp.accountPayment.vslVoy')},
               { field: 'ctnMawb', 
                    width:100,
                    displayName: 'CTN/MAWB#'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 110;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }    
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "dnCharges";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "dnCharges";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi; 
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);                     
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }

                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            GuideRates.getWrieteOffRatesByDnNum({
                companyId: $scope.request.companyId,
                dnNum: $scope.request.dnNum
            }, function(result) {
                $scope.gridOptions.data = result;
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay){
                        data.diffAmount = -data.diffAmount;
                        data.totalAmount = -data.totalAmount;
                    }
                })
            });

            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 110
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });


    }
})();
