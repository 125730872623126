(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AgingAnalysisJobController', AgingAnalysisJobController);


    AgingAnalysisJobController.$inject = ['$scope', '$rootScope', '$translate', '$uibModal', '$timeout', 'DataUtils', 'AsyncTask',
    'Principal',  'OpsVender', 'GuideRates', 'OpsFiles', 'uiGridConstants', 'uiGridGroupingConstants', 'GridState', 'DateUtils'];

    function AgingAnalysisJobController($scope, $rootScope, $translate, $uibModal, $timeout, DataUtils, AsyncTask,
        Principal, OpsVender, GuideRates, OpsFiles, uiGridConstants, uiGridGroupingConstants, GridState, DateUtils) {

        var filterTimer, gridCopy;
        $scope.statParams = {statType: "offset"};
        $scope.statParams.cutDate = new Date();
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                if ($rootScope.account.authorities.indexOf("REPORT_AGING") !== -1){
                    $scope.statParams.isSales = false;
                }
                else {
                    $scope.statParams.isSales = true;
                }        
            });
        }
        else {
            if ($rootScope.account.authorities.indexOf("REPORT_AGING") !== -1){
                $scope.statParams.isSales = false;
            }
            else {
                $scope.statParams.isSales = true;
            }      
        }
        
        //----------统计报表
        $scope.dayFormat = "yyyy-MM-dd";

        $scope.isSaving = false;
        $scope.statsResult = {};
        $scope.creditLimit = $scope.creditBalance = 0;
        $scope.getContents = function() {
            $scope.isSaving = true;
            getStatParams();
            layer.load(1, {shade: 0.3});
            GuideRates.chargesAging($scope.statParams, function(result) {
                $scope.isSaving = false;
                layer.closeAll(); 
                // angular.forEach(result, function(data){
                //     data.payer = data.payer?data.payer.replace(/[ ]/g,""):"NULL";
                // })
                $scope.gridOptions.data = result;
                resize();
                updateCreditTtl();
                if (!result || result.length == 0) {
                    layer.msg($translate.instant('stats.stat.noData'));
                    return;
                }
            }, function(response) {
                $scope.isSaving = false;                
                if(response.status == 404){
                    layer.closeAll(); 
                    layer.msg($translate.instant('stats.stat.noData'));
                }
                else {
                    $rootScope.ALERT_ERROR(response);
                }
            });
        }
        var getStatParams = function() {
            if (!$scope.statParams.payer){
                $scope.statParams.venderId = null;
            }
            if (!$scope.statParams.locked){
                $scope.statParams.locked = null;
            }
            if($scope.salesList && $scope.salesList.length > 0){
                $scope.statParams.salesIds = [];
                angular.forEach($scope.salesList, function(data){
                    $scope.statParams.salesIds.push(data.id);
                })
            }
            else {
                $scope.statParams.salesIds = null;
            }

            if($scope.scopeList && $scope.scopeList.length > 0){
                $scope.statParams.scopeStr = "";
                angular.forEach($scope.scopeList, function(data){
                    $scope.statParams.scopeStr += data + " ";
                })
            }
            else {
                $scope.statParams.scopeStr = null;
            }

            $scope.statParams.allPaid = false;
            $scope.statParams.isHidden = false;

            $scope.statParams.isPay = null;
            if ($scope.statParams.statType == "payable"){
                $scope.statParams.isPay = true;
            }
            else if ($scope.statParams.statType == "receivable"){
                $scope.statParams.isPay = false;
            }
        }
        function updateCreditTtl(){
            $scope.creditLimit = $scope.creditBalance = 0;
            var venderIds = new Set();
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.venderId && !venderIds.has(data.venderId)){
                    if(data.creditLimit){
                        $scope.creditLimit += data.creditLimit;
                    }
                    if(data.creditBalance){
                        $scope.creditBalance += data.creditBalance;
                    }
                    venderIds.add(data.venderId)
                }
            })
        }

        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top -3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        $scope.gridOptions = {
            enableSorting: false,//排序
            enableRowHeaderSelection : false,  //行选中
            enableGridMenu : true,//表格 菜单  
            showGridFooter: true,//表格的footer  
            enableFiltering: true,//高级过滤
            showColumnFooter: true,//每列footer

            minRowsToShow: 15,
            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowSelection: false, //行选中
            enableSelectAll: false, //全部选中
            enableFullRowSelection : false, //行任意位置选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('global.menu.entities.agingAnalysisJob') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            gridFooterTemplate:'<div class="ml-5 mt-5"><span ng-if="grid.appScope.visibelTtl.length > 0"><strong class="text-muted ml-10">Filtered: </strong><span ng-repeat="total in grid.appScope.visibelTtl track by $index" class="label label-info mr-8 f-13">{{total.currency}}{{total.total}}</span></span><span ng-if="grid.appScope.visibelTtl.length > 0"><button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.delVisibelRows()"><span class="glyphicon glyphicon-remove"></span><span translate="entity.action.remove">remove</span></button></span></div>',
            columnDefs: [
                { field: 'payer', 
                    minWidth:200, 
                    grouping: { groupPriority: 0 }, 
                    aggregationType: uiGridConstants.aggregationTypes.count, 
                    aggregationHideLabel: false,
                    displayName: $translate.instant('stats.stat.settlementObj')},
                { field: 'agingVenderType', 
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.agingVenderType')},
                { field: 'payCurrency', 
                    minWidth:150, 
                    grouping: { groupPriority: 1 }, 
                    displayName: $translate.instant('stats.stat.guideRates.currency')},      
                { field: 'balanceAmt', 
                    type:'number',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.total')},                             
                { field: 'baseAmt', 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type:'number',
                    treeAggregationLabel: "Sum: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum, 
                    aggregationHideLabel: false,
                    minWidth:150, 
                    cellClass:'grid-primary',
                    displayName: $translate.instant('stats.stat.baseAmt') + " " + (!$rootScope.account.baseCurrency?"RMB":$rootScope.account.baseCurrency)},
                { field: 'creditLimit',           
                    treeAggregationType: uiGridGroupingConstants.aggregation.AVG,          
                    type:'number',                    
                    minWidth:150, 
                    footerCellTemplate: '<div class="grid-text"><span translate="stats.stat.ttlAmt"></span>: {{grid.appScope.creditLimit}}</div>',
                    cellClass:'grid-primary',
                    displayName: $translate.instant('stats.stat.creditLimit') + " " + (!$rootScope.account.baseCurrency?"RMB":$rootScope.account.baseCurrency)},
                { field: 'creditBalance',              
                    treeAggregationType: uiGridGroupingConstants.aggregation.AVG,       
                    type:'number',                    
                    minWidth:150, 
                    footerCellTemplate: '<div class="grid-text"><span translate="stats.stat.ttlAmt"></span>: {{grid.appScope.creditBalance}}</div>',
                    cellClass:'grid-primary',
                    displayName: $translate.instant('stats.stat.creditBalance') + " " + (!$rootScope.account.baseCurrency?"RMB":$rootScope.account.baseCurrency)},
                { field: 'paymentTerm', 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.paymentTerm')},
                { field: 'creditDays', 
                    type:'number', 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.creditDays')},  
                { field: 'creditRating',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.creditRating')},
                { field: 'jobNum',
                    minWidth:150, 
                    cellTemplate: '<a class="ml-5" href="" ng-click="grid.appScope.viewGuideRate(row.entity)">{{row.entity.jobNum}}</a>',
                    displayName: $translate.instant('stats.stat.jobNum')},
                { field: 'shipmentType',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.shipmentType')},
                { field: 'bizDate', 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.bizDate')}, 
                { field: 'thirtyDays', 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type:'number',
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum, 
                    aggregationHideLabel: false,
                    minWidth:150, 
                    displayName: "0-30 Days(" + (!$rootScope.account.baseCurrency?"RMB":$rootScope.account.baseCurrency) + ")"},
                { field: 'sixtyDays', 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type:'number',
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum, 
                    aggregationHideLabel: false,
                    minWidth:150, 
                    displayName: "31-60 Days(" + (!$rootScope.account.baseCurrency?"RMB":$rootScope.account.baseCurrency) + ")"},
                { field: 'ninetyDays', 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type:'number',
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum, 
                    aggregationHideLabel: false,
                    minWidth:150, 
                    displayName: "61-90 Days(" + (!$rootScope.account.baseCurrency?"RMB":$rootScope.account.baseCurrency) + ")"},
                { field: 'hundredTwentyDays', 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type:'number',
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum, 
                    aggregationHideLabel: false,
                    minWidth:150, 
                    displayName: "91-120 Days(" + (!$rootScope.account.baseCurrency?"RMB":$rootScope.account.baseCurrency) + ")"},
                { field: 'aboveHundredTwenty', 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type:'number',
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum, 
                    aggregationHideLabel: false,
                    minWidth:150, 
                    displayName: ">120 Days(" + (!$rootScope.account.baseCurrency?"RMB":$rootScope.account.baseCurrency) + ")"}, 
                { field: 'salesName', 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterBySales')},
                { field: 'branch', 
                    displayName: $translate.instant('stats.stat.bbranch'), 
                    minWidth:120
                },
                { field: 'team', 
                    displayName: $translate.instant('stats.stat.bteam'), 
                    minWidth:120
                },
                { field: 'cusName', 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByCs')},
                { field: 'opName', 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByOperator')},
                { field: 'docName', 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByDocument')},
                { field: 'billNum', 
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.billNum')},
                { field: 'dnNum', 
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.dnNum')},
                { field: 'invoiceNum', 
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.invoiceNum')},
                { field: 'mblNum', 
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.mblNum')},
                { field: 'soNum', 
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.soNum')},
                { field: 'shipmentId', 
                    minWidth:120,
                    displayName: "Shipment ID"},
                { field: 'customerName', 
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByClient')},
                { field: 'shipperName', 
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByShiperCnee')},
                { field: 'bookingToName', 
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByColoader')},
                { field: 'deliveryName', 
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByAgent')},
                { field: 'clearanceAgent', 
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.clearanceAgent')},
                { field: 'etd', 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.etd')}, 
                { field: 'eta', 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.eta')},
                { field: 'atd',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.atd')},
                { field: 'ataTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.ataTime | date:\'medium\'}}">{{row.entity.ataTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.ataTime')},
                { field: 'wmsInTime', 
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.wmsInTime'),
                    cellTemplate: '<span class="ml-5">{{row.entity.wmsInTime | date:\'medium\'}}</span>'},  
                { field: 'deliveryTime', 
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.deliveryTimeStr'),
                    cellTemplate: '<span class="ml-5">{{row.entity.deliveryTime | date:\'medium\'}}</span>'},
                { field: 'inputTime', 
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.feeInput'),
                    cellTemplate: '<span class="ml-5">{{row.entity.inputTime | date:\'medium\'}}</span>'},
                { field: 'orderRemarks',
                    displayName: $translate.instant('stats.stat.orderRemarks'), 
                    minWidth:100}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 65;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = $translate.instant('global.menu.entities.agingAnalysisJob');
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        if(row.entity.jobNum){
                            jsonObj.gridOptions.data.push(row.entity);
                        }                        
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "agingAnalysisByJob";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = $translate.instant('global.menu.entities.agingAnalysisJob');
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "agingAnalysisByJob";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.grid.registerColumnsProcessor(setGroupValues, 410);
                gridApi.core.on.filterChanged($scope, function() {  
                    gridCopy = this.grid;
                    clearTimeout(filterTimer);
                    filterTimer = setTimeout(function(){                        
                        getVisibelTtl();                            
                    }, 1000);//查询延时时间，单位ms 
                });

            }    
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 65
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });

        var setGroupValues = function(columns, rows) {
            columns.forEach(function(column) {
                if (column.grouping && column.grouping.groupPriority > -1) {
                    // Put the balance next to all group labels.
                    column.treeAggregationFn = function(aggregation, fieldValue, numValue, row) {
                        if (typeof(aggregation.value) === 'undefined') {
                            aggregation.value = 0;
                        }
                        if(column.field == "payCurrency"){
                            aggregation.value = aggregation.value + row.entity.balanceAmt;
                        }
                        else {
                            aggregation.value = aggregation.value + row.entity.baseAmt;
                        }                        
                    };
                    column.customTreeAggregationFinalizerFn = function(aggregation) {
                        if (typeof(aggregation.groupVal) !== 'undefined') {
                            aggregation.value = $rootScope.FORMAT_NUM(DataUtils.round(aggregation.value, 2));
                            if(column.field == "payCurrency"){
                                aggregation.rendered = aggregation.groupVal + ' (' + aggregation.value + ')';
                            }
                            else {
                                aggregation.rendered = aggregation.groupVal + ' (' + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency) + aggregation.value + ')';
                            }                            
                        } else {
                            aggregation.rendered = null;
                        }
                    };
                }
            });
            return columns;
        };


        $scope._onPayerSelected = function(data) {
            $scope.statParams.venderId = data.id;
        }

        $scope.setPaymentTerm = function(val) {
            $scope.statParams.paymentTerm = val;
        }

        $scope.viewGuideRate = function(content) {
            if(content.guideId){
                var obj = {id: content.guideId};
                $rootScope.RATESPOP(obj, "guide", null);
            }
            else if (content.expressParcelId){
                var obj = {id: content.expressParcelId};
                $rootScope.RATESPOP(obj, "expressParcel", null);
            }
            else if (content.wmsInId){
                var obj = {id: content.wmsInId};
                $rootScope.RATESPOP(obj, "wms", null);
            }
        }
        $scope.excelExport = function(resultList) {
            if(!resultList || resultList.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.excelGuideRatesDTO({
                enCn: $translate.instant('global.enCn')
            }, resultList, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('global.menu.entities.agingAnalysisJob') + ".xlsx");
            })
        }    

        $scope.salesList = [];        
        $scope._onClerkSelected = function(fd, item) {
            if(fd == "salesId"){
                $scope.salesList.push(item);
            }
            $scope.statParams[fd] = "";
        };
        $scope.delSales = function(index) {
            $scope.salesList.splice(index, 1);
        };



        $scope.scopeList = [];        
        $scope._onScopeSelected = function(scp) {
            if($scope.scopeList.indexOf(scp) == -1){
                $scope.scopeList.push(scp);
            }
        };
        $scope.delScope = function(index) {
            $scope.scopeList.splice(index, 1);
        };
        $scope.huaChangSheet = function(type) {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var title = $scope.reportName;
            title = title + "(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            OpsFiles.agingCustomReport({
                type: type,
                etdFM: DateUtils.convertLocalDateToServer($scope.statParams.etdFM),
                etdTO: DateUtils.convertLocalDateToServer($scope.statParams.etdTO),
                title: title
            }, $scope.gridOptions.data, function(result) {
                if(type == "02P"){
                    $rootScope.PRINTBYTES(result.data);
                }
                else {
                    $rootScope.DOWNLOADEXCEL(result, title + ".xlsx");
                }                
            })
        }

        $scope.reportName = "客户应收余额按月统计表";
        $scope.statTypeChange = function(type) {
            if(type == "payable"){
                $scope.reportName = "客户应付余额按月统计表";
            }
            else {
                $scope.reportName = "客户应收余额按月统计表";
            }
        }
        $scope.visibelTtl = [];
        function getVisibelTtl(){
            $scope.visibelTtl = [];
            $scope.filterRequired = false;
            angular.forEach(gridCopy.columns, function(column){                    
                angular.forEach(column.filters, function(cf){
                    if(cf.term || cf.term == false){
                        $scope.filterRequired = true;                         
                    }
                })
            })
            if(!$scope.filterRequired){
                $translate.refresh();
                return;
            }
            var visibelRows = $scope.gridApi.core.getVisibleRows(gridCopy);
            var currencies = new Set();
            angular.forEach(visibelRows, function(row){
                if(row.entity.payCurrency){
                    currencies.add(row.entity.payCurrency);
                }     
            });
            currencies.forEach(function(currency){
                var ttl = 0;
                angular.forEach(visibelRows, function(row){
                    if (row.entity.id >= 0 && row.entity.payCurrency == currency && row.entity.balanceAmt){
                        ttl += row.entity.balanceAmt;
                    }
                })
                if (ttl){
                    var newTtl = {};
                    newTtl.currency = currency;
                    newTtl.total = DataUtils.round(ttl, 2);
                    $scope.visibelTtl.push(newTtl);
                }
            });
            $translate.refresh();
        }
        
        $scope.delVisibelRows = function() {
            var visibelRows = $scope.gridApi.core.getVisibleRows(gridCopy);
            for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                var isDel = false;
                angular.forEach(visibelRows, function(data){
                    if(angular.equals(data.entity, $scope.gridOptions.data[i])){
                        isDel = true;
                    }
                })
                if(isDel){
                    $scope.gridOptions.data.splice(i, 1);
                }
            }
            $scope.gridApi.core.clearAllFilters();
            getVisibelTtl();
        } 

        $scope.huanhaiUnpaid = function(resultList) {
            if(!resultList || resultList.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.huanhaiUnpaid({
                enCn: $translate.instant('global.enCn')
            }, resultList, function(result) {
                $rootScope.DOWNLOADEXCEL(result, "unpaid-" + new Date().toLocaleDateString() + ".xlsx");
            })
        }    
        $scope.asyncTasks = function(allCols) {
            getStatParams();
            var gridState = $scope.gridApi.saveState.save();
            DataUtils.fillGridDisplayName(gridState, $scope.gridOptions.columnDefs, allCols);            

            var body = {};
            body.statParams = $scope.statParams;
            body.gridState = gridState;
            body.taskType = "CHARGESAGING";
            $scope.isSaving = true;        
            var loadIndex = layer.load(1, {shade: 0.3});
            AsyncTask.submitAsyncTask(body, function(result){
                $scope.isSaving = false;
                layer.close(loadIndex);
                $rootScope.OPS_ALERT($translate.instant('autopsApp.asyncTask.tips.taskSubmitSuccess'));
            }, $rootScope.ALERT_ERROR);
        }

    }
})();