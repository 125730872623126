(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('ConciseStatsController', ConciseStatsController);

	ConciseStatsController.$inject = ['$scope', '$rootScope', '$translate', '$state', '$uibModal', '$timeout', 'Principal', 
	'OpsFiles', 'uiGridConstants', 'uiGridGroupingConstants', 'GridState', 'DateUtils', 'Clerks', 'DataUtils', 'AsyncTask'];

	function ConciseStatsController($scope, $rootScope, $translate, $state, $uibModal, $timeout, Principal,
		OpsFiles, uiGridConstants, uiGridGroupingConstants, GridState, DateUtils, Clerks, DataUtils, AsyncTask) {		

		$scope.statParams = {};
        $scope.statParams.statType = "month";
        $scope.statParams.month = new Date();
        $scope.statParams.filterRequired = true;
        $scope.statParams.conciseStats = true;

		$scope.datePickerOpenStatus = {};
		$scope.yearFormat = "yyyy";
		$scope.monthFormat = "yyyy-MM";
		$scope.dayFormat = "yyyy-MM-dd";
		$scope.yearDatepickerOptions = {
			minMode: 'year'
		}
		$scope.monthDatepickerOptions = {
			minMode: 'month'
		}
		$scope.dayDatepickerOptions = {
			minMode: 'day'
		}
		$scope.openCalendar = function(date) {
			$scope.datePickerOpenStatus[date] = true;
		}
		
		$scope.isSaving = false;

		$scope.reportavailalbe = false;
		$scope.getContents = function() {            
			DataUtils.updateStatParams($scope.statParams);
			if(!$scope.statParams.etdFM || !$scope.statParams.etdTO){
				layer.msg($translate.instant('stats.stat.dateNullAlert'));				
				return;
			}
            $scope.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});  
            $scope.reportavailalbe = false;
			OpsFiles.comStats($scope.statParams, function(result) {
                layer.close(loadIndex);  
				$scope.isSaving = false;
				if (!result || result.length == 0) {
					layer.msg($translate.instant('global.messages.resultNull'));
                    return;
				}
				$scope.gridOptions.data = result;	
				resize();  
                initContent();                
			}, function(response) {
				$scope.isSaving = false;
                $rootScope.ALERT_ERROR(response);
			});
		}

        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$scope.gridOptions || !$scope.gridOptions.data){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 680){
                tabHeight = 680;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        
        var initContent = function() {
            var items;
            angular.forEach($scope.gridOptions.data, function(data){
				data.gw = data.gw?(data.gw.toFixed(2) -0):null;
				data.vol = data.vol?(data.vol.toFixed(3) -0):null;
				data.chargeWeight = data.chargeWeight?(data.chargeWeight.toFixed(2) -0):null;
                data.salesName = data.salesName?data.salesName.replace(/[ ]/g,""):"NULL";
                data.opName = data.opName?data.opName.replace(/[ ]/g,""):"NULL";
                data.docName = data.docName?data.docName.replace(/[ ]/g,""):"NULL";
                data.finName = data.finName?data.finName.replace(/[ ]/g,""):"NULL";
                data.checkByName = data.checkByName?data.checkByName.replace(/[ ]/g,""):"NULL";
                data.bookingBy = data.bookingBy?data.bookingBy.replace(/[ ]/g,""):"NULL";
                data.customBy = data.customBy?data.customBy.replace(/[ ]/g,""):"NULL";
                data.cusName = data.cusName?data.cusName.replace(/[ ]/g,""):"NULL";
                data.truckBy = data.truckBy?data.truckBy.replace(/[ ]/g,""):"NULL";
                if(data.polRegion){
                    data.polRegion = $translate.instant('global.lanes.' + data.polRegion);
                }
                if(data.podRegion){
                    data.podRegion = $translate.instant('global.lanes.' + data.podRegion);
                }    
                if(!items){
                    items = data.items;
                }                
            })
            getCtnTypeStr();

            $scope.gridOptions.columnDefs = angular.copy(columnDefsCopy);
            angular.forEach(items, function(item){
                $scope.gridOptions.columnDefs.push({
                    field: item, 
                    minWidth:80,
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: item
                })
            })
            angular.forEach($scope.gridOptions.data, function(data){
                for(var i =0; i < items.length; i++){
                    data[items[i]] = data.itemAmtList[i];
                }                
            })             
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }

        $scope.ctnTypeStr = "";
        var getCtnTypeStr = function() {
            $scope.ctnTypeStr = "";
            var ctnTypes = new Set();
            angular.forEach($scope.gridOptions.data, function(data){
                if(!data.isVoid && !data.localTimeTwo && data.ctnTypeNum){
                    var volumes = data.ctnTypeNum.split(' ');
                    angular.forEach(volumes, function(volume){
                        if (volume){
                            var teus = volume.split('X');
                            if(teus.length == 2){
                                ctnTypes.add(teus[1]);
                            }
                        }
                    })
                }
            })
            var amt = 0;
            ctnTypes.forEach(function(ctnType){
                amt = 0;
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isVoid && !data.localTimeTwo && data.ctnTypeNum){
                        var volumes = data.ctnTypeNum.split(' ');
                        angular.forEach(volumes, function(volume){
                            if (volume){
                                var teus = volume.split('X');
                                if(teus.length == 2 && teus[1] == ctnType){
                                    amt += parseInt(teus[0]);
                                }
                            }
                        })
                    }
                })
                $scope.ctnTypeStr += amt + "X" + ctnType + " ";
            })
        }
        function getTitleStr() {
            var outStr = $translate.instant('global.menu.admin.concise-stats');
            outStr += "(" + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) 
                + " - " 
                + DateUtils.convertLocalDateToServer($scope.statParams.etdTO)
                + ")";
            return outStr;
        }
        var statsPageSize = 5000;
        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,   

            enableGridMenu : true,//表格 菜单  
            showGridFooter: true,//表格的footer  
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('global.menu.admin.profitStats') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 

            paginationPageSizes: [1000, 5000, 10000, 15000, 20000],
            paginationPageSize: statsPageSize,
            useExternalPagination: false,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'jobNum', 
                    width: 160,
                    displayName: $translate.instant('stats.stat.jobNum'),
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<span class="ml-5"><a href=""ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity.jobNum}}&nbsp;</a><span class="glyphicon glyphicon-ok-circle"ng-if="row.entity.tkState == \'3\'"></span></span>'},
                { field: 'mblNum', 
                    minWidth:120,
                    visible: false,
                    displayName: $translate.instant('stats.stat.mblNum')},
                { field: 'soNum', 
                    visible: false,
                    displayName: $translate.instant('stats.stat.soNum'), 
                    minWidth:120},
                { field: 'salesName', 
                    visible: false,
                    displayName: $translate.instant('stats.stat.filterBySales'), 
                    minWidth:120},
                { field: 'clientName', 
                    displayName: $translate.instant('stats.stat.filterByClient'), 
                    visible: false,
                    minWidth:120},
                { field: 'teus', 
                    type:'number',
                    visible: false,
                    displayName: $translate.instant('stats.stat.teu'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'pkgs', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.pkgs'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'gw', 
                    type:'number',
                    visible: false,
                    displayName: $translate.instant('stats.stat.gw'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'vol', 
                    type:'number',
                    visible: false,
                    displayName: $translate.instant('stats.stat.vol'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'chargeWeight', 
                    type:'number',
                    visible: false,
                    displayName: $translate.instant('stats.stat.chargeWeight'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'poNum', 
                    displayName: $translate.instant('stats.stat.poNum'), 
                    visible: false,
                    minWidth:120},
                { field: 'shipmentType', 
                    displayName: $translate.instant('stats.stat.shipmentType'), 
                    visible: false,
                    minWidth:120},   
                { field: 'bizDate', 
                    displayName: $translate.instant('stats.stat.bizDate'), 
                    minWidth:120},
                { field: 'etd',
                    minWidth:120,
                    visible: false,
                    displayName: $translate.instant('stats.stat.guideRates.etd')},
                { field: 'eta',
                    minWidth:120,
                    visible: false,
                    displayName: $translate.instant('stats.stat.guideRates.eta')},
                { field: 'platform', 
                    minWidth:120,
                    visible: false,
                    displayName: $translate.instant('stats.stat.platform')},
                { field: 'platformAccount', 
                    minWidth:120,
                    visible: false,
                    displayName: $translate.instant('stats.stat.platformAccount')},
                { field: 'shipmentDate', 
                    minWidth:120,
                    visible: false,
                    displayName: $translate.instant('stats.stat.shipmentDate')},
                { field: 'deliveryDate', 
                    displayName: $translate.instant('stats.stat.deliveryDate'), 
                    minWidth:120},
                { field: 'whName', 
                    minWidth:120,
                    visible: false,
                    displayName: $translate.instant('stats.stat.whName')},
                { field: 'deliveryToName',
                    displayName: $translate.instant('stats.stat.deliveryto'), 
                    minWidth:120},
                { field: 'destCountryCode',
                    displayName: $translate.instant('stats.stat.destCountryCode'), 
                    minWidth:120},      
                { field: 'shiptoAddress',
                    displayName: $translate.instant('stats.stat.shiptoAddress'), 
                    minWidth:180},
                { field: 'productName', 
                    displayName: $translate.instant('stats.stat.productName'), 
                    minWidth:120},
                { field: 'sku', 
                    minWidth:120,
                    visible: false,
                    displayName: "SKU"},
                { field: 'skuCargoName', 
                    minWidth:120,
                    visible: false,
                    displayName: $translate.instant('stats.stat.cargoName') +"(SKU)"},
                { field: 'totalRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.totalRMB', {currency: $rootScope.account.baseCurrency}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'profitRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.profitRMB', {currency: $rootScope.account.baseCurrency}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<strong class="text-primary">{{$rootScope.account.baseCurrency}}{{row.entity.profitRMB}}</strong>',
                    minWidth:100},
                { field: 'gp', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.gp'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.AVG,
                    treeAggregationLabel: "Avg: ",
                    aggregationType: uiGridConstants.aggregationTypes.avg,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<strong class="text-danger">{{row.entity.gp}}%</strong>',
                    minWidth:100},
                { field: 'paymentRcvDate', 
                    displayName: $translate.instant('stats.stat.paymentRcvDate'), 
                    minWidth:120},
                { field: 'paymentPaidDate', 
                    displayName: $translate.instant('stats.stat.paymentPaidDate'), 
                    minWidth:120}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 95;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "conciseStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "conciseStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi; 
                $scope.gridApi.treeBase.on.rowExpanded($scope,function(row) {
                    resize();
                });
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);                     
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }

                    loadAll();  
                });
            }
        }

        var columnDefsCopy = angular.copy($scope.gridOptions.columnDefs);
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 95
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);      
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                if(!$scope.gridOptions.paginationPageSize){
                    $scope.gridOptions.paginationPageSize = statsPageSize;
                }
                columnDefsCopy = angular.copy($scope.gridOptions.columnDefs);
            })
        });

		$scope.statParamsPop = function(gridState, statParams) {
            $uibModal.open({
                templateUrl: 'app/entities/stats/statParams-dialog.html',
                controller: 'StatParamsDialogController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return $scope.statParams;
                    }]
                }
            }).result.then(function(result) {
				$scope.statParams = result;
            }, function() {
            });
        }

        $scope.excelExport = function() {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var excelDTO = {};
            excelDTO.guides = $scope.gridOptions.data;
            var title = $translate.instant('global.menu.admin.profitStats');
            title = title + "(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            OpsFiles.excelProfitStatByUserDTO({
                title: title,
                enCn: $translate.instant('global.enCn')
            }, excelDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('global.menu.admin.profitStats') + ".xlsx");
            })
        }    


        $scope.statParams.searchType = 'Job#';
        $scope.setSearchType = function(type) {
            $scope.statParams.searchType = type;
        }

        $scope.searchGuidePop = function() {            
            if($rootScope.HAVENOAUTH('FMS') && $rootScope.HAVENOAUTH('FBA') && $rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                forConciseStats: true,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                $scope.gridOptions.data = result;
                resize();           
                initContent();
            }, function() {
            });
        }
        $scope.searchParcelsPop = function(shipmentType) {    
            if($rootScope.HAVENOAUTH('TMS') && shipmentType == "TMS"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($rootScope.HAVENOAUTH('PARCEL') && shipmentType == "PARCEL"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                shipmentType: shipmentType,
                forConciseStats: true,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                $scope.gridOptions.data = result;
                resize();           
                initContent();
            }, function() {
            });
        }
        function addNewData(data) {
            var isNew = true;
            angular.forEach($scope.gridOptions.data, function(oldOne){
                if(oldOne.jobNum == data.jobNum){
                    isNew = false;
                }
            })
            if(isNew){
                $scope.gridOptions.data.push(data);
            }
        }
        $scope.comStatsByNums = function(bizType) { 
            if(!$scope.statParams.searchStr){
                layer.msg($translate.instant('global.search'));
                return;
            }
            $scope.statParams.bizType = bizType;
            $scope.statParams.conciseStats = true;
            $scope.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});  
            OpsFiles.comStatsByNums($scope.statParams, function(result) {
                $scope.isSaving = false;
                layer.close(loadIndex);  
                if (!result || result.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                if(!$scope.gridOptions.data){
                    $scope.gridOptions.data = [];
                }
                angular.forEach(result, function(data){
                    addNewData(data);
                })
                resize();           
                initContent();
            }, function(response) {
                $scope.isSaving = false;
                layer.close(loadIndex);  
                $scope.reportavailalbe = false;
                if(response.status == 404){
                    layer.msg($translate.instant('stats.stat.noData'));
                }
            });
        }
        $scope.asyncTasks = function(allCols) {
            DataUtils.updateStatParams($scope.statParams);
            if(!$scope.statParams.etdFM || !$scope.statParams.etdTO){
                layer.msg($translate.instant('stats.stat.dateNullAlert'));              
                return;
            }
            var gridState = $scope.gridApi.saveState.save();
            DataUtils.fillGridDisplayName(gridState, $scope.gridOptions.columnDefs, allCols);            

            var body = {};
            body.statParams = $scope.statParams;
            body.gridState = gridState;
            body.taskType = "CONCISESTATS";
            $scope.isSaving = true;        
            var loadIndex = layer.load(1, {shade: 0.3});
            AsyncTask.submitAsyncTask(body, function(result){
                $scope.isSaving = false;
                layer.close(loadIndex);
                $rootScope.OPS_ALERT($translate.instant('autopsApp.asyncTask.tips.taskSubmitSuccess'));
            }, $rootScope.ALERT_ERROR);
        }

	}
})();