(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AddressBookController', AddressBookController);

    AddressBookController.$inject = ['$scope', '$state', '$http', '$translate', '$location', '$rootScope', '$timeout',
        'WinAES', 'AddressBook', 'AddressBookSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'uiGridConstants', 'GridState'
    ];

    function AddressBookController($scope, $state, $http, $translate, $location, $rootScope, $timeout,
        WinAES, AddressBook, AddressBookSearch, ParseLinks, AlertService, pagingParams, paginationConstants, uiGridConstants, GridState) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.gridId = 172;
        var gridCopy;
        var pageLoaded = true;
        var filterTimer;
        var gridStateObj = {};
        var gridStateCopy = null;
        $scope.expressCarriersScope = [];
        vm.page = 1;
        if ($location.path().indexOf("label-edi") >= 0) {
            $rootScope.IS_LABEL_EDI = true;
        } else {
            $rootScope.IS_LABEL_EDI = false;
        }

        loadAll();

        $timeout(function() {
            if ($rootScope.HAVEAUTH("SUB_ADDR_FB") && !$rootScope.account.companyId) {
                $state.go('home', null, { reload: true });
            }
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: vm.gridId
            }, function(result) {
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if (!gridStateObj || !gridStateObj.id) {
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if (gridCopy) {
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column) {
                        angular.forEach(column.filters, function(cf) {
                            if (cf.term) {
                                filterRequired = true;
                            }
                        })
                    })
                    if (filterRequired) {
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                        return;
                    }
                }
                loadAll();
            })
            if(!$rootScope.IS_LABEL_EDI || !$rootScope.account.companyId){
                for(var index = $scope.gridOptions.columnDefs.length - 1; index >= 0; index--){
                    var col = $scope.gridOptions.columnDefs[index];
                    if(col.field == "bindType" || col.field == "shareToClient" || col.field == "enabled"
                        || col.field == "item" || col.field == "whName" || col.field == "labelPayee"){
                        $scope.gridOptions.columnDefs.splice(index, 1);
                    }
                }
            }
            angular.forEach($rootScope.EXPRESS_CARRIERS, function(actual) {
                if ($rootScope.EXPRESS_CARRIER_FILTER(actual)) {
                    $scope.expressCarriersScope.push(actual);
                }
            });
        });

        function loadAll() {
            if (pagingParams.search) {
                AddressBookSearch.query({
                    labelEdi: $rootScope.IS_LABEL_EDI,
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage
                }, onSuccess, onError);
            } else {
                var filter = {};
                if ($rootScope.IS_LABEL_EDI) {
                    filter.labelEdi = true;
                } else {
                    filter.labelEdi = false;
                }
                if (gridCopy) {
                    angular.forEach(gridCopy.columns, function(column) {
                        angular.forEach(column.filters, function(cf) {
                            if (cf.term != null && angular.isDefined(cf.term)) {
                                var fd = column.field;
                                if (cf.condition) {
                                    if (cf.condition == 128 || cf.condition == 256) {
                                        filter[fd + "TO"] = cf.term;
                                    } else if (cf.condition == 32 || cf.condition == 64) {
                                        filter[fd + "FM"] = cf.term;
                                    }
                                } else {
                                    filter[fd] = cf.term;
                                }

                            }
                        })
                    })
                }
                AddressBook.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + vm.direction];
                return result;
            }

            function onSuccess(data, headers) {
                if (headers('X-Total-Count')) {
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                } else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                vm.isAdvanceSearch = false;

                initData();
                resize();
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        window.onresize = function() {
            resize();
        }

        function resize() {
            if (!$("#gridId") || !$("#gridId").offset()) {
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if (tabHeight > winHeight) {
                tabHeight = winHeight;
            }
            if (tabHeight < 600) {
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function initData() {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.enablePagination = !vm.isAdvanceSearch;
            $scope.gridOptions.enablePaginationControls = !vm.isAdvanceSearch;
            angular.forEach($scope.gridOptions.data, function(data) {
                data.encodeId = WinAES.encode(data.id.toString());
                angular.forEach($rootScope.EXPRESS_CARRIERS, function(carrier) {
                    if (data.bindType == carrier.value) {
                        data.bindTypeLabel = carrier.label;
                    }
                })
            })
        }

        $scope.gridOptions = {
            enableSorting: true, //排序
            useExternalSorting: true,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: true, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            paginationPageSizes: [20, 35, 50, 100],
            paginationPageSize: 20,
            useExternalPagination: true,
            enablePagination: true,
            enablePaginationControls: true,
            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中
            minimumColumnSize: 70,

            exporterExcelFilename: "AddressBook_Data",
            columnDefs: [{
                field: 'id',
                minWidth: 180,
                displayName: "",
                pinnedLeft: true,
                enableColumnMenu: false,
                enableFiltering: false,
                cellTemplate: '<div class="btn-group flex-btn-group-container" ng-click="grid.appScope.setChoosedId(row.entity.id)" role="button" tabindex="0"><button type="button" data-toggle="tooltip" data-placement="top" title="Share In Group" ng-click="grid.appScope.groupShareOne(row.entity)" ng-disabled="grid.appScope.addressBook.companyId==0" class="btn btn-info btn-grid" ng-if="!grid.appScope.IS_LABEL_EDI && grid.appScope.account.companyId"><span class="glyphicon glyphicon-share"></span><span class="hidden-xs hidden-sm" translate="entity.action.groupShare"></span></button><button type="button" ng-disabled="grid.appScope.addressBook.companyId==0" ui-sref="label-edi.copy({id:row.entity.encodeId})" class="btn btn-info btn-grid" ng-if="grid.appScope.IS_LABEL_EDI"><span class="glyphicon glyphicon-duplicate"></span><span class="hidden-xs hidden-sm" translate="entity.action.copy"></span></button><button type="button" ng-disabled="grid.appScope.addressBook.companyId==0" ui-sref="address-book.edit({id:row.entity.encodeId})" class="btn btn-primary btn-grid" ng-if="!grid.appScope.IS_LABEL_EDI"><span class="glyphicon glyphicon-pencil"></span><span class="hidden-xs hidden-sm" translate="entity.action.edit"></span></button><button type="button" ng-disabled="grid.appScope.addressBook.companyId==0" ui-sref="label-edi.edit({id:row.entity.encodeId})" class="btn btn-primary btn-grid" ng-if="grid.appScope.IS_LABEL_EDI"><span class="glyphicon glyphicon-pencil"></span><span class="hidden-xs hidden-sm" translate="entity.action.edit"></span></button><button type="button" ng-disabled="grid.appScope.addressBook.companyId==0" ui-sref="address-book.delete({id:row.entity.encodeId})" class="btn btn-danger btn-grid"><span class="glyphicon glyphicon-remove-circle"></span><span class="hidden-xs hidden-sm" translate="entity.action.delete"></span></button></div>'
            }, {
                field: 'code',
                minWidth: 150,
                displayName: $translate.instant('autopsApp.addressBook.code'),
                cellTemplate: '<span class="grid-text" ng-class="row.entity.id==grid.appScope.choosedId?\'choosedColor\':\'\'" ng-click="grid.appScope.setChoosedId(row.entity.id)"><a ui-sref="address-book.edit({id:row.entity.encodeId})" ng-if="!grid.appScope.IS_LABEL_EDI">{{row.entity.code}}</a><a ui-sref="label-edi.edit({id:row.entity.encodeId})" ng-if="grid.appScope.IS_LABEL_EDI">{{row.entity.code}}</a></span>'
            }, {
                field: 'bindType',
                minWidth: 150,
                displayName: 'EDI/API',
                type: "string",
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.expressCarriersScope
                },
                cellTemplate: '<span class="grid-text"><span class="label label-success" ng-if="row.entity.bindTypeLabel">{{row.entity.bindTypeLabel}}</span></span>'
            }, {
                field: 'item',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.addressBook.item')
            }, {
                field: 'whName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.addressBook.bindWh')
            }, {
                field: 'labelPayee',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.addressBook.labelPayee')
            }, {
                field: 'type',
                minWidth: 100,
                type: "string",
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $rootScope.ADDRESS_TYPES
                },
                displayName: $translate.instant('autopsApp.addressBook.type'),
                cellTemplate: '<span class="grid-text"><span class="label label-info" translate="autopsApp.addressBook.types.{{row.entity.type}}" ng-if="row.entity.type"></span></span>'
            }, {
                field: 'shareToClient',
                minWidth: 80,
                type: "boolean",
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $rootScope.YES_NO_OPTIONS
                },
                displayName: $translate.instant('autopsApp.addressBook.shareToClientAll'),
                cellTemplate: '<span ng-class="row.entity.shareToClient?\'label-success\':\' label-info\'"translate="{{row.entity.shareToClient?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'
            }, {
                field: 'enabled',
                minWidth: 80,
                type: "boolean",
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $rootScope.YES_NO_OPTIONS
                },
                displayName: $translate.instant('autopsApp.addressBook.enabled'),
                cellTemplate: '<span ng-class="row.entity.enabled?\'label-success\':\' label-info\'"translate="{{row.entity.enabled?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'
            }, {
                field: 'disabled',
                minWidth: 80,
                type: "boolean",
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $rootScope.YES_NO_OPTIONS
                },
                displayName: $translate.instant('autopsApp.addressBook.disabled'),
                cellTemplate: '<span ng-class="row.entity.disabled?\'label-success\':\' label-info\'"translate="{{row.entity.disabled?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'
            }, {
                field: 'isResidential',
                minWidth: 80,
                type: "boolean",
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $rootScope.YES_NO_OPTIONS
                },
                displayName: $translate.instant('autopsApp.addressBook.isResidential'),
                cellTemplate: '<span ng-class="row.entity.isResidential?\'label-success\':\' label-info\'"translate="{{row.entity.isResidential?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'
            }, {
                field: 'companyName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.addressBook.companyName')
            }, {
                field: 'addressOne',
                minWidth: 150,
                displayName: $translate.instant('autopsApp.addressBook.addressOne')
            }, {
                field: 'addressTwo',
                minWidth: 150,
                displayName: $translate.instant('autopsApp.addressBook.addressTwo')
            }, {
                field: 'addressThree',
                minWidth: 150,
                displayName: $translate.instant('autopsApp.addressBook.addressThree')
            }, {
                field: 'attn',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.addressBook.attn')
            }, {
                field: 'postCode',
                minWidth: 80,
                displayName: $translate.instant('autopsApp.addressBook.postCode')
            }, {
                field: 'countryCode',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.addressBook.countryCode')
            }, {
                field: 'province',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.addressBook.province')
            }, {
                field: 'city',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.addressBook.city')
            }, {
                field: 'tel',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.addressBook.tel')
            }, {
                field: 'telExt',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.addressBook.telExt')
            }, {
                field: 'email',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.addressBook.email')
            }, {
                field: 'taxId',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.addressBook.taxId')
            }, {
                field: 'creater',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.addressBook.creater')
            }],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = vm.gridId;
                    GridState.update(gridStateObj, function(result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row) {
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "warehousingData";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "warehousingData";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if (!$scope.gridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if (pageLoaded && $scope.gridOptions.useExternalFiltering) {
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function() {
                            loadAll();
                        }, 1000); //查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function(newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if (pageLoaded) {
                        loadAll();
                    }
                });
            }
        }



        // function loadAll () {
        //     if (pagingParams.search) {
        //         AddressBookSearch.query({
        //             labelEdi: $rootScope.IS_LABEL_EDI,
        //             query: pagingParams.search,
        //             page: pagingParams.page - 1,
        //             size: vm.itemsPerPage,
        //             sort: sort()
        //         }, onSuccess, onError);
        //     } else {
        //         AddressBook.query({
        //             labelEdi: $rootScope.IS_LABEL_EDI,
        //             page: pagingParams.page - 1,
        //             size: vm.itemsPerPage,
        //             sort: sort()
        //         }, onSuccess, onError);
        //     }
        //     function sort() {
        //         var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
        //         if (vm.predicate !== 'id') {
        //             result.push('id');
        //         }
        //         return result;
        //     }
        //     function onSuccess(data, headers) {
        //         vm.links = ParseLinks.parse(headers('link'));
        //         vm.totalItems = headers('X-Total-Count');
        //         vm.queryCount = vm.totalItems;
        //         vm.addressBooks = data;
        //         vm.page = pagingParams.page;
        //         angular.forEach(vm.addressBooks, function(data){
        //             data.encodeId = WinAES.encode(data.id.toString())
        //         })
        //     }
        //     function onError(error) {
        //         layer.msg($translate.instant('global.loadFailed'));
        //     }
        // }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.excelImport = function(file) {
            if ($rootScope.account.companyId && $rootScope.HAVENOAUTH('FUN_ADDRESS_BOOK')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_ADDRESS_BOOK');
                return;
            }
            if (!file) { return; }
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/address-books/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                loadAll();
                data = angular.fromJson(data);
                $rootScope.OPS_ALERT($translate.instant('autopsApp.addressBook.importSuccess', {
                    successAmt: data.successAmt
                }));
            }).error(function(error) {
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });
        };
        $scope.groupShareOne = function(addressBook) {
            layer.confirm($translate.instant('autopsApp.addressBook.groupShareOneAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                AddressBook.groupShareOne({ id: addressBook.id }, function() {
                    layer.msg($translate.instant('global.syn_success'));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }



        $scope.groupShareBatch = function() {
            layer.confirm($translate.instant('autopsApp.addressBook.groupShareOneAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                var selectedRows = $scope.gridApi.selection.getSelectedRows();
                if(selectedRows.length == 0){
                    layer.msg($translate.instant('global.noSelectedRecords'));
                    return;
                }
                var selectedIds = [];
                for (var i = 0; i < selectedRows.length; i++) {
                    var row = selectedRows[i];
                    if (row && row.id) {
                        selectedIds.push(row.id);
                    }
                }
                AddressBook.groupShareBatch(selectedIds, function() {
                    layer.msg($translate.instant('global.syn_success'));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }



        $scope.exportExcel = function() {
            var fileName = "address_book.xlsx";
            var loadIndex = layer.load(1, { shade: 0.3 });
            AddressBook.exportExcel({
                enCn: $translate.instant('global.enCn')
            }, null, function(result) {
                layer.close(loadIndex);
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }
    }
})();
