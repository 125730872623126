(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomNodeDialogController', CustomNodeDialogController);

    CustomNodeDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate',
        'entity', 'CustomNode', 'CustomCondition', 'Dictionary', 'DataUtils'];

    function CustomNodeDialogController($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $translate,
        entity, CustomNode, CustomCondition, Dictionary, DataUtils) {
        var vm = this;

        vm.customNode = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addCondition = addCondition;
        vm.delCondition = delCondition;
        vm.shipmentTypeChange = shipmentTypeChange;
        vm.getFdType = getFdType;
        vm.operatorChange = operatorChange;
        vm.conditionTypeChange = conditionTypeChange;
        vm.fieldNameChange = fieldNameChange;
        vm.conditionsCombineChange = conditionsCombineChange;
        vm.getConditions = getConditions;
        vm.hdTxtInput = hdTxtInput;
        vm.setDefultTips = setDefultTips;
        vm.targetFieldChange = targetFieldChange;
        vm.notGuideDateTime = notGuideDateTime;
        vm.showNotEqual = showNotEqual;

        vm.alertFd;
        vm.dateTimeList;

        $timeout(function () {
            if(vm.customNode.type == "AM" && $rootScope.HAVENOAUTH("AU_AM_MGMT")){
                $rootScope.FORBIDDEN_BY_ALERT('AU_AM_MGMT');
                clear();
            }
            // angular.element('.form-group:eq(1)>input').focus();
            shipmentTypeChange(vm.customNode.shipmentType);
            setDefultTips();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function setDefultTips() {
            if(vm.customNode.type == "AM"){
                vm.alertFd = "anomalousTips";
            }
            else {
                vm.alertFd = "customNodeTips";
            }
        }

        function save() {
            vm.isSaving = true;
            angular.forEach(vm.customNode.customConditions, function(data){
                if(!data.tgName || (getFdType(data.fieldName) != 'date' && getFdType(data.fieldName) != 'time')){
                    data.targetField = null;
                }
            })
            if (vm.customNode.id !== null) {
                CustomNode.update(vm.customNode, onSaveSuccess, $rootScope.ALERT_ERROR);
            } else {
                CustomNode.save(vm.customNode, onSaveSuccess, $rootScope.ALERT_ERROR);
            }
            vm.isSaving = false;
        }

        function onSaveSuccess(result) {
            $scope.$emit('autopsApp:customNodeUpdate', result);
            // $uibModalInstance.close(result);
            vm.customNode = result;
            vm.isSaving = false;
            shipmentTypeChange(vm.customNode.shipmentType);
            layer.msg($translate.instant('global.save_success'));
        }

        function addCondition() {
            if (!vm.customNode.customConditions) {
                vm.customNode.customConditions = [];
            }
            if(vm.customNode.customConditions.length >= 10){
                layer.msg($translate.instant('autopsApp.customNode.overConditionsMaxAlert'));
                return;
            }
            vm.customNode.customConditions.push({conditionType: "condition"});
        }

        function delCondition(customCondition) {
            if (customCondition.id) {
                CustomCondition.delete({
                    id: customCondition.id
                });
            };
            var index = vm.customNode.customConditions.indexOf(customCondition);
            if (index > -1) {
                vm.customNode.customConditions.splice(index, 1);
            };
        }
        function checkField(fdArray) {
            angular.forEach(vm.customNode.customConditions, function (data) {
                if(data.conditionType == 'condition' && fdArray.indexOf(data.fieldName) == -1){
                    data.fieldName = null;
                    data.operator = null;
                    data.targetValue = null;
                }
            })
        }

        var dateArray = ["lfd", "eta", "etd", "feederEtd", "deliveryDate", "transferDate", "billDate", "opDate", "atd", "revDeadLine", "cargoReadyDate",
            "bookingEtd", "BKetd", "BKissueDate"];

        var timeArray = ["createdTime", "cls", "ctnReturnTime", "lastModifiedTime", "bookingTime", "soReleaseTime", "truckArrangeTime",
            "customArrangeTime", "siSubmitedTime", "hblConfirmedTime", "mblConfirmedTime", "dnSentTime", "saSentTime", "hblReleasedTime",
            "mblReleasedTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime", "paymentReceivedTime", "siCutTime", "localTimeOne",
            "localTimeTwo", "otherTimeOne", "otherTimeTwo", "otherTimeThree", "vgmTime", "vgm", "ctnsLoadedTime", "customReleaseTime",
            "deliveryTime", "plConfirmTime", "customClearTime", "ataTime", "deliveryAppointmentTime", "soExpectedTime", "applyRlsTime",
            "deliveryGuideTime", "closeTime", "ctnAvailableTime", "BKcreatedTime", "BKlastModifiedTime", "BKsiReceivedTime",
            "RTaccountDate", "RTinputTime", "RTpaidTime", "offShelfTime", "costInputTime", "pickupTime"];

        var numArray = ["wmsGw", "wmsVol", "wmsCw", "profit","gp", "id", "shipperId", "createdBy", "checkById", "deliveryId", "bookingToId", "warehouseVid", "fumigationVid",
            "traderVid", "insuranceVid", "refGuideId", "salesId", "opId", "docId", "finId", "companyId", "branchGuideId", "agentUserId",
            "customerId", "masterId", "cusId", "awbWeight", "awbPrice", "awbChargesTtl", "costId", "bookingById", "truckById", "customById",
            "timeStamp", "agentGuideId", "expressLabelId", "docStamp", "overseasCsId", "customerContactId", "shipperContactId",
            "bookingToContactId", "deliveryContactId", "warehouseContactId", "fumigationContactId", "insuranceContactId", "traderContactId",
            "medCompanyId", "medVenderId", "gw", "vol", "pkgNum", "shipperUserId", "whId", "wmsActAmt", "customerUserId", "bookingId",
            "chargeWeight", "pieces", "palletNum", "vw", "shipFromId", "shipToId", "parcelId", "thirdpartyPlatformId", "clearanceAgentId",
            "clearanceAgentContactId", "BKid", "BKbookingToId", "BKcneeId", "BKgw", "BKvol", "BKpkgNum", "BKguideId", "BKdivVolBy",
            "BKtempFrom", "BKtempTo", "BKrefGuideId", "BKrating", "BKsellingId", "BKnoOfCopies", "BKvolumeShare", "BKcreatedBy",  
            "BKchargeWeight", "BKcompanyId", "RTpayAmount", "RTactual", "divVolBy", "lbs", "collect", "sellAmt", "saleFreight", "platformFee", 
            "otherFee", "cargoValue"];

        var booleanArray = ["cbMark", "revExist", "expExist", "isWood", "isExport", "isTemplate", "ratesLocked", "isLocked", "isApplied",
            "isClosed", "issueFeederHbl", "etdLocked", "mblOnly", "deliverySecret", "trackingFinished", "hidenCost", "isVoid", "spx",
            "hidenSelling", "allBilled", "truckRequired", "customRequired", "vat", "incomeTax", "isDelivering", "feederEtdConfirmed",
            "salesChecked", "submited", "dontSynTtl", "chargesAsArranged", "priceAsArranged", "attachMark", "attachDescription",
            "attachCtns", "isVirtual", "asWmsIn", "asWmsOut", "plConfirmed", "parcelMaster", "chargeByCbm", "isPallet",
            "ownImporter", "autoTracking", "consolidated", "isRemote", "onHold", "customsInspectionExp", "customsInspection", "labelVirtual", "autoSendPlCheck",
            "isResidential", "headTrackFinished", "orderPushTag", "directDelivery", "assistChargesConfirmed", "insuranceRequired",
            "fumigationRequired", "warehouseRequired", "coRequired", "BKisTemplate", "BKisLocked", "BKetdConfirmed", "BKattachCtns",
            "BKmagneticInspection", "BKidentification", "BKotherService", "BKhblPrinted", "BKattachMark", "BKinclTax", "BKattachDescription",
            "BKplConfirmed", "RTisPay", "consoleMasterShipment", "consoleSubShipment", "RTsharedOnTAG", "cwLoked", "guideCtnsExist", "cargoIsOut",
            "inchOz", "irregularPacking", "isSuperRemote", "addressModify", "branchGuideIdTAG"];

        function getFdType(fd){
            if(dateArray.indexOf(fd) != -1){
                return "date";
            }
            if(timeArray.indexOf(fd) != -1 || (fd && fd.startsWith("ON_"))){
                return "time";
            }
            if(numArray.indexOf(fd) != -1){
                return "num";
            }
            if(booleanArray.indexOf(fd) != -1){
                return "boolean";
            }
            return "str";
        }


        function operatorChange(customCondition) {
            if(customCondition.operator == 'isNull' || customCondition.operator == 'notNull'){
                customCondition.targetValue = null;
            }
        }

        function conditionTypeChange(customCondition) {
            customCondition.fieldName = null;
            customCondition.targetValue = null;
        }

        function fieldNameChange(customCondition, item) {
            customCondition.fieldName = item.name;
            customCondition.fdName = item.langName;
            customCondition.targetValue = null;
            customCondition.operator = null;
            if(getFdType(customCondition.fieldName) == 'boolean'){
                customCondition.operator = 'equals';
            }
            $translate.refresh();
        }
        function targetFieldChange(customCondition, item) {
            customCondition.targetField = item.name;
            customCondition.tgName = item.langName;
            $translate.refresh();
        }
        function conditionsCombineChange(conditionsCombine) {
            if(conditionsCombine){
                vm.customNode.role = null;
                vm.customNode.viewScope = null;
            }
        }

        vm.conditions;
        function getConditions() {
            CustomNode.getConditions({userId: $rootScope.account.id, type: vm.customNode.type, shipmentType: vm.customNode.shipmentType}, function (result) {
                vm.conditions = result;
            });
        }

        var comNodes = ["onHold", "status", "createdTime", "deliveryGuideTime", "bookingTime", "soReleaseTime", "truckArrangeTime", "otherTimeOne", "otherTimeTwo", "customArrangeTime",
            "customReleaseTime", "atd", "hblReleasedTime",  "mblReleasedTime", "ataTime", "customsInspectionExp", "customsInspection", "customClearTime", "ctnAvailableTime", "otherTimeThree", "deliveryAppointmentTime",
            "deliveryTime", "closeTime", "dnSentTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime", "paymentReceivedTime", "localTimeTwo",
            "revExist", "expExist", "profit", "gp", "allBilled", "plConfirmed", "isApplied", "isClosed", "isVoid", "consoleMasterShipment", "consoleSubShipment",
            "RTinvoiceNum", "RTdnNum", "RTpayCurrency", "RTpayer", "RTitem", "RTpaymentTerm", "RTbillNum", "RTmcNum", "RTwriteOffCode", "RTcurrency", "RTunit",
            "RTisPay", "RTpayAmount", "RTsharedOnTAG", "RTaccountDate", "RTinputTime", "RTpaidTime", "RTactual", "truckRequired", "customRequired", "insuranceRequired",
            "fumigationRequired", "warehouseRequired", "coRequired", "guideCtnsExist", "jobNum", "lastestTkStatus", "consolidated", "ratesLocked", "customsType", "branchGuideIdTAG", "pickupTimeTAG"];

        var fmsNodes = ["ctnsLoadedTime", "vgmTime", "BKsiReceivedTime", "siSubmitedTime", "hblConfirmedTime", "mblConfirmedTime",
            "saSentTime", "localTimeOne", "ctnReturnTime"];

        var airNodes = ["BKsiReceivedTime", "plConfirmTime", "siSubmitedTime", "hblConfirmedTime", "mblConfirmedTime",
            "saSentTime"];

        var fbaNodes = ["plConfirmTime", "ctnsLoadedTime", "ctnReturnTime", "pickupTime"];
        var parcelTmsTypes = ["PARCELALL", "PARCEL", "LINE", "RETURN", "EP", "TMSALL", "TMS", "FTL", "LTL", "EXD", "CPU", "EPWMS"];
        var tmsTypes = ["TMSALL", "TMS", "FTL", "LTL", "EXD", "CPU", "EPWMS"];

        var pcNodes = [
            "status", "revExist", "expExist", "consoleMasterShipment", "consoleSubShipment", "isClosed", "isVoid",
            "RTinvoiceNum", "RTdnNum", "RTpayCurrency", "RTpayer", "RTitem", "RTpaymentTerm", "RTbillNum", "RTmcNum", "RTwriteOffCode", "RTcurrency", "RTunit",
            "RTisPay", "RTpayAmount", "RTsharedOnTAG", "RTaccountDate", "RTinputTime", "RTpaidTime", "RTactual", "pickupTime"];

        // RTinvoiceNum, RTdnNum, RTpayCurrency, RTpayer, RTitem, RTpaymentTerm, RTbillNum, RTisPay, RTpayAmount, RTaccountDate, RTinputTime, RTpaidTime;

        function getFieldsByShipmentType(shipmentType){
            if(shipmentType == "AIR") {
                var outFds = angular.copy(Dictionary.airRqs);
                angular.forEach(comNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                angular.forEach(airNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                return outFds;
            }
            else if($rootScope.ISFBA(shipmentType) || $rootScope.ISWMS(shipmentType) || shipmentType == "FBAALL" || shipmentType == "WMSALL") {
                var outFds = angular.copy(Dictionary.fbaRqs);
                angular.forEach(comNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                angular.forEach(fbaNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                return outFds;
            }
            else if (parcelTmsTypes.indexOf(shipmentType) != -1) {
                var outFds = angular.copy(Dictionary.parcelRqs);
                angular.forEach(pcNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })                
                return outFds;
            }
            else {
                var outFds = angular.copy(Dictionary.fmsRqs);
                angular.forEach(comNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                angular.forEach(fmsNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                return outFds;
            }
        }
        function getAfCustomNodes(allFds, shipmentType) {
            CustomNode.getAfCustomNodes({
                companyId: $rootScope.account.companyId,
                shipmentType: vm.customNode.shipmentType
            }, function(result){
                angular.forEach(result, function(customNode){
                    allFds.push("ON_" + customNode);                    
                });
                checkField(allFds);
                if(shipmentType == "AIR") {
                    angular.forEach(allFds, function(data){
                        var fd = {name: data};
                        DataUtils.getLangNameAir(fd);
                        vm.fdList.push(fd);
                    })
                }
                else if($rootScope.ISFBA(shipmentType) || $rootScope.ISWMS(shipmentType) || shipmentType == "FBAALL" || shipmentType == "WMSALL") {
                    angular.forEach(allFds, function(data){
                        var fd = {name: data};
                        DataUtils.getLangNameFba(fd);
                        vm.fdList.push(fd);
                    })
                }
                else if(parcelTmsTypes.indexOf(shipmentType) != -1) {
                    angular.forEach(allFds, function(data){
                        var fd = {name: data};
                        DataUtils.getLangNameExpressParel(fd, tmsTypes.indexOf(shipmentType) != -1);
                        vm.fdList.push(fd);
                    })
                }
                else {
                    angular.forEach(allFds, function(data){
                        var fd = {name: data};
                        DataUtils.getLangNameFms(fd);
                        vm.fdList.push(fd);
                    })
                }
                updateFdName(vm.fdList);
                dateTimeListInit();
            })
        }

        vm.fdList = [];
        function shipmentTypeChange(shipmentType) {
            if(vm.customNode.anomalousRank){
                vm.customNode.anomalousRank = vm.customNode.anomalousRank + "";
            }
            if(!shipmentType){
                return;
            }
            vm.fdList = [];
            vm.dateTimeList = [];
            var allFds = getFieldsByShipmentType(shipmentType);
            getAfCustomNodes(allFds, shipmentType);
            getConditions();
            getStatusSet();
        }


        function dateTimeListInit() {
            vm.dateTimeList = [];
            angular.forEach(vm.fdList, function(data){
                if(dateArray.indexOf(data.name) != -1 || timeArray.indexOf(data.name) != -1){
                    vm.dateTimeList.push(data);
                }
            })
        }

        function updateFdName(fdArray) {
            angular.forEach(vm.customNode.customConditions, function (data) {
                angular.forEach(fdArray, function(fd){
                    if(fd.name == data.fieldName){
                        data.fdName = fd.langName;
                    }
                    if(fd.name == data.targetField){
                        data.tgName = fd.langName;
                    }
                })
                if(data.targetValue == "true"){
                    data.targetValue = true;
                }
                else if(data.targetValue == "false"){
                    data.targetValue = false;
                }
            })
            $translate.refresh();
        }
        var selectTxtFds = ["status", "bizType", "internalType", "cargoType", "serviceTerm", "releaseWay",
            "freightTerm", "hblRlsWay", "BKfreightTerm", "RTpaymentTerm", "BKserviceTerm", "checkStatus", 
            "RTisPay", "dutyPayParty", "dcType", "payParty", "deliveryWay"];

        function hdTxtInput(fd) {
            return selectTxtFds.indexOf(fd) !== -1;
        }

        function notGuideDateTime(customCondition) {
            return !customCondition.fieldName || customCondition.fieldName.indexOf("BK") == 0 || customCondition.fieldName.indexOf("RT") == 0 || customCondition.fieldName.indexOf("ON_") == 0;
        }
        var notEqualFds = ["RTitem", "checkByName", "jobNum"];//EtdPeriodDTO
        function showNotEqual(fd) {
            return notEqualFds.indexOf(fd) !== -1;
        }

        var tmsTypes = ["TMSALL", "TMS", "FTL", "LTL", "EXD", "CPU", "EPWMS"];
        var parcelTypes = ["PARCELALL", "PARCEL", "LINE", "RETURN", "EP"];
        vm.statusSet;
        function getStatusSet() {
            if(tmsTypes.indexOf(vm.customNode.shipmentType) !== -1){
                vm.statusSet = angular.copy($rootScope.TMS_STATUS_TYPES);
            }
            else if(parcelTypes.indexOf(vm.customNode.shipmentType) !== -1){
                vm.statusSet = angular.copy($rootScope.PARCEL_STATUS_TYPES);
            }
            else {
                vm.statusSet = angular.copy($rootScope.FMS_STATUS_TYPES);                
            }
        }

    }
})();
