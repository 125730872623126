(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JobPalletController', JobPalletController);

    JobPalletController.$inject = ['$scope', '$state', 'JobPallet', 'JobPalletSearch', 'ParseLinks', 'uiGridGroupingConstants', 'paginationConstants', 'pagingParams', '$uibModal',
        'uiGridConstants', '$translate', 'GridState', '$timeout', '$rootScope', 'WmsWarehouse', '$http'
    ];

    function JobPalletController($scope, $state, JobPallet, JobPalletSearch, ParseLinks, uiGridGroupingConstants, paginationConstants, pagingParams, $uibModal,
        uiGridConstants, $translate, GridState, $timeout, $rootScope, WmsWarehouse, $http) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.getBarcode = getBarcode;
        vm.getAllWarehouses = getAllWarehouses;
        vm.getAllLocations = getAllLocations;
        vm.warehouses = [];
        vm.locations = [];
        vm.getContetns = getContetns;
        $scope.filter = {};
        vm.jobPalletImport = jobPalletImport;
        vm.delAll = delAll;
        vm.create = create;

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function create() {
            $uibModal.open({
                templateUrl: 'app/entities/job-pallet/job-pallet-dialog.html',
                controller: 'JobPalletDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                resolve: {
                    entity: function() {
                        return {
                            companyId: null,
                            jobNum: null,
                            pkgs: null,
                            location: null,
                            palletNo: null,
                            inTime: new Date(),
                            inRegister: null,
                            outTime: null,
                            outRegister: null,
                            id: null
                        };
                    }
                }
            }).result.then(function(code) {
                getContetns();
            }, $rootScope.ALERT_ERROR);
        }

        $scope.gridOptions = {
            enableSorting: true, //排序

            enableGridMenu: true, //表格 菜单  
            showGridFooter: true, //表格的footer  
            showColumnFooter: true, //每列footer

            enableFiltering: true, //高级过滤            

            enableHorizontalScrollbar: 1, //表格的水平滚动条  
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuLabel: "Export",
            exporterExcelFilename: 'job_pallet.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns: ['id'],

            minimumColumnSize: 70,
            columnDefs: [{
                    field: 'id',
                    width: 100,
                    displayName: "",
                    pinnedLeft: true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container"><div class="btn-group" uib-dropdown="" dropdown-append-to-body=""><button id="btn-append-to-body" type="button" class="btn btn-danger btn-grid" uib-dropdown-toggle="" ng-disabled="isSaving || !row.entity.id || row.entity.isVoid"><span class="mr-4 glyphicon glyphicon-wrench"></span><span translate="entity.action.opEnter">opEnter</span><span class="caret"></span></button><ul class="dropdown-menu" uib-dropdown-menu="" role="menu" aria-labelledby="btn-append-to-body"><li><a href="" ng-click="grid.appScope.outCargo(row.entity)"><span class="mr-4 glyphicon glyphicon-log-out"></span><span translate="autopsApp.jobPallet.outCargo">outCargo</span></a></li><li role="separator" class="divider"></li><li><a href="" ng-click="grid.appScope.antiOutCargo(row.entity)"><span class="mr-4 glyphicon glyphicon-erase"></span><span translate="autopsApp.jobPallet.antiOutCargo">antiOutCargo</span></a></li><li role="separator" class="divider"></li><li><a href="" ng-click="grid.appScope.edit(row.entity)"><span class="mr-4 glyphicon glyphicon-edit"></span><span translate="entity.action.amendation">amendation</span></a></li><li role="separator" class="divider"></li><li><a href="" ng-click="grid.appScope.cargoMove(row.entity)"><span class="mr-4 glyphicon glyphicon-transfer"></span><span translate="global.menu.entities.moveShelf">movement</span></a></li><li role="separator" class="divider"></li><li><a href="" ng-click="grid.appScope.delCargo(row.entity)"><span class="mr-4 glyphicon glyphicon-remove"></span><span translate="entity.action.delete">delete</span></a></li></ul></div></div>'
                },
                {
                    field: 'jobNum',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.jobPallet.jobNum'),
                    cellTemplate: '<span class="grid-text"><a href="" ng-click="grid.appScope.BIZPOP_BY_JOB(row.entity.jobNum)">{{row.entity.jobNum}}</a></span>'
                },
                {
                    field: 'pkgs',
                    minWidth: 80,
                    displayName: $translate.instant('autopsApp.jobPallet.pkgs'),
                    type: 'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [{
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ]
                },
                {
                    field: 'palletNo',
                    minWidth: 150,
                    cellTemplate: '<span class="grid-text"><a href="" ng-click="grid.appScope.edit(row.entity)">{{row.entity.palletNo}}</a></span>',
                    displayName: $translate.instant('autopsApp.jobPallet.palletNo')
                },
                {
                    field: 'warehouseName',
                    minWidth: 130,
                    displayName: $translate.instant('autopsApp.jobPallet.warehouseName')
                },
                {
                    field: 'location',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.jobPallet.location')
                },                
                {
                    field: 'inTime',
                    type: 'number',
                    minWidth: 200,
                    displayName: $translate.instant('autopsApp.jobPallet.inTime'),
                    cellTemplate: '<span class="grid-text">{{row.entity.inTime | date:\'medium\'}}</span>',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [{
                            condition: uiGridConstants.filter.GREATER_THAN,
                            placeholder: ' > '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN,
                            placeholder: ' < '
                        }
                    ]
                },
                {
                    field: 'inRegister',
                    minWidth: 120,
                    displayName: $translate.instant('autopsApp.jobPallet.inRegister')
                },
                {
                    field: 'outTime',
                    minWidth: 200,
                    displayName: $translate.instant('autopsApp.jobPallet.outTime'),
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [{
                            condition: uiGridConstants.filter.GREATER_THAN,
                            placeholder: ' > '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN,
                            placeholder: ' < '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.outTime | date:\'medium\'}}</span>',
                },
                {
                    field: 'outRegister',
                    minWidth: 120,
                    displayName: $translate.instant('autopsApp.jobPallet.outRegister')
                }
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 165;
                    GridState.update(gridStateObj, function(result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row) {
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "inventory";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "inventory";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope, function(row) {
                    onSelectionChanged();
                });
                gridApi.selection.on.rowSelectionChangedBatch($scope, function(row) {
                    onSelectionChanged();
                });
            }
        }

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function() {
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 165
            }, function(result) {
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if (!gridStateObj || !gridStateObj.id) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });
        $scope.selectedRows;

        function onSelectionChanged() {
            $scope.selectedRows = $scope.gridApi.selection.getSelectedRows().length;
        }

        function getBarcode(fd) {
            if ("location" == fd) {
                getAllLocations();
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(code) {
                if ("location" == fd) {
                    angular.forEach(vm.locations, function(data) {
                        if (data.code == code) {
                            $scope.filter.location = data.code;
                            $scope.filter.filterType = 'location';
                            getContetns()
                        }
                    });
                } else {
                    $scope.filter[fd] = code;
                }
            }, function() {});
        }

        $scope.edit = function(item) {
            if ($rootScope.account.authorities.indexOf("AU_WMS_AMENDMENT") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AMENDMENT');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/job-pallet/job-pallet-dialog.html',
                controller: 'JobPalletDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: JobPallet.get({ id: item.id }, function(result) {
                        return result;
                    }),
                }
            }).result.then(function(result) {
                getContetns();
            }, function(result) {});
        }

        $scope.delCargo = function(cargo) {
            if (cargo.id) {
                layer.confirm($translate.instant('global.confirmToDelete'), {
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
                    icon: 3
                }, function(index) {
                    layer.close(index);
                    JobPallet.delete({
                        id: cargo.id
                    }, function(result) {
                        removeOne(cargo);
                        layer.msg($translate.instant('global.delete_success'));
                    }, $rootScope.ALERT_ERROR);
                }, function(index) {
                    layer.close(index);
                });
            } else {
                removeOne(cargo);
            }
        };

        function getAllLocations() {
            if ($scope.isSaving || (vm.locations && vm.locations.length > 0)) {
                return;
            }
            WmsWarehouse.getAllLocations({ companyId: $rootScope.account.companyId }, function(data) {
                vm.locations = data;
            });
        }

        function getAllWarehouses() {
            if ($scope.isSaving || (vm.warehouses && vm.warehouses.length > 0)) {
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: true,
                includeGroup: false
            }, function(result) {
                if (!result || result.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                vm.warehouses = result;
            });
        }

        function getContetns() {
            if (!$scope.filter.warehouseName) {
                $scope.filter.warehouseId = null;
                $scope.filter.companyId = null;
            }
            if (!$scope.filter.companyId) {
                $scope.filter.companyId = $rootScope.account.companyId;
            }
            if (!$scope.filter.location) {
                $scope.filter.locationId = null;
            }
            layer.load(1, { shade: 0.3 });
            JobPallet.advancedSearch($scope.filter, function(result) {
                layer.closeAll();
                $scope.gridOptions.data = result;
                resize();
            }, $rootScope.ALERT_ERROR);
        }

        $scope.removeCargo = function(item) {
            var index = $scope.gridOptions.data.indexOf(item);
            if (index > -1) {
                $scope.gridOptions.data.splice(index, 1);
            };
        }

        function removeOne(cargo) {
            var index = $scope.gridOptions.data.indexOf(cargo);
            if (index > -1) {
                $scope.gridOptions.data.splice(index, 1);
            };
        }

        $scope.outCargo = function(cargo) {
            var jobPallets = [];
            if (cargo) {
                jobPallets.push(cargo);
            } else {
                jobPallets = $scope.gridApi.selection.getSelectedRows();
            }
            if (!jobPallets || jobPallets.length == 0) {
                layer.msg($translate.instant('autopsApp.jobPallet.noSelected'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.jobPallet.outCargoAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                JobPallet.outCargo(jobPallets, function() {
                    getContetns();
                }, $rootScope.ALERT_ERROR)
                $translate.refresh();
            }, function() {
                return;
            });

        }

        $scope.antiOutCargo = function(cargo) {
            var jobPallets = [];
            if (cargo) {
                jobPallets.push(cargo);
            } else {
                jobPallets = $scope.gridApi.selection.getSelectedRows();
            }
            if (!jobPallets || jobPallets.length == 0) {
                layer.msg($translate.instant('autopsApp.jobPallet.noSelected'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.jobPallet.antiOutCargoAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                JobPallet.antiOutCargo(jobPallets, function() {
                    getContetns();
                }, $rootScope.ALERT_ERROR)
                $translate.refresh();
            }, function() {
                return;
            });
        }


        $scope.cargoMove = function(cargo) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if (!cargo && selectedRows.length == 0) {
                layer.msg($translate.instant('autopsApp.wmsIn.noInventorySelected'));
                return;
            }
            var entity = {};
            if (cargo) {
                entity.jobPallets = [];
                entity.jobPallets.push(cargo);
            } else if (selectedRows) {
                entity.jobPallets = selectedRows;
            }
            entity.locations = vm.locations;
            $uibModal.open({
                templateUrl: 'app/entities/job-pallet/job-pallet-movement-dialog.html',
                controller: 'JobPalletMovementController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('jobPallet');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                getContetns();
            }, function(result) {});
        }

        function jobPalletImport(file) {
            if (!file || !$rootScope.account.companyId) { return; }
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/job-pallets/jobPalletImport', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                data = angular.fromJson(data);
                if (!data || data.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                getContetns();
                layer.msg($translate.instant('global.importSuccess'));
            }).error(function(error) {
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });
        };

        function delAll() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if (selectedRows.length == 0) {
                layer.msg($translate.instant('autopsApp.wmsIn.noInventorySelected'));
                return;
            }
            layer.confirm($translate.instant('global.delSelectedAlert', { ttlAmt: selectedRows.length }), {
                icon: 0,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                JobPallet.delAll(selectedRows, function() {
                    getContetns();
                    layer.msg($translate.instant('global.delete_success'));
                }, function() {
                    layer.msg($translate.instant('global.delete_fail'));
                })
                layer.close(index);
            }, function() {});
        }
        window.onresize = function() {
            resize();
        }

        function resize() {
            if (!$("#gridId") || !$("#gridId").offset()) {
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if (tabHeight > winHeight) {
                tabHeight = winHeight;
            }
            if (tabHeight < 600) {
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

    }
})();
