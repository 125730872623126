(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AgingAnalysisNewController', AgingAnalysisNewController);

    AgingAnalysisNewController.$inject = ['$scope', '$rootScope', '$translate', '$timeout', '$filter', '$uibModal',
        'OpsFiles', 'Principal', 'AccountPayment', 'AccountRef', 'DateUtils', 'AccountVoucher', 'DataUtils', 'uiGridConstants', 'uiGridGroupingConstants',
        'AccountTitle', 'VoucherContent', 'GuideRates', 'OpsCompany', 'GridState'
    ];

    function AgingAnalysisNewController($scope, $rootScope, $translate, $timeout, $filter, $uibModal,
        OpsFiles, Principal, AccountPayment, AccountRef, DateUtils, AccountVoucher, DataUtils, uiGridConstants, uiGridGroupingConstants,
        AccountTitle, VoucherContent, GuideRates, OpsCompany, GridState) {

        $scope.companys = [];
        $scope.filterDTO = {};
        $scope.filterDTO.cutDate = new Date();
        $scope.filterDTO.agingDateBy = "billDate";
        $scope.filterDTO.type = "receivable";
        $scope.filterDTO.ledgerCode = "client";
        $scope.showCurContents = true;

        function loadCompanys() {
            OpsCompany.getGroupCompanys(function(result) {
                $scope.companys = result;
                $scope.filterDTO.scope = $rootScope.account.companyId;
            })
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                if ($rootScope.account.isSuper) {
                    loadCompanys();
                }
                if ($rootScope.account.authorities.indexOf("REPORT_AGING") !== -1) {
                    $scope.filterDTO.isSales = false;
                } else {
                    $scope.filterDTO.isSales = true;
                }
            });
        } else {
            if ($rootScope.account.isSuper) {
                loadCompanys();
            }
            if ($rootScope.account.authorities.indexOf("REPORT_AGING") !== -1) {
                $scope.filterDTO.isSales = false;
            } else {
                $scope.filterDTO.isSales = true;
            }
        }

        $scope.isSaving = false;

        $scope.getContetns = function() {
            if (!$scope.filterDTO.type) {
                layer.msg($translate.instant('autopsApp.accountTitle.agingNullFilterAlert'));
                return;
            }
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;

            if ($scope.salesList && $scope.salesList.length > 0) {
                $scope.filterDTO.salesIds = [];
                angular.forEach($scope.salesList, function(data) {
                    $scope.filterDTO.salesIds.push(data.id);
                })
            } else {
                $scope.filterDTO.salesIds = null;
            }
            layer.load(1, { shade: 0.3 });
            AccountPayment.agingAnalysisNew($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                layer.closeAll();
                $scope.gridOptions.data = result;
                resize();
                if (!$scope.gridOptions.data || $scope.gridOptions.data.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
            }, $rootScope.ALERT_ERROR);
        }

        $scope.choosedRates = [];
        $scope.viewRates = function(request) {
            var companyId = $scope.filterDTO.scope;
            if (!companyId) {
                companyId = $rootScope.account.companyId;
            }
            $uibModal.open({
                templateUrl: 'app/entities/write-off-rates/write-off-rates-dialog.html',
                controller: 'WriteOffRatesDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: ['GuideRates', function(GuideRates) {
                        return GuideRates.getWrieteOffRatesByDnNum({
                            companyId: companyId,
                            dnNum: request.dnNum
                        }).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }

        $scope.datePickerOpenStatus = {};
        $scope.dayFormat = "yyyy-MM-dd";
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.setAgingDateBy = function(typeValue) {
            $scope.filterDTO.agingDateBy = typeValue;
        }

        $scope.salesList = [];
        $scope._onClerkSelected = function(fd, item) {
            if (fd == "salesId") {
                $scope.salesList.push(item);
            }
            $scope.statParams[fd] = "";
        };
        $scope.delSales = function(index) {
            $scope.salesList.splice(index, 1);
        };

        window.onresize = function() {
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if (tabHeight > winHeight) {
                tabHeight = winHeight;
            }
            if (tabHeight < 500) {
                tabHeight = 500;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        $scope.gridOptions = {
            enableSorting: false, //排序
            enableRowHeaderSelection: false, //行选中
            enableGridMenu: true, //表格 菜单  
            showGridFooter: true, //表格的footer  
            enableFiltering: true, //高级过滤
            showColumnFooter: true, //每列footer

            minRowsToShow: 15,
            enableHorizontalScrollbar: 1, //表格的水平滚动条  
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowSelection: false, //行选中
            enableSelectAll: false, //全部选中
            enableFullRowSelection: false, //行任意位置选中

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuLabel: "Export",
            exporterExcelFilename: $translate.instant('global.menu.entities.agingAnalysis') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns: ['id'],
            columnDefs: [{
                    field: 'companyName',
                    minWidth: 250,
                    grouping: { groupPriority: 0 },
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    aggregationHideLabel: false,
                    displayName: $translate.instant('stats.stat.settlementObj')
                },
                {
                    field: 'originalCur',
                    minWidth: 150,
                    grouping: { groupPriority: 1 },
                    displayName: $translate.instant('stats.stat.guideRates.currency')
                },
                {
                    field: 'originalAmt',
                    type: 'number',
                    minWidth: 100,
                    displayName: $translate.instant('stats.stat.guideRates.total')
                },
                {
                    field: 'baseAmt',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type: 'number',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    minWidth: 150,
                    cellClass: 'grid-primary',
                    displayName: $translate.instant('stats.stat.baseAmt') + " " + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency)
                },
                {
                    field: 'dnNum',
                    minWidth: 150,
                    cellTemplate: '<a class="ml-5" href="" ng-click="grid.appScope.viewRates(row.entity)">{{row.entity.dnNum}}</a>',
                    displayName: $translate.instant('stats.stat.guideRates.dnNum')
                },
                {
                    field: 'thirtyDays',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type: 'number',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    minWidth: 150,
                    displayName: "0-30 Days(" + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency) + ")"
                },
                {
                    field: 'sixtyDays',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type: 'number',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    minWidth: 150,
                    displayName: "31-60 Days(" + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency) + ")"
                },
                {
                    field: 'ninetyDays',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type: 'number',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    minWidth: 150,
                    displayName: "61-90 Days(" + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency) + ")"
                },
                {
                    field: 'hundredTwentyDays',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type: 'number',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    aggregationHideLabel: false,
                    minWidth: 150,
                    displayName: "91-120 Days(" + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency) + ")"
                },
                {
                    field: 'aboveHundredTwenty',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    type: 'number',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    minWidth: 150,
                    displayName: ">120 Days(" + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency) + ")"
                },
                {
                    field: 'creditLimit',
                    treeAggregationType: uiGridGroupingConstants.aggregation.AVG,
                    type: 'number',
                    minWidth: 150,
                    cellClass: 'grid-primary',
                    displayName: $translate.instant('stats.stat.creditLimit') + " " + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency)
                },
                {
                    field: 'creditBalance',
                    treeAggregationType: uiGridGroupingConstants.aggregation.AVG,
                    type: 'number',
                    minWidth: 150,
                    cellClass: 'grid-primary',
                    displayName: $translate.instant('stats.stat.creditBalance') + " " + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency)
                },
                {
                    field: 'paymentTerm',
                    minWidth: 100,
                    displayName: $translate.instant('stats.stat.paymentTerm')
                },
                {
                    field: 'creditRemarks',
                    minWidth: 100,
                    displayName: $translate.instant('stats.stat.creditRemarks')
                },
                {
                    field: 'creditDays',
                    type: 'number',
                    minWidth: 100,
                    displayName: $translate.instant('stats.stat.creditDays')
                },
                {
                    field: 'creditDateType',
                    minWidth: 100,
                    displayName: $translate.instant('stats.stat.creditDateType')
                },
                {
                    field: 'creditRating',
                    minWidth: 100,
                    displayName: $translate.instant('stats.stat.creditRating')
                },
                {
                    field: 'economicDate',
                    minWidth: 100,
                    displayName: $translate.instant('stats.stat.guideRates.etd')
                },
                {
                    field: 'accountDate',
                    minWidth: 100,
                    displayName: $translate.instant('stats.stat.bizDate')
                },
                {
                    field: 'agingVenderType',
                    minWidth: 200,
                    displayName: $translate.instant('stats.stat.agingVenderType')
                },
                {
                    field: 'clientRole',
                    minWidth: 100,
                    displayName: $translate.instant('stats.stat.clientRole')
                },
                {
                    field: 'sales',
                    minWidth: 100,
                    displayName: $translate.instant('stats.stat.filterBySales')
                },
                { field: 'branch', 
                    displayName: $translate.instant('stats.stat.bbranch'), 
                    minWidth:120
                },
                { field: 'team', 
                    displayName: $translate.instant('stats.stat.bteam'), 
                    minWidth:120
                },
                {
                    field: 'billNum',
                    minWidth: 150,
                    displayName: $translate.instant('stats.stat.guideRates.billNum')
                },
                {
                    field: 'invoiceNum',
                    minWidth: 150,
                    displayName: $translate.instant('stats.stat.guideRates.invoiceNum')
                },
                {
                    field: 'jobNums',
                    minWidth: 150,
                    displayName: $translate.instant('stats.stat.jobNum')
                },
                {
                    field: 'blNums',
                    minWidth: 150,
                    displayName: $translate.instant('stats.stat.mblNum')
                },
                {
                    field: 'superiorVenderName',
                    minWidth: 150,
                    displayName: $translate.instant('stats.stat.superiorVenderName')
                }
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 116;
                    GridState.update(gridStateObj, function(result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = $translate.instant('global.menu.entities.agingAnalysis');
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "agingAnalysisByDn";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = $translate.instant('global.menu.entities.agingAnalysis');
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "agingAnalysisByDn";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.grid.registerColumnsProcessor(setGroupValues, 410);
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function() {
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 116
            }, function(result) {
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if (!gridStateObj || !gridStateObj.id) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });
        var setGroupValues = function(columns, rows) {
            columns.forEach(function(column) {
                if (column.grouping && column.grouping.groupPriority > -1) {
                    // Put the balance next to all group labels.
                    column.treeAggregationFn = function(aggregation, fieldValue, numValue, row) {
                        if (typeof(aggregation.value) === 'undefined') {
                            aggregation.value = 0;
                        }
                        if (column.field == "originalCur") {
                            aggregation.value = aggregation.value + row.entity.originalAmt;
                        } else {
                            aggregation.value = aggregation.value + row.entity.baseAmt;
                        }
                    };
                    column.customTreeAggregationFinalizerFn = function(aggregation) {
                        if (typeof(aggregation.groupVal) !== 'undefined') {
                            if (column.field == "originalCur") {
                                aggregation.rendered = aggregation.groupVal + ' (' + aggregation.value + ')';
                            } else {
                                aggregation.rendered = aggregation.groupVal + ' (' + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency) + aggregation.value + ')';
                            }
                        } else {
                            aggregation.rendered = null;
                        }
                    };
                }
            });
            return columns;
        };
        $scope.excelExport = function(resultList) {
            if (!resultList || resultList.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.agingAnalysisByDnReport({
                enCn: $translate.instant('global.enCn')
            }, resultList, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('global.menu.entities.agingAnalysis') + ".xlsx");
            })
        }
        $scope.agingAnalysisByDnSupSubComReport = function(resultList) {
            if (!resultList || resultList.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.agingAnalysisByDnSupSubComReport({
                enCn: $translate.instant('global.enCn')
            }, resultList, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('global.menu.entities.agingAnalysis') + ".xlsx");
            })
        }
        $scope._onVenderSelected = function(item) {
            $scope.filterDTO.venderId = item.id;
        };


    }
})();
