(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountTitleController', AccountTitleController);

    AccountTitleController.$inject = ['$rootScope', '$scope', '$state', '$http', '$translate', '$uibModal', '$interval', '$timeout',
        'Principal', 'JhiLanguageService', 'GridState', 'OpsFiles',
        'AccountTitle', 'AccountTitleSearch', 'ParseLinks', 'OpsCompany', 'DateUtils', 'OpsVender', 'uiGridConstants'
    ];

    function AccountTitleController($rootScope, $scope, $state, $http, $translate, $uibModal, $interval, $timeout,
        Principal, JhiLanguageService, GridState, OpsFiles,
        AccountTitle, AccountTitleSearch, ParseLinks, OpsCompany, DateUtils, OpsVender, uiGridConstants) {
        var vm = this;
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                $rootScope.loadCurrencys();
            });
        } else {
            $rootScope.loadCurrencys();
        }

        $scope.lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            $scope.lang = language;
        });

        if (!$rootScope.titleFilter) {
            $rootScope.titleFilter = "asset";
        }
        $scope.setFilter = function(item) {
            $rootScope.titleFilter = item;
            loadAll();
        }

        vm.predicate = 'code';
        vm.direction = 'asc';
        vm.page = 1;
        vm.itemsPerPage = 20;

        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = null;
        vm.currentSearch = null;

        function loadAll() {
            if (vm.searchQuery) {
                AccountTitleSearch.query({
                    type: $rootScope.titleFilter,
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                AccountTitle.query({
                    type: $rootScope.titleFilter,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [(vm.predicate.endWith('TAG')?vm.predicate.replace('TAG', ''):vm.predicate) + ',' + vm.direction];
                return result;
            }
            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                resize();
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        loadAll();
        window.onresize = function(){
            resize();
        }

        var btnColWidth = null;
        $interval( function() {
            if(!btnColWidth){
                var btnDom = document.getElementById("btnsId");
                if(btnDom){
                    btnColWidth = btnDom.offsetWidth + 10;
                    $scope.gridOptions.columnDefs[0].width = btnColWidth;
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
                }
            }
        }, 2000, 10);

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 130;
            var winHeight = $(window).height() - $("#gridId").offset().top -3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }


        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            loadAll();
        }

        function clear() {
            vm.page = 1;
            vm.predicate = 'code';
            vm.direction = 'asc';
            vm.searchQuery = vm.currentSearch = null;
            loadAll();
        }

        $scope.gridOptions = {
            enableSorting: false,//排序
            enableFiltering: true,//高级过滤

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer
            minRowsToShow: 20,
            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: $translate.instant('autopsApp.accountTitle.home.title') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],

            paginationPageSizes: [20, 25, 30],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 80,
            columnDefs: [
                { name: 'btn',
                    width: 192,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<div id="btnsId" class="btn-group flex-btn-group-container mr-5"><button type="button"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.accountTitle.freezeTips\' | translate}}"ng-click="grid.appScope.freeze(row.entity, true)"class="btn btn-info btn-grid"ng-if="!row.entity.isFreezed"><span class="glyphicon glyphicon-eye-close"></span><span class="hidden-xs hidden-sm"translate="autopsApp.accountTitle.freeze">freeze</span></button><button type="button"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.accountTitle.unFreezeTips\' | translate}}"ng-click="grid.appScope.freeze(row.entity, false)"class="btn btn-danger btn-grid"ng-if="row.entity.isFreezed"><span class="glyphicon glyphicon-eye-open"></span><span class="hidden-xs hidden-sm"translate="autopsApp.accountTitle.unFreeze">unFreeze</span></button><button type="button"ng-hide="row.entity.isFreezed"ng-click="grid.appScope.loadOne(row.entity, false)"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-pencil"></span><span class="hidden-xs hidden-sm"translate="entity.action.edit"></span></button><button type="button"ng-if="row.entity.isFreezed"ng-click="grid.appScope.delete(row.entity)"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-remove"></span><span class="hidden-xs hidden-sm"translate="entity.action.delete"></span></button><button type="button"ng-disabled="row.entity.isFreezed"ng-click="grid.appScope.loadOne(row.entity, true)"class="btn btn-success btn-grid"><span class="glyphicon glyphicon-plus"></span><span class="hidden-xs hidden-sm"translate="autopsApp.accountTitle.addSub"></span></button></div>'},
                { field: 'code',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.accountTitle.code'),
                    cellTemplate: '<a href="" class="ml-5" ng-click="grid.appScope.loadOne(row.entity, null)">{{row.entity.code}}</a>'},
                { field: 'level',
                    displayName: $translate.instant('autopsApp.accountTitle.level'),
                    minWidth:100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: '1', label: "1 " + $translate.instant('autopsApp.accountTitle.class')},
                        { value: '2', label: "2 " + $translate.instant('autopsApp.accountTitle.class')},
                        { value: '3', label: "3 " + $translate.instant('autopsApp.accountTitle.class')},
                        { value: '4', label: "4 " + $translate.instant('autopsApp.accountTitle.class')},
                        { value: '5', label: "5 " + $translate.instant('autopsApp.accountTitle.class')}]
                    },
                    cellTemplate: '<strong class = "ml-5">{{row.entity.level}} <span translate="autopsApp.accountTitle.class"></span></strong>'},
                { field: 'type',
                    displayName: $translate.instant('autopsApp.accountTitle.type'),
                    minWidth:100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: 'asset', label: $translate.instant('autopsApp.accountTitle.detail.asset')},
                        { value: 'liabilities', label: $translate.instant('autopsApp.accountTitle.detail.liabilities')},
                        { value: 'cost', label: $translate.instant('autopsApp.accountTitle.detail.cost')},
                        { value: 'common', label: $translate.instant('autopsApp.accountTitle.detail.common')},
                        { value: 'ownership', label: $translate.instant('autopsApp.accountTitle.detail.ownership')},
                        { value: 'profit', label: $translate.instant('autopsApp.accountTitle.detail.profit')},
                        { value: 'settlement', label: $translate.instant('autopsApp.accountTitle.detail.settlement')}]
                    },
                    cellTemplate: '<span class="ml-5" translate="autopsApp.accountTitle.detail.{{row.entity.type}}" ng-if="row.entity.type">type</span>'},
                { field: 'isDn',
                    displayName: $translate.instant('autopsApp.accountTitle.nature'),
                    minWidth:100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: false, label: $translate.instant('autopsApp.accountTitle.detail.credit')},
                        { value: true, label: $translate.instant('autopsApp.accountTitle.detail.debit')}]
                    },
                    cellTemplate: '<span class="label label-danger ml-5"ng-if="row.entity.isDn == false"translate="autopsApp.accountTitle.detail.credit">credit</span><span class="label label-success ml-5"ng-if="row.entity.isDn == true"translate="autopsApp.accountTitle.detail.debit">debit</span>'},
                { field: 'abb',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.accountTitle.abb')},
                { field: 'cnName',
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.accountTitle.cnName')},
                { field: 'enName',
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.accountTitle.enName')},
                { field: 'format',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.accountTitle.format'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      condition: uiGridConstants.filter.STARTS_WITH,
                      selectOptions: [
                        { value: 'amount', label: $translate.instant('autopsApp.accountTitle.detail.amount')},
                        { value: 'currencyAmount', label: $translate.instant('autopsApp.accountTitle.detail.currencyAmount')}]
                    },
                    cellTemplate: '<span class="ml-5" translate="autopsApp.accountTitle.detail.{{row.entity.format}}" ng-if="row.entity.format">format</span>'},
                { field: 'isForeign',
                    displayName: $translate.instant('autopsApp.accountTitle.isForeign'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<label><input type="checkbox" ng-model="row.entity.isForeign" ng-disabled="true"/></label>'},
                { field: 'currency',
                    displayName: $translate.instant('autopsApp.accountTitle.currency')},
                { field: 'isLast',
                    displayName: $translate.instant('autopsApp.accountTitle.isLast'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<label><input type="checkbox" ng-model="row.entity.isLast" ng-disabled="true"/> </label>'},
                { field: 'isCash',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.accountTitle.isCash'),
                    cellTemplate: '<label><input type="checkbox" ng-model="row.entity.isCash" ng-disabled="true"/></label>'}

            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 54;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    loadAll();
                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 54
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });
        $scope.loadOne = function(accountTitle, isAdd) {
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')) {
                layer.msg($translate.instant('global.forbiddenTeam'));
                return;
            }
            if (accountTitle.type == "settlement") {
                layer.msg($translate.instant('autopsApp.accountTitle.home.creatSettlementAlert'));
                return;
            }
            var entity = {};
            entity.isAdd = isAdd;
            entity.fmVoucher = false;
            entity.accountTitle = accountTitle;
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountTitle/accountTitle-dialog.html',
                controller: 'AccountTitleDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                loadAll();
            }, function(result) {});
        }

        $scope.delete = function(accountTitle) {
            if (accountTitle.level == 1 || !accountTitle.isLast) {
                layer.msg($translate.instant('autopsApp.accountTitle.delLastOnlyAlert'));
                return;
            }
            var delContent = $translate.instant('autopsApp.accountTitle.delete.question', {
                id: accountTitle.code
            });
            layer.confirm(delContent, {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                AccountTitle.delete({
                        id: accountTitle.id
                    },
                    function(result) {
                        layer.msg($translate.instant('global.delete_success'));
                        layer.close(index);
                        loadAll();
                    },
                    function(response) {
                        if (response.status == 403) {
                            layer.msg($translate.instant('autopsApp.accountTitle.this_used'));
                            return;
                        } else {
                            layer.msg($translate.instant('global.delete_fail'));
                        }

                        layer.close(index);
                    });

            }, function() {

            });
        }

        $scope.aTitles = [];
        var aTitlesCopy = [];
        var initializeIndex = 0;
        $scope.startMonth = new Date();

        $scope.datePickerOpenStatus = {};
        $scope.monthFormat = "yyyy-MM";
        $scope.monthDatepickerOptions = {
            minMode: 'month'
        }
        $scope.datePickerOpenStatus.monthpicker = false;
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.initialize = function() {
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')) {
                layer.msg($translate.instant('global.forbiddenTeam'));
                return;
            }
            OpsCompany.get({
                id: $rootScope.account.companyId
            }, function(result) {
                $scope.startMonth = DateUtils.convertLocalDateFromServer(result.openAccountDate);
                $translate.refresh();
                AccountTitle.getAll({
                    type: "initializeAll"
                }, function(result) {
                    $scope.aTitles = result;
                    aTitlesCopy = angular.copy($scope.aTitles);
                    initializeIndex = layer.open({
                        type: 1,
                        area: ['1180px', '800px'],
                        maxmin: true,
                        title: $translate.instant('autopsApp.accountTitle.home.initialize'),
                        shade: 0.3,
                        moveType: 0,
                        shift: 0,
                        content: $("#initializePop"),
                        btn: [$translate.instant('entity.action.save')],
                        yes: function(index, layero) {
                            saveInitialize();
                        }
                    });
                });
            });
        };
        var saveInitialize = function() {
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')) {
                layer.msg($translate.instant('global.forbiddenTeam'));
                return;
            }
            if (!$scope.startMonth) {
                layer.msg($translate.instant('autopsApp.accountTitle.home.beginDateNullAlert'));
                return;
            }
            // if (angular.equals($scope.aTitles, aTitlesCopy)){
            //     layer.msg($translate.instant('global.no_amendmend'));
            //     return;
            // }

            var dnTotal = 0;
            var cnTotal = 0;
            for (var i = 0; i < $scope.aTitles.length; i++) {
                if ($scope.aTitles[i].isLast) {
                    if (!$scope.aTitles[i].accountOpening) {
                        $scope.aTitles[i].accountOpening = 0.00;
                    }
                    if ($scope.aTitles[i].isDn) {
                        dnTotal = dnTotal + $scope.aTitles[i].accountOpening;
                    } else {
                        cnTotal = cnTotal + $scope.aTitles[i].accountOpening;
                    }
                }
            }

            dnTotal = dnTotal.toFixed(2) - 0;
            cnTotal = cnTotal.toFixed(2) - 0;
            var msg = $translate.instant('autopsApp.accountTitle.home.dnTotal') + dnTotal + "; " + $translate.instant('autopsApp.accountTitle.home.cnTotal') + cnTotal;
            if (cnTotal != dnTotal) {
                msg = msg + " " + $translate.instant('autopsApp.accountTitle.home.dnCnNotSame');
                layer.confirm(msg, {
                    title: $translate.instant('autopsApp.accountTitle.home.alert'),
                    btn: [$translate.instant('entity.action.confirm')],
                }, function(index, layero) {
                    layer.close(index);
                });
                return;
            }

            layer.confirm(msg, {
                title: $translate.instant('autopsApp.accountTitle.home.alert'),
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.cancel')],
            }, function(index, layero) {
                layer.close(index);
                var accountTitleInitDTO = {};
                accountTitleInitDTO.companyId = $rootScope.account.companyId;
                accountTitleInitDTO.accountTitles = $scope.aTitles;
                accountTitleInitDTO.startMonth = $scope.startMonth;
                var ldindex = layer.load(1, {shade: 0.3});
                AccountTitle.initialize(accountTitleInitDTO, function(result) {
                    layer.close(ldindex);
                    $scope.aTitles = result;
                    $scope.isSaving = false;
                    $rootScope.OPS_ALERT($translate.instant('global.save_success'));
                }, function(result) {
                    layer.close(ldindex);
                    $scope.isSaving = false;
                    if (result.status === 403) {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.home.initError'));
                    } else if (result.status === 409) {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.home.initConflict'));
                    } else {
                        $rootScope.OPS_ALERT($translate.instant('global.save_fail'));
                    }
                })
            }, function(index) {
                layer.close(index);
            });
        }


        $scope.importExcel = function() {
            $("input[name=depositFile]").trigger('click');
        }

        $("#depositFile").change(function() {
            var file = $('#depositFile').get(0).files[0];
            if (!file) {
                return;
            }
            var fd = new FormData();
            fd.append('file', file);
            fd.append('companyId', $rootScope.account.companyId);

            $http.post(
                'api/accountTitles/importExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(
                function(data) {
                    if (data.success != null && data.success == false) {
                        $rootScope.OPS_ALERT(data.msg);
                    }
                    angular.forEach($scope.aTitles, function(titleOld) {
                        angular.forEach(data, function(titleNew) {
                            if (titleOld.id == titleNew.id) {
                                titleOld.accountOpening = titleNew.accountOpening;
                                titleOld.accountOpeningUsd = titleNew.accountOpeningUsd;
                            }
                        })
                    })
                    layer.msg($translate.instant('global.importSuccess'));

                    $('#depositFile').val(null);
                }).error(function(error) {
                $rootScope.OPS_ALERT(error.msg);
                $('#depositFile').val(null);
            });
        });

        $scope.generateVenderTitle = function() {
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountTitle/generate-vendertitle-dialog.html',
                controller: 'GenerateVenderTitleDialogController',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {};
                    }]
                }
            }).result.then(function(result) {
                loadAll();
            }, function() {})

            $rootScope.LOADALLVENDERS();
        }

        $scope.freeze = function(accountTitle, isFreezed) {
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')) {
                layer.msg($translate.instant('global.forbiddenTeam'));
                return;
            }
            if (accountTitle.type == "settlement") {
                layer.msg($translate.instant('autopsApp.accountTitle.home.creatSettlementAlert'));
                return;
            }
            if (isFreezed && (accountTitle.code == "4103" || accountTitle.code == "6401" || accountTitle.code == "6001" || accountTitle.code == "660302" || accountTitle.code == "660301" || accountTitle.code == "660303")) {
                layer.msg($translate.instant('autopsApp.accountTitle.sys_used'));
                return;
            }
            if (!accountTitle.isLast && isFreezed) {
                layer.msg($translate.instant('autopsApp.accountTitle.topTileAlert'));
                return;
            }
            accountTitle.isFreezed = isFreezed;
            AccountTitle.update(accountTitle, function(){
                layer.msg($translate.instant('global.save_success'));
            });
        };

        $scope.shareAll= function() {
            if ($rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.forbiddenBoss'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.accountTitle.shareAllAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.cancel')]
            }, function(index) {
                layer.close(index);
                index = layer.load(1, {shade: 0.3});
                $http.get('api/accountTitles/shareAll?companyId=' + $rootScope.account.companyId, {
                        transformRequest: angular.identity,
                        responseType: 'arraybuffer',
                        headers: {
                            'Content-Type': undefined
                        },
                    }).success(function(data) {
                        layer.close(index);
                        layer.msg($translate.instant('global.messages.submitted'));
                        var result = new Object();
                        result['data'] = data;
                        $rootScope.DOWNLOADTXT(result, "importResult.txt");
                    }).error(function(error) {
                        layer.close(index);
                        layer.msg($translate.instant('global.messages.submittedfailed'));
                    });
            }, function() {
                return;
            });
        }
        $scope.excelExport = function() {
            OpsFiles.excelAccountTitle({
                title: $translate.instant('autopsApp.accountTitle.home.title'),
                companyId: $rootScope.account.companyId,
                enCn: $translate.instant('global.enCn')
            }, $scope.gridOptions.data, function(result) {
                $rootScope.DOWNLOADEXCEL(result, "accountTitle" + ".xlsx");
            })
        }
        var unsubscribe = $rootScope.$on('autopsApp:accountTitleUpdate', function(event, result) {
            if(result.id){
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.id == result.id){
                        angular.copy(result, data);
                    }
                })
            }
            else {
                loadAll();
            }
        });

    }
})();
