(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WoBodyDeleteController',WoBodyDeleteController);

    WoBodyDeleteController.$inject = ['$uibModalInstance', '$rootScope', '$scope', '$translate', '$timeout', 'entity', 
        'uiGridConstants', 'uiGridGroupingConstants', 'DataUtils', 'GridState'];

    function WoBodyDeleteController($uibModalInstance, $rootScope, $scope, $translate, $timeout, entity, 
        uiGridConstants, uiGridGroupingConstants, DataUtils, GridState) {
        var vm = this;

        
        vm.clear = clear;
        vm.dnNum = entity.dnNum;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $timeout(function (){
            $scope.gridOptions.data = entity.dataList;
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            initGrid();   
            $scope.gridApi.grid.refresh();
            resize();
        })
        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight;
            tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 280){
                tabHeight = 280;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        function initGrid() {
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 143
            }, function(result){                
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);             
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);     
            })            
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            enableGridMenu : true,//表格 菜单  
            showGridFooter: false,//表格的footer  
            enableFiltering: true,//高级过滤
            showColumnFooter: true,//每列footer

            minRowsToShow: 20,
            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('global.menu.admin.chargesReport') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],     
            columnDefs: [
                { field: 'jobNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.jobNum'),
                    cellTemplate: '<a  class="grid-text" href="" ng-click="grid.appScope.VIEWGE(row.entity)" ng-if="row.entity.id">{{row.entity.jobNum}}</a>'},
                { field: 'item',
                    minWidth:100,
                    displayName: $translate.instant('stats.stat.item'),
                    cellTemplate: '<span class="grid-text"><span>{{row.entity.item}}</span><span class="glyphicon glyphicon-alert ml-4 text-danger" ng-hide="row.entity.dnNum && row.entity.dnNum.length > 8" data-toggle="tooltip" data-placement="top" title="Not Locked/Confirmed"></span></span>'},
                { field: 'payCurrency',
                    minWidth:70, 
                    displayName: $translate.instant('stats.stat.currency')},
                { field: 'payAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellTemplate: '<span class="pull-right grid-text" ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.payAmount)}}</span><span ng-if="row.entity.isPay">)</span></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.invoiceAmount')},
                { field: 'thisAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellTemplate: '<span class="grid-text pull-right"><strong ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'" ng-hide="grid.appScope.showIdx == row.entity.idStr" ng-click="grid.appScope.showIdx = row.entity.idStr;grid.appScope.selectTxt(\'amt_field_\'+row.entity.idStr)"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.thisAmount)}}</span><span ng-if="row.entity.isPay">)</span><small class="ml-1 glyphicon glyphicon-pencil"></small></strong><input type="text" class="input-sm form-control text-right" style="padding-right: 5px;" ng-model="row.entity.thisAmount" ng-show="grid.appScope.showIdx == row.entity.idStr" ng-blur="grid.appScope.amountChange(row.entity);grid.appScope.clearShowIdx()" numberic id="amt_field_{{row.entity.idStr}}"/></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.home.writeOffAmount')},               
                { field: 'actualExchangeRate',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:70, 
                    cellTemplate: '<span class="grid-text pull-right"><strong ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'" ng-hide="grid.appScope.showExIdx == row.entity.idStr" ng-click="grid.appScope.setShowIdx(row.entity)"><span>{{row.entity.actualExchangeRate}}</span><small class="ml-1 glyphicon glyphicon-pencil" ng-show="row.entity.payCurrency != grid.appScope.account.baseCurrency"></small></strong><input type="text" class="input-sm form-control text-right" style="padding-right: 5px;" ng-model="row.entity.actualExchangeRate" ng-show="grid.appScope.showExIdx == row.entity.idStr" ng-blur="grid.appScope.amountChange(row.entity);grid.appScope.clearShowIdx()" numberic id="ex_field_{{row.entity.idStr}}" /></span>',
                    displayName: $translate.instant('stats.stat.actualExchangeRate')},
                { field: 'writeOffBaseAmt',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100,         
                    displayName: $translate.instant('stats.stat.guideRates.baseAmt') + "(" + $rootScope.account.baseCurrency + ")"},      
                { field: 'payer',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.settlementObj')},                
                { field: 'diffAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    aggregationType: uiGridConstants.aggregationTypes.sum,         
                    cellTemplate: '<span class="pull-right mr-5" ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.diffAmount)}}</span><span ng-if="row.entity.isPay">)</span></span>',
                    displayName: $translate.instant('stats.stat.notPaid')},
                { field: 'isPayTxt',
                    minWidth:80, 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [ 
                        { value: $translate.instant('stats.stat.isPay'), label: $translate.instant('stats.stat.isPay')}, 
                        { value: $translate.instant('stats.stat.isnotpay'), label: $translate.instant('stats.stat.isnotpay')}]
                    },
                    displayName: $translate.instant('stats.stat.guideRates.isPay')},
                { field: 'dnNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.dnNum')},
                { field: 'paymentTerm',
                    minWidth:50, 
                    displayName: $translate.instant('stats.stat.guideRates.paymentTerm')},
                { field: 'etd',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.etd')},
                { field: 'accountDate',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.accountDate')},
                { field: 'inputBy',
                    minWidth:100,
                    displayName: $translate.instant('stats.stat.inputBy')},
                { field: 'inputTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.inputTime | date:\'medium\'}}">{{row.entity.inputTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.feeInput')},
                { field: 'serviceTerm',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.serviceTerm'), 
                    cellTemplate: '<span class="ml-5" translate="global.{{row.entity.serviceTerm}}" ng-if="row.entity.serviceTerm"></span>'},
                { field: 'currency',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.currency')},
                { field: 'unit',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.unit')},
                { field: 'priceDec',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.price')},
                { field: 'countDec',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.count')},     
                { field: 'shouldCount',
                    type:'number',
                    minWidth:100, 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.guideRates.shouldCount')},    
                { field: 'diffCount',
                    minWidth:100, 
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('stats.stat.guideRates.diffCount'), 
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.diffCount"translate="global.yes_answer" class="label label-danger">yes_answer</span><span ng-hide="row.entity.diffCount"translate="global.no_answer" class="label label-info">no_answer</span></span>'},
                { field: 'totalDec',
                    minWidth:100, 
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.guideRates.total')},
                { field: 'payExchange',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.payExchange')},
                { field: 'pkgNum',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.pkgs')}, 
                { field: 'gw',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.gw')},
                { field: 'vol',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.vol')},
                { field: 'chargeWeight',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.chargeWeight')},
                { field: 'chargeWeightKg', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.chargeWeight') + "(KG)", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'chargeWeightCbm', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.chargeWeight') + "(CBM)", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'volumes',
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.volumes')},
                { field: 'destName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.destName')},
                { field: 'mblNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.mblNum')},
                { field: 'soNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.soNum')},
                { field: 'clientSoNum', 
                    minWidth:150, 
                    displayName: $translate.instant('autopsApp.guide.clientWmsInNum')},
                { field: 'poNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.poNum')},
                { field: 'hblNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.hblNum')},
                { field: 'ctnMawb',
                    minWidth:150,
                    displayName: $translate.instant('stats.stat.ctnNumsStr')},
                { field: 'billNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.billNum')},
                { field: 'mcNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.mcNum')},
                { field: 'billMakeDate', 
                    displayName: $translate.instant('stats.stat.billMakeDate'), 
                    width:120},                
                { field: 'exchangeRate',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.exchangeRate')},
                { field: 'baseAmt',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.baseAmt')}, 
                { field: 'invoiceNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.invoiceNum')},
                { field: 'writeOffCode',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.writeOffCode')},
                { field: 'remarks',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.remarks')},
                { field: 'salesName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterBySales')},
                { field: 'cusName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByCs')},                    
                { field: 'opName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByOperator')},
                { field: 'docName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByDocument')},
                { field: 'customerName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByClient')},
                { field: 'shipperName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByShiperCnee')},
                { field: 'bookingToName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByColoader')},
                { field: 'deliveryName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByAgent')},
                { field: 'clearanceAgent',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.clearanceAgent')},
                { field: 'itemCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.itemCode')},
                { field: 'destCountryCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.destCountryCode')}, 
                { field: 'porName', 
                    displayName: $translate.instant('stats.stat.porName'),
                    minWidth:100},
                { field: 'polName', 
                    displayName: $translate.instant('stats.stat.polName'),
                    minWidth:100},
                { field: 'podName', 
                    displayName: $translate.instant('stats.stat.podName'),
                    minWidth:100},
                { field: 'destName', 
                    displayName: $translate.instant('stats.stat.destName'), 
                    minWidth:100},
                { field: 'deliveryTo', 
                    displayName: $translate.instant('stats.stat.deliveryto'), 
                    minWidth:100},
                { field: 'deliveryTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.deliveryTime | date:\'medium\'}}">{{row.entity.deliveryTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.deliveryTime')},
                { field: 'wmsInTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.wmsInTime')},
                { field: 'ataTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.ataTime | date:\'medium\'}}">{{row.entity.ataTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.ataTime')},
                { field: 'productName', 
                    displayName: $translate.instant('stats.stat.productName'), 
                    minWidth:120},
                { field: 'mjobNum', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.mjobNum')},
                { field: 'carrier',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.bcarrier')},
                { field: 'vsl', 
                    displayName: $translate.instant('stats.stat.vsl'), 
                    minWidth:100},
                { field: 'voy', 
                    displayName: $translate.instant('stats.stat.voy'), 
                    minWidth:100},
                { field: 'team', 
                    displayName: $translate.instant('stats.stat.bteam'), 
                    minWidth:100},
                { field: 'branch',
                    displayName: $translate.instant('stats.stat.bbranch'), 
                    minWidth:100},
                { field: 'handleType',
                    displayName: $translate.instant('stats.stat.handleType'), 
                    minWidth:100},
                { field: 'orderRemarks',
                    displayName: $translate.instant('stats.stat.orderRemarks'), 
                    minWidth:100}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 143;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "chargesReport";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "chargesReport";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.grid.registerRowsProcessor( $scope.singleFilter, 200 );
                // gridApi.selection.on.rowSelectionChanged($scope,function(row){
                //     selectionChange();
                // });
                // gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                //     selectionChange();
                // });
            }
        }
        $scope.filter = function() {
            $scope.gridApi.grid.refresh();
        };
        $scope.singleFilter = function( renderableRows ){
            var matcher = new RegExp(vm.dnNum);
            renderableRows.forEach( function( row ) {
              var match = false;
              [ 'dnNum' ].forEach(function( field ){
                if ( row.entity[field].match(matcher) ){
                  match = true;
                }
              });
              if ( !match ){
                row.visible = false;
              }
            });
            return renderableRows;
        };
        $scope.setShowIdx = function(rate) {
            if(rate.payCurrency != $rootScope.account.baseCurrency){
                 $scope.showExIdx = rate.idStr;
                 $rootScope.selectTxt('ex_field_'+rate.idStr);
            }
        }
        $scope.clearShowIdx = function(){
            $scope.showIdx = null;
            $scope.showExIdx = null;
        }
        $scope.amountChange = function(data){
            if (!data.actual){
                data.actual = 0;
            }                
            if(!data.diffAmount){
                data.diffAmount = DataUtils.round(data.payAmount - data.actual, 2);
            }
            if(!data.thisAmount && data.thisAmount != 0){
                data.thisAmount = data.diffAmount;
            }                
            if(data.diffAmount > 0 && (data.thisAmount < 0 || data.thisAmount > data.diffAmount)){
                data.thisAmount = data.diffAmount;
                layer.msg($translate.instant('autopsApp.opsClient.invalidInput'));
            }
            if(data.diffAmount < 0 && (data.thisAmount > 0 || data.thisAmount < data.diffAmount)){
                data.thisAmount = data.diffAmount;
                layer.msg($translate.instant('autopsApp.opsClient.invalidInput'));
            } 
            data.writeOffBaseAmt = DataUtils.round(data.actualExchangeRate * data.thisAmount, 2); 
            $scope.gridApi.grid.refresh();      

        }

    }
})();
