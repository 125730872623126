(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('ChargesCompareController', ChargesCompareController);

	ChargesCompareController.$inject = ['$scope', '$rootScope', '$translate', '$timeout', 
    'Principal',  'OpsVender', 'GuideRates', 'DateUtils', 'uiGridConstants', 'GridState', 'DataUtils', 'AsyncTask'];

	function ChargesCompareController($scope, $rootScope, $translate, $timeout, 
        Principal, OpsVender, GuideRates, DateUtils, uiGridConstants, GridState, DataUtils, AsyncTask) {
		
		$scope.statParams = {};
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
		//----------统计报表

		$scope.datePickerOpenStatus = {};
		$scope.dayFormat = "yyyy-MM-dd";
		$scope.datePickerOpenStatus.dateFromPicker = false;
		$scope.datePickerOpenStatus.dateToPicker = false;

		$scope.openCalendar = function(date) {
			$scope.datePickerOpenStatus[date] = true;
		}
		var getStatParams = function() {
            $scope.statParams.companyId = $rootScope.account.companyId;
			if (!$scope.statParams.payer){
				$scope.statParams.venderId = null;
			}
			if (!$scope.statParams.item){
				$scope.statParams.itemId = null;
			}
			if (!$scope.statParams.agentName){
				$scope.statParams.deliveryId = null;
			}
			if (!$scope.statParams.coloaderName){
				$scope.statParams.bookingToId = null;	
			}
			if (!$scope.statParams.clientName){
				$scope.statParams.shipperId = null;
			}
			if (!$scope.statParams.customerName){
				$scope.statParams.customerId = null;
			}
		}
		$scope.isSaving = false;
		$scope.getContents = function() {
            var diffDays = DateUtils.getDaysDiff($scope.statParams.dateFrom, $scope.statParams.dateTo);
            if (diffDays < 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            if (diffDays > 92) {
                layer.msg($translate.instant('global.messages.etdPeriodQuarterAlert'));
                return;
            }             
			$scope.isSaving = true;
			getStatParams();
            var loadIndex = layer.load(1, {shade: 0.3});			
			GuideRates.chargesCompare($scope.statParams, function(result) {
				$scope.isSaving = false;
                layer.close(loadIndex);  
				$scope.gridOptions.data = result;
				if (!$scope.gridOptions.data || $scope.gridOptions.data.length == 0) {
					layer.msg($translate.instant('stats.stat.noData'));
					return;
				}
                resize();
			}, function(response) {
				$scope.isSaving = false;
                layer.close(loadIndex);  
				if(response.status == 404){
					layer.msg($translate.instant('stats.stat.noData'));
				}
			});
		}

        $scope._onClientSelected = function(data) {
        	$scope.statParams.shipperId = data.id;
        }
        $scope._onCustomerSelected = function(data) {
        	$scope.statParams.customerId = data.id;
        }
        $scope._onColoaderSelected = function(data) {
        	$scope.statParams.bookingToId = data.id;
        }
        $scope._onAgentSelected = function(data) {
        	$scope.statParams.deliveryId = data.id;
        }
        $scope._onPayerSelected = function(data) {
        	$scope.statParams.venderId = data.id;
        }
        $scope._onItemSelected = function(data) {
            $scope.statParams.itemId = data.id;
        }

        $scope.setLocked = function(val) {
            $scope.statParams.locked = val;
        }
        $scope.setPaymentTerm = function(val) {
            $scope.statParams.paymentTerm = val;
        }
        
        function getTitleStr() {
            var outStr = $translate.instant('global.menu.admin.charges-compare');
            outStr += "(" + DateUtils.convertLocalDateToServer($scope.statParams.dateFrom) 
                + " - " 
                + DateUtils.convertLocalDateToServer($scope.statParams.dateTo)
                + ")";
            return outStr;
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,   

            enableGridMenu : true,//表格 菜单  
            showGridFooter: false,//表格的footer  
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'jobNum', 
                    width: 150,
                    displayName: $translate.instant('stats.stat.jobNum'),
                    cellTemplate: '<span class="grid-text"><a href=""ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity.jobNum}}</a></span>'},
                { field: 'mblNum', 
                    width:120,
                    displayName: $translate.instant('stats.stat.mblNum')},
                { field: 'soNum', 
                    width:120,
                    displayName: $translate.instant('stats.stat.soNum')},
                { field: 'bizDate', 
                    width:120,
                    displayName: $translate.instant('stats.stat.bizDate')},
                { field: 'etd',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.etd')},
                { field: 'eta',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.eta')},                    
                { field: 'serviceTerm', 
                    width:120,
                    cellTemplate: '<span class="grid-text"><span ng-if="row.entity.serviceTerm" translate="global.{{row.entity.serviceTerm}}"></span></span>',
                    displayName: $translate.instant('stats.stat.guideRates.serviceTerm')},
                { field: 'item', 
                    width:120,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    displayName: $translate.instant('stats.stat.item')},
                { field: 'receivableCurrency', 
                    width:60,
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.receivableCurrency}}</strong>',
                    displayName: $translate.instant('stats.stat.currency') + "(R)"},
                { field: 'receivable', 
                    width:80,
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.receivable}}</strong>',
                    displayName: $translate.instant('stats.stat.total') + "(R)"},
                { field: 'receivablePayer', 
                    width:120,
                    displayName: $translate.instant('stats.stat.settlementObj') + "(R)"},
                { field: 'receivableTerm', 
                    width:60,                   
                    displayName: $translate.instant('stats.stat.guideRates.paymentTerm') + "(R)"},
                { field: 'payableCurrency', 
                    width:60,
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.payableCurrency}}</strong>',
                    displayName: $translate.instant('stats.stat.currency') + "(P)"},
                { field: 'payable', 
                    width:80,
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.payable}}</strong>',
                    displayName: $translate.instant('stats.stat.total') + "(P)"},
                { field: 'payablePayer', 
                    width:120,
                    displayName: $translate.instant('stats.stat.settlementObj') + "(P)"},
                { field: 'payableTerm', 
                    width:60,                    
                    displayName: $translate.instant('stats.stat.guideRates.paymentTerm') + "(P)"},
                { field: 'balanceCurrency', 
                    width:60,
                    cellTemplate: '<strong class="grid-text text-danger">{{row.entity.balanceCurrency}}</strong>',
                    displayName: $translate.instant('stats.stat.guideRates.baseCurrency') + "(B)"},
                { field: 'balance', 
                    width:80,
                    cellTemplate: '<strong class="grid-text text-danger">{{row.entity.balance}}</strong>',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('stats.stat.diffAmt') + "(B)"},
                { field: 'salesName', 
                    width:100,
                    displayName: $translate.instant('stats.stat.filterBySales')},
                { field: 'opName', 
                    width:100,
                    displayName: $translate.instant('stats.stat.filterByOperator')},
                { field: 'docName', 
                    width:100,
                    displayName: $translate.instant('stats.stat.filterByDocument')},
                { field: 'polName', 
                    width:100,
                    displayName: $translate.instant('stats.stat.polName')},
                { field: 'podName', 
                    width:100,
                    displayName: $translate.instant('stats.stat.podName')},
                { field: 'destName', 
                    width:100,
                    displayName: $translate.instant('stats.stat.destName')},
                { field: 'deliveryTo', 
                    width:100,
                    displayName: $translate.instant('stats.stat.deliveryTo')},
                { field: 'destCountryCode', 
                    width:60,
                    displayName: $translate.instant('stats.stat.destCountryCode')},
                { field: 'productName', 
                    width:100,
                    displayName: $translate.instant('stats.stat.productName')},
                { field: 'customerName', 
                    width:120,
                    displayName: $translate.instant('stats.stat.filterByClient')},
                { field: 'shipperName', 
                    width:120,
                    displayName: $translate.instant('stats.stat.filterByShiperCnee')},
                { field: 'bookingToName', 
                    width:120,
                    displayName: $translate.instant('stats.stat.filterByColoader')},
                  { field: 'deliveryName', 
                    width:120,
                    displayName: $translate.instant('stats.stat.filterByAgent')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 109;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }    
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "chargeCompare";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "chargeCompare";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi; 
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);                     
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 109
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });

        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 280){
                tabHeight = 280;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        $scope.HHExportChargesCompareExcel = function () {
            if ($scope.gridOptions.data == null || $scope.gridOptions.data.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            GuideRates.HHExportChargesCompareExcel($scope.gridOptions.data, function (result) {
                layer.closeAll();
                var filename = DateUtils.convertLocalDateToServer($scope.statParams.dateFrom) + "_" + DateUtils.convertLocalDateToServer($scope.statParams.dateTo) + "chargesCompare.xlsx";
                $rootScope.DOWNLOADEXCEL(result, filename);
            }, function () {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }

        $scope.asyncTasks = function(allCols) {
            getStatParams();
            if(!$scope.statParams.dateFrom || !$scope.statParams.dateTo){
                layer.msg($translate.instant('stats.stat.dateNullAlert'));              
                return;
            }
            var gridState = $scope.gridApi.saveState.save();
            DataUtils.fillGridDisplayName(gridState, $scope.gridOptions.columnDefs, allCols);
            
            var body = {};
            body.statParams = $scope.statParams;
            body.gridState = gridState;
            body.taskType = 'CHARGES_COMPARE';
            var loadIndex = layer.load(1, {shade: 0.3});
            AsyncTask.submitAsyncTask(body, function(result) {
                layer.close(loadIndex);
                layer.msg($translate.instant('autopsApp.asyncTask.tips.taskSubmitSuccess'));
            }, $rootScope.ALERT_ERROR);
        }

	}
})();