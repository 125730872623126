(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WarehousingDataController', WarehousingDataController);

        WarehousingDataController.$inject = ['$scope', '$state', '$timeout', '$translate', '$rootScope', '$uibModal',
        'WarehousingData', 'DataUtils', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WinAES', 'uiGridConstants',
        'GridState'];

    function WarehousingDataController($scope, $state, $timeout, $translate, $rootScope, $uibModal,
        WarehousingData, DataUtils, ParseLinks, AlertService, pagingParams, paginationConstants, WinAES, uiGridConstants,
        GridState) {
        var vm = this;
        vm.exportExcel = exportExcel;
        vm.loadPage = loadPage;
        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.page = 1;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAdvanceSearch = false;
        var gridCopy;
        var pageLoaded = true;
        var filterTimer;
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function () {
            initGrid();
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 133
            }, function (result) {
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if (!gridStateObj || !gridStateObj.id) {
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if (gridCopy) {
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function (column) {
                        angular.forEach(column.filters, function (cf) {
                            if (cf.term) {
                                filterRequired = true;
                            }
                        })
                    })
                    if (filterRequired) {
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                        return;
                    }
                }
                loadAll();
            })
        });

        $scope.linghangDataMap = { comCodeList: [], transportIdList: [], applicantCertificateTypeList: [], goodsTypeList: [], bigPackList: [], smallPackList: [], jzxList: [], currencyList: [], startPortSmallList: [] };

        function loadAll() {
            if (vm.searchQuery) {
                WarehousingData.query({
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                filter.guide = {};
                if (gridCopy) {
                    angular.forEach(gridCopy.columns, function (column) {
                        angular.forEach(column.filters, function (cf) {
                            if (cf.term != null && angular.isDefined(cf.term)) {
                                var fd = column.field;
                                if(fd.indexOf("guide.") == 0){
                                    fd = fd.replace("guide.", "");
                                    if (cf.condition) {
                                        if (cf.condition == 128 || cf.condition == 256) {
                                            filter.guide[fd + "TO"] = cf.term;
                                        } else if (cf.condition == 32 || cf.condition == 64) {
                                            filter.guide[fd + "FM"] = cf.term;
                                        }
                                    }
                                    else {
                                        filter.guide[fd] = cf.term;
                                    }
                                }
                                else {
                                    if (cf.condition) {
                                        if (cf.condition == 128 || cf.condition == 256) {
                                            filter[fd + "TO"] = cf.term;
                                        } else if (cf.condition == 32 || cf.condition == 64) {
                                            filter[fd + "FM"] = cf.term;
                                        }
                                    }
                                    else {
                                        filter[fd] = cf.term;
                                    }
                                }
                            }
                        })
                    })
                }
                console.log(angular.toJson(filter, true))
                WarehousingData.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + vm.direction];
                return result;
            }
            function onSuccess(data, headers) {
                if (headers('X-Total-Count')) {
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                } else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                vm.isAdvanceSearch = false;
                initData();
                resize();
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        window.onresize = function () {
            resize();
        }

        function resize() {
            if (!$("#gridId") || !$("#gridId").offset()) {
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if (tabHeight > winHeight) {
                tabHeight = winHeight;
            }
            if (tabHeight < 600) {
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        function initData() {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.enablePagination = !vm.isAdvanceSearch;
            $scope.gridOptions.enablePaginationControls = !vm.isAdvanceSearch;
            DataUtils.addWarehousingDataTag($scope.gridOptions.data);
            initGrid();
        }
        function initGrid() {
            if(!$rootScope.account.companyId){
                for(var index = $scope.gridOptions.columnDefs.length - 1; index >= 0; index--){
                    var col = $scope.gridOptions.columnDefs[index];
                    if(col.field == "guide.customerName" || col.field == "guide.register"){
                        $scope.gridOptions.columnDefs.splice(index, 1);
                    }
                }
            }
        }

        $scope.gridOptions = {
            enableSorting: true, //排序
            useExternalSorting: true,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: true, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            paginationPageSizes: [20, 35, 50, 100],
            paginationPageSize: 20,
            useExternalPagination: true,
            enablePagination: true,
            enablePaginationControls: true,

            minimumColumnSize: 70,
            exporterExcelFilename: "WareHousing Data",
            columnDefs: [{
                field: 'guide.jobNum',
                minWidth: 150,
                displayName: $translate.instant('autopsApp.warehousingData.jobNum') + "(JOB)",
                aggregationType: uiGridConstants.aggregationTypes.count,
                cellTemplate: '<span class="grid-text"><a href="" ng-click="grid.appScope.FMS_RAPID_IN(grid.appScope.ISFBA(row.entity.guide.shipmentType), row.entity.guide.id)">{{row.entity.guide.jobNum}}</a><span class="ml-5 text-danger" ng-if="grid.appScope.vm.isAdvanceSearch" ng-click="grid.appScope.deleteOne(row.entity)"><span class="glyphicon glyphicon-remove-circle"></span></span></span>'
            }, {
                field: 'guide.poNum',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.warehousingData.poNum') + "(JOB)"
            }, {
                field: 'guide.productName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.warehousingData.productName') + "(JOB)"
            }, {
                field: 'guide.customerName',
                minWidth: 200,
                displayName: $translate.instant('autopsApp.warehousingData.customerName') + "(JOB)"
            }, {
                field: 'guide.porName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.warehousingData.porName') + "(JOB)"
            }, {
                field: 'guide.whName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.warehousingData.whName') + "(JOB)"
            }, {
                field: 'guide.shipmentId',
                minWidth: 120,
                displayName: "Shipment ID(JOB)"
            }, {
                field: 'guide.fbaRef',
                minWidth: 120,
                displayName: "Reference ID(JOB)"
            }, {
                field: 'guide.chargeWeight',
                minWidth: 90,
                type:'number',
                aggregationType: uiGridConstants.aggregationTypes.sum,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.wmsIn.cw') + "(JOB)"
            }, { field: 'guide.otherTimeOneTAG',
                displayName: $translate.instant('autopsApp.warehousingData.isWmsIn') + "(JOB)",
                minWidth:100,
                filter: {
                type: uiGridConstants.filter.SELECT,
                selectOptions: $rootScope.YES_NO_OPTIONS
                },
                type: "boolean",
                cellTemplate: '<span ng-class="row.entity.guide.otherTimeOneTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.guide.otherTimeOneTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'
            }, { field: 'guide.otherTimeTwoTAG',
                displayName: $translate.instant('autopsApp.warehousingData.isWmsOut') + "(JOB)",
                minWidth:100,
                filter: {
                type: uiGridConstants.filter.SELECT,
                selectOptions: $rootScope.YES_NO_OPTIONS
                },
                type: "boolean",
                cellTemplate: '<span ng-class="row.entity.guide.otherTimeTwoTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.guide.otherTimeTwoTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'
            }, {
                field: 'batchNumber',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.warehousingData.batchNumber')
            }, {
                field: 'cargoName',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.warehousingData.cargoName')
            }, {
                field: 'barcode',
                minWidth: 100,
                displayName: $translate.instant('autopsApp.warehousingData.barcode')
            }, {
                field: 'pkgType',
                minWidth: 70,
                displayName: $translate.instant('autopsApp.warehousingData.pkgType')
            }, {
                field: 'location',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.warehousingData.location')
            }, {
                field: 'remarks',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.warehousingData.remarks')
            }, {
                field: 'pkgNum',
                minWidth: 70,
                type:'number',
                aggregationType: uiGridConstants.aggregationTypes.sum,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.pkgNum')
            }, {
                field: 'gw',
                type:'number',
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.gw'),
                minWidth:100
            }, {
                field: 'vol',
                type:'number',
                minWidth: 100,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.vol')
            }, {
                field: 'length',
                type:'number',
                minWidth: 70,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.length')
            }, {
                field: 'width',
                type:'number',
                minWidth: 70,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.width')
            },
            {
                field: 'height',
                type:'number',
                minWidth: 70,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.height')
            }, {
                field: 'girth',
                type:'number',
                minWidth: 70,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.wmsIn.girth')
            }, {
                field: 'pallets',
                minWidth: 100,
                type:'number',
                aggregationType: uiGridConstants.aggregationTypes.sum,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.pallets')
            }, {
                field: 'gwTtl',
                minWidth: 100,
                type:'number',
                aggregationType: uiGridConstants.aggregationTypes.sum,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.gwTtl')
            }, {
                field: 'volTtl',
                minWidth: 100,
                type:'number',
                aggregationType: uiGridConstants.aggregationTypes.sum,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.warehousingData.volTtl')
            }, {
                field: 'vw',
                minWidth: 100,
                type:'number',
                aggregationType: uiGridConstants.aggregationTypes.sum,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.wmsIn.vw')
            }, {
                field: 'cw',
                minWidth: 100,
                type:'number',
                aggregationType: uiGridConstants.aggregationTypes.sum,
                filters: [
                    {
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                    },
                    {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                    }
                ],
                displayName: $translate.instant('autopsApp.wmsIn.cw')
            }, {
                field: 'registrationTime',
                minWidth: 200,
                type:'date',
                filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                filters: [{
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                }, {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                }],
                displayName: $translate.instant('autopsApp.warehousingData.registrationTime'),
                cellTemplate: "<span>{{row.entity.registrationTime | date:'medium'}}</span>"
            }, {
                field: 'register',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.warehousingData.register')
            }, {
                field: 'guide.cargoName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.guide.cargoName') + "(JOB)"
            }, {
                field: 'guide.soNum',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.guide.soWmsInNum') + "(JOB)"
            }, {
                field: 'guide.deliveryTo',
                minWidth: 120   ,
                displayName: $translate.instant('autopsApp.warehousingData.deliveryTo'),
            }],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function ($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 133;
                    GridState.update(gridStateObj, function (result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function (error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function ($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function ($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "warehousingData";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "warehousingData";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                    if (!$scope.gridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function (a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function () {
                    gridCopy = this.grid;
                    if (pageLoaded && $scope.gridOptions.useExternalFiltering) {
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function () {
                            loadAll();
                        }, 1000); //查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if (pageLoaded) {
                        loadAll();
                    }
                });
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + 'desc',
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction = 'desc';
            vm.currentSearch = null;
            vm.isAdvanceSearch = false;
            vm.transition();
            loadAll();
        }

        $scope.searchRequestPop = function () {
            var searchParams = {};
            searchParams.guide = {};
            $uibModal.open({
                templateUrl: 'app/entities/warehousing-data/warehousing-data-search.html',
                controller: 'WarehousingDataSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function () {
                        return searchParams;
                    }]
                }
            }).result.then(function (results) {
                $scope.gridOptions.data = results;
                vm.isAdvanceSearch = vm.currentSearch = true;
                initData();
                $scope.gridOptions.totalItems = results.length;
                $timeout(function (){
                    resize();
                });

            }, function () {
            });
        }

        function exportExcel() {
            if (!$scope.gridOptions.data || $scope.gridOptions.data.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = "warehousingData.xlsx";
            WarehousingData.exportWarehousingData($scope.gridOptions.data, function (result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }

        $scope.deleteOne = function(row) {
            var index = $scope.gridOptions.data.indexOf(row);
            $scope.gridOptions.data.splice(index, 1);
        }
    }

})();
