(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ParcelGoodsDialogController', ParcelGoodsDialogController);

    ParcelGoodsDialogController.$inject = ['$timeout', '$scope', '$translate', '$uibModal', '$uibModalInstance', '$rootScope',
        'entity', 'GridState', 'WmsIn', 'DataUtils', 'uiGridGroupingConstants', 'uiGridConstants'];

    function ParcelGoodsDialogController ($timeout, $scope, $translate, $uibModal, $uibModalInstance, $rootScope,
        entity, GridState, WmsIn, DataUtils, uiGridGroupingConstants, uiGridConstants) {
        var vm = this;

        vm.parcelGoods = entity;
        vm.clear = clear;
        vm.loadTdOrder = loadTdOrder;
        vm.createTms = createTms;
        vm.advanceSearch = advanceSearch;
        vm.searchStr;


        var wmsInCopy = {};
        $timeout(function (){
            if(entity && entity.id){
                loadOrder(entity.id);
            }
        });

        function loadOrder (tdId) {
            WmsIn.get({id: tdId}, function(result){
                angular.forEach(result.cargoList, function(data){
                    data.eta = result.etd;
                })
                addToDataList_1(result.cargoList);
                wmsInCopy = angular.copy(result);
            });
        }
        function loadTdOrder () {
            WmsIn.loadWmsIn({
                searchNum: vm.searchStr
            }, function(result) {
                if(!result || !result.cargoList || result.cargoList.length == 0){
                    layer.msg($translate.instant('global.noRecord'));
                    return;
                }
                angular.forEach(result.cargoList, function(data){
                    data.eta = result.etd;
                })
                addToDataList_1(result.cargoList);
                if(!wmsInCopy){
                    wmsInCopy = angular.copy(result);
                }
            }, function(error) {
                layer.msg($translate.instant('global.loadFailed'));
            });
        }

        function addToDataList_1(resultList) {
            if(!resultList || resultList.length == 0){
                return;
            }
            if(!$scope.gridOptions_1.data){
                $scope.gridOptions_1.data = [];
            }            
            var cargoIdSet = new Set();
            angular.forEach($scope.gridOptions_1.data, function(data){
                cargoIdSet.add(data.id);
            })
            angular.forEach(resultList, function(data){
                if(!cargoIdSet.has(data.id)){
                    if(!data.batchThree){
                        cargoIdSet.add(data.id);
                        updateRowGwVolTtl(data);
                        $scope.gridOptions_1.data.push(data);
                    }
                }
                else {
                    layer.msg($translate.instant('global.messages.repeated'));
                }
            })
        }

        $scope.addToList = function(data) {
            if(!$scope.gridOptions_2.data){
                $scope.gridOptions_2.data = [];
            }
            var isRepeated = false;
            for(var j = $scope.gridOptions_2.data.length -1; j >=0; j--){
                if (data.id && data.id == $scope.gridOptions_2.data[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                $scope.gridOptions_2.data.push(data);
            }          
            var index = $scope.gridOptions_1.data.indexOf(data);
            if (index > -1) {
                $scope.gridOptions_1.data.splice(index, 1);
            };
        }
        $scope.delFromList = function(data) {
            var index = $scope.gridOptions_2.data.indexOf(data);
            if (index > -1) {
                $scope.gridOptions_2.data.splice(index, 1);
            };

            var isRepeated = false;
            for(var j = $scope.gridOptions_1.data.length -1; j >=0; j--){
                if (data.id == $scope.gridOptions_1.data[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (!isRepeated){
                $scope.gridOptions_1.data.push(data);
            }
        }

        $scope.clearList = function() {
            $scope.gridOptions_1.data = [];
        }

        $scope.addAllToList = function() {
            if(!$scope.gridOptions_1.data){
                return;
            }
            for(var i = $scope.gridOptions_1.data.length -1; i>=0; i--){
                $scope.addToList($scope.gridOptions_1.data[i]);
            }
        }
        $scope.delAllFromList = function() {
            if(!$scope.gridOptions_2.data){
                return;
            }
            for(var i = $scope.gridOptions_2.data.length -1; i>=0; i--){
                $scope.delFromList($scope.gridOptions_2.data[i]);
            }
        }
        function updateRowGwVolTtl (data) {
            if(data.pkgs){
                if(data.nw){
                    data.nwTtl = DataUtils.round(data.nw * data.pkgs, 4);
                }
                if(data.gw){
                    data.gwTtl = DataUtils.round(data.gw * data.pkgs, 4);
                }
                if(data.vol){
                    data.volTtl = DataUtils.round(data.vol * data.pkgs, 4);
                }
                if(data.capacity){
                    data.capacityTtl = DataUtils.round(data.capacity * data.pkgs, 2);
                }
                if(data.piece){
                    data.pieceTtl = DataUtils.round(data.piece * data.pkgs, 2);
                }
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.gridOptions_1 = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'unchoosed.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 80,
            columnDefs: [
                { field: 'id', 
                    width: 50,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<button type="button"ng-click="grid.appScope.addToList(row.entity)"class="btn btn-danger btn-grid ml-5"><span class="glyphicon glyphicon-plus"></span></button>'},
                { field: 'podName',  
                    displayName: $translate.instant('autopsApp.wmsIn.shiptoPlace'), 
                    minWidth:80},
                { field: 'batchOne', 
                    minWidth:120,
                    displayName: "Shipment ID"},
                { field: 'wmsInNum', 
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.wmsIn.wmsInNum')}, 
                { field: 'returnHandle', 
                    minWidth:80,            
                    displayName: $translate.instant('autopsApp.wmsIn.deliveryService')},
                { field: 'palletAmt', 
                    type: 'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.palletAmt'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},
                { field: 'pkgs',  
                    type: 'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,     
                    displayName: $translate.instant('autopsApp.wmsIn.pkgs'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},
                { field: 'gw',   
                    type: 'number',
                    displayName: $translate.instant('autopsApp.wmsIn.gw'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},
                { field: 'vol', 
                    type: 'number',
                    displayName: $translate.instant('autopsApp.wmsIn.vol'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},
                { field: 'gwTtl',   
                    type: 'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,     
                    displayName: $translate.instant('autopsApp.wmsIn.gwTtl'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},
                { field: 'volTtl', 
                    type: 'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.volTtl'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},                
                { field: 'length', 
                    type: 'number',
                    displayName: "L(CM)", 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:50},
                { field: 'width', 
                    type: 'number',
                    displayName: "W(CM)", 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:50},
                { field: 'height', 
                    type: 'number',
                    displayName: "H(CM)", 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:50},
                { field: 'shiptoAddress',  
                    displayName: $translate.instant('autopsApp.wmsIn.shiptoAddress'), 
                    minWidth:150},
                { field: 'sku',
                    minWidth:150,
                    displayName: "SKU"},
                { field: 'batchTwo', 
                    minWidth:150,            
                    displayName: "Reference ID"},
                { field: 'batchFour', 
                    minWidth:150,            
                    displayName: "Ref Job#"},
                { field: 'ctnNum', 
                    minWidth:150,            
                    displayName: $translate.instant('autopsApp.wmsIn.ctnNum')},
                { field: 'truckNum', 
                    minWidth:150,            
                    displayName: $translate.instant('autopsApp.wmsIn.truckNum')},
                { field: 'poNum', 
                    minWidth:150,            
                    displayName: $translate.instant('autopsApp.wmsIn.poNum')},
                // { field: 'availableAmt', 
                //     type: 'number',
                //     displayName: $translate.instant('autopsApp.wmsIn.availableAmt'), 
                //     filters: [
                //         {
                //           condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                //           placeholder: ' >= '
                //         },
                //         {
                //           condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                //           placeholder: ' <= '
                //         }
                //     ],
                //     minWidth:80},
                // { field: 'inventoryAmt', 
                //     type: 'number',
                //     displayName: $translate.instant('autopsApp.wmsIn.inventoryAmt'), 
                //     filters: [
                //         {
                //           condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                //           placeholder: ' >= '
                //         },
                //         {
                //           condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                //           placeholder: ' <= '
                //         }
                //     ],
                //     minWidth:80},
                { field: 'eta', 
                    displayName: $translate.instant('autopsApp.wmsIn.eta'), 
                    minWidth:120,
                    type:'date',
                    cellTemplate: '<span class="grid-text">{{row.entity.eta | date:\'mediumDate\'}}</span>'},
                { field: 'inTime', 
                    displayName: $translate.instant('autopsApp.wmsIn.actualInTime'), 
                    minWidth:160,
                    type:'date',
                    // filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    // filters: [
                    //     {
                    //       condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    //       placeholder: ' >= '
                    //     },
                    //     {
                    //       condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    //       placeholder: ' <= '
                    //     }
                    // ],
                    cellTemplate: '<span class="grid-text">{{row.entity.inTime | date:\'medium\'}}</span>'},
                { field: 'cargoName',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.cargoName')},
                { field: 'clientName',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.clientName')},                    
                { field: 'packageType',  
                    displayName: $translate.instant('autopsApp.wmsIn.pkgType'), 
                    minWidth:50},
                { field: 'remarks',
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.wmsIn.remarks')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_1.gridState = angular.toJson($scope.gridApi_1.saveState.save());
                    gridStateObj_1.userId = $rootScope.account.id;
                    gridStateObj_1.gridId = 150;
                    GridState.update(gridStateObj_1, function(result){
                        gridStateObj_1 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_1.gridState){
                        $scope.gridApi_1.saveState.restore($scope, angular.fromJson(gridStateObj_1.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi_1.saveState.restore($scope, gridStateCopy_1);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi_1 = gridApi;
            }
        }
        $scope.gridOptions_2 = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'choosed.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 80,
            columnDefs: [
                { field: 'id', 
                    width: 50,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<button type="button"ng-click="grid.appScope.delFromList(row.entity)"class="btn btn-danger btn-grid ml-5"><span class="glyphicon glyphicon-remove"></span></button>'},
                { field: 'podName',  
                    displayName: $translate.instant('autopsApp.wmsIn.shiptoPlace'), 
                    minWidth:80},
                { field: 'batchOne', 
                    minWidth:120,
                    displayName: "Shipment ID"},
                { field: 'wmsInNum', 
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.wmsIn.wmsInNum')}, 
                { field: 'returnHandle', 
                    minWidth:80,            
                    displayName: $translate.instant('autopsApp.wmsIn.deliveryService')},
                { field: 'palletAmt', 
                    type: 'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.palletAmt'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},
                { field: 'pkgs',  
                    type: 'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,     
                    displayName: $translate.instant('autopsApp.wmsIn.pkgs'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},
                { field: 'gw',   
                    type: 'number',
                    displayName: $translate.instant('autopsApp.wmsIn.gw'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},
                { field: 'vol', 
                    type: 'number',
                    displayName: $translate.instant('autopsApp.wmsIn.vol'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},
                { field: 'gwTtl',   
                    type: 'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,     
                    displayName: $translate.instant('autopsApp.wmsIn.gwTtl'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},
                { field: 'volTtl', 
                    type: 'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.volTtl'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:80},                
                { field: 'length', 
                    type: 'number',
                    displayName: "L(CM)", 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:50},
                { field: 'width', 
                    type: 'number',
                    displayName: "W(CM)", 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:50},
                { field: 'height', 
                    type: 'number',
                    displayName: "H(CM)", 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:50},
                { field: 'shiptoAddress',  
                    displayName: $translate.instant('autopsApp.wmsIn.shiptoAddress'), 
                    minWidth:150},
                { field: 'sku',
                    minWidth:150,
                    displayName: "SKU"},
                { field: 'batchTwo', 
                    minWidth:150,            
                    displayName: "Reference ID"},
                { field: 'batchFour', 
                    minWidth:150,            
                    displayName: "Ref Job#"},
                { field: 'ctnNum', 
                    minWidth:150,            
                    displayName: $translate.instant('autopsApp.wmsIn.ctnNum')},
                { field: 'truckNum', 
                    minWidth:150,            
                    displayName: $translate.instant('autopsApp.wmsIn.truckNum')},
                { field: 'poNum', 
                    minWidth:150,            
                    displayName: $translate.instant('autopsApp.wmsIn.poNum')},
                // { field: 'availableAmt', 
                //     type: 'number',
                //     displayName: $translate.instant('autopsApp.wmsIn.availableAmt'), 
                //     filters: [
                //         {
                //           condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                //           placeholder: ' >= '
                //         },
                //         {
                //           condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                //           placeholder: ' <= '
                //         }
                //     ],
                //     minWidth:80},
                // { field: 'inventoryAmt', 
                //     type: 'number',
                //     displayName: $translate.instant('autopsApp.wmsIn.inventoryAmt'), 
                //     filters: [
                //         {
                //           condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                //           placeholder: ' >= '
                //         },
                //         {
                //           condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                //           placeholder: ' <= '
                //         }
                //     ],
                //     minWidth:80},
                { field: 'eta', 
                    displayName: $translate.instant('autopsApp.wmsIn.eta'), 
                    minWidth:120,
                    type:'date',
                    cellTemplate: '<span class="grid-text">{{row.entity.eta | date:\'mediumDate\'}}</span>'},
                { field: 'inTime', 
                    displayName: $translate.instant('autopsApp.wmsIn.actualInTime'), 
                    minWidth:160,
                    type:'date',
                    cellTemplate: '<span class="grid-text">{{row.entity.inTime | date:\'medium\'}}</span>'},
                { field: 'cargoName',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.cargoName')},
                { field: 'packageType',  
                    displayName: $translate.instant('autopsApp.wmsIn.pkgType'), 
                    minWidth:50},
                { field: 'remarks',
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.wmsIn.remarks')}                
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_2.gridState = angular.toJson($scope.gridApi_2.saveState.save());
                    gridStateObj_2.userId = $rootScope.account.id;
                    gridStateObj_2.gridId = 151;
                    GridState.update(gridStateObj_2, function(result){
                        gridStateObj_2 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_2.gridState){
                        $scope.gridApi_2.saveState.restore($scope, angular.fromJson(gridStateObj_2.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi_2.saveState.restore($scope, gridStateCopy_2);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi_2 = gridApi;
            }
        }


        var gridStateObj_1 = {};
        var gridStateCopy_1 = null;
        var gridStateObj_2 = {};
        var gridStateCopy_2 = null;

        $timeout(function (){
            gridStateCopy_1 = $scope.gridApi_1.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 150
            }, function(result){
                gridStateObj_1 = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions_1);
                if(!gridStateObj_1 || !gridStateObj_1.id){
                    $scope.gridApi_1.saveState.restore($scope, gridStateCopy_1);
                    return;
                }   
                $scope.gridApi_1.saveState.restore($scope, angular.fromJson(gridStateObj_1.gridState));
            })

            gridStateCopy_2 = $scope.gridApi_2.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 151
            }, function(result){
                gridStateObj_2 = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions_2);
                if(!gridStateObj_2 || !gridStateObj_2.id){
                    $scope.gridApi_2.saveState.restore($scope, gridStateCopy_2);
                    return;
                }   
                $scope.gridApi_2.saveState.restore($scope, angular.fromJson(gridStateObj_2.gridState));
            })
        });

        vm.tmsType = "FTL";
        function createTms(combineCreate){            
            wmsInCopy.cargoList = angular.copy($scope.gridOptions_2.data);
            if(!wmsInCopy.cargoList.length){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }

            var warehouseIdSet = [];
            angular.forEach(wmsInCopy.cargoList, function(data){
                if(data.warehouseId && warehouseIdSet.indexOf(data.warehouseId) == -1){
                    warehouseIdSet.push(data.warehouseId);
                }                
            })
            if(warehouseIdSet.length > 1){
                layer.confirm($translate.instant('autopsApp.wmsIn.diffWarehouseAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    WmsIn.createTms({
                        combineCreate: combineCreate,
                        tmsType: vm.tmsType
                    }, wmsInCopy, onSaveSuccess, onSaveError);
                }, function() {
                });
            }
            else {
                layer.confirm($translate.instant('autopsApp.wmsIn.createTmsAlert', {param: wmsInCopy.cargoList.length}), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    WmsIn.createTms({
                        combineCreate: combineCreate,
                        tmsType: vm.tmsType
                    }, wmsInCopy, onSaveSuccess, onSaveError);
                }, function() {
                });
            }
        };


        function onSaveSuccess (result) {
            layer.msg($translate.instant('global.messages.submitted'));
            $scope.$emit('autopsApp:tdCombineUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        function advanceSearch() {
            var filter = {
                companyId: $rootScope.account.companyId,
                forTmsCombine: true,
                exactSearch: true
            }
            $uibModal.open({
                templateUrl: 'app/entities/parcel-goods/parcel-goods-detail.html',
                controller: 'ParcelGoodsDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return filter;
                    }]
                }
            }).result.then(function(result) {
                if(!result || result.length == 0){
                    layer.msg($translate.instant('global.noRecord'));
                    return;
                }
                addToDataList_1(result);
            }, function() {
            });
        }



    }
})();
