(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('TmsCombineController', TmsCombineController);

    TmsCombineController.$inject = ['$rootScope', '$scope', '$state', '$translate', '$uibModal', '$timeout', '$location', 
    'Principal', 'DataUtils', 'Guide', 'GuideSearch', 'OpsFiles', 'WmsWarehouse', 'uiGridConstants', 'GridState', 'ExpressParcel',
    'ExpressPacking'];
    
    function TmsCombineController($rootScope, $scope, $state, $translate, $uibModal, $timeout, $location, 
        Principal, DataUtils, Guide, GuideSearch, OpsFiles, WmsWarehouse, uiGridConstants, GridState, ExpressParcel, 
        ExpressPacking) {

        $scope.choosedCompanyId = $rootScope.account.companyId;
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;     
                $scope.choosedCompanyId = $rootScope.account.companyId;           
            });
        }
        $scope.isTms = false;
        $scope.isParcel = false;
        $scope.consolToFms = false;    
        $scope.mblShipmentType = "";    
        $scope.fmsConsol = false;


        var loadFmsBiz = false;
        var urlPath = $location.path();
        if (urlPath.indexOf("tms-combine") >= 0){
            $scope.isTms = true;
        }
        else if (urlPath.indexOf("parcel-combine") >= 0){
            $scope.mblShipmentType = "FBAAIR";
            $scope.consolToFms = true;
            $scope.isParcel = true;
        }
        else if (urlPath.indexOf("shipments-consol") >= 0){
            $scope.consolToFms = true;
            loadFmsBiz = true;
            $scope.fmsConsol = true;
        }

        $scope.shipmentTypeChange = function(type) {
            if(($rootScope.parcelTmsTypes.indexOf(type) == -1 || type == "EXPRESS" || type == "WMS") != $scope.consolToFms){
                $scope.consolToFms = $rootScope.parcelTmsTypes.indexOf(type) == -1;
                $scope.clearConsolData();
            }
        }

        $scope.searchType = null;

        $scope.setSearchType = function(type) {
            $scope.searchType = type;
        }

        $scope.searchStr = "";
        $scope.virtualWms = false;
        $scope.exWmsOut = true;
        $scope.searchGuide = function(value) {            
            loadFmsBiz = value;
            if(loadFmsBiz){
                if($rootScope.HAVENOAUTH('FMS') && $rootScope.HAVENOAUTH('FBA') && $rootScope.HAVENOAUTH('WMS')){
                    $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                    return;
                }
                searchGuides();                
            }
            else {
                if($rootScope.HAVENOAUTH('PARCEL') && $rootScope.HAVENOAUTH('TMS')){
                    $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                    return;
                }
                searchParcels();
            }
        };

        $scope.searchParcelPacking = function() {            
            ExpressPacking.searchByNums({
                numStr: $scope.searchStr,
                companyId: $scope.choosedCompanyId,
            }, function(result) {
                addAllToGuides(result);
                $scope.searchStr = null;
            }, function(response) {
            });  
        };

        function searchGuides() {
            if($scope.searchType){
                Guide.searchByNums({
                    jobType: "consol",
                    type: $scope.searchType,
                    isFms: $scope.consolToFms,
                    shipmentType: "all",
                    companyId: $scope.choosedCompanyId
                }, $scope.searchStr, function(result, headers) {
                    $rootScope.ALERT_RESULT_MSG(headers);
                    addAllToGuides(result);
                }, function(response) {
                });
                return;
            }
            if(!$scope.searchType && $scope.searchStr){
                $scope.searchStr = $scope.searchStr.replace(/\s+/g,"");
            }       
            if (!$scope.searchStr){
                return;
            }    
            Guide.searchGuide({
                isExport: $scope.isExportCombine,
                isFms: $scope.consolToFms,
                searchType: $scope.searchType,
                jobType: "consol",
                virtualWms: $scope.virtualWms,
                companyId: $scope.choosedCompanyId,
                inputStr: $scope.searchStr
            }, function(result) {
                addAllToGuides(result);
                $scope.searchStr = null;
            }, function(response) {
            });  
        }

        function searchParcels() {
            if($scope.searchType){
                ExpressParcel.searchByNums({
                    isFms: $scope.consolToFms,
                    type: $scope.searchType,
                    shipmentType: "ALL",
                    searchType: "consol",
                    companyId: $rootScope.account.companyId
                }, $scope.searchStr, function(result, headers) {
                    $rootScope.ALERT_RESULT_MSG(headers);
                    addAllToGuides(result);
                }, function(response) {
                });
                return;
            }
            $scope.searchStr = $scope.searchStr.replace(/\s+/g,"");
            if (!$scope.searchStr){
                return;
            }
            ExpressParcel.searchByNum({
                isFms: $scope.consolToFms,
                type: "consol",
                searchType: $scope.searchType,
                companyId: $scope.choosedCompanyId,
                inputStr: $scope.searchStr,
                isTms: $scope.isTms
            }, function(result) {
                addAllToGuides(result);
                $scope.searchStr = null;
            }, function(response) {
            });  
        }


        $scope.clearConsolData = function() {
            $scope.gridOptions_2.data= [];
            $scope.gridOptions_1.data= [];

        }
        $scope.virtualWmsChange = function() {
            $scope.virtualWms = !$scope.virtualWms;
            $scope.clearConsolData();
            $scope.choosedWarehouse = null;
            $scope.choosedCompanyId = $rootScope.account.companyId;
        }

        $scope.isExportCombine = true;
        $scope.setIsExportCombine = function(isExportCombine) {
            if($scope.isExportCombine != isExportCombine){
                $scope.isExportCombine = isExportCombine;
                $scope.clearConsolData();
            }            
        }

        $scope.searchParcelsPop = function(shipmentType) {    
            if($rootScope.HAVENOAUTH('TMS') && shipmentType == "TMS"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($rootScope.HAVENOAUTH('PARCEL') && shipmentType == "PARCEL"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                shipmentType: shipmentType,
                forConsol: true,
                // otherTimeThreeTAG: !$scope.exWmsOut,
                isFmsConsol: $scope.consolToFms,
                companyId: $scope.choosedCompanyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                addAllToGuides(result);
            }, function() {
            });
        }
        $scope.searchGuidePop = function() {            
            if($scope.virtualWms && $rootScope.account.companyId == $scope.choosedCompanyId){
                layer.msg($translate.instant('autopsApp.guide.warehouseNullAlert'));
                return;
            }
            if($rootScope.HAVENOAUTH('FMS') && $rootScope.HAVENOAUTH('FBA') && $rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }            
            var searchParams = {
                isExport: $scope.isExportCombine,
                forConsol: true,
                isFmsConsol: $scope.consolToFms,
                companyId: $scope.choosedCompanyId
            };
            if($scope.consolToFms){
                searchParams.otherTimeTwoTAG = !$scope.exWmsOut;
            }

            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                addAllToGuides(result);
            }, function() {
            });
        }
        function addAllToGuides(results){
            if (!results || results.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
            }
            else {
                angular.forEach(results, function(data){
                    if ($scope.virtualWms && data.medCompanyId != $rootScope.account.companyId){

                    }
                    else if ($scope.fmsConsol && $scope.exWmsOut && data.wmsOutTime){

                    }
                    else {
                        addToGuides(data);
                    }   
                })
            }
        }
        $scope.addAllToList = function() {
            var allvisiblerows = $scope.gridApi_1.core.getVisibleRows($scope.gridApi_1.grid);
            if(!allvisiblerows || allvisiblerows.length == 0){
                return;
            }
            
            for(var i = allvisiblerows.length -1; i>=0; i--){
                $scope.addToList(allvisiblerows[i].entity);
            }
        }

        $scope.addToList = function(guide) {
            if(guide.isClosed){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if(guide.wmsOutTime && !$scope.isTms && $scope.exWmsOut){
                layer.msg($translate.instant('autopsApp.guide.sts.wmsOut'));        
                return;                   
            }
            if(guide.isVoid){
                layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                return;
            }
            if(guide.masterId != null){
                layer.msg($translate.instant('autopsApp.guide.home.combineConflict'));
                return;
            }
            var isRepeated = false;
            for(var j = $scope.gridOptions_2.data.length -1; j >=0; j--){
                if (guide.parcelId && guide.parcelId == $scope.gridOptions_2.data[j].parcelId){
                    isRepeated = true;
                    break;
                }
                if (guide.guideId && guide.guideId == $scope.gridOptions_2.data[j].guideId){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                DataUtils.fillFdLabel(guide);
                $scope.gridOptions_2.data.push(guide);
            }          
            var index = $scope.gridOptions_1.data.indexOf(guide);
            if (index > -1) {
                $scope.gridOptions_1.data.splice(index, 1);
            };
        }
        $scope.delAllFromList = function() {
            if(!$scope.gridOptions_2.data){
                return;
            }
            for(var i = $scope.gridOptions_2.data.length -1; i>=0; i--){
                $scope.delFromList($scope.gridOptions_2.data[i]);
            }
        }
        $scope.delFromList = function(guide) {
            var index = $scope.gridOptions_2.data.indexOf(guide);
            if (index > -1) {
                $scope.gridOptions_2.data.splice(index, 1);
            };

            var isRepeated = false;
            for(var j = $scope.gridOptions_1.data.length -1; j >=0; j--){
                if (guide.parcelId && guide.parcelId == $scope.gridOptions_1.data[j].parcelId){
                    isRepeated = true;
                    break;
                }
                if (guide.guideId && guide.guideId == $scope.gridOptions_1.data[j].guideId){
                    isRepeated = true;
                    break;
                }
            }
            if (!isRepeated){
                DataUtils.fillFdLabel(guide);
                $scope.gridOptions_1.data.push(guide);
            }
        }
        $scope.delFromGuides = function(guide) {
            var index = $scope.gridOptions_1.data.indexOf(guide);
            if (index > -1) {
                $scope.gridOptions_1.data.splice(index, 1);
            };
        }
        
        var addToGuides = function(guide) {
            var isRepeated = false;
            for(var j = $scope.gridOptions_1.data.length -1; j >=0; j--){
                if (guide.parcelId && guide.parcelId == $scope.gridOptions_1.data[j].parcelId){
                    isRepeated = true;
                    break;
                }
                if (guide.guideId && guide.guideId == $scope.gridOptions_1.data[j].guideId){
                    isRepeated = true;
                    break;
                }
                if (guide.packingId && guide.packingId == $scope.gridOptions_1.data[j].packingId){
                    isRepeated = true;
                    break;
                }
            }
            if (!isRepeated){
                DataUtils.fillFdLabel(guide);
                $scope.gridOptions_1.data.push(guide);
            }       
        }
        var onCombineSuccess = function(result) {
            $scope.isSaving = false;       
            $scope.gridOptions_2.data = [];
            if(!$scope.fmsConsol && $rootScope.parcelTmsTypes.indexOf(result.shipmentType) !== -1){
                $rootScope.VIEWEXPRESS(result.id);
            }
            else {
                $rootScope.BIZPOP(result.id);
            } 
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onCombineError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409){
                layer.msg($translate.instant('autopsApp.guide.home.notAuditedAlert'));
            }
            else if (result.status == 403){
                layer.msg($translate.instant('autopsApp.guide.home.combineConflict'));
            }
            else if (result.status == 406) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.overdue'));
            } 
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }                      
        };

        
        $scope.combineMBL = function() {
            if ($scope.gridOptions_2.data.length == 0) {                
                return;
            }
            layer.confirm($translate.instant('autopsApp.guide.home.combineConfirm'), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                $scope.isSaving = true;
                ExpressParcel.combine({
                    shipmentType: $scope.mblShipmentType
                }, $scope.gridOptions_2.data, onCombineSuccess, onCombineError);
                layer.close(index);
            }, function() {
            });
        }

        $scope.excelExport = function(guides) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = $translate.instant('autopsApp.guide.packingList') + ".xlsx";           
            OpsFiles.exportConsolPackingList({
                enCn: $translate.instant('global.enCn')
            }, guides, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }  


        $scope.clearList = function() {
            $scope.gridOptions_1.data = [];
        }


        $scope.wmswarehouses = null;
        $scope.getWarehouses = function() {
            if ($scope.wmswarehouses){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: false,
                    includeVirtual: true,
                    includeGroup: false
                }, function (data) {
                $scope.wmswarehouses = data;
            });
        }

        $scope.choosedWarehouse = null;
        $scope.warehouseSelected = function(warehouse) {
            $scope.choosedWarehouse = warehouse;
            $scope.choosedCompanyId = warehouse.companyId;
        }

        $scope.gridOptions_1 = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'unchoosed.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 80,
            columnDefs: [
                { field: 'id', 
                    width: 50,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<button type="button"ng-click="grid.appScope.addToList(row.entity)"class="btn btn-danger btn-grid ml-5"><span class="glyphicon glyphicon-plus"></span></button>'},
                { field: 'jobNum', 
                    displayName: 'Job#',
                    width: 150,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<a href=""ng-click="grid.appScope.BIZPOP(row.entity.guideId)"class="ml-5"ng-if="row.entity.guideId">{{row.entity.jobNum}}</a><a href=""ng-click="grid.appScope.VIEWEXPRESS(row.entity.parcelId)"class="ml-5"ng-if="row.entity.parcelId">{{row.entity.jobNum}}</a><span class="ml-5"ng-if="row.entity.packingId">{{row.entity.jobNum}}</span><a href=""class="ml-5"ng-click="grid.appScope.delFromGuides(row.entity)"><span class="glyphicon glyphicon-remove"></span></a><span class="glyphicon glyphicon-ok-circle ml-5 text-info"ng-if="row.entity.checkStatus == \'approved\'"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.guide.sts.approved\' | translate}}"></span><span class="glyphicon glyphicon-lock ml-5 text-danger"ng-if="row.entity.isClosed"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.guide.sts.isClosed\' | translate}}"></span>'},
                { field: 'checkStatus', 
                    displayName: $translate.instant('autopsApp.guide.checkStatus'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CHECK_STATUS
                    },
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.checkStatus"><span class="label label-info" ng-if="row.entity.checkStatus == \'notChecked\'" translate="autopsApp.guide.sts.notChecked">status</span><span class="label label-success" ng-if="row.entity.checkStatus == \'approved\'" translate="autopsApp.guide.sts.approved">status</span><span class="label label-danger" ng-if="row.entity.checkStatus == \'unapproved\'" translate="autopsApp.guide.sts.unapproved">status</span></span>'},
                { field: 'onHold', 
                    displayName: $translate.instant('autopsApp.guide.onHold'), 
                    width: 50,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5" ng-click="grid.appScope.consoleOnHoldShipmentPop(row.entity)"><span ng-if="row.entity.onHold"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.onHold"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'soNum', 
                    displayName: 'SO#',
                    visible: false},
                { field: 'poNum', 
                    displayName: 'PO#',
                    visible: false},
                { field: 'mblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.mblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="MBL released: {{row.entity.mblReleasedTime | date:\'medium\'}}"ng-if="row.entity.mblReleasedTime"></span></span>',
                    displayName: 'MBL/Mawb#'},
                { field: 'hblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.hblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="HBL released: {{row.entity.hblReleasedTime | date:\'medium\'}}"ng-if="row.entity.hblReleasedTime"></span></span>',
                    displayName: 'HBL/Hawb#'},
                { field: 'refNum', 
                    displayName: 'EDI Ref#',
                    visible: false},
                { field: 'thirdpartyPlatformRef', 
                    displayName: $translate.instant('autopsApp.guide.thirdpartyPlatformRef'),
                    visible: false},
                { field: 'gw', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW', 
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'vol', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL', 
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNum', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKGS', 
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pallets', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'Pallets', 
                    aggregationType: uiGridConstants.aggregationTypes.sum}, 
                { field: 'shipmentType', 
                    displayName: 'TYPE',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_TYPES
                    },  
                    cellTemplate: '<span ng-if="row.entity.shipmentType" class="label label-info ml-5" translate="global.{{row.entity.shipmentType}}"></span>'},
                { field: 'deliveryTo', 
                    displayName: $translate.instant('autopsApp.guide.deliveryTo')},
                { field: 'shiptoCode', 
                    displayName: $translate.instant('autopsApp.guide.shipToParty')},                    
                { field: 'destName', 
                    displayName: $translate.instant('autopsApp.guide.destName')},
                { field: 'shiptoCountryCode', 
                    displayName: $translate.instant('autopsApp.guide.destCountryCode')}, 
                { field: 'shiptoZipCode', 
                    displayName: $translate.instant('autopsApp.guide.destPostCode')}, 
                { field: 'polName', 
                    displayName: $translate.instant('autopsApp.guide.polName')}, 
                { field: 'warehouseName', 
                    displayName: $translate.instant('autopsApp.guide.warehouse')}, 
                { field: 'cargoType', 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CARGOTYPES
                    },  
                    displayName: $translate.instant('autopsApp.guide.cargoType'),
                    cellTemplate: '<span class="label label-info ml-5">{{row.entity.cargoTypeLabel}}</span>'},
                { field: 'createdTime', 
                    displayName: $translate.instant('autopsApp.guide.home.createdTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.createdTime | date:\'medium\'}}" class="ml-5">{{row.entity.createdTime | date:\'mediumDate\'}}</span>'},
                { field: 'cargoReadyDate', 
                    displayName: $translate.instant('autopsApp.guide.cargoReadyDate')},
                { field: 'wmsInTime', 
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}" class="ml-5">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>'},
                { field: 'etd', 
                    displayName: 'ETD'},
                { field: 'customerName', 
                    displayName: $translate.instant('autopsApp.guide.bookingPartyName')},
                { field: 'shipperName', 
                    displayName: $translate.instant('autopsApp.guide.shipperName')},
                { field: 'productName', 
                    displayName: $translate.instant('autopsApp.guide.productName')},
                { field: 'deliveryChannel', 
                    displayName: $translate.instant('autopsApp.guide.deliveryChannel')},
                { field: 'salesName', 
                    displayName: $translate.instant('autopsApp.guide.salesName')},
                { field: 'opName', 
                    displayName: $translate.instant('autopsApp.guide.opName')},
                { field: 'docName', 
                    displayName: $translate.instant('autopsApp.guide.docName')},
                { field: 'cusName', 
                    displayName: $translate.instant('autopsApp.guide.cusName')},
                { field: 'vsl', 
                    displayName: 'Vessel'},
                { field: 'voy', 
                    displayName: 'Voy/Flight'},
                { field: 'porName', 
                    displayName: $translate.instant('autopsApp.guide.porName')},
                { field: 'memo', 
                    displayName: $translate.instant('autopsApp.guide.table.memo')},
                { field: 'cargoName', 
                    displayName: $translate.instant('autopsApp.guide.cargoName')},
                { field: 'innerRemark', 
                    displayName: $translate.instant('autopsApp.guide.remarks')},
                { field: 'bookingRemark', 
                    displayName: $translate.instant('autopsApp.booking.remarks')},
                { field: 'ownImporter', 
                    displayName: $translate.instant('autopsApp.guide.ownImporter'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.ownImporter"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.ownImporter"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'plConfirmed', 
                    displayName: $translate.instant('autopsApp.booking.plConfirmed'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.plConfirmed"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.plConfirmed"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'customsType', 
                    displayName: $translate.instant('autopsApp.guide.customsType')},
                { field: 'bizType',
                    displayName: $translate.instant('autopsApp.guide.bizType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.BIZTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.bizTypeLabel}}</span>'},
                { field: 'internalType',
                    displayName: $translate.instant('autopsApp.guide.bizInternalType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.INTERNALTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.internalTypeLabel}}</span>'},
                { field: 'deliveryWay', 
                    displayName: $translate.instant('autopsApp.booking.deliveryWay'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.DELIVERYTYPES
                    },       
                    minWidth:100,
                    cellTemplate: '<span class="ml-5" translate="autopsApp.booking.deliveryType.{{row.entity.deliveryWay}}" ng-if="row.entity.deliveryWay" class="label-info label"></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_1.gridState = angular.toJson($scope.gridApi_1.saveState.save());
                    gridStateObj_1.userId = $rootScope.account.id;
                    gridStateObj_1.gridId = 96;
                    GridState.update(gridStateObj_1, function(result){
                        gridStateObj_1 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_1.gridState){
                        $scope.gridApi_1.saveState.restore($scope, angular.fromJson(gridStateObj_1.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi_1.saveState.restore($scope, gridStateCopy_1);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi_1 = gridApi;
            }
        }

        $scope.gridOptions_2 = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'choosed.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 80,
            columnDefs: [
                { field: 'id', 
                    width: 50,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<button type="button"ng-click="grid.appScope.delFromList(row.entity)"class="btn btn-danger btn-grid ml-5"><span class="glyphicon glyphicon-remove"></span></button>'},
                { field: 'jobNum', 
                    displayName: 'Job#',
                    width: 150,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<a href=""ng-click="grid.appScope.BIZPOP(row.entity.guideId)"class="ml-5"ng-if="row.entity.guideId">{{row.entity.jobNum}}</a><a href=""ng-click="grid.appScope.VIEWEXPRESS(row.entity.parcelId)"class="ml-5"ng-if="row.entity.parcelId">{{row.entity.jobNum}}</a><span class="ml-5"ng-if="row.entity.packingId">{{row.entity.jobNum}}</span><span class="glyphicon glyphicon-ok-circle ml-5 text-info"ng-if="row.entity.checkStatus == \'approved\'"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.guide.sts.approved\' | translate}}"></span><span class="glyphicon glyphicon-lock ml-5 text-danger"ng-if="row.entity.isClosed"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.guide.sts.isClosed\' | translate}}"></span>'},
                { field: 'checkStatus', 
                    displayName: $translate.instant('autopsApp.guide.checkStatus'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CHECK_STATUS
                    },
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.checkStatus"><span class="label label-info" ng-if="row.entity.checkStatus == \'notChecked\'" translate="autopsApp.guide.sts.notChecked">status</span><span class="label label-success" ng-if="row.entity.checkStatus == \'approved\'" translate="autopsApp.guide.sts.approved">status</span><span class="label label-danger" ng-if="row.entity.checkStatus == \'unapproved\'" translate="autopsApp.guide.sts.unapproved">status</span></span>'},
                { field: 'onHold', 
                    displayName: $translate.instant('autopsApp.guide.onHold'), 
                    width: 50,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5" ng-click="grid.appScope.consoleOnHoldShipmentPop(row.entity)"><span ng-if="row.entity.onHold"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.onHold"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'soNum', 
                    displayName: 'SO#',
                    visible: false},
                { field: 'poNum', 
                    displayName: 'PO#',
                    visible: false},
                { field: 'mblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.mblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="MBL released: {{row.entity.mblReleasedTime | date:\'medium\'}}"ng-if="row.entity.mblReleasedTime"></span></span>',
                    displayName: 'MBL/Mawb#'},
                { field: 'hblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.hblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="HBL released: {{row.entity.hblReleasedTime | date:\'medium\'}}"ng-if="row.entity.hblReleasedTime"></span></span>',
                    displayName: 'HBL/Hawb#'},
                { field: 'refNum', 
                    displayName: 'EDI Ref#',
                    visible: false},
                { field: 'thirdpartyPlatformRef', 
                    displayName: $translate.instant('autopsApp.guide.thirdpartyPlatformRef'),
                    visible: false},
                { field: 'gw', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW', 
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'vol', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL', 
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNum', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKGS', 
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pallets', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'Pallets', 
                    aggregationType: uiGridConstants.aggregationTypes.sum}, 
                { field: 'shipmentType', 
                    displayName: 'TYPE',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_TYPES
                    },  
                    cellTemplate: '<span ng-if="row.entity.shipmentType" class="label label-info ml-5" translate="global.{{row.entity.shipmentType}}"></span>'},
                { field: 'deliveryTo', 
                    displayName: $translate.instant('autopsApp.guide.deliveryTo')},
                { field: 'shiptoCode', 
                    displayName: $translate.instant('autopsApp.guide.shipToParty')},                    
                { field: 'destName', 
                    displayName: $translate.instant('autopsApp.guide.destName')},
                { field: 'shiptoCountryCode', 
                    displayName: $translate.instant('autopsApp.guide.destCountryCode')}, 
                { field: 'shiptoZipCode', 
                    displayName: $translate.instant('autopsApp.guide.destPostCode')}, 
                { field: 'polName', 
                    displayName: $translate.instant('autopsApp.guide.polName')}, 
                { field: 'warehouseName', 
                    displayName: $translate.instant('autopsApp.guide.warehouse')}, 
                { field: 'cargoType', 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CARGOTYPES
                    },  
                    displayName: $translate.instant('autopsApp.guide.cargoType'),
                    cellTemplate: '<span class="label label-info ml-5">{{row.entity.cargoTypeLabel}}</span>'},
                { field: 'createdTime', 
                    displayName: $translate.instant('autopsApp.guide.home.createdTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.createdTime | date:\'medium\'}}" class="ml-5">{{row.entity.createdTime | date:\'mediumDate\'}}</span>'},
                { field: 'cargoReadyDate', 
                    displayName: $translate.instant('autopsApp.guide.cargoReadyDate')},
                { field: 'wmsInTime', 
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}" class="ml-5">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>'},
                { field: 'etd', 
                    displayName: 'ETD'},
                { field: 'customerName', 
                    displayName: $translate.instant('autopsApp.guide.bookingPartyName')},
                { field: 'shipperName', 
                    displayName: $translate.instant('autopsApp.guide.shipperName')},
                { field: 'productName', 
                    displayName: $translate.instant('autopsApp.guide.productName')},
                { field: 'deliveryChannel', 
                    displayName: $translate.instant('autopsApp.guide.deliveryChannel')},
                { field: 'salesName', 
                    displayName: $translate.instant('autopsApp.guide.salesName')},
                { field: 'opName', 
                    displayName: $translate.instant('autopsApp.guide.opName')},
                { field: 'docName', 
                    displayName: $translate.instant('autopsApp.guide.docName')},
                { field: 'cusName', 
                    displayName: $translate.instant('autopsApp.guide.cusName')},
                { field: 'vsl', 
                    displayName: 'Vessel'},
                { field: 'voy', 
                    displayName: 'Voy/Flight'},
                { field: 'porName', 
                    displayName: $translate.instant('autopsApp.guide.porName')},
                { field: 'memo', 
                    displayName: $translate.instant('autopsApp.guide.table.memo')},
                { field: 'cargoName', 
                    displayName: $translate.instant('autopsApp.guide.cargoName')},
                { field: 'innerRemark', 
                    displayName: $translate.instant('autopsApp.guide.remarks')},
                { field: 'bookingRemark', 
                    displayName: $translate.instant('autopsApp.booking.remarks')},
                { field: 'ownImporter', 
                    displayName: $translate.instant('autopsApp.guide.ownImporter'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.ownImporter"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.ownImporter"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'plConfirmed', 
                    displayName: $translate.instant('autopsApp.booking.plConfirmed'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.plConfirmed"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.plConfirmed"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'customsType', 
                    displayName: $translate.instant('autopsApp.guide.customsType')},
                { field: 'bizType',
                    displayName: $translate.instant('autopsApp.guide.bizType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.BIZTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.bizTypeLabel}}</span>'},
                { field: 'internalType',
                    displayName: $translate.instant('autopsApp.guide.bizInternalType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.INTERNALTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.internalTypeLabel}}</span>'},
                { field: 'deliveryWay', 
                    displayName: $translate.instant('autopsApp.booking.deliveryWay'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.DELIVERYTYPES
                    },       
                    minWidth:100,
                    cellTemplate: '<span class="ml-5" translate="autopsApp.booking.deliveryType.{{row.entity.deliveryWay}}" ng-if="row.entity.deliveryWay" class="label-info label"></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_2.gridState = angular.toJson($scope.gridApi_2.saveState.save());
                    gridStateObj_2.userId = $rootScope.account.id;
                    gridStateObj_2.gridId = 97;
                    GridState.update(gridStateObj_2, function(result){
                        gridStateObj_2 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_2.gridState){
                        $scope.gridApi_2.saveState.restore($scope, angular.fromJson(gridStateObj_2.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi_2.saveState.restore($scope, gridStateCopy_2);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi_2 = gridApi;
            }
        }

        var gridStateObj_1 = {};
        var gridStateCopy_1 = null;
        var gridStateObj_2 = {};
        var gridStateCopy_2 = null;

        $timeout(function (){
            gridStateCopy_1 = $scope.gridApi_1.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 96
            }, function(result){
                gridStateObj_1 = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions_1);
                if(!gridStateObj_1 || !gridStateObj_1.id){
                    $scope.gridApi_1.saveState.restore($scope, gridStateCopy_1);
                    return;
                }   
                $scope.gridApi_1.saveState.restore($scope, angular.fromJson(gridStateObj_1.gridState));
            })

            gridStateCopy_2 = $scope.gridApi_2.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 97
            }, function(result){
                gridStateObj_2 = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions_2);
                if(!gridStateObj_2 || !gridStateObj_2.id){
                    $scope.gridApi_2.saveState.restore($scope, gridStateCopy_2);
                    return;
                }   
                $scope.gridApi_2.saveState.restore($scope, angular.fromJson(gridStateObj_2.gridState));
            })

        });
        $scope.exWmsOutChange = function(){
            $scope.exWmsOut = !$scope.exWmsOut;
        }

    }

    
})();