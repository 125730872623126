(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EdiInsuranceFbaController', EdiInsuranceFbaController);

    EdiInsuranceFbaController.$inject = ['$scope', '$state', '$timeout', '$translate', '$rootScope', '$uibModal',
        'EdiInsurance', 'EdiInsuranceSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WinAES', 'uiGridConstants',
        'GridState'];

    function EdiInsuranceFbaController($scope, $state, $timeout, $translate, $rootScope, $uibModal,
        EdiInsurance, EdiInsuranceSearch, ParseLinks, AlertService, pagingParams, paginationConstants, WinAES, uiGridConstants,
        GridState) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.page = 1;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAdvanceSearch = false;
        vm.searchType = "self";
        if ($rootScope.HAVEAUTH('INSUR')) {
            vm.insuranceType = "FBA";
        }else if ($rootScope.HAVEAUTH('WYB')) {
            vm.insuranceType = "WYB";
        }else {
            vm.insuranceType = "";
        }
        vm.changeType = changeType;
        var gridCopy;
        var pageLoaded = true;
        var filterTimer;
        loadAll();
        $scope.viewAllChange = function() {
            if ($rootScope.HAVENOAUTH('AU_VIEW_ALL_INSUR')) {
                vm.viewAll = !vm.viewAll;
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_ALL_INSUR');
                return;
            }
            if(vm.viewAll){
                vm.searchType = "all";
            }
            else {
                vm.searchType = "self";
            }
            loadAll();
        }

        var statusList = [{
            value: '0',
            label: $translate.instant('autopsApp.ediInsurance.sts.0')
        },
        {
            value: '1',
            label: $translate.instant('autopsApp.ediInsurance.sts.1')
        },
        {
            value: '2',
            label: $translate.instant('autopsApp.ediInsurance.sts.2')
        },
        {
            value: '3',
            label: $translate.instant('autopsApp.ediInsurance.sts.3')
        },
        {
            value: '4',
            label: $translate.instant('autopsApp.ediInsurance.sts.4')
        },
        {
            value: '5',
            label: $translate.instant('autopsApp.ediInsurance.sts.5')
        },
        {
            value: '6',
            label: $translate.instant('autopsApp.ediInsurance.sts.6')
        },
        {
            value: '7',
            label: $translate.instant('autopsApp.ediInsurance.sts.7')
        },
        {
            value: '8',
            label: $translate.instant('autopsApp.ediInsurance.sts.8')
        },
        {
            value: '9',
            label: $translate.instant('autopsApp.ediInsurance.sts.9')
        }]

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function () {
            if ($rootScope.HAVEAUTH('INSUR')) {
                vm.insuranceType = "FBA";
            }
            else if ($rootScope.HAVEAUTH('WYB')) {
                vm.insuranceType = "WYB";
            }
            else {
                vm.insuranceType = "";
            }
            getLinghangDataMap();
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 121
            }, function (result) {
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if (!gridStateObj || !gridStateObj.id) {
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if (gridCopy) {
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function (column) {
                        angular.forEach(column.filters, function (cf) {
                            if (cf.term) {
                                filterRequired = true;
                            }
                        })
                    })
                    if (filterRequired) {
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });
        $scope.linghangDataMap = { comCodeList: [], transportIdList: [], applicantCertificateTypeList: [], goodsTypeList: [], bigPackList: [], smallPackList: [], jzxList: [], currencyList: [], startPortSmallList: [] };
        function getLinghangDataMap() {
            $scope.linghangDataMap.comCodeList.length = 0;
            $scope.linghangDataMap.transportIdList.length = 0;
            $scope.linghangDataMap.applicantCertificateTypeList.length = 0;
            $scope.linghangDataMap.goodsTypeList.length = 0;
            $scope.linghangDataMap.bigPackList.length = 0;
            $scope.linghangDataMap.smallPackList.length = 0;
            $scope.linghangDataMap.jzxList.length = 0;
            $scope.linghangDataMap.currencyList.length = 0;
            $scope.linghangDataMap.startPortSmallList.length = 0;
            if (vm.insuranceType == "WYB") {
                EdiInsurance.getWybDataMap(function (result) {
                    $scope.linghangDataMap['comCode' + 'Obj'] = {};
                    angular.forEach(result.comCode, function (data) {
                        $scope.linghangDataMap['comCode' + 'Obj'][data.code] = data.name;
                        $scope.linghangDataMap.comCodeList.push({ value: data.code, label: data.name });
                    })
                    $scope.linghangDataMap['transportId' + 'Obj'] = {};
                    angular.forEach(result.transportId, function (data) {
                        $scope.linghangDataMap['transportId' + 'Obj'][data.code] = data.name;
                        $scope.linghangDataMap.transportIdList.push({ value: data.code, label: data.name });
                    })
                    $scope.linghangDataMap['applicantCertificateType' + 'Obj'] = {};
                    angular.forEach(result.applicantCertificateType, function (data) {
                        $scope.linghangDataMap['applicantCertificateType' + 'Obj'][data.code] = data.name;
                        $scope.linghangDataMap.applicantCertificateTypeList.push({ value: data.code, label: data.name });
                    })
                    $scope.linghangDataMap['goodsType' + 'Obj'] = {};
                    angular.forEach(result.goodsType, function (data) {
                        $scope.linghangDataMap['goodsType' + 'Obj'][data.code] = data.name;
                        $scope.linghangDataMap.goodsTypeList.push({ value: data.code, label: data.name });
                    })
                    $scope.linghangDataMap['pack' + 'Obj'] = {};
                    angular.forEach(result.pack, function (data) {
                        $scope.linghangDataMap['pack' + 'Obj'][data.code] = data.name;
                        $scope.linghangDataMap.bigPackList.push({ value: data.code, label: data.name });
                    })
                    $scope.linghangDataMap['jzx' + 'Obj'] = {};
                    angular.forEach(result.jzx, function (data) {
                        $scope.linghangDataMap['jzx' + 'Obj'][data.code] = data.name;
                        $scope.linghangDataMap.jzxList.push({ value: data.code, label: data.name });
                    })
                    $scope.linghangDataMap['currency' + 'Obj'] = {};
                    angular.forEach(result.currency, function (data) {
                        $scope.linghangDataMap['currency' + 'Obj'][data.code] = data.enName;
                        $scope.linghangDataMap.currencyList.push({ value: data.code, label: data.enName });
                    })
                    $scope.linghangDataMap['startPortSmall' + 'Obj'] = {};
                    angular.forEach(result.startPortSmall, function (data) {
                        $scope.linghangDataMap['startPortSmall' + 'Obj'][data.code] = data.name;
                        $scope.linghangDataMap.startPortSmallList.push({ value: data.code, label: data.name });
                    })
                    $translate.refresh();
                });
                return;
            }
            EdiInsurance.getLinghangDataMap(function (result) {
                // 依照原先在代码，fba只有这些保险公司、运输方式，故加上此逻辑
                result.comCode = [{ code: "2", name: "太平洋" }];
                result.transportId = [
                    { code: "2", name: "海运" },
                    { code: "4", name: "铁路" },
                    { code: "6", name: "空运" },
                    { code: "17", name: "公路" },
                    { code: "26", name: "快递" }
                ];
                result.jzx = [
                    { code: "1", name: "FBA" },
                    { code: "2", name: "菜鸟仓" },
                    { code: "3", name: "海外仓" }
                ]
                result.startPortSmall = [
                    { code: "1", name: "保到仓" },
                    { code: "3", name: "保上架" }
                ]

                $scope.linghangDataMap['comCode' + 'Obj'] = {};
                angular.forEach(result.comCode, function (data) {
                    $scope.linghangDataMap['comCode' + 'Obj'][data.code] = data.name;
                    $scope.linghangDataMap.comCodeList.push({ value: data.code, label: data.name });
                })
                $scope.linghangDataMap['transportId' + 'Obj'] = {};
                angular.forEach(result.transportId, function (data) {
                    $scope.linghangDataMap['transportId' + 'Obj'][data.code] = data.name;
                    $scope.linghangDataMap.transportIdList.push({ value: data.code, label: data.name });
                })
                $scope.linghangDataMap['applicantCertificateType' + 'Obj'] = {};
                angular.forEach(result.applicantCertificateType, function (data) {
                    $scope.linghangDataMap['applicantCertificateType' + 'Obj'][data.code] = data.name;
                    $scope.linghangDataMap.applicantCertificateTypeList.push({ value: data.code, label: data.name });
                })
                $scope.linghangDataMap['goodsType' + 'Obj'] = {};
                angular.forEach(result.goodsType, function (data) {
                    $scope.linghangDataMap['goodsType' + 'Obj'][data.code] = data.name;
                    $scope.linghangDataMap.goodsTypeList.push({ value: data.code, label: data.name });
                })
                $scope.linghangDataMap['pack' + 'Obj'] = {};
                angular.forEach(result.pack, function (data) {
                    $scope.linghangDataMap['pack' + 'Obj'][data.smCode] = data.name;
                    if (data.bgCode == 0) {
                        $scope.linghangDataMap.bigPackList.push({ value: data.smCode, label: data.name });
                    } else {
                        $scope.linghangDataMap.smallPackList.push({ value: data.smCode, label: data.name });
                    }

                })
                $scope.linghangDataMap['jzx' + 'Obj'] = {};
                angular.forEach(result.jzx, function (data) {
                    $scope.linghangDataMap['jzx' + 'Obj'][data.code] = data.name;
                    $scope.linghangDataMap.jzxList.push({ value: data.code, label: data.name });
                })
                $scope.linghangDataMap['currency' + 'Obj'] = {};
                angular.forEach(result.currency, function (data) {
                    $scope.linghangDataMap['currency' + 'Obj'][data.code] = data.enName;
                    $scope.linghangDataMap.currencyList.push({ value: data.code, label: data.enName });
                })
                $scope.linghangDataMap['startPortSmall' + 'Obj'] = {};
                angular.forEach(result.startPortSmall, function (data) {
                    $scope.linghangDataMap['startPortSmall' + 'Obj'][data.code] = data.name;
                    $scope.linghangDataMap.startPortSmallList.push({ value: data.code, label: data.name });
                })
                $translate.refresh();
            });
        }

        function loadAll() {
            if (vm.searchQuery) {
                EdiInsuranceSearch.query({
                    searchType: vm.searchType,
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    insuranceType: vm.insuranceType
                }, onSuccess, onError);
            } else {
                var filter = {};
                if (gridCopy) {
                    angular.forEach(gridCopy.columns, function (column) {
                        angular.forEach(column.filters, function (cf) {
                            if (cf.term != null && angular.isDefined(cf.term)) {
                                if (cf.condition) {
                                    var fd = column.field;
                                    if (cf.condition == 128 || cf.condition == 256) {
                                        filter[fd + "TO"] = cf.term;
                                    } else if (cf.condition == 32 || cf.condition == 64) {
                                        filter[fd + "FM"] = cf.term;
                                    }
                                } else if (column.field == 'terminiPortSmall') {
                                    // 由于后端接口此字段不接受布尔值，而是接受int，故做此转换
                                    if (cf.term) {
                                        filter[column.field] = 1;
                                    } else if (!cf.term) {
                                        filter[column.field] = 0;
                                    }
                                } else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                EdiInsurance.getFilterPage({
                    searchType: vm.searchType,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    insuranceType: vm.insuranceType
                }, filter, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + vm.direction];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                $scope.gridOptions.columnDefs.splice(0, $scope.gridOptions.columnDefs.length);
                if (vm.insuranceType == 'WYB') {
                    angular.forEach(wybColumnDefs, function(data) {
                        $scope.gridOptions.columnDefs.push(data);
                    })
                }else {
                    angular.forEach(fbaColumnDefs, function(data) {
                        $scope.gridOptions.columnDefs.push(data);
                    })
                }
                if (headers('X-Total-Count')) {
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                } else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                angular.forEach($scope.gridOptions.data, function (data) {
                    data.encodeId = WinAES.encode(data.id.toString());
                    data.planInfoList = angular.fromJson(data.planInfoList);
                })
                vm.isAdvanceSearch = false;
                initData();
                resize();
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        window.onresize = function () {
            resize();
        }

        function resize() {
            if (!$("#gridId") || !$("#gridId").offset()) {
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if (tabHeight > winHeight) {
                tabHeight = winHeight;
            }
            if (tabHeight < 600) {
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        function initData() {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = vm.isAdvanceSearch;
            // DataUtils.addVenderTag($scope.gridOptions.data);
        }

        $scope.gridOptions = {
            enableSorting: true, //排序
            useExternalSorting: true,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            exporterExcelFilename: "Insurance Fba",
            columnDefs: [],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function ($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 121;
                    GridState.update(gridStateObj, function (result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function (error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function ($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function ($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "ediInsurance";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "ediInsurance";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                    if (!$scope.gridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function (a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function () {
                    gridCopy = this.grid;
                    if (pageLoaded && $scope.gridOptions.useExternalFiltering) {
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function () {
                            loadAll();
                        }, 1000); //查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if (pageLoaded) {
                        loadAll();
                    }
                });
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + vm.direction,
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction = 'desc';
            vm.currentSearch = null;
            vm.transition();
        }

        var onUpdateSuccess = function (result) {
            angular.forEach(vm.ediInsurances, function (data) {
                if (data.id == result.id) {
                    angular.copy(result, data);
                    data.encodeId = WinAES.encode(data.id.toString());
                    data.planInfoList = angular.fromJson(data.planInfoList);
                }
            })
        }
        $scope.apiOrderinfo = function (ediInsurance) {
            EdiInsurance.apiOrderinfo(ediInsurance, function (result) {
                onUpdateSuccess(result);
                layer.msg($translate.instant('global.update_success'));
            }, $rootScope.ALERT_ERROR)
        }
        $scope.apiChedan = function (ediInsurance) {
            EdiInsurance.apiChedan(ediInsurance, function (result) {
                onUpdateSuccess(result);
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR)
        }
        $scope.edit = function (ediInsurance) {
            $uibModal.open({
                templateUrl: 'app/entities/edi-insurance/edi-insurance-dialog.html',
                controller: 'EdiInsuranceDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xxl',
                resolve: {
                    entity: ['EdiInsurance', 'WinAES', function (EdiInsurance, WinAES) {
                        return EdiInsurance.get({ id: WinAES.decode(ediInsurance.encodeId) }).$promise;
                    }]
                }
            }).result.then(function () {
                // $state.go('edi-insurance-fba', null, { reload: 'edi-insurance-fba' });
                loadAll();
            }, function () { });
        }

        $scope.delete = function (ediInsurance) {
            $uibModal.open({
                templateUrl: 'app/entities/edi-insurance/edi-insurance-delete-dialog.html',
                controller: 'EdiInsuranceDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['EdiInsurance', 'WinAES', function (EdiInsurance, WinAES) {
                        return EdiInsurance.get({ id: WinAES.decode(ediInsurance.encodeId) }).$promise;
                    }]
                }
            }).result.then(function () {
                // $state.go('edi-insurance-fba', null, { reload: 'edi-insurance-fba' });
                loadAll();
            }, function () { });
        }

        $scope.new = function () {
            $uibModal.open({
                templateUrl: 'app/entities/edi-insurance/edi-insurance-dialog.html',
                controller: 'EdiInsuranceDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xxl',
                resolve: {
                    entity: function () {
                        return {
                            comCode: null,
                            transportId: null,
                            userId: null,
                            tradeNo: null,
                            applicantName: null,
                            applicantCertificateType: null,
                            applicantCertificateNo: null,
                            applicantLinkManName: null,
                            applicantMobileTelephone: null,
                            insurantName: null,
                            goodsType: null,
                            bigPack: null,
                            smallPack: null,
                            goodsDesc: null,
                            goodsNum: null,
                            waybillNumber: null,
                            invoiceNumber: null,
                            jzx: null,
                            chuanName: null,
                            transportInfo: null,
                            startPortBig: null,
                            startPortSmall: null,
                            startPortName: null,
                            terminiPortBig: null,
                            terminiPortSmall: null,
                            terminiPortName: null,
                            startDate: null,
                            printFormat: null,
                            oppoPortName: null,
                            serveyCode: null,
                            payPlace: null,
                            jcl: null,
                            invoiceAmount: null,
                            currencyCode: null,
                            hasCredit: null,
                            planInfoList: null,
                            guideId: null,
                            companyId: null,
                            createTime: null,
                            submitter: null,
                            submitterName: null,
                            insuranceCost: null,
                            status: null,
                            id: null,
                            insuranceType: vm.insuranceType
                        };
                    }
                }
            }).result.then(function () {
                // $state.go('edi-insurance-fba', null, { reload: 'edi-insurance-fba' });
                loadAll();
            }, function () { });
        }
        $scope.batch_insurance = function () {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            var rows = angular.copy(selectedRows);
            if (rows.length == 0) {
                layer.msg($translate.instant("autopsApp.ediInsurance.notSelected"));
                return;
            }
            // 因为后端接口此参数为String，而前端传入的是个数组，故需要先转换才能调用接口。
            angular.forEach(rows, function (data) {
                data.planInfoList = "";
            })
            EdiInsurance.batchInsurance(rows, function () {
                layer.msg($translate.instant("autopsApp.ediInsurance.batchInsuranceSuccess"));
                loadAll();
            }, $rootScope.ALERT_ERROR);
        }

        $scope.batch_audit_insurance = function () {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            var rows = angular.copy(selectedRows);
            if (rows.length == 0) {
                layer.msg($translate.instant("autopsApp.ediInsurance.notSelected"));
                return;
            }
            // 因为后端接口此参数为String，而前端传入的是个数组，故需要先转换才能调用接口。
            angular.forEach(rows, function (data) {
                data.planInfoList = "";
            })
            EdiInsurance.batchAuditInsurance(rows, function () {
                layer.msg($translate.instant("autopsApp.ediInsurance.batchAuditInsuranceSuccess"));
                loadAll();
            }, $rootScope.ALERT_ERROR);
        }

        $scope.audit = function (data) {
            EdiInsurance.audit({ ediInsuranceId: data.id,isAudit: true}, '', function () {
                layer.msg($translate.instant("autopsApp.ediInsurance.auditSuccess"));
                loadAll();
            }, $rootScope.ALERT_ERROR);
        }

        var fbaColumnDefs = [
            {
                field: 'id',
                width: 150,
                displayName: "",
                pinnedLeft: true,
                enableColumnMenu: false,
                enableFiltering: false,
                aggregationType: uiGridConstants.aggregationTypes.count,
                cellTemplate: '<div class="btn-group flex-btn-group-container ml-5"><button class="btn btn-grid btn-primary"type=submit ng-click=grid.appScope.edit(row.entity)><span class="glyphicon glyphicon-edit"></span> <span class="hidden-sm hidden-xs"translate=entity.action.amendation>amendation</span></button><div class="btn-group flex-btn-group-container ml-5"dropdown-append-to-body uib-dropdown><button class="btn btn-grid btn-info"type=button ng-disabled=grid.appScope.isSaving uib-dropdown-toggle><span class="glyphicon glyphicon-option-horizontal"></span> <span translate=entity.action.others>others</span> <span class=caret></span></button><ul uib-dropdown-menu><li><a ng-click="grid.appScope.PDFPOP(row.entity.epolicyUrl, null)"href=""><span class="glyphicon glyphicon-print"></span> <span translate=autopsApp.ediInsurance.home.printInsurance>Print Insurance</span></a><li class=divider role=separator><li><a ng-click=grid.appScope.apiOrderinfo(row.entity) href=""><span class="glyphicon glyphicon-refresh"></span> <span translate=autopsApp.ediInsurance.home.update>Update</span></a><li class=divider role=separator><li><a ng-click=grid.appScope.apiChedan(row.entity) href=""><span class="glyphicon glyphicon-trash"></span> <span translate=autopsApp.ediInsurance.home.revoke>Revoke</span></a><li class=divider role=separator><li><a ng-click=grid.appScope.delete(row.entity)><span class="glyphicon glyphicon-remove-circle"></span><span class="hidden-sm hidden-xs"translate=entity.action.delete></span></a></ul></div></div>'
            }, {
                field: 'comCode',
                minWidth: 100,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.comCodeList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.comCode'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.comCode">{{grid.appScope.linghangDataMap["comCode"+ "Obj"][row.entity.comCode]}}</span>'
            }, {
                field: 'transportId',
                minWidth: 100,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.transportIdList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.transportId'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.transportId">{{grid.appScope.linghangDataMap["transportId"+ "Obj"][row.entity.transportId]}}</span>'
            }, {
                field: 'tradeNo',
                minWidth: 150,
                displayName: $translate.instant('autopsApp.ediInsurance.tradeNo'),
                cellTemplate: '<a href="" class="ui-grid-cell-contents" ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity.tradeNo}}</a>'
            }, {
                field: 'policyNo',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.policyNo'),
                cellTemplate: '<a ui-sref="edi-insurance.edit({id:row.entity.encodeId})" ng-show="row.entity.policyNo">{{row.entity.policyNo}}</a>'
            }, {
                field: 'transSerialNo',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.transSerialNo'),
                cellTemplate: '<a ui-sref="edi-insurance.edit({id:row.entity.encodeId})" ng-show="row.entity.transSerialNo">{{row.entity.transSerialNo}}</a>'
            }, {
                field: 'marks',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.marks')
            }, {
                field: 'waybillNumber',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.FBAWaybillNumber')
            }, {
                field: 'invoiceNumber',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.FBAInvoiceNumber')
            }, {
                field: 'status',
                minWidth: 110,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: statusList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.status'),
                cellTemplate: '<span class="label label-info" translate="autopsApp.ediInsurance.sts.{{row.entity.status}}" ng-if="row.entity.status"></span>'
            }, {
                field: 'auditTime',
                minWidth: 180,
                                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                filters: [{
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                }, {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                }],
                displayName: $translate.instant('autopsApp.ediInsurance.auditTime'),
                cellTemplate: '<span ng-if=row.entity.auditTime>{{row.entity.auditTime | date:\'medium\'}}</span> <span class="label label-primary"ng-click=grid.appScope.audit(row.entity) ng-if=!row.entity.auditTime style=cursor:pointer><span class="glyphicon glyphicon-pencil"></span> <span translate=autopsApp.ediInsurance.audit>Audit</span></span>'
            }, {
                field: 'author',
                minWidth: 80,
                displayName: $translate.instant('autopsApp.ediInsurance.author')
            }, {
                field: 'insuranceCost',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.insuranceCost'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.insuranceCostCur">{{grid.appScope.linghangDataMap["currency"+ "Obj"][row.entity.insuranceCostCur]}} </span>{{row.entity.insuranceCost}}'
            }, {
                field: 'invoiceAmount',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.invoiceAmount'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.currencyCode">{{grid.appScope.linghangDataMap["currency"+ "Obj"][row.entity.currencyCode]}} </span>{{row.entity.invoiceAmount}} <span ng-if=row.entity.jcl>X {{row.entity.jcl}}%</span>'
            }, {
                field: 'goodsType',
                minWidth: 160,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.goodsTypeList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.goodsType'),
                cellTemplate: '<div class=ui-grid-cell-contents data-placement=top data-toggle=tooltip ng-if="grid.appScope.linghangDataMap && row.entity.goodsType"title="{{grid.appScope.linghangDataMap["goodsType"+ "Obj"][row.entity.goodsType]}}">{{grid.appScope.linghangDataMap["goodsType"+ "Obj"][row.entity.goodsType]}}</div><div>{{row.entity.goodsDesc}}</div>'
            }, {
                field: 'applicantName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.applicantName')
            }, {
                field: 'insurantName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.insurantName'),
            }, {
                field: 'startPortSmall',
                minWidth: 120,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.startPortSmallList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.startPortSmall'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.startPortSmall">{{grid.appScope.linghangDataMap["startPortSmall"+ "Obj"][row.entity.startPortSmall]}}</span>'
            }, {
                field: 'terminiPortSmall',
                minWidth: 160,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $rootScope.YES_NO_OPTIONS
                },
                displayName: $translate.instant('autopsApp.ediInsurance.terminiPortSmall'),
                cellTemplate: '<span translate="global.{{row.entity.terminiPortSmall == 1?\'yes_answer\':\'no_answer\'}}"></span>'
            }, {
                field: 'goodsDesc',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.goodsDesc')
            }, {
                field: 'bigPack',
                minWidth: 160,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.bigPackList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.bigPack'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.bigPack">{{grid.appScope.linghangDataMap["pack"+ "Obj"][row.entity.bigPack]}}</span>'
            }, {
                field: 'smallPack',
                minWidth: 160,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.smallPackList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.smallPack'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.smallPack">{{grid.appScope.linghangDataMap["pack"+ "Obj"][row.entity.smallPack]}}</span>'
            }, {
                field: 'weight',
                minWidth: 150,
                displayName: $translate.instant('autopsApp.ediInsurance.weight')
            }, {
                field: 'goodsNum',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.goodsNum')
            }, {
                field: 'jzx',
                minWidth: 120,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.jzxList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.jzx'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.jzx">{{grid.appScope.linghangDataMap["jzx"+ "Obj"][row.entity.jzx]}}</span>'
            }, {
                field: 'transportInfo',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.transportInfo')
            }, {
                field: 'startPortName',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.startPortName')
            }, {
                field: 'terminiPortName',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.terminiPortName')
            }, {
                field: 'startDate',
                minWidth: 160,
                                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                filters: [{
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                }, {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                }],
                displayName: $translate.instant('autopsApp.ediInsurance.startDate')
            }, {
                field: 'oppoPortName',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.oppoPortName')
            }, {
                field: 'createTime',
                minWidth: 200,
                                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                filters: [{
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                }, {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                }],
                displayName: $translate.instant('autopsApp.ediInsurance.createTime'),
                cellTemplate: "<span>{{row.entity.createTime | date:'medium'}}</span>"
            }, {
                field: 'submitterName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.submitterName')
            }, {
                field: 'applicantCertificateType',
                minWidth: 160,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.applicantCertificateTypeList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.applicantCertificateType'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.applicantCertificateType">{{grid.appScope.linghangDataMap["applicantCertificateType"+ "Obj"][row.entity.applicantCertificateType]}}</span>'
            }, {
                field: 'applicantCertificateNo',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.applicantCertificateNo')
            }, {
                field: 'applicantLinkManName',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.applicantLinkManName')
            }, {
                field: 'applicantMobileTelephone',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.applicantMobileTelephone')
            }
        ];

        var wybColumnDefs = [
            {
                field: 'id',
                width: 150,
                displayName: "",
                pinnedLeft: true,
                enableColumnMenu: false,
                enableFiltering: false,
                aggregationType: uiGridConstants.aggregationTypes.count,
                cellTemplate: '<div class="btn-group flex-btn-group-container ml-5"><button class="btn btn-grid btn-primary"type=submit ng-click=grid.appScope.edit(row.entity)><span class="glyphicon glyphicon-edit"></span> <span class="hidden-sm hidden-xs"translate=entity.action.amendation>amendation</span></button><div class="btn-group flex-btn-group-container ml-5"dropdown-append-to-body uib-dropdown><button class="btn btn-grid btn-info"type=button ng-disabled=grid.appScope.isSaving uib-dropdown-toggle><span class="glyphicon glyphicon-option-horizontal"></span> <span translate=entity.action.others>others</span> <span class=caret></span></button><ul uib-dropdown-menu><li><a ng-click="grid.appScope.PDFPOP(row.entity.epolicyUrl, null)"href=""><span class="glyphicon glyphicon-print"></span> <span translate=autopsApp.ediInsurance.home.printInsurance>Print Insurance</span></a><li class=divider role=separator><li><a ng-click=grid.appScope.apiOrderinfo(row.entity) href=""><span class="glyphicon glyphicon-refresh"></span> <span translate=autopsApp.ediInsurance.home.update>Update</span></a><li class=divider role=separator><li><a ng-click=grid.appScope.delete(row.entity)><span class="glyphicon glyphicon-remove-circle"></span><span class="hidden-sm hidden-xs"translate=entity.action.delete></span></a></ul></div></div>'
            }, {
                field: 'comCode',
                minWidth: 100,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.comCodeList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.comCode'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.comCode">{{grid.appScope.linghangDataMap["comCode"+ "Obj"][row.entity.comCode]}}</span>'
            }, {
                field: 'transportId',
                minWidth: 100,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.transportIdList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.transportId'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.transportId">{{grid.appScope.linghangDataMap["transportId"+ "Obj"][row.entity.transportId]}}</span>'
            }, {
                field: 'tradeNo',
                minWidth: 150,
                displayName: $translate.instant('autopsApp.ediInsurance.tradeNo'),
                cellTemplate: '<a href="" class="ui-grid-cell-contents" ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity.tradeNo}}</a>'
            }, {
                field: 'policyNo',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.policyNo'),
                cellTemplate: '<a ui-sref="edi-insurance.edit({id:row.entity.encodeId})" ng-show="row.entity.policyNo">{{row.entity.policyNo}}</a>'
            }, {
                field: 'marks',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.wybMarks')
            }, {
                field: 'waybillNumber',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.wybWaybillNumber')
            }, {
                field: 'status',
                minWidth: 110,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: statusList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.status'),
                cellTemplate: '<span class="label label-info" translate="autopsApp.ediInsurance.sts.{{row.entity.status}}" ng-if="row.entity.status"></span>'
            }, {
                field: 'auditTime',
                minWidth: 180,
                                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                filters: [{
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                }, {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                }],
                displayName: $translate.instant('autopsApp.ediInsurance.auditTime'),
                cellTemplate: '<span ng-if=row.entity.auditTime>{{row.entity.auditTime | date:\'medium\'}}</span> <span class="label label-primary"ng-click=grid.appScope.audit(row.entity) ng-if=!row.entity.auditTime style=cursor:pointer><span class="glyphicon glyphicon-pencil"></span> <span translate=autopsApp.ediInsurance.audit>Audit</span></span>'
            }, {
                field: 'author',
                minWidth: 80,
                displayName: $translate.instant('autopsApp.ediInsurance.author')
            }, {
                field: 'insuranceCost',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.insuranceCost'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.insuranceCostCur">{{grid.appScope.linghangDataMap["currency"+ "Obj"][row.entity.insuranceCostCur]}} </span> <span>{{row.entity.insuranceCost}}</span>'
            }, {
                field: 'invoiceAmount',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.invoiceAmount'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.currencyCode">{{grid.appScope.linghangDataMap["currency"+ "Obj"][row.entity.currencyCode]}} </span>{{row.entity.invoiceAmount}} <span ng-if=row.entity.jcl>X {{row.entity.jcl}}%</span>'
            }, {
                field: 'goodsType',
                minWidth: 160,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.goodsTypeList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.goodsType'),
                cellTemplate: '<div class=ui-grid-cell-contents data-placement=top data-toggle=tooltip ng-if="grid.appScope.linghangDataMap && row.entity.goodsType"title="{{grid.appScope.linghangDataMap["goodsType"+ "Obj"][row.entity.goodsType]}}">{{grid.appScope.linghangDataMap["goodsType"+ "Obj"][row.entity.goodsType]}}</div><div>{{row.entity.goodsDesc}}</div>'
            }, {
                field: 'insurantName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.insurantName'),
            }, {
                field: 'isShelf',
                minWidth: 160,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $rootScope.YES_NO_OPTIONS
                },
                displayName: $translate.instant('autopsApp.ediInsurance.isShelf'),
                cellTemplate: '<span translate="global.{{row.entity.isShelf?\'yes_answer\':\'no_answer\'}}"></span>'
            }, {
                field: 'isUnrest',
                minWidth: 160,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $rootScope.YES_NO_OPTIONS
                },
                displayName: $translate.instant('autopsApp.ediInsurance.isUnrest'),
                cellTemplate: '<span translate="global.{{row.entity.isUnrest?\'yes_answer\':\'no_answer\'}}"></span>'
            }, {
                field: 'goodsDesc',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.goodsDesc')
            }, {
                field: 'bigPack',
                minWidth: 160,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.bigPackList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.wybBigPack'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.bigPack">{{grid.appScope.linghangDataMap["pack"+ "Obj"][row.entity.bigPack]}}</span>'
            }, {
                field: 'smallPack',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.wybSmallPack')
            }, {
                field: 'weight',
                minWidth: 150,
                displayName: $translate.instant('autopsApp.ediInsurance.weight')
            }, {
                field: 'jzx',
                minWidth: 120,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.jzxList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.wybJzx'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.jzx">{{grid.appScope.linghangDataMap["jzx"+ "Obj"][row.entity.jzx]}}</span>'
            }, {
                field: 'transportInfo',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.wybTransportInfo')
            }, {
                field: 'startDate',
                minWidth: 160,
                                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                filters: [{
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                }, {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                }],
                displayName: $translate.instant('autopsApp.ediInsurance.startDate')
            }, {
                field: 'oppoPortName',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.wybOppoPortName')
            }, {
                field: 'createTime',
                minWidth: 200,
                                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                filters: [{
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                }, {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                }],
                displayName: $translate.instant('autopsApp.ediInsurance.createTime'),
                cellTemplate: "<span>{{row.entity.createTime | date:'medium'}}</span>"
            }, {
                field: 'submitterName',
                minWidth: 120,
                displayName: $translate.instant('autopsApp.ediInsurance.submitterName')
            }, {
                field: 'applicantCertificateType',
                minWidth: 160,
                filter: {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: $scope.linghangDataMap.applicantCertificateTypeList
                },
                displayName: $translate.instant('autopsApp.ediInsurance.wybApplicantCertificateType'),
                cellTemplate: '<span ng-if="grid.appScope.linghangDataMap && row.entity.applicantCertificateType">{{grid.appScope.linghangDataMap["applicantCertificateType"+ "Obj"][row.entity.applicantCertificateType]}}</span>'
            }, {
                field: 'applicantCertificateNo',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.wybApplicantCertificateNo')
            }, {
                field: 'applicantMobileTelephone',
                minWidth: 160,
                displayName: $translate.instant('autopsApp.ediInsurance.wybApplicantMobileTelephone')
            }
        ];

        function changeType () {
            loadAll();
            getLinghangDataMap();
        }
    }
})();
