(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BlRlsController', BlRlsController);

    BlRlsController.$inject = ['$rootScope', '$timeout', '$scope', '$stateParams', '$state', '$translate', '$http', '$uibModal',
    'DataUtils', '$location', 'DateUtils', 'CustomNode',
    'WinAES', 'Principal', 'Guide', 'GuideSearch','OpsFiles', 'uiGridConstants', 'GridState'];

    function BlRlsController($rootScope, $timeout, $scope, $stateParams, $state, $translate, $http, $uibModal,
        DataUtils, $location, DateUtils, CustomNode,
        WinAES, Principal, Guide, GuideSearch, OpsFiles, uiGridConstants, GridState) {

        if(!$scope.viewScope){
            $scope.viewScope = "self";
        }
        $scope.workType = null;
        $rootScope.choosedRole = "ROLE_ACCOUNT";

        $scope.setRole = function(role) {
            $rootScope.choosedRole = role;
            // $scope.isAdvanceSearch = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            clear();
        }


        var vm = this;
        vm.setWorkType = setWorkType;
        vm.getWorkload = getWorkload;
        function getWorkload (){
            if(!$rootScope.account.companyId){
                return;
            }
            Guide.getBillsWorkload({viewScope: $scope.viewScope}, function(result){
                $rootScope.WORKLOADBILLS = result;
                $translate.refresh();
            });
        }
        $scope.changeScope = function(scope) {
            if (scope == "all" && $rootScope.account.authorities.indexOf("FUN_VIEW_ALL_BIZ") == -1
                && $rootScope.account.authorities.indexOf("FUN_VIEW_TEAM_BIZ") == -1
                && $rootScope.account.authorities.indexOf("FUN_VIEW_BRANCH_BIZ") == -1){
                if(vm.customNodeCopy){
                    clearCustomNoe()
                }
                else {
                    layer.msg($translate.instant('global.forbidden'));
                }
                return;
            }
            vm.customNodeCopy = null;
            $scope.viewScope = scope;
            getWorkload();
            clear();
        }

        $scope.getCustomTasks = function(){
            CustomNode.getCustomTasks({requestType: "RLS"}, function (result) {
                vm.tasks = result;
            });
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                getWorkload();
            });
        }
        else {
            getWorkload();
        }

        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.page = 1;
        vm.itemsPerPage = 20;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = null;
        vm.currentSearch = null;
        $scope.isAdvanceSearch = false;
        $scope.jobType = null;

        $scope.setJobType = function(type) {
            $scope.jobType = type;
        }
        var gridCopy;
        var pageLoaded = false;
        var filterTimer;
        function loadAll() {
            if (vm.searchQuery) {
                if($scope.jobType){
                    Guide.searchByNums({
                        type: $scope.jobType,
                        shipmentType: "all",
                        companyId: $rootScope.account.companyId
                    }, vm.searchQuery, onSuccess, onError);
                    return;
                }
                GuideSearch.billsQuery({
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                filter.fmsType = "EXPALL";
                filter.role = $rootScope.choosedRole;
                filter.type = $scope.viewScope;
                filter.isApplied = true;
                if(vm.customNodeCopy){
                    filter.workType = "customNode";
                    filter.nodeName = vm.customNodeCopy.chartTitle;
                    filter.isVoid = null;
                }
                else if ($scope.workType == "hblNotRls"){
                    filter.hblReleasedTimeTAG = false;
                    filter.mblOnly = false;
                }
                else if ($scope.workType == "mblNotRls"){
                    filter.mblReleasedTimeTAG = false;
                }
                DataUtils.fillGuideFilter(gridCopy, filter);
                layer.load(1, { shade: 0.3 });
                Guide.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [(vm.predicate.endWith('TAG')?vm.predicate.replace('TAG', ''):vm.predicate) + ',' + vm.direction];
                return result;
            }

            function onSuccess(data, headers) {
                $rootScope.ALERT_RESULT_MSG(headers);
                layer.closeAll();
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                initData();
                resize();
                updateFilterRequired();
                if($scope.jobType){
                    $scope.isAdvanceSearch = true;
                }
                else {
                    $scope.isAdvanceSearch = false;
                }
                updateByIsAdvanceSearch();
                if (vm.searchQuery && !$scope.jobType && $scope.gridOptions.data.length == 0 && !$scope.isAdvanceSearch){
                    layer.msg($translate.instant('global.searchResultNullAlert'));
                }
            }

            function onError(error) {
                layer.closeAll();
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        function updateByIsAdvanceSearch(searchQuery) {
            $scope.gridOptions.useExternalSorting = !$scope.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !$scope.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = $scope.isAdvanceSearch || $scope.filterRequired;
            if($scope.isAdvanceSearch){
                $timeout(function(){
                    $scope.gridApi.selection.selectAllRows();
                })
            }

            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }
        function search(searchQuery) {
            if (!searchQuery) {
                return clear();
            }
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            loadAll();
        }
        function clear() {
            if(vm.predicate == "_score"){
                vm.page = 1;
                vm.predicate = 'id';
                vm.direction =  'desc';
            }
            vm.searchQuery = vm.currentSearch = null;
            $scope.jobType = null;
            loadAll();
        }

        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 200;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        $scope.searchGuidePop = function() {
            var searchParams = {
                shipmentType: 'fba',
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                $scope.gridOptions.data = results;
                initData();
                $scope.isAdvanceSearch = vm.currentSearch = true;
                $scope.gridOptions.totalItems = results.length;
                updateByIsAdvanceSearch();
                resize();
            }, function() {
            });
        }

        $scope.teusTotal = 0;
        function initData() {
            $scope.teusTotal = DataUtils.getTeusTotal($scope.gridOptions.data);
            $scope.gridApi.selection.clearSelectedRows();
        }

        $scope.choosedId = null;
        $scope.setChoosedId = function(id){
            $scope.choosedId = id;
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)
            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            exporterExcelFilename: $translate.instant('global.menu.entities.allExport'),

            paginationPageSizes: [20, 35, 50, 100, 200],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'id',
                    width: 180,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<div class="btn-group flex-btn-group-container mr-3" ng-click="grid.appScope.setChoosedId(row.entity.id)"><button type="button" class="btn btn-info btn-grid" ng-click="grid.appScope.viewDocs(row.entity)"><span class="glyphicon glyphicon-file"></span><span class="hidden-xs hidden-sm" translate="autopsApp.guide.table.docMgmt">docMgmt</span></button><button type="button" class="btn btn-primary btn-grid" ng-click="grid.appScope.auditGuide(row.entity)"><span class="glyphicon glyphicon-eye-open"></span><span translate="entity.action.check">check</span></button></div>'},
                { field: 'jobNum',
                    minWidth:150,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    displayName: $translate.instant('autopsApp.guide.jobNum'),
                    cellTemplate: '<span class="grid-text" ng-class="row.entity.id==grid.appScope.choosedId?\'choosedColor\':\'\'" ng-click="grid.appScope.setChoosedId(row.entity.id)"><span class="label label-danger mr-5" ng-if="row.entity.parcelMaster || row.entity.masterId == 0" ng-click="grid.appScope.consoleListPop(row.entity.id, true)" data-toggle="tooltip" data-placement="top" title="FMS/FBA  Consol Master">M</span><span class="label label-success mr-5" ng-if="row.entity.masterId" ng-click="grid.appScope.consoleListPop(row.entity.masterId, true)" data-toggle="tooltip" data-placement="top" title="FMS/FBA Consol Subshipment">H</span><span class="label label-info mr-5" ng-if="row.entity.parcelId" ng-click="grid.appScope.consoleListPop(row.entity.parcelId, false)" data-toggle="tooltip" data-placement="top" title="TMS/PARCEL Consol Subshipment">H</span><a href="" ng-click="grid.appScope.BIZPOP(row.entity.id)">{{row.entity.jobNum}}</a></span>'},
                { field: 'finMemo',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.guide.table.memo'),
                    cellTemplate: '<input type="text" class="grid-input" ng-model="row.entity.finMemo" ng-focus="grid.appScope.copyMemo(row.entity)" ng-blur="grid.appScope.saveMemo(row.entity)" maxlength="255"/>'},
                { field: 'appliedBy',
                    displayName: $translate.instant('autopsApp.guide.appliedBy'),
                    minWidth:100},
                { field: 'hblReleasedTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.hblReleasedTime'),
                    minWidth:100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-hide="row.entity.mblOnly && !grid.appScope.ISFBA(row.entity.shipmentType)" class="grid-text"><span class="label label-info" ng-click="grid.appScope.blRelease(row.entity)" ng-if="!row.entity.hblReleasedTime"translate="autopsApp.guide.sts.notReleased" style="cursor: pointer;">notReleased</span><span class="label label-success" ng-click="grid.appScope.blRelease(row.entity)" ng-if="row.entity.hblReleasedTime"translate="autopsApp.guide.sts.released" style="cursor: pointer;" data-toggle="tooltip" data-placement="top" title="{{row.entity.hblReleasedTime | date:\'medium\'}}">released</span></span>'},
                { field: 'mblReleasedTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.mblReleasedTime'),
                    minWidth:100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="grid-text"><span class="label label-info" ng-click="grid.appScope.mblRelease(row.entity)" ng-if="!row.entity.mblReleasedTime" translate="autopsApp.guide.sts.notReleased" style="cursor: pointer;">notReleased</span><span class="label label-success" ng-click="grid.appScope.mblRelease(row.entity)" ng-if="row.entity.mblReleasedTime" translate="autopsApp.guide.sts.released" style="cursor: pointer;" data-toggle="tooltip" data-placement="top" title="{{row.entity.mblReleasedTime | date:\'medium\'}}">released</span></span>'},
                { field: 'paymentPaidTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.paymentPaidTime'),
                    minWidth:70,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="grid-text glyphicon" ng-class="(row.entity.paymentPaidTime==null) ? \'glyphicon-minus\' : \'glyphicon-ok\'"></span>'},
                { field: 'paymentReceivedTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.paymentReceivedTime'),
                    minWidth:70,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="grid-text glyphicon" ng-class="(row.entity.paymentReceivedTime==null) ? \'glyphicon-minus\' : \'glyphicon-ok\'"></span>'},
                 { field: 'ratesLocked',
                    minWidth:70,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.ratesLocked'),
                    cellTemplate: '<span class="grid-text text-primary"><span class="glyphicon glyphicon-lock" ng-if="row.entity.ratesLocked" style="cursor: pointer;" ng-click="grid.appScope.lockRateOne(row.entity, false)" data-toggle="tooltip" data-placement="top" title="{{\'entity.action.locked\' | translate}}"></span><span class="glyphicon glyphicon-minus" ng-hide="row.entity.ratesLocked" style="cursor: pointer;" ng-click="grid.appScope.lockRateOne(row.entity, true)" data-toggle="tooltip" data-placement="top" title="{{\'entity.action.notLocked\' | translate}}"></span></span>'},
                 { field: 'isClosed',
                    minWidth:70,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.sts.isClosed'),
                    cellTemplate: '<span class="grid-text text-primary"><span class="glyphicon glyphicon-lock" ng-if="row.entity.isClosed" style="cursor: pointer;" ng-click="grid.appScope.lockGuideOne(row.entity)" data-toggle="tooltip" data-placement="top" title="{{\'entity.action.locked\' | translate}}"></span><span class="glyphicon glyphicon-minus" ng-hide="row.entity.isClosed" style="cursor: pointer;" ng-click="grid.appScope.lockGuideOne(row.entity)" data-toggle="tooltip" data-placement="top" title="{{\'entity.action.notLocked\' | translate}}"></span></span>'},
                 { field: 'isApplied',
                    minWidth:70,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.applyRelease'),
                    cellTemplate: '<span class="grid-text text-primary"><span class="glyphicon glyphicon-ok" ng-if="row.entity.isApplied" style="cursor: pointer;" ng-click="grid.appScope.APPLY_RELEASE(row.entity)"></span><span class="glyphicon glyphicon-minus" ng-hide="row.entity.isApplied" style="cursor: pointer;" ng-click="grid.appScope.APPLY_RELEASE(row.entity)"></span></span>'},
                { field: 'finName',
                    displayName: $translate.instant('autopsApp.guide.account'),
                    cellTemplate: '<input type="text" class="grid-input" ng-model="row.entity.finName" uib-typeahead="clerk.name as clerk for clerk in grid.appScope.clerks | filter: $viewValue | filter:grid.appScope.finFilter | limitTo:100" typeahead-template-url="clerkTemplate.html" typeahead-on-select="grid.appScope.updateFin(row.entity, $item)" typeahead-editable="false" typeahead-min-length="0" maxlength="100" ng-focus="grid.appScope.loadClerks()" ng-disabled="row.entity.hblReleasedTime"/>',
                    minWidth:100},
                { field: 'status',
                    displayName: $translate.instant('autopsApp.guide.status'),
                    minWidth:180,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_STATUS_TYPES
                    },
                    cellTemplate: '<span class="grid-text"><span>{{row.entity.showStatus}} <span class="text-muted ml-4" data-toggle="tooltip" data-placement="top" title="{{row.entity.nodeTime | date:\'medium\'}}">{{row.entity.nodeTime|date:\'mediumDate\'}}</span></span></span>'},
                { field: 'shipmentType',
                    displayName: $translate.instant('autopsApp.guide.shipmentType'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_TYPES
                    },
                    minWidth:90,
                    cellTemplate: '<span class="grid-text"><span translate="global.{{row.entity.shipmentType}}"ng-if="row.entity.shipmentType"></span></span>'},
                 { field: 'isExport',
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [{ value: true, label: $translate.instant('autopsApp.guide.home.isExport')},
                            { value: false, label: $translate.instant('autopsApp.guide.home.isImport')}]
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.home.exportOrImport'),
                    cellTemplate: '<span class="grid-text"><span ng-if="row.entity.isExport"><span translate="autopsApp.guide.home.isExport">isExport</span></span><span ng-if="!row.entity.isExport"><span translate="autopsApp.guide.home.isImport">isImport</span></span></span>'},
                { field: 'mblNum',
                    displayName: $translate.instant('autopsApp.guide.mblNum'),
                    minWidth:150},
                { field: 'soNum',
                    displayName: $translate.instant('autopsApp.guide.soNum'),
                    minWidth:150},
                { field: 'customerName',
                    displayName: $translate.instant('autopsApp.guide.bookingPartyName'),
                    minWidth:150},
                { field: 'clientCredit',
                    displayName: $translate.instant('autopsApp.guide.clientCredit'),
                    minWidth:180},
                { field: 'shipperName',
                    displayName: $translate.instant('autopsApp.guide.shipperName'),
                    minWidth:150},
                { field: 'carrier',
                    displayName: $translate.instant('autopsApp.guide.carrier'),
                    minWidth:60},
                { field: 'bookingTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.bookingTime'),
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.bookingTime | date:\'medium\'}}">{{row.entity.bookingTime | date:\'mediumDate\'}}</span>'},
                { field: 'soReleaseTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.soReleaseTime'),
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.soReleaseTime | date:\'medium\'}}">{{row.entity.soReleaseTime | date:\'mediumDate\'}}</span>'},
                { field: 'etd',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.etd'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.etd | date:\'mediumDate\'}}</span>'},
                { field: 'otherTimeOne',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.otherTimeOne | date:\'medium\'}}">{{row.entity.otherTimeOne | date:\'mediumDate\'}}</span>'},
                { field: 'otherTimeTwo',
                    displayName: $translate.instant('autopsApp.guide.wmsOutTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.otherTimeTwo | date:\'medium\'}}">{{row.entity.otherTimeTwo | date:\'mediumDate\'}}</span>'},
                { field: 'volumes',
                    displayName: $translate.instant('autopsApp.guide.volumes'),
                    footerCellTemplate: '<div class="ml-5">{{grid.appScope.teusTotal}} TEUS</div>',
                    minWidth:150},
                { field: 'onHold',
                    displayName: $translate.instant('autopsApp.guide.onHold'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5" ng-click="grid.appScope.problemShipmentHandle(row.entity, null)"><span ng-if="row.entity.onHold"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.onHold"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'destName',
                    displayName: $translate.instant('autopsApp.guide.destName'),
                    minWidth:60},
                { field: 'destCountryCode',
                    displayName: $translate.instant('autopsApp.guide.destCountryCode'),
                    minWidth:50},
                { field: 'salesName',
                    displayName: $translate.instant('autopsApp.guide.salesName'),
                    minWidth:60},
                { field: 'vsl',
                    displayName: $translate.instant('autopsApp.guide.vsl'),
                    minWidth:60},
                { field: 'voy',
                    displayName: $translate.instant('autopsApp.guide.voy'),
                    minWidth:60},
                { field: 'cls',
                    displayName: $translate.instant('autopsApp.guide.cls'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.cls | date:\'medium\'}}">{{row.entity.cls | date:\'mediumDate\'}}</span>'},
                { field: 'siCutTime',
                    displayName: $translate.instant('autopsApp.guide.siCutTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:200,
                    cellTemplate: '<span class="grid-text">{{row.entity.siCutTime | date:\'medium\'}}</span>'},
                { field: 'polName',
                    displayName: $translate.instant('autopsApp.guide.polName'),
                    minWidth:60},
                { field: 'podName',
                    displayName: $translate.instant('autopsApp.guide.podName'),
                    minWidth:60},
                { field: 'eta',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.eta'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.eta | date:\'mediumDate\'}}</span>'},
                { field: 'deliveryDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.deliveryDate'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.deliveryDate | date:\'mediumDate\'}}</span>'},
                { field: 'lfd',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.lfd'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.lfd | date:\'mediumDate\'}}</span>'},
                { field: 'lastestTkStatus',
                    minWidth:60,
                    displayName: $translate.instant('autopsApp.expressParcel.lastestTkStatus')},
                { field: 'hblNum',
                    displayName: $translate.instant('autopsApp.guide.hblNum'),
                    minWidth:150},
                { field: 'serviceTerm',
                    displayName: $translate.instant('autopsApp.guide.serviceTerm'),
                    minWidth:60},
                { field: 'poNum',
                    displayName: $translate.instant('autopsApp.booking.poNum'),
                    minWidth:60},
                { field: 'trackingId',
                    displayName: $translate.instant('autopsApp.guide.trackingId'),
                    minWidth:60},
                { field: 'shipmentId',
                    displayName: "Shipment Id",
                    minWidth:60},
                { field: 'fbaRef',
                    displayName: "Reference Id",
                    minWidth:60},
                { field: 'bizType',
                    displayName: $translate.instant('autopsApp.guide.bizType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.BIZTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.bizTypeLabel}}</span>'},
                { field: 'internalType',
                    displayName: $translate.instant('autopsApp.guide.bizInternalType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.INTERNALTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.internalTypeLabel}}</span>'},
                { field: 'cusName',
                    displayName: $translate.instant('autopsApp.guide.cusName'),
                    minWidth:60},
                { field: 'opName',
                    displayName: $translate.instant('autopsApp.guide.opName'),
                    minWidth:60},
                { field: 'bookingBy',
                    displayName: $translate.instant('autopsApp.guide.table.bookingBy'),
                    minWidth:60},
                { field: 'truckBy',
                    displayName: $translate.instant('autopsApp.guide.table.truckBy'),
                    minWidth:60},
                { field: 'customBy',
                    displayName: $translate.instant('autopsApp.guide.table.customBy'),
                    minWidth:60},
                { field: 'docName',
                    displayName: $translate.instant('autopsApp.guide.docName'),
                    minWidth:60},
                { field: 'overseasCs',
                    displayName: $translate.instant('autopsApp.guide.overseasCs'),
                    minWidth:60},
                { field: 'productName',
                    displayName: $translate.instant('autopsApp.guide.productName'),
                    minWidth:150},
                { field: 'deliveryName',
                    displayName: $translate.instant('autopsApp.guide.deliveryName'),
                    minWidth:150},
                { field: 'clearanceAgent',
                    displayName: $translate.instant('autopsApp.guide.clearanceAgent'),
                    minWidth:150},
                { field: 'bookingToName',
                    displayName: $translate.instant('autopsApp.guide.bookingToName'),
                    minWidth:150},
                { field: 'bookingToCode',
                    displayName: $translate.instant('autopsApp.guide.bookingToCode'),
                    width:150},
                { field: 'createdTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.home.createdTime'),
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.createdTime | date:\'medium\'}}">{{row.entity.createdTime | date:\'mediumDate\'}}</span>'},
                { field: 'mblOnly',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.mblOnly'),
                    cellTemplate: '<span class="ml-5"><span translate="global.yes_answer"class="label label-success"ng-if="row.entity.mblOnly">yes</span><span translate="global.no_answer"class="label label-primary"ng-if="!row.entity.mblOnly">no</span></span>'},
                { field: 'salesChecked',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.salesChecked'),
                    cellTemplate: '<span ng-class="row.entity.salesChecked?\'label-primary\':\' label-danger\'"translate="{{row.entity.salesChecked?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
               { field: 'isVoid',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.sts.voided'),
                    cellTemplate: '<span ng-class="row.entity.isVoid?\'label-primary\':\' label-danger\'"translate="{{row.entity.isVoid?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'submited',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.sts.Requested'),
                    cellTemplate: '<span ng-class="row.entity.submited?\'label-primary\':\' label-danger\'"translate="{{row.entity.submited?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                 { field: 'etdLocked',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: "ETD" + $translate.instant('entity.action.locked'),
                    cellTemplate: '<span ng-class="row.entity.etdLocked?\'label-primary\':\' label-danger\'"translate="{{row.entity.etdLocked?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isLocked',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.home.salesLock'),
                    cellTemplate: '<span ng-class="row.entity.isLocked?\'label-primary\':\' label-danger\'"translate="{{row.entity.isLocked?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},

                { field: 'wmsActAmt',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.wmsActAmt'),
                    minWidth:80},
                { field: 'wmsGw',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.wmsGw'),
                    minWidth:80},
                { field: 'wmsVol',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.wmsVol'),
                    minWidth:80},
                { field: 'wmsCw',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.wmsCw'),
                    minWidth:80},
                { field: 'pkgNum',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.pkgNum'),
                    minWidth:80},
                { field: 'gw',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.gw'),
                    minWidth:80},
                { field: 'vol',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.vol'),
                    minWidth:80},
                { field: 'chargeWeight',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.booking.chargeWeight'),
                    minWidth:80},
                { field: 'palletNum',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.booking.palletNum'),
                    minWidth:80},
                { field: 'pieces',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.booking.bookingCtns.pieces'),
                    minWidth:80},
                { field: 'profit',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.profit'),
                    minWidth:80},
                { field: 'gp',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.avg,
                    displayName: $translate.instant('autopsApp.guide.gp'),
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.gp">{{row.entity.gp}}%</span>',
                    minWidth:60},
                { field: 'customRequired',
                    displayName: $translate.instant('autopsApp.guide.customRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.customRequired"class="label label-primary"><span translate="global.yes_answer">isExport</span></span><span ng-hide="row.entity.customRequired"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'insuranceRequired',
                    displayName: $translate.instant('autopsApp.guide.insuranceRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.insuranceRequired"class="label label-primary"><span translate="global.yes_answer">isExport</span></span><span ng-hide="row.entity.insuranceRequired"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'fumigationRequired',
                    displayName: $translate.instant('autopsApp.guide.fumigationRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.fumigationRequired"class="label label-primary"><span translate="global.yes_answer">isExport</span></span><span ng-hide="row.entity.fumigationRequired"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'warehouseRequired',
                    displayName: $translate.instant('autopsApp.guide.warehouseRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.warehouseRequired"class="label label-primary"><span translate="global.yes_answer">isExport</span></span><span ng-hide="row.entity.warehouseRequired"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'coRequired',
                    displayName: $translate.instant('autopsApp.guide.coRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.coRequired"class="label label-primary"><span translate="global.yes_answer">isExport</span></span><span ng-hide="row.entity.coRequired"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'truckRequired',
                    displayName: $translate.instant('autopsApp.guide.truckRequired'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.truckRequired"class="label label-primary"><span translate="global.yes_answer">isExport</span></span><span ng-hide="row.entity.truckRequired"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'porName',
                    displayName: $translate.instant('autopsApp.guide.porName'),
                    minWidth:60},
                { field: 'deliveryAppointmentTime',
                    displayName: $translate.instant('autopsApp.guide.deliveryAppointmentTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.deliveryAppointmentTime | date:\'medium\'}}">{{row.entity.deliveryAppointmentTime | date:\'mediumDate\'}}</span>'},
                { field: 'deliveryTime',
                    displayName: $translate.instant('autopsApp.guide.deliveryTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.deliveryTime | date:\'medium\'}}">{{row.entity.deliveryTime | date:\'mediumDate\'}}</span>'},
                { field: 'ctnReturnTime',
                    displayName: $translate.instant('autopsApp.guide.ctnReturnTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.ctnReturnTime | date:\'medium\'}}">{{row.entity.ctnReturnTime | date:\'mediumDate\'}}</span>'},
                { field: 'customsType',
                    displayName: $translate.instant('autopsApp.guide.customsType'),
                    minWidth:150},
                { field: 'refNum',
                    displayName: $translate.instant('autopsApp.guide.refNum'),
                    minWidth:150},
                { field: 'thirdpartyPlatformRef',
                    displayName: $translate.instant('autopsApp.guide.thirdpartyPlatformRef'),
                    minWidth:150},
                { field: 'remarks',
                    displayName: $translate.instant('autopsApp.guide.remarks'),
                    minWidth:130},
                { field: 'branchGuideIdTAG',
                    displayName: $translate.instant('autopsApp.guide.authorizeBranch'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.branchGuideId?\'label-primary\':\' label-danger\'"translate="{{row.entity.branchGuideId?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'cargoName',
                    displayName: $translate.instant('autopsApp.guide.cargoName'),
                    minWidth:150},
                { field: 'deliverytoId',
                    displayName: $translate.instant('autopsApp.booking.deliverytoId'),
                    minWidth:60},
                { field: 'deliveryTo',
                    displayName: $translate.instant('autopsApp.booking.deliveryTo'),
                    minWidth:60},
                { field: 'directDelivery',
                    displayName: $translate.instant('autopsApp.guide.directDelivery'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.directDelivery?\'label-primary\':\' label-danger\'"translate="{{row.entity.directDelivery?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'consolidated',
                    displayName: $translate.instant('autopsApp.guide.consolidated'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.consolidated?\'label-primary\':\' label-danger\'"translate="{{row.entity.consolidated?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'shiptoPostcode',
                    displayName: $translate.instant('autopsApp.guide.destPostCode'),
                    minWidth:60},
                { field: 'deliveryBl',
                    displayName: $translate.instant('autopsApp.booking.cneeName'),
                    minWidth:200},
                { field: 'cargoReadyDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.wmsInDate'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.cargoReadyDate | date:\'mediumDate\'}}</span>'},
                { field: 'bookingEtd',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.bookingEtd'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.bookingEtd | date:\'mediumDate\'}}</span>'},
                { field: 'atd',
                    displayName: "ATD",
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.atd | date:\'mediumDate\'}}</span>'},
                { field: 'revDeadLine',
                    displayName: $translate.instant('autopsApp.guide.revDeadLine'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.revDeadLine | date:\'mediumDate\'}}</span>'},
                { field: 'ataTime',
                    displayName: 'ATA',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.ataTime | date:\'medium\'}}">{{row.entity.ataTime | date:\'mediumDate\'}}</span>'},
                { field: 'truckArrangeTime',
                    displayName: $translate.instant('autopsApp.guide.truckArrangeTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.truckArrangeTime | date:\'medium\'}}">{{row.entity.truckArrangeTime | date:\'mediumDate\'}}</span>'},
                { field: 'customArrangeTime',
                    displayName: $translate.instant('autopsApp.guide.customArrangeTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.customArrangeTime | date:\'medium\'}}">{{row.entity.customArrangeTime | date:\'mediumDate\'}}</span>'},
                { field: 'customReleaseTime',
                    displayName: $translate.instant('autopsApp.guide.customReleaseTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.customReleaseTime | date:\'medium\'}}">{{row.entity.customReleaseTime | date:\'mediumDate\'}}</span>'},
                { field: 'customClearTime',
                    displayName: $translate.instant('autopsApp.guide.sts.customCleared'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.customClearTime | date:\'medium\'}}">{{row.entity.customClearTime | date:\'mediumDate\'}}</span>'},
                { field: 'ctnAvailableTime',
                    displayName: $translate.instant('autopsApp.guide.sts.ctnAvailable'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.ctnAvailableTime | date:\'medium\'}}">{{row.entity.ctnAvailableTime | date:\'mediumDate\'}}</span>'},
                { field: 'otherTimeThree',
                    displayName: $translate.instant('autopsApp.guide.transferOutTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.otherTimeThree | date:\'medium\'}}">{{row.entity.otherTimeThree | date:\'mediumDate\'}}</span>'},
                { field: 'closeTime',
                    displayName: $translate.instant('autopsApp.guide.closeTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.closeTime | date:\'medium\'}}">{{row.entity.closeTime | date:\'mediumDate\'}}</span>'},
                { field: 'whName',
                    displayName: $translate.instant('autopsApp.guide.warehouse'),
                    minWidth:130},
                { field: 'feederEtd',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.feederEtd'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.feederEtd | date:\'mediumDate\'}}</span>'},
                { field: 'feederVsl',
                    displayName: $translate.instant('autopsApp.guide.feederVsl'),
                    minWidth:130},
                { field: 'feederVoy',
                    displayName: $translate.instant('autopsApp.guide.feederVoy'),
                    minWidth:130},
                { field: 'ataTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.sts.arrivedPod'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.ataTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.ataTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'bookingTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.bookingTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.bookingTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.bookingTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'truckArrangeTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.truckArrangeTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.truckArrangeTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.truckArrangeTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'ctnsLoadedTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.sts.CtnStuffed'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.ctnsLoadedTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.ctnsLoadedTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'soReleaseTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.soReleaseTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.soReleaseTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.soReleaseTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'customArrangeTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.customArrangeTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.customArrangeTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.customArrangeTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'customReleaseTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.customReleaseTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.customReleaseTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.customReleaseTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'otherTimeOneTAG',
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.otherTimeOneTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.otherTimeOneTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'otherTimeTwoTAG',
                    displayName: $translate.instant('autopsApp.guide.wmsOutTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.otherTimeTwoTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.otherTimeTwoTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'otherTimeThreeTAG',
                    displayName: $translate.instant('autopsApp.guide.transferOutTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.otherTimeThreeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.otherTimeThreeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'plConfirmTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.sts.plConfirmed'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.plConfirmTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.plConfirmTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'vgmTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.vgmTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.vgmTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.vgmTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'siSubmitedTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.siSubmitedTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.siSubmitedTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.siSubmitedTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'customClearTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.customClearTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.customClearTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.customClearTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'customsInspectionExp',
                    displayName: $translate.instant('autopsApp.guide.customsInspectionExp'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.customsInspectionExp?\'label-primary\':\' label-danger\'"translate="{{row.entity.customsInspectionExp?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'customsInspection',
                    displayName: $translate.instant('autopsApp.guide.customsInspection'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.customsInspection?\'label-primary\':\' label-danger\'"translate="{{row.entity.customsInspection?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'ctnAvailableTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.sts.ctnAvailable'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.ctnAvailableTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.ctnAvailableTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'deliveryAppointmentTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.deliveryAppointmentTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.deliveryAppointmentTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.deliveryAppointmentTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'deliveryTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.deliveryTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.deliveryTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.deliveryTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'dnSentTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.dnSentTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.dnSentTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.dnSentTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'allBilled',
                    displayName: $translate.instant('autopsApp.guide.allBilled'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.allBilled"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-click="grid.appScope.FMS_RATES_SUBMIT(row.entity.id, false)" ng-hide="row.entity.allBilled"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                 { field: 'assistChargesConfirmed',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.assistChargesConfirmed'),
                    cellTemplate: '<span ng-class="row.entity.assistChargesConfirmed?\'label-primary\':\' label-danger\'"translate="{{row.entity.assistChargesConfirmed?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'invoiceIssuedTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.invoiceIssuedTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.invoiceIssuedTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.invoiceIssuedTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'invoiceReceivedTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.invoiceReceivedTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.invoiceReceivedTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.invoiceReceivedTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'hblConfirmedTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.hblConfirmedTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.hblConfirmedTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.hblConfirmedTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},


                { field: 'saSentTimeTAG',
                    displayName: $translate.instant('autopsApp.guide.saSentTime'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.saSentTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.saSentTimeTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 156;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        pageLoaded = false;
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                        pageLoaded = true;
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    pageLoaded = false;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    pageLoaded = true;
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "guide";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "guide";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope,function(row){
                    clearTimeout(filterTimer);
                    filterTimer = setTimeout(function(){
                        updateSelectedTtl();
                    }, 1000);
                });
                gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                    clearTimeout(filterTimer);
                    filterTimer = setTimeout(function(){
                        updateSelectedTtl();
                    }, 1000);
                });
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if(pageLoaded){
                        loadAll();
                    }
                });
            }
        }

        var gridStateObj = {};
        var gridStateCopy = null;
        $scope.filterRequired = false;
        $timeout(function (){
            $scope.getCustomTasks();
            $rootScope.REMOVE_COL_BY_AUTH($scope.gridOptions.columnDefs);
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 156
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    $scope.loadByCustomNode($stateParams.customNode);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if(gridCopy){
                    updateFilterRequired();
                    if($scope.filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                    }
                }
                $scope.loadByCustomNode($stateParams.customNode);
            })
        });
        var updateFilterRequired = function(){
            if(!gridCopy){
                return;
            }
            $scope.filterRequired = false;
            angular.forEach(gridCopy.columns, function(column){
                angular.forEach(column.filters, function(cf){
                    if(cf.term || cf.term == false){
                        $scope.filterRequired = true;
                    }
                })
            })
        }


        function updateGuide (result){
            angular.forEach($scope.gridOptions.data, function(guide){
                if(guide.id == result.id){
                    angular.copy(result, guide);
                    DataUtils.addGuideTag(guide);
                }
            })
        }

        var unsubscribeGuide = $rootScope.$on('autopsApp:guideUpdate', function(event, result) {
            if(result.id){
                updateGuide(result);
            }
            else {
                loadAll();
            }
            $scope.getCustomTasks();
        });
        $scope.$on('$destroy', unsubscribeGuide);

        var rateUnsubscribe = $rootScope.$on('autopsApp:guideRatesUpdate', function(event, result) {
            if(result && result.id){
                angular.forEach($scope.gridOptions.data, function(guide){
                    if(guide.id == result.id){
                        guide.etd = result.etd;
                        guide.isApplied = result.isApplied;
                    }
                })
            }
        });
        $scope.$on('$destroy', rateUnsubscribe);

        var loadAllUnsubscribe = $rootScope.$on('autopsApp:loadAllRequred', function(event, result) {
            loadAll();
            $scope.getCustomTasks();
        });
        $scope.$on('$destroy', loadAllUnsubscribe);

        $scope.deleteFromList = function (row) {
            var index = $scope.gridOptions.data.indexOf(row);
            $scope.gridOptions.data.splice(index, 1);
        }

        $scope.deleteSelectedFromList = function (row) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            angular.forEach(selectedRows, function(data){
                var index = $scope.gridOptions.data.indexOf(data);
                $scope.gridOptions.data.splice(index, 1);
            })
        }

        vm.customNodeCopy = null;
        $scope.loadByCustomNode = function(customNode){
            if(customNode){
                if(vm.customNodeCopy && vm.customNodeCopy.id == customNode.id){
                    clearCustomNoe();
                    return;
                }
                $scope.viewScope = "self";
                vm.customNodeCopy = customNode;
                $scope.gridApi.core.clearAllFilters();
                if($rootScope.choosedRole != customNode.choosedRole){
                    $rootScope.choosedRole = customNode.choosedRole;
                    vm.searchQuery = null;
                    vm.currentSearch = null;
                }
                $scope.workType = null;
                setWorkType(customNode.workType);
            }
            else {
                clearCustomNoe();
            }
        }
        function setWorkType (work) {
            if($scope.workType != work){
                $scope.gridOptions.data = [];
            }
            // vm.customNodeCopy = null;
            $scope.workType = work;
            clear();
        }

        function clearCustomNoe(){
            if(vm.customNodeCopy){
                vm.customNodeCopy = null;
            }
            loadAll();
        }

        var memoCopy;
        $scope.copyMemo = function(guide) {
            memoCopy = angular.copy(guide.finMemo);
        }

        $scope.saveMemo = function(guide) {
            if(memoCopy != guide.finMemo){
                Guide.upateMemo({
                    memoString: guide.finMemo,
                    type: "TABLE_FIN",
                    companyId: guide.companyId,
                    guideId: guide.id
                }, function(){
                    layer.msg($translate.instant('global.save_success'));
                }, function(){
                    layer.msg($translate.instant('global.save_fail'));
                });
            }
        }
        $scope.finFilter = function(item) {
            return item.authorities.indexOf("ROLE_ACCOUNT") !== -1;
        };
        $scope.updateFin = function(guide, item) {
            if(guide.finId == item.id){
                return;
            }
            $scope.isSaving = true;
            Guide.updateFin({
                guideId: guide.id,
                userId: item.id,
                userName: item.name
            }, function() {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        };
        var onSaveError = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        };
        $scope.blRelease = function(guide) {
            var guideDTO = {};
            guideDTO.id = guide.id;
            guideDTO.mblOnly = false;
            if (guide.hblReleasedTime) {
                guideDTO.hblReleasedTime = null;
                guideDTO.status = "HblHold";
            } else {
                guideDTO.hblReleasedTime = new Date();
                guideDTO.status = "HblReleased";
            }
            $scope.isSaving = true;
            Guide.setRelease(guideDTO, function(result) {
                guide.status = result.status;
                guide.hblReleasedTime = result.hblReleasedTime;
                guide.lastModifiedTime = result.lastModifiedTime;
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        }
        $scope.mblRelease = function(guide) {
            var guideDTO = {};
            guideDTO.id = guide.id;
            guideDTO.mblOnly = true;
            if (guide.mblReleasedTime) {
                guideDTO.mblReleasedTime = null;
                guideDTO.status = "MblHold";
            } else {
                guideDTO.mblReleasedTime = new Date();
                guideDTO.status = "MblReleased";
            }
            $scope.isSaving = true;
            Guide.setRelease(guideDTO, function(result) {
                guide.status = result.status;
                guide.mblReleasedTime = result.mblReleasedTime;
                guide.lastModifiedTime = result.lastModifiedTime;
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        }

        $scope.lockRateOne = function(guide, rateLock) {
            if (rateLock && $rootScope.account.authorities.indexOf("AU_RATE_LOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_LOCK');
                return;
            }
            if (!rateLock && $rootScope.account.authorities.indexOf("AU_RATE_UNLOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_UNLOCK');
                return;
            }
            $scope.isSaving = true;
            Guide.lockRateOne({
                guideId: guide.id,
                ratesLocked: rateLock
            }, function(result) {
                $scope.isSaving = false;
                guide.ratesLocked = rateLock;
                layer.msg($translate.instant('global.messages.submitted'));
                if(result.masterId == 0){
                    layer.confirm($translate.instant('autopsApp.guide.lockSubGuidesAllTips'), {
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                        }, function(index) {
                            layer.msg($translate.instant('global.isSavingMsg'), {
                              shade: 0.3,
                              time: 200000
                            });
                            Guide.lockSubGuidesAll({
                                masterId: result.id,
                                bValue: result.ratesLocked,
                                type: "rate"
                            }, function(result) {
                                layer.closeAll();
                                layer.msg($translate.instant('global.save_success'));
                            })
                            layer.close(index);
                        }, function() {
                    });
                }
            }, onSaveError);
        };
        $scope.lockGuideOne = function(guide) {
            if (!guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            if (guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                return;
            }
            if (!guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                return;
            };

            $scope.isSaving = true;
            Guide.setIsClosed({id: guide.id}, function(result) {
                $scope.isSaving = false;
                guide.isClosed = result.isClosed;
                guide.status = result.status;
                layer.msg($translate.instant('global.messages.submitted'));
                if(result.masterId == 0){
                    layer.confirm($translate.instant('autopsApp.guide.lockSubGuidesAllTips'), {
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                        }, function(index) {
                            layer.msg($translate.instant('global.isSavingMsg'), {
                              shade: 0.3,
                              time: 200000
                            });
                            Guide.lockSubGuidesAll({
                                masterId: result.id,
                                bValue: result.isClosed,
                                type: "guide"
                            }, function(result) {
                                layer.closeAll();
                                layer.msg($translate.instant('global.save_success'));
                            })
                            layer.close(index);
                        }, function() {
                    });
                }
            }, onSaveError);
        }

        var onReleaseAllSuccess = function(result) {
            var msgToShow = "";
            if (result && result.length > 0) {
                for (var i = 0; i < result.length; i++) {
                    for (var j = 0; j < $scope.gridOptions.data.length; j++) {
                        if ($scope.gridOptions.data[j].id == result[i].id) {
                            angular.copy(result[i], $scope.gridOptions.data[j]);
                        }
                    }
                }
                msgToShow = result.length.toString();
                angular.forEach($scope.gridOptions.data, function(data) {
                    data.encodeId = WinAES.encode(data.id.toString())
                })
            } else {
                msgToShow = "0";
            }
            msgToShow = msgToShow + $translate.instant('autopsApp.guide.home.updatedNum');
            $scope.isSaving = false;
            layer.msg(msgToShow);
        };

        $scope.releaseAll = function(receivedOnly) {
            var guidesToRelase = $scope.gridApi.selection.getSelectedRows();
            if(guidesToRelase.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            for (var i = guidesToRelase.length - 1; i >= 0; i--) {
                if (guidesToRelase[i].hblReleasedTime && guidesToRelase[i].mblReleasedTime) {
                    guidesToRelase.splice(i, 1);
                }
            }
            if (guidesToRelase.length == 0) {
                var msgToShow = "0" + $translate.instant('autopsApp.guide.home.updatedNum');
                layer.msg(msgToShow);
                return;
            }
            $scope.isSaving = true;

            Guide.releaseGuides({
                receivedOnly: receivedOnly
            }, guidesToRelase, onReleaseAllSuccess, onSaveError);
        };
        $scope.lockRatesAll = function(targetValue) {
            if (targetValue && $rootScope.account.authorities.indexOf("AU_RATE_LOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_LOCK');
                return;
            }
            if (!targetValue && $rootScope.account.authorities.indexOf("AU_RATE_UNLOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_UNLOCK');
                return;
            }
            var guidesToRelase = $scope.gridApi.selection.getSelectedRows();
            if(guidesToRelase.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }

            for (var i = guidesToRelase.length - 1; i >= 0; i--) {
                if (targetValue && guidesToRelase[i].ratesLocked) {
                    guidesToRelase.splice(i, 1);
                }
                else if (!targetValue && !guidesToRelase[i].ratesLocked) {
                    guidesToRelase.splice(i, 1);
                }
            }
            if (guidesToRelase.length == 0) {
                var msgToShow = "0" + $translate.instant('autopsApp.guide.home.updatedNum');
                layer.msg(msgToShow);
                return;
            }
            $scope.isSaving = true;
            Guide.lockRatesAll({targetValue: targetValue}, guidesToRelase, onReleaseAllSuccess, onSaveError);
        };

        $scope.lockGuidesAll = function(targetValue) {
            if (targetValue && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            if (!targetValue && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                return;
            }
            var guidesToRelase = $scope.gridApi.selection.getSelectedRows();
            if(guidesToRelase.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }

            for (var i = guidesToRelase.length - 1; i >= 0; i--) {
                if (targetValue && guidesToRelase[i].isClosed) {
                    guidesToRelase.splice(i, 1);
                }
                else if (!targetValue && !guidesToRelase[i].isClosed) {
                    guidesToRelase.splice(i, 1);
                }
            }
            if (guidesToRelase.length == 0) {
                var msgToShow = "0" + $translate.instant('autopsApp.guide.home.updatedNum');
                layer.msg(msgToShow);
                return;
            }
            $scope.isSaving = true;
            Guide.lockGuidesAll({targetValue: targetValue}, guidesToRelase, onReleaseAllSuccess, onSaveError);
        };

        $scope.blReleaseReport = function(apiName) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var loadIndex = layer.load(1, {shade: 0.3});
            if(apiName == "jdxtBlReleaseReport"){
                OpsFiles.jdxtBlReleaseReport(selectedRows, function(result) {
                    layer.close(loadIndex);
                    $rootScope.DOWNLOADEXCEL(result, "shipments_report.xlsx");
                })
            }
            else {
                OpsFiles.blReleaseReport(selectedRows, function(result) {
                    layer.close(loadIndex);
                    $rootScope.DOWNLOADEXCEL(result, "shipments_report.xlsx");
                })
            }
        }
        vm.auditResults;
        var updateSelectedTtl = function (argument) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                return;
            }
            Guide.updateAuditInfo({companyId: $rootScope.account.companyId}, selectedRows, function(result){
                vm.auditResults = result;
            })
        }
        $scope.auditGuide = function(guide) {
            var entity = {};
            entity.guide = angular.copy(guide);
            entity.isFin = true;
            $rootScope.VENDER_AUDIT_POP(entity);
        }
    }

})();
