(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('ExpressParcel', ExpressParcel);

    ExpressParcel.$inject = ['$resource', 'DateUtils'];

    function ExpressParcel ($resource, DateUtils) {
        var dateFds = ["eta", "cargoReadyDate", "etd"];
        var timeFds = ["createdTime", "dnSentTime", "paymentPaidTime", "paymentReceivedTime", "otherTimeOne", "otherTimeTwo", "otherTimeThree", 
            "customReleaseTime", "podTime", "costInputTime", "deliveryTime", "orderTime", "lastModifiedTime", "deliveryAppointmentTime", "pickupTime"];
        function initParcelResponse(data){
            if (!data){return;}   
            data = angular.fromJson(data);
            DateUtils.convertObjDateAndTimeFromServer(data, dateFds, timeFds);
            return data;
        }
        function initParcelRequest (data) {
            data = angular.copy(data);
            data.eta = DateUtils.convertLocalDateToServer(data.eta);
            data.cargoReadyDate = DateUtils.convertLocalDateToServer(data.cargoReadyDate);
            data.etd = DateUtils.convertLocalDateToServer(data.etd);
            return angular.toJson(data);
        }
        return $resource('api/express-parcels/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },            
            'eltPostParcel': {
                url:'api/express-parcels/eltPostParcel',
                method: 'POST',
                transformResponse: function (data) {
                    if(data){
                        data = angular.fromJson(data);
                    }                    
                    return data;
                }
            },
            'amendEtd': {
                method:'POST',
                url:'api/express-parcels/amendEtd',
                transformRequest: function (data) {
                   return initParcelRequest(data);
                }
            },
            'dismissPickingTicketBatch': {
                method:'POST',
                url:'api/express-parcels/dismissPickingTicketBatch'
            },
            'removeFromPickingTicket': {
                method:'POST',
                url:'api/express-parcels/removeFromPickingTicket'
            },
            'amassAddLabelCost': {
                method:'GET',
                url:'api/express-parcels/amassAddLabelCost'
            },            
            'orderAcknowledgement': {
                url:'api/express-parcels/orderAcknowledgement',
                method: 'POST',
                isArray: true
            },
            'trackingAndUpdateParcel': {
                url:'api/express-parcels/trackingAndUpdateParcel',
                method: 'GET'
            },
            'getZoneName': {
                url:'api/express-parcels/getZoneName',
                method: 'POST'
            },            
            'trialCost': {
                url:'api/express-parcels/trialCost',
                method: 'POST',
                isArray: true
            },
            'pushOrders': {
                url:'api/express-parcels/pushOrders',
                method: 'POST',
                isArray: true
            },
            'lockRateOne': {
                url:'api/express-parcels/lockRateOne',
                method: 'GET'
            },
            'imageAutomaticProcessing': {
                url:'api/express-parcels/imageAutomaticProcessing',
                method: 'GET'
            },            
            'pushLabel': {
                url:'api/express-parcels/pushLabel',
                method: 'POST',
                isArray: true
            },
            'pushOneLabel': {
                url:'api/express-parcels/pushOneLabel',
                method: 'POST'
            },
            'getFilterPage': {
                url:'api/express-parcels/getFilterPage',
                method: 'POST',
                isArray: true
            },
            'getEdiOrders': {
                url:'api/express-parcels/getEdiOrders',
                method: 'POST'
            },       
            'getZipCode': {
                url:'api/express-parcels/getZipCode',
                method: 'POST'
            },  
            'getCityState': {
                url:'api/express-parcels/getCityState',
                method: 'POST'
            },  
            'getFbaZipCode': {
                url:'api/express-parcels/getFbaZipCode',
                method: 'POST'
            },  
            'pushInventory': {
                url:'api/express-parcels/pushInventory',
                method: 'POST'
            },
            'getFbaCityState': {
                url:'api/express-parcels/getFbaCityState',
                method: 'POST'
            },
            'checkChannel': {
                url:'api/express-parcels/checkChannel',
                method: 'POST',
                transformRequest: function (data) {
                   return initParcelRequest(data);
                }
            },
            'createReturnOrder': {
                url:'api/express-parcels/createReturnOrder',
                method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.poDate = DateUtils.convertLocalDateFromServer(data.poDate);
                        data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                        data.expectedWarehousingTime = DateUtils.convertDateTimeFromServer(data.expectedWarehousingTime);
                    }
                    return data;
                }
            },   
            'zipOssFiles': {
                method: 'POST',
                url:'api/express-parcels/zipOssFiles',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getAllLabelRates': {
                url:'api/express-parcels/getAllLabelRates',
                method: 'POST'
            },                      
            'inventoryCompare': {
                url:'api/express-parcels/inventoryCompare',
                method: 'POST',
                isArray: true
            },
            'updatePushOrdersStatus': {
                url:'api/express-parcels/updatePushOrdersStatus',
                method: 'POST',
                isArray: true
            },
            'checkPoNum': {
                method:'GET',
                url:'api/express-parcels/checkPoNumRepeat'
            },
            'getServicesList': {
                method:'GET',
                url:'api/express-parcels/getServicesList',
                isArray: true
            },            
            'getRapidInParcel': {
                method:'GET',
                url:'api/express-parcels/getRapidInParcel',
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'fillByCommodityDatabase': {
                method:'GET',
                url:'api/express-parcels/fillByCommodityDatabase',
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },            
            'parcelRegistIn': {
                method:'PUT',
                url:'api/express-parcels/parcelRegistIn',
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'parcelPackageSave': {
                method:'PUT',
                url:'api/express-parcels/parcelPackageSave',
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'addAutoCharges': {
                method:'POST',
                url:'api/express-parcels/addAutoCharges',
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'allAutoCharges': {
                method:'POST',
                url:'api/express-parcels/allAutoCharges'
            },            
            'delPush': {
                url:'api/express-parcels/delPush',
                method: 'POST',
                isArray: true
            },        
            'checkPushEdiAvailable': {
                url:'api/express-parcels/checkPushEdiAvailable',
                method: 'POST'
            },
            'nextslsLabel': {
                url:'api/apiConnect/nextslsLabel',
                method: 'POST',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'autoCheapestChannel': {
                url:'api/express-parcels/autoCheapestChannel',
                method: 'POST',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },            
            'shipNoice': {
                url:'api/express-parcels/shipNoice',
                method: 'POST',
                isArray: true
            },
            'sendInvoice': {
                url:'api/express-parcels/sendInvoice',
                method: 'POST',
                isArray: true
            },
            'deleteFulfillment': {
                url:'api/express-parcels/deleteFulfillment',
                method: 'POST',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'getGuideAndRatesByJobNum': {
                method:'GET',
                url:'api/express-parcels/getGuideAndRatesByJobNum',
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },  
            'setVoid': {
                method: 'GET', 
                url:'api/express-parcels/setVoid'
            },   
            'setStockout': {
                method: 'GET', 
                url:'api/express-parcels/setStockout'
            },
            'setCancelShipment': {
                method: 'GET', 
                url:'api/express-parcels/setCancelShipment'
            },
            'workingPop': {
                method: 'POST', 
                url:'api/express-parcels/workingPop'
            },               
            'getLastOrder': {
                method: 'PUT', 
                url:'api/express-parcels/getLastOrder'
            },               
            'getGuideRates': {
                method: 'GET', 
                url:'api/express-parcels/getGuideRates',
                isArray: true
            },      
            'searchByNums': {
                method: 'PUT', 
                url:'api/express-parcels/searchByNums',
                isArray: true
            },
            'searchByNoPrefix': {
                method: 'PUT', 
                url:'api/express-parcels/searchByNoPrefix'
            },
            'checkJobNum': {
                method:'GET',
                url:'api/express-parcels/checkJobNumRepeat'
            },
            'queryVirtual': {
                method: 'GET', 
                url:'api/express-parcels/queryVirtual',
                isArray: true
            },            
            'shopifyorders': {
                method: 'PUT', 
                url:'api/express-parcels/shopifyorders'
            },
            'getSftpAmazonOrders': {
                method: 'PUT', 
                url:'api/express-parcels/getSftpAmazonOrders'
            },
            'bindedSkuCheck': {
                method: 'PUT', 
                url:'api/express-parcels/bindedSkuCheck'
            },
            'rePickingUp': {
                method: 'POST', 
                url:'api/express-parcels/rePickingUp',
                isArray: true
            },            
            'importOrders': {
                method: 'PUT', 
                url:'api/express-parcels/importOrders',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getMfycangOrders': {
                method: 'PUT', 
                url:'api/express-parcels/getMfycangOrders'
            },
            'getOverstockOrders': {
                method: 'PUT', 
                url:'api/express-parcels/getOverstockOrders'
            },            
            'walmartOrders': {
                method: 'PUT', 
                url:'api/express-parcels/walmartOrders'
            },
            'ebayOrders': {
                method: 'PUT', 
                url:'api/express-parcels/ebayOrders'
            },
            'amazonOrders': {
                method: 'PUT', 
                url:'api/express-parcels/amazonOrders'
            },
            'getFtpOrders': {
                method: 'PUT', 
                url:'api/express-parcels/getFtpOrders'
            },
            'getToken': {
                method: 'PUT', 
                url:'api/express-parcels/getToken'
            }, 
            'setIsClosed': {
                method: 'GET', 
                url:'api/express-parcels/setIsClosed'
            },
            'checkOrders': {
                method: 'PUT',
                url:'api/express-parcels/checkOrders',
                isArray: true
            },
            'saveGuideAndRates': {
                method:'POST',
                url:'api/express-parcels/saveGuideAndRates',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etd = DateUtils.convertLocalDateToServer(data.etd);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'getCopyGuide': {
                method: 'GET',
                url:'api/express-parcels/getCopyGuide',
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            }, 
            'byCtnsChange': {
                method: 'GET',
                url:'api/express-parcels/byCtnsChange'
            },
            'authorize': {
                method:'PUT',
                url:'api/express-parcels/authorize',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'fedexHomeDeliveryCheck': {
                method: 'POST',
                url:'api/express-parcels/fedexHomeDeliveryCheck',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },            
            'getReturnLabel': {
                url:'api/express-parcels/getReturnLabel',
                method: 'POST',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'getShipperByTrackingNum': {
                url:'api/express-parcels/getShipperByTrackingNum',
                method: 'GET'
            },
            'updateByChannel': {
                url:'api/express-parcels/updateByChannel',
                method: 'POST',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'getGuideAndRates': {
                method:'GET',
                url:'api/express-parcels/getGuideAndRates',
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },   
            'combine': {
                method:'PUT',
                url:'api/consol/combine',
                transformResponse: function (data) {  
                    if (!data){return;}           
                    data = angular.fromJson(data);
                    data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                    return data;
                }
            },
            'combineCost': {
                method:'GET',
                url:'api/consol/combineCost'
            },
            'delAllCombineCost': { 
                method: 'GET', 
                url:'api/consol/delAllCombineCost'
            },            
            'reCombineCost': { 
                method: 'PUT', 
                url:'api/consol/reCombineCost'
            },
            'getHblsProfit':{
                method:'GET',
                url:'api/stats/getHblsProfit',
            },
            'synExpressParcel': {
                method:'PUT',
                url:'api/express-parcels/synExpressParcel',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'getWorkload': { 
                method: 'PUT', 
                url:'api/express-parcels/workLoad'
            },
            'unbind': { 
                method: 'GET', 
                url:'api/express-parcels/unbind'
            },
            'upateMemo': { 
                method: 'GET', 
                url:'api/express-parcels/upateMemo'
            },
            'shareSelling': { 
                method: 'POST', 
                url:'api/consol/shareSelling',
                isArray:true
            },  
            'setConsolidated': {
                method: 'GET',
                url:'api/consol/setConsolidated'
            },
            'cargoIsOutChange': { 
                method: 'GET', 
                url:'api/express-parcels/cargoIsOutChange'
            },            
            'getLabelRates':{
                method: 'POST',
                url:'api/express-parcels/getLabelRates'
            },
            'getLtlRates':{
                method: 'POST',
                url:'api/express-parcels/getLtlRates'
            },
            'getLabelApiRequestBody':{
                method: 'POST',
                url:'api/express-parcels/getLabelApiRequestBody'
            },
            'getPushApiRequestBody':{
                method: 'POST',
                url:'api/express-parcels/getPushApiRequestBody'
            },
            'getGuideLabelApiRequestBody':{
                method: 'POST',
                url:'api/express-parcels/getGuideLabelApiRequestBody'
            },            
            'getGuideLabelRates':{
                method: 'POST',
                url:'api/express-parcels/getGuideLabelRates'
            },
            'updateStatusBatch':{
                method: 'PUT',
                url:'api/express-parcels/updateStatusBatch',
                isArray:true
            },
            'submitAll':{
                method: 'POST',
                url:'api/express-parcels/submitAll',
                isArray:true
            },
            'delAll':{
                method: 'PUT',
                url:'api/express-parcels/delAll',
                isArray:true
            },
            'lockAndChargeAll':{
                method: 'PUT',
                url:'api/express-parcels/lockAndChargeAll'
            },
            'unlockAndChargeAll':{
                method: 'PUT',
                url:'api/express-parcels/unlockAndChargeAll'
            },
            'setStatus': {
                method:'GET',
                url:'api/express-parcels/setStatus'         
            },  
            'getParcelPuLabel':{
                method: 'POST',
                url:'api/express-parcels/getParcelPuLabel',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'printLabel':{
                method: 'POST',
                url:'api/express-parcels/printLabel',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'printParcelPkLabel':{
                method: 'POST',
                url:'api/express-parcels/printParcelPkLabel',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'printOwnLabels':{
                method: 'POST',
                url:'api/express-parcels/printOwnLabels',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },            
            'mergePdfFiles':{
                method: 'POST',
                url:'api/express-parcels/mergePdfFiles',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'printLabels':{
                method: 'POST',
                url:'api/express-parcels/printLabels',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'printPickingTicketAndLabel':{
                method: 'POST',
                url:'api/express-parcels/printPickingTicketAndLabel',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'autoPrintLabel':{
                method: 'GET',
                url:'api/express-parcels/autoPrintLabel',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'generateLabels':{
                method: 'POST',
                url:'api/express-parcels/generateLabels'
            }, 
            'generateOneLabel':{
                method: 'GET',
                url:'api/express-parcels/generateOneLabel'
            },
            'checkChannelValid':{
                method: 'POST',
                url:'api/express-parcels/checkChannelValid'
            },            
            'setSubmited': {
                method: 'GET',
                url:'api/express-parcels/setSubmited',
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },  
            'getCloseouts':{
                method: 'POST',
                url:'api/express-parcels/getCloseouts',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'voidLabel':{
                method: 'POST',
                url:'api/express-parcels/voidLabel',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                }
            },
            'getLabel':{
                method: 'POST',
                url:'api/express-parcels/getLabel',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'getLtlLabel':{
                method: 'POST',
                url:'api/ups/groundFreightShipLabel',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'advancedSearch': {
                method:'PUT',
                url:'api/express-parcels/advancedSearch',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    data.etaFM = DateUtils.convertLocalDateToServer(data.etaFM);
                    data.etaTO = DateUtils.convertLocalDateToServer(data.etaTO);
                    return angular.toJson(data);
                },
                transformResponse: function (resultList) {
                    if (!resultList){return;}   
                    resultList = angular.fromJson(resultList);
                    angular.forEach(resultList, function(data){
                        DateUtils.convertObjDateAndTimeFromServer(data, dateFds, timeFds);
                    })
                    return resultList;
                }
            },
            'refreshHblsEtd': { 
                method: 'PUT', 
                url:'api/consol/refreshHblsEtd'
            },
            'getConsoleShipments': { 
                method: 'GET', 
                url:'api/consol/getConsoleShipments',
                isArray: true
            },
            'checkBeforeRemove': { 
                method: 'PUT', 
                url:'api/consol/checkBeforeRemove'
            },
            'addGuidesToMaster': { 
                method: 'POST', 
                url:'api/consol/addGuidesToMaster',
                isArray: true
            },
            'addParcelToMaster': { 
                method: 'POST', 
                url:'api/consol/addParcelToMaster',
                isArray: true
            },
            'addToPickingTicket': { 
                method: 'POST', 
                url:'api/express-parcels/addToPickingTicket'
            },
            'getAllPayers': { 
                method: 'GET', 
                url:'api/consol/getAllPayers',
                isArray: true
            },
            'addToParcelConsol': {
                method:'GET',
                url:'api/consol/addToParcelConsol'
            },
            'addToGuideConsol': {
                method:'GET',
                url:'api/consol/addToGuideConsol'
            },
            'removeMaster': {
                method:'POST',
                url:'api/consol/removeMaster'
            },    
            'removeFromConsol': {
                method:'GET',
                url:'api/consol/removeFromConsol'
            },     
            'removeSelectedFromConsol': {
                method:'POST',
                url:'api/consol/removeSelectedFromConsol'
            },    
            'getSplitShipments': {
                method: 'GET',
                url:'api/consol/getSplitShipments',
                isArray: true
            },
            'getSubShipments': {
                method: 'GET',
                url:'api/consol/getSubShipments',
                isArray: true
            },
            'shareCharges': {
                method: 'PUT',
                url:'api/consol/shareCharges'
            },
            'addToConsol': {
                method: 'POST',
                url:'api/consol/addToConsol'
            },
            'addListToConsol': {
                method: 'POST',
                url:'api/consol/addListToConsol'
            },
            'getUnshippedMbls': {
                method: 'GET',
                url:'api/consol/getUnshippedMbls',
                isArray: true
            },
            'getUnconsolidatedMshipment': {
                method: 'GET',
                url:'api/consol/getUnconsolidatedMshipment',
                isArray: true
            },
            'getUnshippedHbls': { 
                method: 'PUT', 
                url:'api/consol/getUnshippedHbls',
                isArray: true
            },
            'reShareCharges': { 
                method: 'PUT', 
                url:'api/consol/reShareCharges'
            }, 
            'delAllShareCharges': { 
                method: 'GET', 
                url:'api/consol/delAllShareCharges'
            }, 
            'removeFromPicking': { 
                method: 'DELETE', 
                url:'api/express-parcels/removeFromPicking'
            },
            'dismissPickingTicket': { 
                method: 'DELETE', 
                url:'api/express-parcels/dismissPickingTicket'
            },
            'confirmOut': { 
                method: 'POST', 
                url:'api/express-parcels/confirmOut'
            },
            'generatePickingTicket':{
                method:'POST',
                url:'api/express-parcels/generatePickingTicket',
                isArray:false
            },
            'getPickingTicket':{
                method:'GET',
                url:'api/express-parcels/getPickingTicket',
                isArray:false
            },
            'getPickingWmsIn':{
                method:'GET',
                url:'api/express-parcels/getPickingWmsIn',
                isArray:true
            },
            'remoteCheck':{
                method:'GET',
                url:'api/trackingMore/remoteCheck',
                isArray:true
            },
            'addressCheck':{
                method:'POST',
                url:'api/express-parcels/addressCheck'
            },
            'addressCheckGuide':{
                method:'POST',
                url:'api/express-parcels/addressCheckGuide'
            },            
            'printPickingTicket':{
                method:'POST',
                url:'api/express-parcels/printPickingTicket',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'printBolsBatch':{
                method:'POST',
                url:'api/express-parcels/printBolsBatch',
                responseType: 'arraybuffer',
                isArray:false,
                transformResponse: function (data) {
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            },
            'createTmsByGuide': {
                method: 'PUT',
                url:'api/express-parcels/createTmsByGuide'
            },
            'createTmsByGuideDTOs': {
                method: 'PUT',
                url:'api/express-parcels/createTmsByGuideDTOs'
            },
            'searchByNum': {
                method:'GET',
                url:'api/express-parcels/searchByNum',
                isArray:true
            },
            'changeParcelBooleanField': {
                method:'GET',
                url:'api/express-parcels/changeParcelBooleanField'
            },            
            'printAllBol':{
                method:'POST',
                url:'api/express-parcels/printAllBol',
                responseType: 'arraybuffer',
                isArray:false,
                transformResponse: function (data) {
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            },
            'updateSelectedTtl': { 
                method: 'POST', 
                url:'api/express-parcels/updateSelectedTtl'
            },
            'sendThundEbayOrders': {
                url:'api/express-parcels/sendThundEbayOrders',
                method: 'POST',
                isArray: true
            },
            'fillSuggestedClass': {
                url:'api/express-parcels/fillSuggestedClass',
                method: 'POST'
            },
            'parcelAutoLockCloseTest': {
                method:'GET',
                url:'api/apiErp/parcelAutoLockCloseTest'
            },
            'editOtherTimeThree': {
                method: 'GET',
                url:'api/express-parcels/editOtherTimeThree'
            },       
            'switchJobCompany': {
                method: 'GET',
                url: 'api/express-parcels/switchJobCompany'
            },
            'exportLabelsZip':{
                method:'POST',
                url:'api/express-parcels/exportLabelsZip',
                responseType: 'arraybuffer',
                isArray:false,
                transformResponse: function (data) {
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            },
            'getLabelInfo':{
                method: 'POST',
                url:'api/express-parcels/getLabelInfo',
                transformRequest: function (data) {
                    return initParcelRequest(data);
                },
                transformResponse: function (data) {
                    return initParcelResponse(data);
                }
            },
            'getLabelsInfo':{
                method: 'POST',
                url:'api/express-parcels/getLabelsInfo'
            },
            'checkOkForAutoTrack': {
                method: 'GET',
                url:'api/express-parcels/checkOkForAutoTrack'
            }
        });
    }
})();
