(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AutoCheckDialogController', AutoCheckDialogController);

    AutoCheckDialogController.$inject = ['$scope', '$rootScope', '$translate', '$http', '$timeout', '$state', '$uibModal', 'previousState', 'Principal',
    'AutoCheck', 'DateUtils', 'OpsVender', 'entity', 'DataUtils', 'OpsFiles', 'JhiSignature', 'uiGridConstants', 'GridState', 'Base64'];

    function AutoCheckDialogController($scope, $rootScope, $translate, $http, $timeout, $state, $uibModal, previousState, Principal,
        AutoCheck, DateUtils, OpsVender, entity, DataUtils, OpsFiles, JhiSignature, uiGridConstants, GridState, Base64) {

        $scope.autoCheck = entity;
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                $rootScope.loadCurrencys();
            });
        }
        else {
            $rootScope.loadCurrencys();
        }

        var vm = this;
        var ratesTempList = [];
        var ratesRespCopy = [];
        var filterTimer, gridCopy;
        vm.previousState = previousState.name;

        vm.save = save;
        // vm.goBack = goBack;
        vm.autoCheckGet = autoCheckGet;
        vm.getRatesByRefNum = getRatesByRefNum;
        vm.filterPop = filterPop;
        vm.setMcBillNum = setMcBillNum;

        $scope.files = [];

        $scope.setFdValue= function(fd, expImp) {
            if($scope.autoCheck[fd] != expImp){
                $scope.autoCheck[fd] = expImp;
                if(fd == 'exBilled'){
                    getUnBilledAmt();
                }
            }
        }

        $timeout(function (){
            initAutoCheck();
            autoCheckGet();
            $rootScope.LOADALLVENDERS();
            getUnBilledAmt();
        });

        vm.showSideBar = false;
        vm.unBilledAmt = null;
        vm.unbilledResult = {};

        vm.getUnbilledResult = getUnbilledResult;
        vm.showSideBarCheck = showSideBarCheck;
        vm.getUnBilledAmt = getUnBilledAmt;
        vm.choosedUnbilledPayer = choosedUnbilledPayer;
        function choosedUnbilledPayer(unbilledPayer) {
            var autoCheckCopy = angular.copy($scope.autoCheck);
            $scope.autoCheck = {};
            $scope.autoCheck.isPay = autoCheckCopy.isPay;
            $scope.autoCheck.dateFrom = autoCheckCopy.dateFrom;
            $scope.autoCheck.dateTo = autoCheckCopy.dateTo;
            $scope.autoCheck.byBillDate = autoCheckCopy.byBillDate;
            $scope.autoCheck.includePaid = autoCheckCopy.includePaid;
            $scope.autoCheck.exBilled = autoCheckCopy.exBilled;

            $scope.autoCheck.createdBy = $rootScope.account.id;
            $scope.autoCheck.creater = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            $scope.autoCheck.companyId = $rootScope.account.companyId;
            $scope.autoCheck.exOneChecked = true;
            $scope.autoCheck.isAuto = false;
            $scope.autoCheck.payer = unbilledPayer.venderName;
            $scope.autoCheck.payerVid = unbilledPayer.venderId;

            $scope.autoCheck.idSet = "";
            angular.forEach(unbilledPayer.guideRateIdSet, function(rateId){
                $scope.autoCheck.idSet += rateId + ";";
            })
            initAutoCheck();
            var loadIndex = layer.load(1, { shade: 0.3 });
            AutoCheck.autoCheckGet($scope.autoCheck, function(result) {
                layer.close(loadIndex);
                ratesRespCopy = angular.copy(result);
                $scope.typeChange();
            }, function(response) {
                layer.close(loadIndex);
                $scope.isSaving = false;
            });
        }

        function getUnBilledAmt() {
            if(!$scope.autoCheck.dateFrom || !$scope.autoCheck.dateTo || DateUtils.compareDate($scope.autoCheck.dateFrom, $scope.autoCheck.dateTo) > 0) {
                return;
            }
            AutoCheck.getUnBilledAmt($scope.autoCheck, function(result) {
                vm.unbilledResult = {};
                vm.unbilledResult.ttlAmt = result.value;
            });
        }
        function getUnbilledResult () {
            if(!$scope.autoCheck.dateFrom || !$scope.autoCheck.dateTo) {
                layer.msg($translate.instant('autopsApp.autoCheck.billDateNullAlert'));
                return;
            }
            if (DateUtils.compareDate($scope.autoCheck.dateFrom, $scope.autoCheck.dateTo) > 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            AutoCheck.getUnbilledResult($scope.autoCheck, function(result) {
                layer.closeAll();
                vm.unbilledResult = result;
                vm.showSideBar = true;
                var billType = getBillType();
                $rootScope.OPS_ALERT($translate.instant('autopsApp.autoCheck.unbilledResultAlert', {
                    billType: billType,
                    dateRange: DateUtils.convertLocalDateToServer($scope.autoCheck.dateFrom) + " _ " + DateUtils.convertLocalDateToServer($scope.autoCheck.dateTo),
                    ttlAmt: result.ttlAmt,
                    resultAmt: result.resultAmt
                }));
            }, $rootScope.ALERT_ERROR);
        }
        function getBillType () {
            var billType;
            if($scope.autoCheck.isPay == true){
                billType = $translate.instant('autopsApp.autoCheck.isDn');
            }
            else if($scope.autoCheck.isPay == false){
                billType = $translate.instant('autopsApp.autoCheck.isCn');
            }
            else {
                billType = $translate.instant('autopsApp.autoCheck.balance');
            }

            billType += " + " + $translate.instant('stats.stat.notBilled') + "(" + $translate.instant('autopsApp.autoCheck.orderOrMcBill') + ")";

            if($scope.autoCheck.includePaid){
                billType += " + " + $translate.instant('autopsApp.autoCheck.includePaidTrue');
            }
            else {
                billType += " + " + $translate.instant('autopsApp.autoCheck.includePaidFalse');
            }

            if($scope.autoCheck.exBilled == true){
                billType += " + " + $translate.instant('autopsApp.autoCheck.exBilled');
            }
            else if($scope.autoCheck.exBilled == false){
                billType += " + " + $translate.instant('autopsApp.autoCheck.billedOnly');
            }
            else {
                billType += " + " + $translate.instant('autopsApp.autoCheck.billedOnly') + "+" + $translate.instant('autopsApp.autoCheck.exBilled');
            }
            if($scope.autoCheck.byBillDate == null){
                billType += ", " + $translate.instant('autopsApp.autoCheck.byBizDate');
            }
            else if($scope.autoCheck.byBillDate){
                billType += ", " + $translate.instant('autopsApp.autoCheck.byBillDate');
            }
            else {
                billType += ", " + $translate.instant('autopsApp.autoCheck.byInputTime');
            }
            return billType;
        }

        function showSideBarCheck () {
            vm.showSideBar = vm.unbilledResult && vm.unbilledResult.unbilledPayerDTOList;
        }

        function autoCheckGet () {
            if($scope.autoCheck.id){
                var loadIndex = layer.load(1, {shade: 0.3});
                AutoCheck.autoCheckGet($scope.autoCheck, function(result) {
                    layer.close(loadIndex);
                    ratesRespCopy = angular.copy(result);
                    $scope.typeChange();
                }, function(response) {
                    layer.close(loadIndex);
                    $scope.isSaving = false;
                });
            }
        }
        $scope._onClientSelected = function(data) {
            $scope.autoCheck.payerVid = data.id;
            // angular.forEach($rootScope.OPSVENDERS, function(data){
            //     if(data.companyName && data.companyName.indexOf("MIKE") !== -1){
            //         alert(data.companyName);
            //     }
            // })
        }

        // $scope._onItemSelected = function(data) {
        //     $scope.autoCheck.itemId = data.id;
        // }

        $scope.billNumEnabled = false;
        function initAutoCheck () {
            if($scope.autoCheck.isPay == true){
                $scope.autoCheck.isCn = true;
                $scope.autoCheck.isDn = false;
                $scope.autoCheck.isDiff = false;
            }
            else if ($scope.autoCheck.isPay == false){
                $scope.autoCheck.isCn = false;
                $scope.autoCheck.isDn = true;
                $scope.autoCheck.isDiff = false;
            }
            else {
                $scope.autoCheck.isCn = false;
                $scope.autoCheck.isDn = false;
                $scope.autoCheck.isDiff = true;
            }
            if($scope.autoCheck.shipmentTypesSet){
                var shipmentTypes = $scope.autoCheck.shipmentTypesSet.split(";");
                $scope.autoCheck.shipmentTypes = [];
                angular.forEach(shipmentTypes, function(type){
                    if(type){
                        $scope.autoCheck.shipmentTypes.push(type);
                    }
                })
            }
            if($scope.autoCheck.billNum){
                $scope.billNumEnabled = true;
            }
            else {
                $scope.billNumEnabled = false;
            }
            if($scope.autoCheck.billNum && vm.unbilledResult
                && vm.unbilledResult.unbilledPayerDTOList && vm.unbilledResult.unbilledPayerDTOList.length > 0){
                angular.forEach(vm.unbilledResult.unbilledPayerDTOList, function(unbilledPayer){
                    if(unbilledPayer.guideRateIdSet.length > 0 && unbilledPayer.venderId == $scope.autoCheck.payerVid){
                        for(var i = unbilledPayer.guideRateIdSet.length - 1; i >= 0; i--){
                            if($scope.autoCheck.idSet.indexOf(unbilledPayer.guideRateIdSet[i]) != -1){
                                unbilledPayer.guideRateIdSet.splice(i, 1);
                            }
                        }
                    }
                });
            }
        }

        $scope.setIsPay = function (isPay) {
            if(vm.locked){
                return;
            }
            $scope.autoCheck.isPay = isPay;
            initAutoCheck();
            getUnBilledAmt();
        }
        $scope.clearAll = function () {
            ratesRespCopy = [];
            ratesTempList = [];
            $scope.totalList = [];
            vm.locked = false;
            $scope.autoCheck.checkAmt = "";
            $scope.gridOptions.data = [];
            $translate.refresh();
        }

        var period = "";
        $scope.getContents = function() {
            if(!$scope.autoCheck.payerVid){
                layer.msg($translate.instant('autopsApp.autoCheck.payerNullAlert'));
                return;
            }

            if($scope.autoCheck.byBillDate == false){
                if(!$scope.autoCheck.timeFm || !$scope.autoCheck.timeTo) {
                    if(!$scope.autoCheck.billNum){
                        layer.msg($translate.instant('autopsApp.autoCheck.datePayerNullAlert'));
                        return;
                    }
                }
                if (DateUtils.compareDate($scope.autoCheck.timeFm, $scope.autoCheck.timeTo) > 0) {
                    layer.msg($translate.instant('global.messages.beginGreater'));
                    return;
                }
            }
            else {
                if(!$scope.autoCheck.dateFrom || !$scope.autoCheck.dateTo) {
                    if(!$scope.autoCheck.billNum){
                        layer.msg($translate.instant('autopsApp.autoCheck.datePayerNullAlert'));
                        return;
                    }
                }

                if (DateUtils.compareDate($scope.autoCheck.dateFrom, $scope.autoCheck.dateTo) > 0) {
                    layer.msg($translate.instant('global.messages.beginGreater'));
                    return;
                }
            }


            // if (DateUtils.getDaysDiff($scope.autoCheck.dateFrom, $scope.autoCheck.dateTo) > 365) {
            //     layer.msg($translate.instant('global.messages.etdPeriodAlert'));
            //     return;
            // }
            $scope.autoCheck.companyId = $rootScope.account.companyId;
            var shipmentTypesSet = "";
            angular.forEach($scope.autoCheck.shipmentTypes, function(type){
                if(type){
                    shipmentTypesSet = shipmentTypesSet + type + ";";
                }
            })
            $scope.autoCheck.shipmentTypesSet = shipmentTypesSet;
            var loadIndex = layer.load(1, {shade: 0.3});
            AutoCheck.monthlyCheck($scope.autoCheck, function(result) {
                layer.close(loadIndex);
                if (!result || result.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                ratesRespCopy = angular.copy(result);
                $scope.typeChange();
            }, $rootScope.ALERT_ERROR);
        }


        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.shipmentCount = 0;
        $scope.typeChange = function(type) {
            if(type && "ref" != type){
                if(!vm.locked){
                    return;
                }
                $scope.autoCheck[type] = !$scope.autoCheck[type];
            }
        	if(type == "isCol"){
                if($scope.autoCheck.isCol){
                    $scope.autoCheck.monthTtl = false;
                    $scope.autoCheck.jobTtl = false;
                    $scope.autoCheck.ref = "jobNum";
                }
                else {
                    delItemCols();
                }
                $scope.gridOptions.showColumnFooter = $scope.autoCheck.isCol;
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        	}
            else if (type == "jobTtl" && $scope.autoCheck.jobTtl){
                $scope.autoCheck.isCol = false;
                $scope.autoCheck.monthTtl = false;
                $scope.autoCheck.ref = null;
            }
            else if (type == "monthTtl" && $scope.autoCheck.monthTtl){
                $scope.autoCheck.isCol = false;
                $scope.autoCheck.jobTtl = false;
            }
            else if (type == "ref" && $scope.autoCheck.ref){
                $scope.autoCheck.isCol = false;
                $scope.autoCheck.jobTtl = false;
            }

            var jobNumSet = new Set();
            $scope.shipmentCount = 0;
            angular.forEach(ratesRespCopy, function(data){
                if(data.jobNum && !jobNumSet.has(data.jobNum)){
                    jobNumSet.add(data.jobNum);
                    $scope.shipmentCount++;
                }
            })

            // if(ratesRespCopy.length == 0){return;}
            ratesTempList = [];
            //币种和收付过滤
            if($scope.autoCheck.ref){
                var refs = new Set();
                var currencies = new Set();
                angular.forEach(ratesRespCopy, function(data){
                    refs.add(data[$scope.autoCheck.ref]);
                    currencies.add(data.currency);
                })
                refs.forEach(function(ref){
                    currencies.forEach(function(currency){//s.has(100);//返回bool结果，true表示有，false表示无
                        var ttl = 0;
                        var etd = null;
                        var deliveryDate = null;
                        var eta = null;
                        var newRate = null;
                        var baseAmtTtl = 0;
                        angular.forEach(ratesRespCopy, function(data){
                            if (data.currency == currency && data[$scope.autoCheck.ref] == ref && data.totalB){
                                if(!newRate){
                                    if($scope.autoCheck.ref == "mblNum" || $scope.autoCheck.ref == "soNum" || $scope.autoCheck.ref == "jobNum"
                                        || $scope.autoCheck.ref == "hblNum" || $scope.autoCheck.ref == "poNum"){
                                        newRate = angular.copy(data);
                                        newRate.price = null;
                                    }
                                    else {
                                        newRate = {};
                                    }
                                    newRate[$scope.autoCheck.ref] = ref;
                                }
                                ttl = ttl + data.totalB;
                                if(data.baseAmt){
                                    baseAmtTtl = baseAmtTtl + data.baseAmt;
                                }
                                etd = data.etd;
                                if(data.deliveryDate){
                                    deliveryDate = data.deliveryDate;
                                }
                                if(data.eta){
                                    eta = data.eta;
                                }
                                if((!newRate.jobNum || newRate.jobNum.indexOf(data.jobNum) == -1) && data.jobNum){
                                    newRate.jobNum = ((newRate.jobNum == null)? "":(newRate.jobNum + " ")) + data.jobNum;
                                }
                                if(!newRate.venderId){
                                    newRate.venderId = data.venderId;
                                }
                                if($scope.autoCheck.ref == "jobNum"){
                                    newRate.guideId = data.guideId;
                                    newRate.expressParcelId = data.expressParcelId;
                                    newRate.volumes = data.volumes;
                                    newRate.volStr = data.volStr;
                                    newRate.gwStr = data.gwStr;
                                    newRate.pkgStr = data.pkgStr;
                                    newRate.chargeableWeight = data.chargeableWeight;
                                }
                                if((!newRate.payer || newRate.payer.indexOf(data.payer) == -1) && data.payer){
                                    newRate.payer = ((newRate.payer == null)? "":(newRate.payer + " ")) + data.payer;
                                }
                                if((!newRate.dnNum || newRate.dnNum.indexOf(data.dnNum) == -1) && data.dnNum){
                                    newRate.dnNum = ((newRate.dnNum == null)? "":(newRate.dnNum + " ")) + data.dnNum;
                                }
                                if((!newRate.vsl || newRate.vsl.indexOf(data.vsl) == -1) && data.vsl){
                                    newRate.vsl = ((newRate.vsl == null)? "":(newRate.vsl + " ")) + data.vsl;
                                }
                                if((!newRate.voy || newRate.voy.indexOf(data.voy) == -1) && data.voy){
                                    newRate.voy = ((newRate.voy == null)? "":(newRate.voy + " ")) + data.voy;
                                }
                                if((!newRate.invoiceNum || newRate.invoiceNum.indexOf(data.invoiceNum) == -1) && data.invoiceNum){
                                    newRate.invoiceNum = ((newRate.invoiceNum == null)? "":(newRate.invoiceNum + " ")) + data.invoiceNum;
                                }
                                if((!newRate.billNum || newRate.billNum.indexOf(data.billNum) == -1) && data.billNum){
                                    newRate.billNum = ((newRate.billNum == null)? "":(newRate.billNum + " ")) + data.billNum;
                                }
                                if((!newRate.mblNum || newRate.mblNum.indexOf(data.mblNum) == -1) && data.mblNum){
                                    newRate.mblNum = ((newRate.mblNum == null)? "":(newRate.mblNum + " ")) + data.mblNum;
                                }
                                if((!newRate.soNum || newRate.soNum.indexOf(data.soNum) == -1) && data.soNum){
                                    newRate.soNum = ((newRate.soNum == null)? "":(newRate.soNum + " ")) + data.soNum;
                                }
                                if((!newRate.hblNum || newRate.hblNum.indexOf(data.hblNum) == -1) && data.hblNum){
                                    newRate.hblNum = ((newRate.hblNum == null)? "":(newRate.hblNum + " ")) + data.hblNum;
                                }
                                if((!newRate.productName || newRate.productName.indexOf(data.productName) == -1) && data.productName){
                                    newRate.productName = ((newRate.productName == null)? "":(newRate.productName + " ")) + data.productName;
                                }
                                if((!newRate.poNum || newRate.poNum.indexOf(data.poNum) == -1) && data.poNum){
                                    newRate.poNum = ((newRate.poNum == null)? "":(newRate.poNum + " ")) + data.poNum;
                                }
                                if((!newRate.salesName || newRate.salesName.indexOf(data.salesName) == -1) && data.salesName){
                                    newRate.salesName = ((newRate.salesName == null)? "":(newRate.salesName + " ")) + data.salesName;
                                }
                                if((!newRate.shipmentType || newRate.shipmentType.indexOf(data.shipmentType) == -1) && data.shipmentType){
                                    newRate.shipmentType = ((newRate.shipmentType == null)? "":(newRate.shipmentType + " ")) + data.shipmentType;
                                }
                                if((!newRate.polName || newRate.polName.indexOf(data.polName) == -1) && data.polName){
                                    newRate.polName = ((newRate.polName == null)? "":(newRate.polName + " ")) + data.polName;
                                }
                                if((!newRate.podName || newRate.podName.indexOf(data.podName) == -1) && data.podName){
                                    newRate.podName = ((newRate.podName == null)? "":(newRate.podName + " ")) + data.podName;
                                }
                                if((!newRate.destName || newRate.destName.indexOf(data.destName) == -1) && data.destName){
                                    newRate.destName = ((newRate.destName == null)? "":(newRate.destName + " ")) + data.destName;
                                }
                                if((!newRate.ctnNumsStr || newRate.ctnNumsStr.indexOf(data.ctnNumsStr) == -1) && data.ctnNumsStr){
                                    newRate.ctnNumsStr = ((newRate.ctnNumsStr == null)? "":(newRate.ctnNumsStr + " ")) + data.ctnNumsStr;
                                }
                                if((!newRate.fbaRef || newRate.fbaRef.indexOf(data.fbaRef) == -1) && data.fbaRef){
                                    newRate.fbaRef = ((newRate.fbaRef == null)? "":(newRate.fbaRef + " ")) + data.fbaRef;
                                }
                                if((!newRate.cargoName || newRate.cargoName.indexOf(data.cargoName) == -1) && data.cargoName){
                                    newRate.cargoName = ((newRate.cargoName == null)? "":(newRate.cargoName + " ")) + data.cargoName;
                                }
                                if((!newRate.remarks || newRate.remarks.indexOf(data.remarks) == -1) && data.remarks){
                                    newRate.remarks = ((newRate.remarks == null)? "":(newRate.remarks + " ")) + data.remarks;
                                }
                                if($scope.autoCheck.ref == "billNum" && data.billMakeDate){
                                    newRate.billMakeDate = data.billMakeDate;
                                }
                            }
                        })
                        if (ttl){
                            newRate.currency = currency;
                            newRate.item = "TOTAL";
                            newRate.totalB = DataUtils.round(ttl, 2);
                            newRate.total = DataUtils.round(ttl, 2);
                            newRate.baseAmt = DataUtils.round(baseAmtTtl, 2);
                            newRate.etd = etd;
                            newRate.deliveryDate = deliveryDate;
                            newRate.eta = eta;
                            newRate.id = 0;
                            ratesTempList.push(newRate);
                        }
                    });
                });
            }
            else {
                ratesTempList = angular.copy(ratesRespCopy);
            }
            // if(!ratesTempList.length || ratesTempList.length == 0){
            //    return;
            // }
            vm.locked = true;


            if($scope.autoCheck.isCol){
                getItemTtlList();
            }
            else {
                getTotalList();
            }
            var ratesListSize = ratesTempList.length;
            if (ratesListSize > 10000) {
                layer.confirm($translate.instant('global.dataSizeAlert', {amt: ratesListSize}), {
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    $scope.excelExport();
                    layer.close(index);
                }, function() {
                    ratesListShowFill();
                });
            }
            else {
                ratesListShowFill();
            }
        }
        var ratesListShowFill = function() {
            $scope.gridOptions.data = [];
            angular.forEach(ratesTempList, function(data) {
                $scope.gridOptions.data.push(data);
            })
            $translate.refresh();
            resize();
        }

        $scope.itemsList = [];
        $scope.itemTtl = {};
        var getItemTtlList = function() {
            $scope.itemsList = [];
            var items = new Set();
            var currencies = new Set();
            angular.forEach(ratesRespCopy, function(data){
                items.add(data.item);
                currencies.add(data.currency);
            });
            items.forEach(function(item){
                $scope.itemsList.push(item);
            });
            var itemTtl = 0;
            angular.forEach(ratesTempList, function(jobLine){
                var itemTtlList = [];
                items.forEach(function(item){
                    itemTtl = 0;
                    angular.forEach(ratesRespCopy, function(rate){
                        if (rate.id
                            && rate.jobNum == jobLine.jobNum
                            && rate.currency == jobLine.currency
                            && rate.item == item
                            && rate.totalB){
                            itemTtl = itemTtl + rate.totalB;
                        }
                    })
                    itemTtlList.push(DataUtils.round(itemTtl, 2));
                    jobLine[item] = itemTtl;
                });
                jobLine.itemTtlList = itemTtlList;

            })
            items.forEach(function(item){
                var itemTtlStr = "";
                currencies.forEach(function(cur){
                    var itemCurAmt = 0;
                    angular.forEach(ratesRespCopy, function(rate){
                        if (rate.id
                            && rate.currency == cur
                            && rate.item == item
                            && rate.totalB){
                            itemCurAmt = itemCurAmt + rate.totalB;
                        }
                    })
                    if(itemCurAmt){
                        itemTtlStr += cur + itemCurAmt + " "
                    }
                });
                $scope.itemTtl[item] = itemTtlStr;
            });

            getTotalList(items);
            $scope.gridOptions.columnDefs = angular.copy(columnDefsCopy);
            addItemCols();
        }
        var delItemCols = function(){
            for(var i = $scope.gridOptions.columnDefs.length -1; i >= 0; i--){
                if($scope.itemsList.indexOf($scope.gridOptions.columnDefs[i].name) != -1){
                    $scope.gridOptions.columnDefs.splice(i, 1);
                }
            }
        }

        var addItemCols = function(){
            var idx = 0;
            for(idx = 0; idx < $scope.gridOptions.columnDefs.length; idx++){
                if($scope.gridOptions.columnDefs[idx].field == "totalB"){
                    break;
                }
            }
            angular.forEach($scope.itemsList, function(item){
                $scope.gridOptions.columnDefs.splice(idx+1, 0, {
                    field: item,
                    width:120,
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellClass:'grid-primary',
                    footerCellTemplate: '<div class="ml-5 text-danger">' + $scope.itemTtl[item] + '</div>',
                    displayName: item
                })
            })
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }

        var getTotalList = function(items) {
            //是否选中
            angular.forEach(ratesTempList, function(data){
                data.isChecked = false;
            })
            angular.forEach($scope.gridApi.selection.getSelectedRows(), function(data){
                data.isChecked = true;
            })

            $scope.autoCheck.partChecked = false;
            angular.forEach(ratesTempList, function(data){
                if(data.isChecked){
                    $scope.autoCheck.partChecked = true;
                }
            })

            if($scope.autoCheck.jobTtl){
                ratesTempList = DataUtils.sortList(ratesTempList, 'jobNum', false);

                var currencies = new Set();
                var jobNums = new Set();
                angular.forEach(ratesRespCopy, function(data){
                    currencies.add(data.currency);
                    if(data.jobNum){
                        jobNums.add(data.jobNum);
                    }
                })
                var ratesCopy = angular.copy(ratesTempList);
                jobNums.forEach(function(jobNum){
                    currencies.forEach(function(currency){
                        var ttl = 0;
                        var baseAmtTtl = 0;
                        angular.forEach(ratesRespCopy, function(data){
                            if(data.etd && currency == data.currency && jobNum == data.jobNum){
                                ttl = ttl + data.totalB;
                                if(data.baseAmt){
                                    baseAmtTtl = baseAmtTtl + data.baseAmt;
                                }
                            }
                        });
                        var index = 0;
                        for(var i = 0; i < ratesTempList.length; i ++){
                            if(ratesTempList[i].etd && jobNum == ratesTempList[i].jobNum && !index){
                                index = -1;
                                continue;
                            }

                            if (ratesTempList[i].etd && index && jobNum != ratesTempList[i].jobNum){
                                index = i;
                                break;
                            }
                        }
                        if(ttl){
                            var newRate = {};
                            newRate.item = "Total";
                            newRate.totalB = DataUtils.round(ttl, 2);
                            newRate.total = DataUtils.round(ttl, 2);
                            newRate.baseAmt = DataUtils.round(baseAmtTtl, 2);
                            newRate.currency = currency;
                            if(index == -1){
                                ratesTempList.push(newRate);
                            }
                            else {
                                ratesTempList.splice(index,0,newRate);
                            }
                        }
                    })
                    for(var i = ratesCopy.length - 1; i >= 0; i --){
                        if(ratesCopy[i].etd && jobNum == ratesCopy[i].jobNum) {
                            ratesCopy.splice(i, 1);
                        }
                    }
                })
            }

            if($scope.autoCheck.monthTtl){
                var currencies = new Set();
                var months = new Set();
                angular.forEach(ratesTempList, function(data){
                    currencies.add(data.currency);
                    if(data.etd){
                        months.add(DateUtils.convertLocalDateToServer(data.etd).substring(0, 7));
                    }
                })

                ratesTempList.sort(function(a,b){
                    return a.etd > b.etd ? 1:-1;
                });

                months = Array.from(months);
                months.sort(function(a,b){
                    return a < b ? 1:-1;
                });
                var ratesCopy = angular.copy(ratesTempList);
                months.forEach(function(month){
                    currencies.forEach(function(currency){
                        var ttl = 0;
                        var baseAmtTtl = 0;
                        angular.forEach(ratesCopy, function(data){
                            if(data.etd && currency == data.currency && month == DateUtils.convertLocalDateToServer(data.etd).substring(0, 7)){
                                ttl = ttl + data.totalB;
                                if(data.baseAmt){
                                    baseAmtTtl = baseAmtTtl + data.baseAmt;
                                }
                            }
                        })
                        var index = 0;
                        for(var i = 0; i < ratesTempList.length; i ++){
                            if(ratesTempList[i].etd && month == DateUtils.convertLocalDateToServer(ratesTempList[i].etd).substring(0, 7) && !index){
                                index = -1;
                                continue;
                            }

                            if (ratesTempList[i].etd && index && month != DateUtils.convertLocalDateToServer(ratesTempList[i].etd).substring(0, 7)){
                                index = i;
                                break;
                            }
                        }
                        if(ttl){
                            var newRate = {};
                            newRate.item = month;
                            newRate.totalB = DataUtils.round(ttl, 2);
                            newRate.total = DataUtils.round(ttl, 2);
                            newRate.baseAmt = DataUtils.round(baseAmtTtl, 2);
                            newRate.currency = currency;
                            if(index == -1){
                                ratesTempList.push(newRate);
                            }
                            else {
                                ratesTempList.splice(index,0,newRate);
                            }
                        }
                    })
                    for(var i = ratesCopy.length - 1; i >= 0; i --){
                        if(ratesCopy[i].etd && month == DateUtils.convertLocalDateToServer(ratesCopy[i].etd).substring(0, 7)){
                            ratesCopy.splice(i, 1);
                        }
                    }
                })
            }
            $scope.totalList = [];
            var currencies = new Set();
            angular.forEach(ratesTempList, function(data){
                currencies.add(data.currency);
            })
            currencies.forEach(function(currency){
                var ttl = 0;
                var checkedTtl = 0;
                angular.forEach(ratesTempList, function(data){
                    if (data.id >=0 && data.currency == currency && data.totalB){
                        ttl += data.totalB;
                        if(data.isChecked){
                            checkedTtl += data.totalB;
                        }
                    }
                })
                var newRate = {};
                newRate.currency = currency;
                newRate.totalB = DataUtils.round(ttl, 2);
                newRate.total = DataUtils.round(ttl, 2);
                newRate.checkedTtl = DataUtils.round(checkedTtl, 2);
                if($scope.autoCheck.isCol && items){
                    var itemTtlList = [];
                    var itemTtl = 0;
                    items.forEach(function(item){
                        itemTtl = 0;
                        angular.forEach(ratesRespCopy, function(rate){
                            if (rate.id
                                && rate.currency == currency
                                && rate.item == item
                                && rate.totalB){
                                itemTtl = itemTtl + rate.totalB;
                            }
                        })
                        itemTtlList.push(DataUtils.round(itemTtl, 2));
                    });
                    newRate.itemTtlList = itemTtlList;
                }
                $scope.totalList.push(newRate);
            });

            $scope.autoCheck.checkAmt = "";
            angular.forEach($scope.totalList, function(data){
                $scope.autoCheck.checkAmt += data.currency + data.totalB + " ";
            })
            $translate.refresh();
            // $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            // $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }
        function getRateIdSet () {
            var idSet = "";
            angular.forEach(ratesRespCopy, function(rate){
                if(rate.id && rate.id > 0){
                    idSet = idSet + rate.id + ";";
                }
                else if (rate.dnNum){
                    idSet = idSet + rate.dnNum + ";";
                }
            })
            return idSet;
        }

        function save () {
            if (!$scope.autoCheck.code) {
                layer.msg($translate.instant('autopsApp.autoCheck.nameNullAlert'));
                return;
            }
            if ($scope.autoCheck.submited) {
                layer.msg($translate.instant('autopsApp.autoCheck.submitedAlert'));
                return;
            }

            vm.isSaving = true;
            $scope.autoCheck.idSet = getRateIdSet();

            var shipmentTypesSet = "";
            angular.forEach($scope.autoCheck.shipmentTypes, function(type){
                if(type){
                    shipmentTypesSet = shipmentTypesSet + type + ";";
                }
            })
            $scope.autoCheck.shipmentTypesSet = shipmentTypesSet;

            $scope.autoCheck.companyId = $rootScope.account.companyId;
            $scope.autoCheck.gradName = $scope.choosedOne.stateName;
            if ($scope.autoCheck.id !== null) {
                AutoCheck.update($scope.autoCheck, onSaveSuccess, onSaveError);
            } else {
                AutoCheck.save($scope.autoCheck, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            layer.closeAll();
            vm.isSaving = false;
            $scope.autoCheck = result;
            initAutoCheck();
            layer.msg($translate.instant('global.save_success'));
        }

        function onSaveError () {
            layer.closeAll();
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        }

        // function goBack () {
        //     $state.go('monthly-check');
        // }

        $scope.emailPopUp = function(reportCode) {
            period = "From " + DateUtils.convertLocalDateToServer($scope.autoCheck.dateFrom) + " to " + DateUtils.convertLocalDateToServer($scope.autoCheck.dateTo);
            var statMt = {};
            statMt.ratesList = ratesTempList;
            statMt.totalList = $scope.totalList;
            statMt.userBankInfo = $scope.autoCheck.signature;
            statMt.shipmentCount = $scope.shipmentCount;
            statMt.autoCheck = $scope.autoCheck;
            OpsFiles.generateMonthlyStatementAttachment({
                fileType: reportCode,
                period: period
            }, statMt, function(result) {
                sendEmailPop(result);
            });
        }

        function sendEmailPop (result) {
            var email = {};
            email.attachments = [];
            email.head = $scope.autoCheck.payer;
            email.subject = $translate.instant('stats.stat.monthlyStatementSubject');
            email.content = $translate.instant('stats.stat.monthlyStatementContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }

            var entity = {};
            entity.fileResult = result;
            entity.email = email;
            entity.venderId = $scope.autoCheck.payerVid;
            entity.guide = $scope.autoCheck;
            entity.isAutoCheck = true;

            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {});
        }


        $scope.getAccounts = function() {
            JhiSignature.query({companyId: $rootScope.account.companyId}, function(result) {
                $scope.opsAccounts = result;
            });
        }
        $scope.onAccountSelected = function(data) {
            $scope.autoCheck.userBankInfo = data.signature;
            layer.msg($translate.instant('autopsApp.autoCheck.dbClickEdit'));
        }

        $scope.excelExport = function() {
            if(!ratesTempList || ratesTempList.length == 0){
                layer.msg($translate.instant('autopsApp.autoCheck.listNullAlert'));
                return;
            }
            var autoCheckDTO = angular.copy($scope.autoCheck);
            autoCheckDTO.contents = ratesTempList;
            autoCheckDTO.totalList = $scope.totalList;
            OpsFiles.excelMonthlyStatement({
                timezoneOffset: new Date().getTimezoneOffset()/60,
                title: $translate.instant('autopsApp.autoCheck.detail.titleMohthly'),
                enCn: $translate.instant('global.enCn')
            }, autoCheckDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, ($scope.autoCheck.code?$scope.autoCheck.code:$scope.autoCheck.payer) + ".xlsx");
            })
        }
        $scope.excelExportCol = function() {
            if(!ratesTempList || ratesTempList.length == 0){
                layer.msg($translate.instant('autopsApp.autoCheck.listNullAlert'));
                return;
            }
            var autoCheckDTO = angular.copy($scope.autoCheck);
            autoCheckDTO.contents = ratesTempList;
            autoCheckDTO.totalList = $scope.totalList;
            autoCheckDTO.itemsList = $scope.itemsList;
            OpsFiles.excelMonthlyStatementCol({
                title: $translate.instant('autopsApp.autoCheck.detail.titleMohthly'),
                enCn: $translate.instant('global.enCn')
            }, autoCheckDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, ($scope.autoCheck.code?$scope.autoCheck.code:$scope.autoCheck.payer) + ".xlsx");
            })
        }

        $scope.delShipmentType = function(index) {
            $scope.autoCheck.shipmentTypes.splice(index, 1);
        };
        $scope.newShipmentType = null;
        $scope.shipmentTypeSelected = function(item) {
            if(!$scope.autoCheck.shipmentTypes){
                $scope.autoCheck.shipmentTypes = [];
            }
            $scope.autoCheck.shipmentTypes.push(item);
        }

        function filterPop (result) {
            if(!ratesRespCopy || ratesRespCopy.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/account-auth/account-auths.html',
                controller: 'AccountAuthController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return ratesRespCopy;
                    }]
                }
            }).result.then(function(result) {
                angular.copy(result, ratesRespCopy);
                $scope.typeChange();
            }, function(result) {});
        }
        function addSearchResults (results) {
            var isNew = true;
            angular.forEach(results, function(data){
                isNew = true;
                angular.forEach(ratesRespCopy, function(rate){
                    if(rate.id == data.id){
                        isNew = false;
                    }
                })
                if(isNew){
                    ratesRespCopy.push(data);
                }
            })
            $scope.typeChange();
        }

        $scope.searchPopFms = function() {
            if (!$scope.autoCheck.payerVid) {
                layer.msg($translate.instant('autopsApp.autoCheck.payerNullAlert'));
                return;
            }
            if($rootScope.HAVENOAUTH('FMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                autoCheck: $scope.autoCheck,
                isExport: $scope.autoCheck.isExport,
                companyId: $rootScope.account.companyId
            };
            if($scope.autoCheck.byBillDate == null){
                searchParams.etdFM = $scope.autoCheck.dateFrom;
                searchParams.etdTO = $scope.autoCheck.dateTo;
            }
            else if($scope.autoCheck.byBillDate){
                searchParams.billDateFM = $scope.autoCheck.dateFrom;
                searchParams.billDateTO = $scope.autoCheck.dateTo;
            }
            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                addSearchResults(results);
            }, function() {
            });
        }

        $scope.searchPopParcel = function(type) {
            if (!$scope.autoCheck.payerVid) {
                layer.msg($translate.instant('autopsApp.autoCheck.payerNullAlert'));
                return;
            }
            if(type == "TMS" && $rootScope.HAVENOAUTH('TMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($rootScope.HAVENOAUTH('PARCEL') && type == "PARCEL"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                submited : true,
                autoCheck: $scope.autoCheck,
                shipmentType: type,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                addSearchResults(results);
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        $scope.searchPopWms = function(isout) {
            if (!$scope.autoCheck.payerVid) {
                layer.msg($translate.instant('autopsApp.autoCheck.payerNullAlert'));
                return;
            }
            if($rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                companyId: $rootScope.account.companyId,
                autoCheck: $scope.autoCheck,
                isOut: isout
            };
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-search.html',
                controller: 'WmsInSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                addSearchResults(results);
            }, function() {
            });
        }


        $scope.delAllChecked = function() {
            angular.forEach(ratesTempList, function(content){
                if(content.isChecked == true){
                    if($scope.autoCheck.ref){
                        for(var i = ratesRespCopy.length - 1; i >= 0; i--){
                            if(ratesRespCopy[i][$scope.autoCheck.ref] == content[$scope.autoCheck.ref]
                                && ratesRespCopy[i].currency == content.currency){
                                ratesRespCopy.splice(i, 1);
                            }
                        }
                    }
                    else {
                        for(var i = ratesRespCopy.length - 1; i >= 0; i--){
                            if(content.id){
                                if(ratesRespCopy[i].id == content.id){
                                    ratesRespCopy.splice(i, 1);
                                }                                
                            }
                            else if(content.dnNum && ratesRespCopy[i].dnNum == content.dnNum){
                                ratesRespCopy.splice(i, 1);
                            }  
                        }
                    }
                }
                else {
                    content.isChecked = null;
                }
            })
            $scope.typeChange();
        }

        $scope.delAllUnChecked = function() {
            angular.forEach(ratesTempList, function(content){

                if(content.isChecked == false){
                    if($scope.autoCheck.ref){
                        for(var i = ratesRespCopy.length - 1; i >= 0; i--){
                            if(ratesRespCopy[i][$scope.autoCheck.ref] == content[$scope.autoCheck.ref]
                                && ratesRespCopy[i].currency == content.currency){
                                ratesRespCopy.splice(i, 1);
                            }
                        }
                    }
                    else {
                        for(var i = ratesRespCopy.length - 1; i >= 0; i--){
                            if(content.id){
                                if(ratesRespCopy[i].id == content.id){
                                    ratesRespCopy.splice(i, 1);
                                }                                
                            }
                            else if(content.dnNum && ratesRespCopy[i].dnNum == content.dnNum){
                                ratesRespCopy.splice(i, 1);
                            }  
                        }
                    }
                }
                else {
                    content.isChecked = null;
                }
            })
            $scope.typeChange();
        }

        function getTitleStr() {
            var outStr = $translate.instant('autopsApp.autoCheck.detail.titleMohthly');
            outStr += " To: " + $scope.autoCheck.payer + "/ " + $scope.shipmentCount + " Shipments";
            outStr += " (" + DateUtils.convertLocalDateToServer($scope.autoCheck.dateFrom) + " - " + DateUtils.convertLocalDateToServer($scope.autoCheck.dateTo) + ")";
            return outStr;
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,

            enableGridMenu : true,//表格 菜单
            showGridFooter: true,//表格的footer
            showColumnFooter: $scope.autoCheck.isCol,//每列footer

            enableFiltering: true,//高级过滤

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)
            minimumColumnSize: 70,
            gridFooterTemplate:'<div class="ml-5 mt-5"><strong><span translate="stats.stat.totalText" class="text-muted"></span><span class="label label-primary mr-8 f-13">{{grid.appScope.shipmentCount}} Jobs</span><span ng-repeat="total in grid.appScope.totalList track by $index" ng-class="{true: \'label label-success\', false: \'label label-primary\'}[$index%2 ==0]" class="mr-8 f-13">{{total.currency}}{{total.totalB}}</span><span translate="stats.stat.choosed" class="text-primary ml-10 f-13" ng-if="grid.appScope.autoCheck.partChecked"></span><span ng-repeat="total in grid.appScope.totalList track by $index" class="label label-info mr-8 f-13" ng-if="total.checkedTtl">{{total.currency}}{{total.checkedTtl}}</span></strong><span ng-if="grid.appScope.autoCheck.partChecked"><button type="button" class="btn btn-default btn-xs ml-10 mr-5" ng-click="grid.appScope.delAllChecked()"><span class="glyphicon glyphicon-remove"></span><span translate="entity.action.delAllChecked">delAllChecked</span></button><button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.delAllUnChecked()"><span class="glyphicon glyphicon-remove"></span><span translate="entity.action.delAllUnChecked">delAllUnChecked</span></button></span><span ng-if="grid.appScope.visibelTtl.length > 0"><strong class="text-muted ml-10">Filtered Total: </strong><span ng-repeat="total in grid.appScope.visibelTtl track by $index" class="label label-info mr-8 f-13">{{total.currency}}{{total.total}}</span></span></div>',
            columnDefs: [
                { field: 'jobNum',
                    width: 120,
                    displayName: $translate.instant('stats.stat.jobNum'),
                    cellTemplate: '<span class="ml-5"><a href="" ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity.jobNum}}</a></span>'},
                { field: 'shipmentType',
                    width:120,
                    displayName: $translate.instant('stats.stat.shipmentType')},
                    // cellTemplate: '<span class="ml-5"><span translate="global.{{row.entity.shipmentType}}"ng-if="row.entity.shipmentType"></span></span>'},
                { field: 'bizDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.bizDate'),
                    cellTemplate: '<span class="grid-text">{{row.entity.bizDate | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'item',
                    width:120,
                    cellTemplate: '<span class="grid-text"><strong class="mr-5"" ng-class="row.entity.id?\'text-primary\':\'text-danger\'">{{row.entity.item}}</strong><span class="glyphicon glyphicon-bookmark text-muted" ng-if="row.entity.billNum && row.entity.id" data-toggle="tooltip" data-placement="top" title="已发送账单"></span><span class="glyphicon glyphicon-ok-circle text-muted" ng-if="row.entity.clientChecked && row.entity.id" data-toggle="tooltip" data-placement="top" title="客户已确认（在线对账）"></span><span class="glyphicon glyphicon-lock text-muted" ng-if="row.entity.dnNum && row.entity.id" data-toggle="tooltip" data-placement="top" title="已确认/锁定"></span><span class="glyphicon glyphicon-tag text-muted" ng-if="row.entity.isApplied && row.entity.id" data-toggle="tooltip" data-placement="top" title="已申请开票/付款"></span><span class="glyphicon glyphicon-ok-sign text-muted" ng-if="row.entity.isPaid && row.entity.id" data-toggle="tooltip" data-placement="top" title="已核销"></span></span>',
                    displayName: $translate.instant('stats.stat.item')},
                { field: 'currency',
                    width:120,
                    cellTemplate: '<strong class="grid-text" ng-class="row.entity.id?\'text-primary\':\'text-danger\'">{{row.entity.currency}}</strong>',
                    displayName: $translate.instant('stats.stat.guideRates.payCurrency')},
                { field: 'totalB',
                    type:'number',
                    displayName: $translate.instant('stats.stat.guideRates.payAmount'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellTemplate: '<strong class="grid-text" ng-class="row.entity.id?\'text-primary\':\'text-danger\'">{{grid.appScope.FORMAT_NUM(row.entity.totalB)}}</strong>',
                    width:100},
                { field: 'paymentTerm',
                    width:120,
                    displayName: $translate.instant('stats.stat.paymentTerm')},
                { field: 'exchangeRate',
                    type:'number',
                    displayName: $translate.instant('stats.stat.exchangeRate'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{grid.appScope.FORMAT_NUM(row.entity.exchangeRate)}}</span>',
                    width:100},
                { field: 'baseAmt',
                    type:'number',
                    displayName: $translate.instant('stats.stat.guideRates.baseAmt'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellTemplate: '<strong class="grid-text">{{grid.appScope.account.baseCurrency}}{{grid.appScope.FORMAT_NUM(row.entity.baseAmt)}}</strong>',
                    width:100},
                { field: 'createDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.createTime'),
                    cellTemplate: '<span class="grid-text">{{row.entity.createDate | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'billDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.billDate'),
                    cellTemplate: '<span class="grid-text">{{row.entity.billDate | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'billMakeDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.billMakeDate'),
                    cellTemplate: '<span class="grid-text">{{row.entity.billMakeDate | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'accountDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.accountingDate'),
                    cellTemplate: '<span class="grid-text">{{row.entity.accountDate | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'etd',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.guideRates.etd'),
                    cellTemplate: '<span class="grid-text">{{row.entity.etd | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'eta',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.guideRates.eta'),
                    cellTemplate: '<span class="grid-text">{{row.entity.eta | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'deliveryDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.deliveryDate'),
                    cellTemplate: '<span class="grid-text">{{row.entity.deliveryDate | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'deadline',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.deadLine'),
                    cellTemplate: '<span class="grid-text">{{row.entity.deadline | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'cargoReadyDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.cargoReadyDate'),
                    cellTemplate: '<span class="grid-text">{{row.entity.cargoReadyDate | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'wmsInTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.wmsInTime'),
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>',
                    width:160},
                { field: 'wmsOutTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.wmsOutTime'),
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsOutTime | date:\'medium\'}}">{{row.entity.wmsOutTime | date:\'mediumDate\'}}</span>',
                    width:160},
                { field: 'atd',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.atd'),
                    cellTemplate: '<span class="grid-text">{{row.entity.atd | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'ataTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.ataTime'),
                    width:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.ataTime | date:\'medium\'}}">{{row.entity.ataTime | date:\'mediumDate\'}}</span>'},
                { field: 'hblReleasedTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.hblReleasedTime'),
                    width:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.hblReleasedTime | date:\'medium\'}}">{{row.entity.hblReleasedTime | date:\'mediumDate\'}}</span>'},
                { field: 'mblReleasedTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.mblReleasedTime'),
                    width:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.mblReleasedTime | date:\'medium\'}}">{{row.entity.mblReleasedTime | date:\'mediumDate\'}}</span>'},
                { field: 'inputTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.feeInput'),
                    width:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.inputTime | date:\'medium\'}}">{{row.entity.inputTime | date:\'mediumDate\'}}</span>'},
                { field: 'paidTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.paymentClearTime'),
                    width:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.paidTime | date:\'medium\'}}">{{row.entity.paidTime | date:\'mediumDate\'}}</span>'},
                { field: 'soNum',
                    width:120,
                    displayName: $translate.instant('stats.stat.soNum')},
                { field: 'payer',
                    width:120,
                    displayName: $translate.instant('stats.stat.payer')},
                { field: 'poNum',
                    width:120,
                    displayName: $translate.instant('stats.stat.poNum')},
                { field: 'mblNum',
                    width:120,
                    displayName: $translate.instant('stats.stat.mblNum')},
                { field: 'hblNum',
                    width:120,
                    displayName: $translate.instant('stats.stat.hblNum')},
                { field: 'billNum',
                    width:120,
                    displayName: $translate.instant('stats.stat.billNum')},
                { field: 'mcNum',
                    width:120,
                    displayName: $translate.instant('stats.stat.mcNum')},
                { field: 'dnNum',
                    width:120,
                    displayName: $translate.instant('stats.stat.dnNum')},
                { field: 'contractNum',
                    width:120,
                    displayName: $translate.instant('stats.stat.contractNum')},
                { field: 'refNum',
                    width:120,
                    displayName: 'EDI Ref#(IN)'},
                { field: 'ref',
                    width:120,
                    displayName: 'EDI Ref#(OT)'},
                { field: 'clientSoNum',
                    width:120,
                    displayName: $translate.instant('stats.stat.clientSoNum')},
                { field: 'invoiceNum',
                    width:120,
                    displayName: $translate.instant('stats.stat.invoiceNum')},
                { field: 'invoiceTime',
                    width:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.invoiceTime | date:\'medium\'}}">{{row.entity.invoiceTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('autopsApp.autoCheck.invoiceTime')},
                { field: 'fbaRef',
                    width:120,
                    displayName: $translate.instant('stats.stat.fbaRef')},
                { field: 'shipmentId',
                    width:120,
                    displayName: 'Shipment ID'},
                { field: 'isPay',
                    displayName: $translate.instant('stats.stat.isPay'),
                    width:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.isPay?\'label-primary\':\' label-danger\'"translate="{{row.entity.isPay?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'},
                { field: 'isExport',
                    displayName: $translate.instant('stats.stat.isExport'),
                    width:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.isExport?\'label-primary\':\' label-danger\'"translate="{{row.entity.isExport?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'},
                { field: 'clientChecked',
                    displayName: $translate.instant('stats.stat.clientChecked'),
                    width:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.clientChecked?\'label-primary\':\' label-danger\'"translate="{{row.entity.clientChecked?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'},
                { field: 'isApplied',
                    displayName: $translate.instant('stats.stat.isApplied'),
                    width:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.isApplied?\'label-primary\':\' label-danger\'"translate="{{row.entity.isApplied?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'},
                { field: 'isPaid',
                    displayName: $translate.instant('stats.stat.isPaid'),
                    width:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.isPaid?\'label-primary\':\' label-danger\'"translate="{{row.entity.isPaid?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'},
                { field: 'disputeFee',
                    displayName: $translate.instant('stats.stat.disputeFee'),
                    width:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.disputeFee?\'label-primary\':\' label-danger\'"translate="{{row.entity.disputeFee?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'},
                { field: 'ratesLocked',
                    displayName: $translate.instant('stats.stat.ratesLocked'),
                    width:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.ratesLocked?\'label-primary\':\' label-danger\'"translate="{{row.entity.ratesLocked?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'},
                { field: 'revLock',
                    displayName: $translate.instant('stats.stat.revLock'),
                    width:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.revLock?\'label-primary\':\' label-danger\'"translate="{{row.entity.revLock?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'},
                { field: 'expLock',
                    displayName: $translate.instant('stats.stat.expLock'),
                    width:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.expLock?\'label-primary\':\' label-danger\'"translate="{{row.entity.expLock?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'},
                { field: 'unit',
                    width:120,
                    displayName: $translate.instant('stats.stat.guideRates.unit')},
                { field: 'count',
                    type:'number',
                    displayName: $translate.instant('stats.stat.guideRates.count'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{grid.appScope.FORMAT_NUM(row.entity.count)}}</span>',
                    width:100},
                { field: 'days',
                    type:'number',
                    displayName: $translate.instant('stats.stat.guideRates.days'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    width:100},
                { field: 'price',
                    type:'number',
                    cellTemplate: '<span class="grid-text">{{grid.appScope.FORMAT_NUM(row.entity.price)}}</span>',
                    displayName: $translate.instant('stats.stat.price'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    width:100},
                { field: 'qCurrency',
                    width:120,
                    displayName: $translate.instant('stats.stat.guideRates.currency')},
                { field: 'qTotal',
                    type:'number',
                    cellTemplate: '<span class="grid-text">{{grid.appScope.FORMAT_NUM(row.entity.qTotal)}}</span>',
                    displayName: $translate.instant('stats.stat.guideRates.total'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    width:100},
                { field: 'payExchange',
                    type:'number',
                    cellTemplate: '<span class="grid-text">{{grid.appScope.FORMAT_NUM(row.entity.payExchange)}}</span>',
                    displayName: $translate.instant('stats.stat.guideRates.payExchange'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    width:100},
                { field: 'volumes',
                    width:120,
                    displayName: $translate.instant('stats.stat.volumes')},
                { field: 'fclVolumes',
                    width:120,
                    displayName: 'FCL ' + $translate.instant('stats.stat.volumes')},
                { field: 'pkgStr',
                    type:'number',
                    displayName: $translate.instant('stats.stat.pkgs'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    width:100},
                { field: 'gwStr',
                    type:'number',
                    displayName: $translate.instant('stats.stat.gw'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    width:100},
                { field: 'volStr',
                    type:'number',
                    displayName: $translate.instant('stats.stat.vol'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    width:100},
                { field: 'nwStr',
                    type:'number',
                    displayName: $translate.instant('stats.stat.nw'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    width:100},
                { field: 'vw',
                    type:'number',
                    displayName: $translate.instant('stats.stat.vw'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    width:100},
                { field: 'chargeableWeight',
                    type:'number',
                    displayName: $translate.instant('stats.stat.chargeWeight'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    width:100},
                { field: 'chargeBy',
                    width:120,
                    displayName: $translate.instant('stats.stat.chargeBy')},
                { field: 'ctnNumsStr',
                    width:120,
                    displayName: $translate.instant('stats.stat.ctnNumsStr')},
                { field: 'sealNumsStr',
                    width:120,
                    displayName: $translate.instant('stats.stat.sealNums')},
                { field: 'ctnTypesStr',
                    width:120,
                    displayName: $translate.instant('stats.stat.ctnType')},
                { field: 'customerName',
                    width:120,
                    displayName: $translate.instant('stats.stat.filterByClient')},
                { field: 'shipperName',
                    width:120,
                    displayName: $translate.instant('stats.stat.filterByShipper')},                    
                { field: 'cusName',
                    width:120,
                    displayName: $translate.instant('stats.stat.bcs')},
                { field: 'salesName',
                    width:120,
                    displayName: $translate.instant('stats.stat.salesName')},
                { field: 'opName',
                    width:120,
                    displayName: $translate.instant('stats.stat.opName')},
                { field: 'docName',
                    width:120,
                    displayName: $translate.instant('stats.stat.docName')},
                { field: 'finName',
                    width:120,
                    displayName: $translate.instant('stats.stat.finName')},
                { field: 'polName',
                    width:120,
                    displayName: $translate.instant('stats.stat.polName')},
                { field: 'podName',
                    width:120,
                    displayName: $translate.instant('stats.stat.podName')},
                { field: 'destName',
                    width:120,
                    displayName: $translate.instant('stats.stat.destName')},
                { field: 'deliveryto',
                    width:120,
                    displayName: $translate.instant('stats.stat.deliveryto')},
                { field: 'shiptoPostcode',
                    width:120,
                    displayName: $translate.instant('stats.stat.shiptoPostcode')},
                { field: 'destCountryCode',
                    width:120,
                    displayName: $translate.instant('stats.stat.destCountryCode')},
                { field: 'productName',
                    width:120,
                    displayName: $translate.instant('stats.stat.productName')},
                { field: 'channel',
                    width:120,
                    displayName: $translate.instant('stats.stat.deliveryChannel')},
                { field: 'cargoName',
                    width:120,
                    displayName: $translate.instant('stats.stat.cargoName')},
                { field: 'customsType',
                    width:120,
                    displayName: $translate.instant('stats.stat.customsType')},
                { field: 'carrier',
                    width:120,
                    displayName: $translate.instant('stats.stat.bcarrier')},
                { field: 'vsl',
                    width:120,
                    displayName: $translate.instant('stats.stat.vsl')},
                { field: 'voy',
                    width:120,
                    displayName: $translate.instant('stats.stat.voy')},
                { field: 'mhTag',
                    width:120,
                    displayName: "M/H"},
                { field: 'cargoValue',
                    width:120,
                    displayName: $translate.instant('stats.stat.cargoValue')},
                { field: 'lastestTkStatus',
                    width:120,
                    displayName: $translate.instant('stats.stat.lastestTkStatus')},
                { field: 'remarks',
                    width:120,
                    displayName: $translate.instant('stats.stat.remarks')},
                { field: 'cbNo',
                    width:120,
                    displayName: $translate.instant('stats.stat.cbNo')},
                { field: 'cbMark',
                    displayName: $translate.instant('stats.stat.cbMark'),
                    width:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.cbMark?\'label-primary\':\' label-danger\'"translate="{{row.entity.cbMark?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    $scope.saveSate();
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if($scope.choosedOne.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson($scope.choosedOne.gridState).gridState);
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();

                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })

                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "monthlyCheck";
                    jsonObj.sumBy = "currency";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "monthlyCheck";
                    jsonObj.sumBy = "currency";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope,function(row){
                    getTotalList();
                });
                gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                    getTotalList();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    clearTimeout(filterTimer);
                    filterTimer = setTimeout(function(){
                        getVisibelTtl();
                    }, 1000);//查询延时时间，单位ms
                });
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    vm.predicate = sortColumns[sortColumns.length - 1].field;
                    vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    loadAll();
                });
            }
        }

        var columnDefsCopy = angular.copy($scope.gridOptions.columnDefs);
        $scope.gridStateList = [];
        var gridStateCopy = null;
        $scope.choosedOne = {};
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridStateList({
                userId: $rootScope.account.id,
                gridId: 106
            }, function(result){
                $scope.gridStateList = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                angular.forEach($scope.gridStateList, function(result){
                    if($scope.autoCheck.gradName == result.stateName){
                        $scope.selectSate(result);
                    }
                })
            })
        });

        $scope.visibelTtl = [];
        function getVisibelTtl(){
            $scope.visibelTtl = [];
            var filterRequired = false;
            angular.forEach(gridCopy.columns, function(column){
                angular.forEach(column.filters, function(cf){
                    if(cf.term || cf.term == false){
                        filterRequired = true;
                    }
                })
            })
            if(!filterRequired){
                $translate.refresh();
                return;
            }

            var visibelRows = $scope.gridApi.core.getVisibleRows(gridCopy);

            var currencies = new Set();
            angular.forEach(visibelRows, function(row){
                currencies.add(row.entity.currency);
            });

            currencies.forEach(function(currency){
                var ttl = 0;
                angular.forEach(visibelRows, function(row){
                    if (row.entity.id >= 0 && row.entity.currency == currency && row.entity.totalB){
                        ttl += row.entity.totalB;
                    }
                })
                if (ttl){
                    var newTtl = {};
                    newTtl.currency = currency;
                    newTtl.total = DataUtils.round(ttl, 2);
                    $scope.visibelTtl.push(newTtl);
                }
            });
            $translate.refresh();
        }


        $scope.saveSate = function() {
            if(!$scope.choosedOne.stateName){
                layer.msg($translate.instant('autopsApp.autoCheck.statsNameNull'));
                return;
            }
            var stateObj = $scope.gridApi.saveState.save();
            for(var i = stateObj.columns.length -1; i >= 0; i--){
                if($scope.itemsList.indexOf(stateObj.columns[i].name) != -1){
                    stateObj.columns.splice(i, 1);
                }
            }
            $scope.choosedOne.gridState = angular.toJson(stateObj);
            $scope.choosedOne.userId = $rootScope.account.id;
            $scope.choosedOne.gridId = 106;
            GridState.update($scope.choosedOne, function(result){
                if(!$scope.choosedOne.id){
                    $scope.gridStateList.push(result);
                }
                angular.copy(result, $scope.choosedOne);
                layer.msg($translate.instant('global.save_success'));
            }, function(error){
                layer.msg($translate.instant('global.save_fail'));
            });
        }
        $scope.deleteState = function(gridState) {
            $uibModal.open({
                templateUrl: 'app/entities/grid-state/grid-state-delete-dialog.html',
                controller: 'GridStateDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['GridState', function(GridState) {
                        return GridState.get({id : gridState.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('gridState');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                var index = $scope.gridStateList.indexOf(gridState);
                if (index > -1) {
                    $scope.gridStateList.splice(index, 1);
                };
                $scope.choosedOne = {};
            }, function() {

            });
        }

        $scope._onClerkSelected = function(data) {
            if(!$scope.choosedOne.id){
                return;
            }
            var newOne = angular.copy($scope.choosedOne);
            newOne.id = null;
            newOne.userId = data.id;
            GridState.update(newOne, function(result){
                layer.msg($translate.instant('global.shareSuccess'));
            }, function(error){
                layer.msg($translate.instant('global.save_fail'));
            });
        }


        $scope.selectSate = function(gridState) {
            if(gridState && $scope.choosedOne
                && gridState.id == $scope.choosedOne.id){
                gridState = {};
            }
            $scope.choosedOne = gridState;
            if(!gridState || !gridState.id){
                $scope.gridApi.saveState.restore($scope, gridStateCopy);
            }
            else {
                $scope.gridApi.saveState.restore($scope, angular.fromJson($scope.choosedOne.gridState));
            }
            delItemCols();
            columnDefsCopy = angular.copy($scope.gridOptions.columnDefs);
            if($scope.autoCheck.isCol){
                addItemCols();
            }
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }

        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 200;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        $scope.searchType = 'BillNum';
        $scope.setSearchType = function(type) {
            $scope.searchType = type;
        }
        vm.searchStr = null;
        function getRatesByRefNum (bizType) {
            if($scope.isSaving){return;}
            if(!vm.searchStr){
                return;
            }
            if(!bizType && $scope.searchType !== 'BillNum'){
                return;
            }
            $scope.isSaving = true;
            if (!$scope.autoCheck.payerVid || !$scope.autoCheck.dateFrom || !$scope.autoCheck.dateTo) {
                layer.msg($translate.instant('stats.stat.nullFilterAlert'));
                $scope.isSaving = false;
                return;
            }
            var date1, date2;
            date1 = DateUtils.convertLocalDateToServer($scope.autoCheck.dateFrom);
            date2 = DateUtils.convertLocalDateToServer($scope.autoCheck.dateTo);
            if (date1 > date2) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                $scope.isSaving = false;
                return;
            }
            var checkCopy = angular.copy($scope.autoCheck);
            checkCopy.companyId = $rootScope.account.companyId;
            AutoCheck.getRatesByRefNum({
                searchType: $scope.searchType,
                bizType: bizType,
                inputStr: vm.searchStr
            }, checkCopy, function(newRates) {
                $scope.isSaving = false;
                if (!newRates || newRates.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                    $scope.isSaving = false;
                    return;
                }
                var ratesTemp = [];
                var notNew = false;
                angular.forEach(newRates, function(newRate){
                    notNew = false;
                    angular.forEach(ratesRespCopy, function(oldRate){
                        if(newRate.id == oldRate.id){
                            notNew = true;
                        }
                    })
                    if(!notNew){
                        ratesTemp.push(newRate);
                    }
                })

                angular.forEach(ratesTemp, function(newRate){
                    ratesRespCopy.push(newRate);
                })
                $scope.typeChange();

            }, function(response) {
                $scope.isSaving = false;
            });
        }
        function setMcBillNum (){
            if (!$scope.autoCheck.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var title;
            if($scope.autoCheck.billNum){
                title = $translate.instant('autopsApp.autoCheck.clearMcNumAlert');
            }
            else {
                title = $translate.instant('autopsApp.autoCheck.mcNumAlert');
            }
            layer.confirm(title, {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                $scope.autoCheck.idSet = getRateIdSet();
                AutoCheck.setMcBillNum($scope.autoCheck, onSaveSuccess, onSaveError);
            }, function() {
                $scope.billNumEnabled = !$scope.billNumEnabled;
            });
        }

        $scope.setByBillDate = function (byBillDate) {
            if(vm.locked){
                return;
            }
            $scope.autoCheck.byBillDate = byBillDate;
        }
        $scope.dldStatement = function() {
            if(!ratesTempList || ratesTempList.length == 0){
                layer.msg($translate.instant('autopsApp.autoCheck.listNullAlert'));
                return;
            }
            OpsFiles.dldStatement({}, ratesTempList, function(result) {
                $rootScope.DOWNLOADEXCEL(result, ($scope.autoCheck.code?$scope.autoCheck.code:$scope.autoCheck.payer)  + ".xlsx");
            })
        }

        $scope.scAccountStatement = function(reportType) {
            if(!ratesTempList || ratesTempList.length == 0){
                layer.msg($translate.instant('autopsApp.autoCheck.listNullAlert'));
                return;
            }
            OpsFiles.scAccountStatement({
                reportType: reportType,
                enCn: $translate.instant('global.enCn')
            }, ratesTempList, function(result) {
                $rootScope.DOWNLOADEXCEL(result, ($scope.autoCheck.code?$scope.autoCheck.code:$scope.autoCheck.payer) + ".xlsx");
            })
        }

        $scope.exportMonthlyStatement = function(format, reportType) {
            period = "From " + DateUtils.convertLocalDateToServer($scope.autoCheck.dateFrom) + " to " + DateUtils.convertLocalDateToServer($scope.autoCheck.dateTo);
            var statMt = {};
            statMt.ratesList = [];
            var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
            angular.forEach(allvisiblerows, function(row){
                statMt.ratesList.push(row.entity);
            })
            // statMt.ratesList = ratesTempList;
            statMt.totalList = $scope.totalList;
            statMt.shipmentCount = $scope.shipmentCount;
            statMt.autoCheck = $scope.autoCheck;
            OpsFiles.generateMonthlyStatementReport({
                period: period,
                fileType: format,
                reportType: reportType
            }, statMt, function(result) {
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, format, $scope.autoCheck.code?$scope.autoCheck.code:$scope.autoCheck.payer);
            }, function(error) {
                layer.msg($translate.instant('stats.stat.generateTrackingReportFailed'));
            });
        }

        $scope.bxAccountStatement = function(format, reportType) {
            period = "From " + DateUtils.convertLocalDateToServer($scope.autoCheck.dateFrom) + " to " + DateUtils.convertLocalDateToServer($scope.autoCheck.dateTo);
            var statMt = {};
            statMt.ratesList = angular.copy(ratesRespCopy);
            statMt.autoCheck = angular.copy($scope.autoCheck);
            OpsFiles.bxAccountStatement({
                period: period,
                fileType: format,
                reportType: reportType
            }, statMt, function(result) {
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, format, $scope.autoCheck.code?$scope.autoCheck.code:$scope.autoCheck.payer);
            }, function(error) {
                layer.msg($translate.instant('stats.stat.generateTrackingReportFailed'));
            });
        }

        $scope.linkGoExcelMonthlyStatementCol = function() {
            if(!ratesTempList || ratesTempList.length == 0){
                layer.msg($translate.instant('autopsApp.autoCheck.listNullAlert'));
                return;
            }
            var autoCheckDTO = angular.copy($scope.autoCheck);
            autoCheckDTO.contents = ratesTempList;
            autoCheckDTO.totalList = $scope.totalList;
            autoCheckDTO.itemsList = $scope.itemsList;
            var fileName = ($scope.autoCheck.code?$scope.autoCheck.code:$scope.autoCheck.payer) + ".xlsx";
            OpsFiles.linkGoExcelMonthlyStatementCol({
                enCn: $translate.instant('global.enCn')
            }, autoCheckDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }

        $scope.haoBoExcelMonthlyStatementCol = function() {
            if (!$scope.autoCheck.isCol) {
                $scope.autoCheck.isCol = true;
                $scope.typeChange('isCol');
            }
            if(!ratesTempList || ratesTempList.length == 0){
                layer.msg($translate.instant('autopsApp.autoCheck.listNullAlert'));
                return;
            }
            var autoCheckDTO = angular.copy($scope.autoCheck);
            autoCheckDTO.contents = ratesTempList;
            autoCheckDTO.totalList = $scope.totalList;
            autoCheckDTO.itemsList = $scope.itemsList;
            var fileName = autoCheckDTO.payer + ".xlsx";
            OpsFiles.haoBoExcelMonthlyStatementCol({
                enCn: $translate.instant('global.enCn')
            }, autoCheckDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }
    }
})();
