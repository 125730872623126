(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$scope', '$timeout', '$rootScope', '$http', '$uibModal', '$state', '$translate', '$location', 'JhiLanguageService', 'WinAES', 'DateUtils', 'OpsItem', 'OpsExchangeRate',
        'Auth', 'Principal', 'ProfileService', 'LoginService', 'ImChatFactory', 'ImChatService', 'HfBill', 'WmsIn', 'OpsVender', 'WmsGoods', 'OaApplication', 'GuideCtns', 'ReportTemplate',
        'MsgTrackerService', 'OpsReport', 'ApiErp', 'Guide', 'OpsCompany', 'Base64', 'DataUtils', 'Clerks', 'ExpressParcel', 'OpsFiles', 'ManageEnterprise',
        'OpsPorts', 'OpsCarrier', 'Dictionary', 'OpsShipper', 'Account', 'User', 'WmsCargo', 'WoHead', 'CommodityDatabase', 'OpsCost', 'VgmHead', 'AutoCheck',
        'CustomNode', 'DataSubscription', 'GuideRates', 'AccountBind', 'GridState', 'OrderNode', 'AsyncTask', 'AccountInvoice', 'AccountPayment', 'OpsImporterSearch'
    ];

    function NavbarController($scope, $timeout, $rootScope, $http, $uibModal, $state, $translate, $location, JhiLanguageService, WinAES, DateUtils, OpsItem, OpsExchangeRate,
        Auth, Principal, ProfileService, LoginService, ImChatFactory, ImChatService, HfBill, WmsIn, OpsVender, WmsGoods, OaApplication, GuideCtns, ReportTemplate,
        MsgTrackerService, OpsReport, ApiErp, Guide, OpsCompany, Base64, DataUtils, Clerks, ExpressParcel, OpsFiles, ManageEnterprise,
        OpsPorts, OpsCarrier, Dictionary, OpsShipper, Account, User, WmsCargo, WoHead, CommodityDatabase, OpsCost, VgmHead, AutoCheck,
        CustomNode, DataSubscription, GuideRates, AccountBind, GridState, OrderNode, AsyncTask, AccountInvoice, AccountPayment, OpsImporterSearch) {

        var loadInit = function() {
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                Principal.identity().then(function(account) {
                    $rootScope.account = account;
                    if ($rootScope.account && $rootScope.account.id) {
                        WoHead.workLoad();
                        $rootScope.LOAD_CONFIGURATION();
                        $rootScope.LOAD_CUSTOM_NODE_FIELD();
                    }
                });
            } else if ($rootScope.account && $rootScope.account.id) {
                WoHead.workLoad();
                $rootScope.LOAD_CONFIGURATION();
                $rootScope.LOAD_CUSTOM_NODE_FIELD();
            }
        }
        loadInit();

        $rootScope.encn = $translate.instant('global.enCn');

        $timeout(function() {
            MsgTrackerService.receive().then(null, null, function(result) {
                msgHandler(result);
            });
        });

        $rootScope.absUrl = $location.absUrl();
        // if($rootScope.absUrl.indexOf("8080") === -1){
        //     setInterval(function() {
        //         if($rootScope.account && $rootScope.account.id == 5){
        //             WoHead.workLoad();
        //         }
        //     }, 10000);
        // }
        String.prototype.endWith = function(endStr) {
            var d = this.length - endStr.length;
            return (d >= 0 && this.lastIndexOf(endStr) == d);
        }

        var clientOverCreditAlert = function() {
            if (!$rootScope.CLIENT_DEPOSITE || !$rootScope.CLIENT_DEPOSITE.unpaidAmount) {
                return false;
            }
            $rootScope.OPS_ALERT($translate.instant('home.clientDeposite', {
                currency: $rootScope.account.baseCurrency,
                unpaidAmount: $rootScope.CLIENT_DEPOSITE.unpaidAmount,
                parcelCount: $rootScope.CLIENT_DEPOSITE.parcelCount
            }))
            return true;
        }

        function msgHandler(result) {
            if (result.msg) {
                $rootScope.OPS_ALERT(result.msg);
            }
            if (result.logo) {
                $rootScope.account.companyLogo = result.logo;
            }
            if (result.companyDeposite && result.companyDeposite.amt && $rootScope.HAVEAUTH('ROLE_BOSS')) {
                $rootScope.OPS_ALERT($translate.instant('home.checkDeposite', {
                    currency: $rootScope.account.baseCurrency,
                    amt: result.companyDeposite.amt,
                    date: result.companyDeposite.date
                }))
            }
            if (result.clientDeposite) {
                $rootScope.CLIENT_DEPOSITE = result.clientDeposite;
                clientOverCreditAlert();
            }

            if (result.membershipExpired) {
                $rootScope.membershipExpired = result.membershipExpired;
                $rootScope.OPS_ALERT($translate.instant('global.membershipExpiredAlert', { param: result.membershipExpired }));
            }
            if (result.appsCount) {
                $rootScope.appsCount = result.appsCount;
            }
            if (result.woWorkLoad || result.woWorkLoad == 0) {
                $rootScope.WOWORKLOAD = result.woWorkLoad;
            }
            if (result.excelFile) {
                $rootScope.DOWNLOAD_BASE64(result.excelFile, result.excelFileName);
            }
            if (result.warehousingData) {
                $scope.$emit('autopsApp:warehousingDataReceived', result.warehousingData);
            }
            if (result.expressCargo) {
                $scope.$emit('autopsApp:expressCargoReceived', result.expressCargo);
            }
            if (result.parcelWorkLoad) {
                $scope.$emit('autopsApp:parcelWorkLoadDataReceived', result.parcelWorkLoad);
            }
            if (result.guideUpdateByChannel) {
                $scope.$emit('autopsApp:guideUpdateByChannel', result.guideUpdateByChannel);
            }
            if (result.parcelUpdateByChannel) {
                $scope.$emit('autopsApp:parcelUpdateByChannel', result.parcelUpdateByChannel);
            }
            if (result.woUpdated) {
                $scope.$emit('autopsApp:woUpdated', result.woUpdated);
            }
            if (result.connectTest) {
                setTimeout(function() {
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.messages.equipmentConnected'))
                }, 800);
            }
        }


        function hidenFdYes(type, fd) {
            // if(!$rootScope.COM_CONFG.FBAHIDEN){
            //     loadInit();
            // }
            if ($rootScope.seaTypes.indexOf(type) != -1 && $rootScope.COM_CONFG.FMSHIDEN &&
                $rootScope.COM_CONFG.FMSHIDEN.indexOf(fd) != -1) {
                return true;
            }
            if ($rootScope.fbaTypes.indexOf(type) != -1 && $rootScope.COM_CONFG.FBAHIDEN &&
                $rootScope.COM_CONFG.FBAHIDEN.indexOf(fd) != -1) {
                return true;
            }
            if ($rootScope.airTypes.indexOf(type) != -1 && $rootScope.COM_CONFG.AIRHIDEN &&
                $rootScope.COM_CONFG.AIRHIDEN.indexOf(fd) != -1) {
                return true;
            }
        }

        $rootScope.WF_SHOW = function(guide, role, field) {
            var fd = guide.shipmentType + role + field;
            if (!wfFieldsMap.has(fd)) {
                return false;
            }
            return !nodeNotMatch(guide, wfFieldsMap.get(fd));
        }

        $rootScope.DISPLAY_CHECK = function(guide, fd) {
            if (!guide) {
                return false;
            }
            if (hidenFdYes(guide.shipmentType, fd)) {
                return false;
            }
            var cstHdFd = guide.shipmentType + fd;
            if (!hideFieldsMap.has(cstHdFd)) {
                return true;
            }
            return nodeNotMatch(guide, hideFieldsMap.get(cstHdFd));
        }

        $rootScope.LOAD_CONFIGURATION = function(companyId) {
            if(!companyId){
                companyId = $rootScope.account.companyId;
            }
            User.getConfigurationWithLabel({
                companyId: companyId,
                langKey: $translate.instant('global.enCn')
            }, function(result) {
                $rootScope.COM_CONFG = result;
                if(result.BIZTYPES){
                    $rootScope.BIZTYPES = result.BIZTYPES;
                    $rootScope.BIZTYPES.splice(0, 0, {value:"", label:""});
                }
                if(result.INTERNALTYPES){
                    $rootScope.INTERNALTYPES = result.INTERNALTYPES;
                    $rootScope.INTERNALTYPES.splice(0, 0, {value:"", label:""});
                }
                if(result.PRODUCTNAMES){
                    $rootScope.PRODUCTNAMES = result.PRODUCTNAMES;
                }
                if(result.BRANCHS){
                    $rootScope.BRANCHS = result.BRANCHS;
                }
                if(result.DEPARTMENTS){
                    $rootScope.DEPARTMENTS = result.DEPARTMENTS;
                }
                if(result.CARGOTYPES){
                    $rootScope.CARGOTYPES = result.CARGOTYPES;
                }
                if(result.HANDLETYPETITLES){
                    $rootScope.HANDLETYPETITLES = result.HANDLETYPETITLES;
                }
                if(result.OATYPES){
                    $rootScope.OATYPES = result.OATYPES;
                }
                if(result.CUSFIELDS){
                    $rootScope.CUSFIELDS = result.CUSFIELDS;
                }
                if(result.CUSTOMSTYPES){
                    $rootScope.CUSTOMS_TYPES = result.CUSTOMSTYPES;
                }
                if(result.CLIENTSOURCES){
                    $rootScope.CLIENT_SOURCES = result.CLIENTSOURCES;
                }
                if(result.CLIENTTYPES){
                    $rootScope.CLIENT_TYPES = result.CLIENTTYPES;
                }

                $translate.refresh();
            });
        }
        $rootScope.DOWNLOAD_BASE64 = function(base64Str, fileName) {
            var bstr = atob(base64Str),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            var blob = new Blob([u8arr], {
                type: "application/vnd.ms-excel"
            });
            var downloadUrl = window.URL.createObjectURL(blob);

            var anchor = document.createElement("a");
            anchor.href = downloadUrl;
            anchor.download = fileName + ".xlsx";
            anchor.click();
            window.URL.revokeObjectURL(blob);
        }


        var vm = this;
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });

        $scope.enLang = function() {
            return lang == 'en';
        }

        $scope.opsMenuCol = function() {
            if ($rootScope.HAVEAUTH("PARCEL")) {
                return $scope.enLang() ? 'menu-3en' : 'menu-3cn';
            } else {
                return $scope.enLang() ? 'menu-2en' : 'menu-2cn';
            }
        }

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.rateFinder = rateFinder;
        vm.pubSearch = pubSearch;
        vm.fastWriteOff = fastWriteOff;
        vm.hfBills = hfBills;
        vm.goWmsGoods = goWmsGoods;
        vm.writeOff = writeOff;
        $rootScope.WORKLOAD = {};

        $rootScope.priceUnits = Dictionary.priceUnits;
        $rootScope.pkgTypes = Dictionary.pkgTypes;
        $rootScope.ctnTypes = Dictionary.ctnTypes;
        $rootScope.countryCodes = Dictionary.countryCodes;

        $rootScope.fms_fba_all_types = ["FCL", "LCL", "RAIL", "BULK", "ITCT", "AIR", "EXPRESS", "FBAAIR", "FBASEA", "FBARAIL", "WMS", "LOCAL", "LOCALKG"];
        $rootScope.traditionalTypes = ["FCL", "LCL", "RAIL", "BULK", "ITCT", "AIR"];
        $rootScope.seaTypes = ["FCL", "LCL", "RAIL", "BULK", "ITCT"];

        $rootScope.wmsTypes = ["WMS", "LOCAL", "LOCALKG"];
        $rootScope.fbaTypes = ["EXPRESS", "FBAAIR", "FBASEA", "FBARAIL"];
        $rootScope.fbaWmsTypes = ["EXPRESS", "FBAAIR", "FBASEA", "FBARAIL", "WMS", "LOCAL", "LOCALKG"];

        $rootScope.airTypes = ["AIR"];
        $rootScope.COURIERS = ["UPS", "FedEx", "DPD", "DHL"];
        $rootScope.parcelTmsTypes = ["TMS", "FTL", "LTL", "EXD", "PARCEL", "LINE", "RETURN", "CPU", "EXP", "OWMS", "WMS", "EXPRESS"];
        $rootScope.parcelTypes = ["PARCEL", "EXPRESS","LINE", "RETURN", "TST"];
        $rootScope.customNodeParcelTypes = ["PARCEL", "LINE", "RETURN", "EP"];
        $rootScope.customNodeTmsTypes = ["TMS", "FTL", "LTL", "EXD", "CPU", "EPWMS"];
        $rootScope.tmsTypes = ["TMS", "FTL", "LTL", "EXD", "CPU", "WMS"];
        $rootScope.T_PLATFORM = ["Offline", "Amazon", "AmazonSftp", "biaoju", "auwms"];
        $rootScope.CUR_TYPES = [];
        $rootScope.fclTypes = ["FCL", "RAIL", "ITCT"];

        $rootScope.FEDEX_PKG_TYPES = ["YOUR_PACKAGING", "FEDEX_PAK",
        "FEDEX_ENVELOPE", "FEDEX_TUBE", "FEDEX_BOX", "FEDEX_SMALL_BOX", "FEDEX_MEDIUM_BOX", "FEDEX_LARGE_BOX", "FEDEX_EXTRA_LARGE_BOX", "FEDEX_10KG_BOX", "FEDEX_25KG_BOX"];

        $rootScope.IS_FCL = function(type) {
            return $rootScope.fclTypes.indexOf(type) !== -1;
        }

        $rootScope.IS_TMS = function(type) {
            return $rootScope.tmsTypes.indexOf(type) !== -1;
        }

        $rootScope.IS_T_PLATFORM = function(platform) {
            return $rootScope.T_PLATFORM.indexOf(platform) !== -1;
        }

        $rootScope.LOAD_CUR_TYPES = function() {
            if ($rootScope.CUR_TYPES && $rootScope.CUR_TYPES.length > 1) {
                return;
            }
            if ($rootScope.OPSCURRENCYS && $rootScope.OPSCURRENCYS.length > 1) {
                addCurTypes();
            } else {
                OpsExchangeRate.getAllCurrencys(function(result) {
                    $rootScope.OPSCURRENCYS = result;
                    $rootScope.OPSCURRENCYS.splice(0, 0, "");
                    addCurTypes();
                })
            }
        }

        function addCurTypes() {
            angular.forEach($rootScope.OPSCURRENCYS, function(curData) {
                if (curData) {
                    $rootScope.CUR_TYPES.push({
                        value: curData,
                        label: curData
                    })
                }
            })
        }

        $rootScope.YES_NO_OPTIONS = [
            { value: true, label: $translate.instant('global.yes_answer') },
            { value: false, label: $translate.instant('global.no_answer') }
        ];

        $rootScope.NO_YES_OPTIONS = [
            { value: false, label: $translate.instant('global.yes_answer') },
            { value: true, label: $translate.instant('global.no_answer') }
        ];

        $rootScope.IS_PAY_OPTIONS = [
            { value: true, label: $translate.instant('autopsApp.guide.exp') },
            { value: false, label: $translate.instant('autopsApp.guide.rev') }
        ];
        $rootScope.SERVICE_TYPES = [
            { value: "", label: "", type: "ALL" },
            { value: "AIRPORT-AIRPORT", label: "AIRPORT-AIRPORT", type: "AIR" },
            { value: "AIRPORT-DOOR", label: "AIRPORT-DOOR", type: "AIR" },
            { value: "CY-CY", label: "CY-CY", type: "LCL FCL ITCT" },
            { value: "CY-RAMP", label: "CY-RAMP", type: "LCL FCL" },
            { value: "CY-DOOR", label: "CY-DOOR", type: "LCL FCL ITCT" },
            { value: "CY-CFS", label: "CY-CFS", type: "LCL FCL FBA" },
            { value: "CY-FO", label: "CY-FO", type: "LCL FCL" },
            { value: "CFS-CFS", label: "CFS-CFS", type: "LCL FCL FBA RAIL" },
            { value: "CFS-CY", label: "CFS-CY", type: "LCL FCL FBA" },
            { value: "CFS-FO", label: "CFS-FO", type: "LCL FCL" },
            { value: "CFS-DOOR", label: "CFS-DOOR", type: "LCL FCL FBA" },
            { value: "CFS-DOOR(DAP)", label: "CFS-DOOR(DAP)", type: "LCL FCL FBA" },
            { value: "CFS-DOOR(DAT)", label: "CFS-DOOR(DAT)", type: "LCL FCL FBA" },
            { value: "CFS-DOOR(DDP)", label: "CFS-DOOR(DDP)", type: "LCL FCL FBA" },
            { value: "CFS-DOOR(DDU)", label: "CFS-DOOR(DDU)", type: "LCL FCL FBA" },
            { value: "FLT", label: "FLT", type: "BULK" },
            { value: "FIO", label: "FIO", type: "BULK" },
            { value: "FILO", label: "FILO", type: "BULK" },
            { value: "LIFO", label: "LIFO", type: "BULK" },
            { value: "STATION-STATION", label: "STATION-STATION", type: "RAIL" },
            { value: "STATION-DOOR", label: "STATION-DOOR", type: "RAIL" },
            { value: "DOOR-AIRPORT", label: "DOOR-AIRPORT", type: "AIR" },
            { value: "DOOR-STATION", label: "DOOR-STATION", type: "RAIL" },
            { value: "DOOR-CY", label: "DOOR-CY", type: "LCL FCL ITCT" },
            { value: "DOOR-CFS", label: "DOOR-CFS", type: "LCL FCL FBA" },
            { value: "DOOR-DOOR", label: "DOOR-DOOR", type: "ALL" }
        ];
        $rootScope.EXPRESS_CARRIERS = [
            { value: "UPSNEW", label: "UPS-API", type: "PRINT_LABEL UPS"},
            { value: "UPSNEWRETURN", label: "UPS Return", type: "PRINT_LABEL UPS"},
            { value: "UPS", label: "UPS-Deprecated", type: "PRINT_LABEL UPS"},
            { value: "DHL", label: "DHL(V2)", type: "PRINT_LABEL DHL"},
            { value: "DhlV4", label: "DHL(V4)", type: "PRINT_LABEL DHL"},
            { value: "FEDEXNEW", label: "FedEx-API", type: "PRINT_LABEL FEDEX"},
            { value: "FEDEXNEWTEST", label: "FedEx-API TEST", type: "PRINT_LABEL FEDEX"},
            { value: "FEDEX", label: "FEDEX-WebSevice", type: "PRINT_LABEL FEDEX"},
            { value: "FEDEXTST", label: "FEDEX-WebSevice TEST", type: "PRINT_LABEL FEDEX"},
            { value: "DPD", label: "DPD(UK)", type: "PRINT_LABEL DPD"},
            { value: "DPDDE", label: "DPD(DE)", type: "PRINT_LABEL DPDDE"},
            { value: "EUB", label: "EUB", type: "PRINT_LABEL EUB"},
            { value: "AUOPS", label: "AU-OPS", type: "PRINT_LABEL AUOPS"},
            { value: "AUOPSNEW", label: "AU-OPS(推荐)", type: "PRINT_LABEL AUOPS"},
            { value: "AMAZONSHIPPING", type: "PRINT_LABEL AUOPS", label: "Amazon Shipping"},
            { value: "ANMEI", label: "ANMEI", type: "ANMEI"},
            { value: "AWESUNG", label: "AWESUNG", type: "AWESUNG"},
            { value: "DELIVERR", type: "DELIVERR", label: "DELIVERR"},
            { value: "ZRSUPPLY", type: "ZRSUPPLY", label: "ZRSUPPLY"},
            { value: "TUSPS", type: "TUSPS", label: "TUSPS"},
            { value: "HLT", type: "HLT", label: "HLT"},
            { value: "KINGKONG", type: "KINGKONG", label: "KINGKONG"},
            { value: "RTB", type: "RTB", label: "RTB"},
            { value: "AID", type: "AID", label: "AID"},
            { value: "SPBR", type: "SPBR", label: "SHIPBER"},
            { value: "HTK", type: "HTK", label: "HTK"},
            { value: "POSTPONY", type: "POSTPONY", label: "POSTPONY"},
            { value: "POSTLABEL", type: "POSTLABEL", label: "POSTLABEL"},
            { value: "DEFT", type: "DEFT", label: "DEFT"},
            { value: "YUNKE", type: "YUNKE", label: "YUNKE"},
            { value: "HF", type: "HF", label: "HF"},
            { value: "EZE", type: "EZE", label: "EZE"},
            { value: "ADB", type: "ADB", label: "ADBBER"},
            { value: "DDUE", type: "DDUE", label: "DDUEXPRESS"},
            { value: "LSTM", type: "LSTM", label: "LAST MILE"},
            { value: "SPRT", type: "SPRT", label: "SUPER ROUTE"},
            { value: "ORGE", type: "ORGE", label: "ORANGE EXPRESS"},
            { value: "KSPD", type: "KSPD", label: "KingSpeed"},
            { value: "OPZA", type: "OPZA", label: "OnePizza"},
            { value: "GBLS", type: "GBLS", label: "Gblinker"},
            { value: "FLYT", type: "FLYT", label: "FlytExpress"},
            { value: "VTDRCT", type: "VTDRCT", label: "Vitedirect"},
            { value: "FRAYUN", type: "FRAYUN", label: "Frayun"},
            { value: "PACC", type: "PACC", label: "ParcelC"},
            { value: "ECPRT", type: "ECPRT", label: "EC PRINT"},
            { value: "ECTMS", type: "ECTMS", label: "EC TMS"},
            { value: "SHPSTT", type: "SHPSTT", label: "Ship Station"},
            { value: "IMILE", type: "IMILE", label: "iMile"},
            { value: "RPDL", type: "RPDL", label: "Rapiddeals"},
            { value: "HUBEZ", type: "HUBEZ", label: "Hub-Ez"},
            { value: "VITEUPS", type: "VITEUPS", label: "VITE-UPS"},
            { value: "VITEFEDEX", type: "VITEFEDEX", label: "VITE-FedEx"},
            { value: "ITDIDA", type: "ITDIDA", label: "ITDIDA"},
            { value: "RAPID", type: "RAPID", label: "Rapiddeals Label"},
            { value: "SIXSIXPOST", type: "SIXSIXPOST", label: "66Post"},
            { value: "HYEUS", type: "HYEUS", label: "hyeus"},
            { value: "HUALEI", type: "HUALEI", label: "Hua Lei"},
            { value: "YUEMA", type: "YUEMA", label: "Yue Ma"},
            { value: "GLS", type: "GLS", label: "GLS"},
            { value: "PRIORITYONE", type: "PRIORITYONE", label: "Priority1"},
            { value: "YUNWUJ", type: "YUNWUJ", label: "Yun wu jie"},
            { value: "HTY", type: "HTY", label: "HTY"},
            { value: "MAPLE", type: "MAPLE", label: "Maple"},
            { value: "FURTHERTRY", type: "FURTHERTRY", label: "Furthertry"},
            { value: "APLUS", type: "APLUS", label: "Aplus"},
            { value: "BAOCHEN", type: "BAOCHEN", label: "Bao Chen"},
            { value: "MFYC", type: "MFYC", label: "MFYC"},
            { value: "MAILAMERICAS", type: "MAILAMERICAS", label: "Mail Americas"},
            { value: "IFFS", type: "IFFS", label: "IFFS"},
            { value: "SHIPILOT", type: "SHIPILOT", label: "Shipilot"},
            { value: "OIS", type: "OIS", label: "易快通 OIS"},
            { value: "UNI", type: "UNI", label: "uniuni"},
            { value: "SQUAREMART", type: "SQUAREMART", label: "Squaremart"},
            { value: "DTOP", type: "DTOP", label: "DTOP"},
            { value: "ZDA", type: "ZDA", label: "ZDA"},
            { value: "EXPRESS.EC", type: "EPC", label: "运单王"},
            { value: "WallTech", type: "WallTech", label: "UBI Wall Tech"},
            { value: "AwesungNew", type: "AWESUNGNEW", label: "新火"}
        ];
        $rootScope.EXPRESS_CARRIER_FILTER = function(actual, expected) {
            if(actual.type.indexOf("PRINT_LABEL") != -1){
                return $rootScope.HAVEANYAUTH("PRINT_LABEL") || $rootScope.HAVEANYAUTH(actual.type.replace("PRINT_LABEL ", ""));
            }
            return $rootScope.HAVEANYAUTH(actual.type);
        }
        $rootScope.ADDRESS_TYPES = [{value:"amazon",type:"amazon",label:$translate.instant("autopsApp.addressBook.types.amazon")},
            {value:"self", label:$translate.instant("autopsApp.addressBook.types.self")},
            {value:"private", label:$translate.instant("autopsApp.addressBook.types.private")},
            {value:"thirdParty", label:$translate.instant("autopsApp.addressBook.types.thirdParty")},
            {value:"walmart", label:$translate.instant("autopsApp.addressBook.types.walmart")},
            {value:"gcang", label:$translate.instant("autopsApp.addressBook.types.gcang")},
            {value:"tiktok", label:$translate.instant("autopsApp.addressBook.types.tiktok")},
            {value:"linkw", label:$translate.instant("autopsApp.addressBook.types.linkw")},
            {value:"temu", label:$translate.instant("autopsApp.addressBook.types.temu")},
            {value:"wayfair", label:$translate.instant("autopsApp.addressBook.types.wayfair")},
            {value:"winit", label:$translate.instant("autopsApp.addressBook.types.winit")},
            {value:"4px", label:$translate.instant("autopsApp.addressBook.types.4px")},
            {value:"westernPost", label:$translate.instant("autopsApp.addressBook.types.westernPost")},
            {value:"orangeConnex", label:$translate.instant("autopsApp.addressBook.types.orangeConnex")},
            {value:"amazonca", label:$translate.instant("autopsApp.addressBook.types.amazonca")},
            {value:"newegg", label:$translate.instant("autopsApp.addressBook.types.newegg")}
        ];
        $rootScope.PLATFORM_OWMS = [{ value: "AmazonSp", type: "WMS_OVERSEAS", label: "Amazon(SP-API)"},
            { value: "spApi", type: "WMS_OVERSEAS", label: "Amazon(自研APP)"},
            { value: "Ebay", type: "WMS_OVERSEAS", label: "Ebay"},
            { value: "Walmart", type: "WMS_OVERSEAS", label: "Walmart"},
            { value: "Shopify", type: "WMS_OVERSEAS", label: "Shopify"},
            { value: "AmazonSftp", type: "WMS_OVERSEAS", label: "Amazon(VC)"},
            { value: "wayfair", type: "WMS_OVERSEAS", label: "Wayfair(FTP)"},
            { value: "wayfairSftp", type: "WMS_OVERSEAS", label: "Wayfair(SFTP)"},
            { value: "Overstock", type: "WMS_OVERSEAS", label: "Overstock"},
            { value: "HomeDepot", type: "WMS_OVERSEAS", label: "HomeDepot"},
            { value: "Costco", type: "WMS_OVERSEAS", label: "Costco"},
            { value: "temuErp", type: "WMS_OVERSEAS", label: "TEMU ERP"},
            { value: "tikTokShop", type: "WMS_OVERSEAS", label: "TikTok Shop"},
            { value: "ecang", type: "ECANG", label: "ecang"},
            { value: "MfyCang", type: "MFYC", label: "MfyCang"},
            { value: "chukou1", type: "CK1", label: "chukou1"},
            { value: "anmei", type: "ANMEI", label: "anmei"},
            { value: "logiwa", type: "LOGIWA", label: "logiwa"},
            { value: "deliverr", type: "DELIVERR", label: "deliverr"},
            { value: "mabang", type: "MABANG", label: "mabang"},
            { value: "tongtool", type: "TONGTOOL", label: "tongtool"},
            { value: "aidelivery", type: "AID", label: "aidelivery"},
            { value: "biaoju", type: "BIAOJU", label: "biaoju"},
            { value: "auwms", type: "AUWMS", label: "AU-OPS"},
            { value: "westernpost", type: "WSTPST", label: "Western Post"},
            { value: "GOEOS", type: "GOEOS", label: "GO EOS"},
            { value: "GOEOSV2", type: "GOEOS", label: "GO EOS V2"},
            { value: "mbWms", type: "MBWMS", label: "Mabang WMS"},
            { value: "bondex", type: "BONDEX", label: "Bondex"},
            { value: "SHPST", type: "SHPST", label: "Ship Station"},
            { value: "pedder", type: "PEDDER", label: "Pedder(八达仓)"},
            { value: "RXO", type: "RXO", label: "RXO"},
            { value: "JDFOP", type: "JDFOP", label: "JDFOP"},
            { value: "ThundEbay", type: "THUNDEBAY", label: "Ebay(Vender)"},
            { value: "AmazonMapping", type: "AMAZONMAPPING", label: "AmazonMapping"},
            { value: "AmazonTrack", type: "AMAZONTRACK", label: "AmazonTrack"},
            { value: "eccang", type: "ECCANG", label: "eccang"},
            { value: "TransfarSp", type: "TRANSFARSP", label: "Transfar shipping"},
            { value: "westernpostNew", type: "WESTERNPOST", label: "Western Post(New)"},
            { value: "WYB", type: "WYB", label: "无忧堡"},
            { value: "Shein", type: "SHEIN", label: "Shein"},
            { value: "Sinotrans", type: "SINOTRANS", label: "Sinotrans"},
            { value: "nextsls", type: "NEXTSLS", label: "Parcel C"},
            { value: "tongtoolOpenAPI", type: "TONGTOOLOPENAPI", label: "tongtool(OpenAPI)"},
            { value: "MoreLinkHW", type: "MORELINKHW", label: "MoreLink(Overseas)"},
            { value: "MoreLinkBC", type: "MORELINKBC", label: "MoreLink(BigCustomer)"},
            { value: "MoreLinkWH", type: "MORELINKWH", label: "MoreLink(Warehousing)"},
            { value: "PTPOCLK", type: "PTPOCLK", label: "PTP Onclick"},
            { value: "TEMU", type: "TEMU", label: "TEMU"},
            { value: "TEMU_TRACKING", type: "TEMU", label: "TEMU Tracking"},
            { value: "TEMUCW", type: "TEMUCW", label: "TEMU CW(合作仓)"},
            { value: "MoreLinkHWNew", type: "MORELINKHWNEW", label: "MoreLink(Overseas New)"},
            { value: "MoreLinkBCNew", type: "MORELINKBCNEW", label: "MoreLink(BigCustomer New)"},
            { value: "MoreLinkWHNew", type: "MORELINKWHNEW", label: "MoreLink(Warehousing New)"},
            { value: "MorelinkOutNew", type: "MORELINKOUTNEW", label: "MoreLink(OutBound New)"},
            { value: "nextslsV1", type: "NEXTSLSV1", label: "nextsls V1"},
            { value: "nodeWms", type: "NODEWMS", label: "Node WMS"},
            { value: "openSF", type: "OPENSF", label: "Open SF"},
            { value: "QianYiERP", type: "QIANYIERP", label: "千易ERP"},
            { value: "CVTE", type: "CVTE", label: "CVTE"},
            { value: "ShipOut", type: "SHIPOUT", label: "ShipOut"},
            { value: "LingXing", type: "LINGXING", label: "领星"},
            { value: "TPL", type: "TPL", label: "TPL"},
            { value: "ecangFBA", type: "ECANGFBA", label: "ecang(TMS-FBA)"},
            { value: "wuliucat", type: "WULIUCAT", label: "物流猫"},
            { value: "sinolines", type: "SINOLINES", label: "中外集运订舱"},
            { value: "usniuku", type: "USNIUKU", label: "纽酷US"},
            { value: "pacific", type: "PACIFIC", label: "太平洋保险"}];

        var getOrderTypes = ["mbWms", "AmazonSp", "spApi", "Amazon", "Ebay", "Walmart", "Shopify", "AmazonSftp", "wayfair", "Overstock", "HomeDepot", "deliverr",
            "mabang", "tongtool", "MfyCang", "biaoju", "SHPST", "tongtoolOpenAPI", "tikTokShop", "temuErp", "Shein"
        ];
        var pushOrderTypes = ["chukou1", "ecang", "anmei", "logiwa", "aidelivery", "auwms", "westernpost", "MfyCang", "JDFOP", "ThundEbay", "GOEOS", "GOEOSV2", "PufumeHouse", "nodeWms", "ShipOut", "LingXing", "nodeWms"];


        $rootScope.PLATFORM_OWMS_FILTER = function(actual, expected) {
            if($rootScope.account.companyId){
                return $rootScope.HAVEANYAUTH(actual.type);
            }
            return $rootScope.HAVEANYAUTH(actual.type) && getOrderTypes.indexOf(actual.value) !== -1;
        }
        $rootScope.PLATFORM_GET_FILTER = function(actual, expected) {
            return $rootScope.HAVEANYAUTH(actual.type) && getOrderTypes.indexOf(actual.value) !== -1;
        }
        $rootScope.PLATFORM_PUSH_FILTER = function(actual, expected) {
            return $rootScope.HAVEANYAUTH(actual.type) && pushOrderTypes.indexOf(actual.value) !== -1;
        }
        $rootScope.SHOW_PUSH_ORDERS = function(type) {
            return pushOrderTypes.indexOf(type) !== -1;
        }

        $rootScope.SHOW_GET_ORDERS = function(type) {
            return getOrderTypes.indexOf(type) !== -1;
        }

        $rootScope.ISFBAWMS = function(type) {
            return $rootScope.fbaTypes.indexOf(type) !== -1 || $rootScope.wmsTypes.indexOf(type) !== -1;
        }

        $rootScope.SERVICE_TYPE_FILTER = function(actual, expected) {
            if ($rootScope.ISFBAWMS(expected)) {
                return actual == "ALL" || actual.indexOf("FBA") !== -1;
            }
            return actual == "ALL" || actual.indexOf(expected) !== -1;
        }

        $rootScope.LOCATION_TYPES = [
            { value: "KD", label: $translate.instant('global.locationTypes.KD') },
            { value: "TK", label: $translate.instant('global.locationTypes.TK') },
            { value: "FB", label: $translate.instant('global.locationTypes.FB') },
            { value: "RT", label: $translate.instant('global.locationTypes.RT') },
            { value: null, label: $translate.instant('global.locationTypes.NA') }
        ];

        $rootScope.FMS_TYPES = [
            { value: 'FCL', label: $translate.instant('global.FCL') },
            { value: 'AIR', label: $translate.instant('global.AIR') },
            { value: 'LCL', label: $translate.instant('global.LCL') },
            { value: 'ITCT', label: $translate.instant('global.ITCT') },
            { value: 'WMS', label: $translate.instant('global.WMS') },
            { value: 'LOCAL', label: $translate.instant('global.LOCAL') },
            { value: 'LOCALKG', label: $translate.instant('global.LOCALKG') },
            { value: 'EXPRESS', label: $translate.instant('global.EXPRESS') },
            { value: 'FBASEA', label: $translate.instant('global.FBASEA') },
            { value: 'FBAAIR', label: $translate.instant('global.FBAAIR') },
            { value: 'FBARAIL', label: $translate.instant('global.FBARAIL') },
            { value: 'BULK', label: $translate.instant('global.BULK') },
            { value: 'RAIL', label: $translate.instant('global.RAIL') }
        ];

        $rootScope.FMS_PARCEL_TYPES = [
            { value: 'FCL', label: $translate.instant('global.FCL') },
            { value: 'AIR', label: $translate.instant('global.AIR') },
            { value: 'LCL', label: $translate.instant('global.LCL') },
            { value: 'ITCT', label: $translate.instant('global.ITCT') },
            { value: 'WMS', label: $translate.instant('global.WMS') },
            { value: 'LOCAL', label: $translate.instant('global.LOCAL') },
            { value: 'LOCALKG', label: $translate.instant('global.LOCALKG') },
            { value: 'EXPRESS', label: $translate.instant('global.EXPRESS') },
            { value: 'FBASEA', label: $translate.instant('global.FBASEA') },
            { value: 'FBAAIR', label: $translate.instant('global.FBAAIR') },
            { value: 'FBARAIL', label: $translate.instant('global.FBARAIL') },
            { value: 'BULK', label: $translate.instant('global.BULK') },
            { value: 'RAIL', label: $translate.instant('global.RAIL') },
            { value: 'PARCEL', label: $translate.instant('global.PARCEL') },
            { value: 'TMS', label: $translate.instant('global.TMS') }
        ];

        $rootScope.TK_STATES = [
            { value: '0', label: $translate.instant('autopsApp.expressTracking.state.0') },
            { value: '1', label: $translate.instant('autopsApp.expressTracking.state.1') },
            { value: '2', label: $translate.instant('autopsApp.expressTracking.state.2') },
            { value: '3', label: $translate.instant('autopsApp.expressTracking.state.3') },
            { value: '4', label: $translate.instant('autopsApp.expressTracking.state.4') },
            { value: '5', label: $translate.instant('autopsApp.expressTracking.state.5') },
            { value: '6', label: $translate.instant('autopsApp.expressTracking.state.6') },
            { value: '7', label: $translate.instant('autopsApp.expressTracking.state.7') },
            { value: '8', label: $translate.instant('autopsApp.expressTracking.state.8') }
        ];

        $rootScope.WMS_ACTION_TYPES = [
            { value: 'appIn', label: $translate.instant('autopsApp.wmsCargo.action.appIn') },
            { value: 'rapidIn', label: $translate.instant('autopsApp.wmsCargo.action.rapidIn') },
            { value: 'registerIn', label: $translate.instant('autopsApp.wmsCargo.action.registerIn') },
            { value: 'movement', label: $translate.instant('autopsApp.wmsCargo.action.movement') },
            { value: 'appOut', label: $translate.instant('autopsApp.wmsCargo.action.appOut') },
            { value: 'registerOut', label: $translate.instant('autopsApp.wmsCargo.action.registerOut') },
            { value: 'amendment', label: $translate.instant('autopsApp.wmsCargo.action.amendment') },
            { value: 'claim', label: $translate.instant('autopsApp.wmsCargo.action.claim') }
        ];

        $rootScope.PAYMENT_TYPES = [
            { value: 'DP', label: $translate.instant('autopsApp.opsClient.DP') },
            { value: 'beforeETA', label: $translate.instant('autopsApp.opsClient.beforeETA') },
            { value: 'credit', label: $translate.instant('autopsApp.opsClient.credit') },
            { value: 'weekly', label: $translate.instant('autopsApp.opsClient.weekly') },
            { value: 'halfMonthly', label: $translate.instant('autopsApp.opsClient.halfMonthly') },
            { value: 'monthly', label: $translate.instant('autopsApp.opsClient.monthly') },
            { value: 'doubleMonthly', label: $translate.instant('autopsApp.opsClient.doubleMonthly') },
            { value: 'tripleMonthly', label: $translate.instant('autopsApp.opsClient.tripleMonthly') },
            { value: 'fourMonthly', label: $translate.instant('autopsApp.opsClient.fourMonthly') },
            { value: 'fiveMonthly', label: $translate.instant('autopsApp.opsClient.fiveMonthly') },
            { value: 'sixMonthly', label: $translate.instant('autopsApp.opsClient.sixMonthly') },
            { value: 'payByDelivery', label: $translate.instant('autopsApp.opsClient.payByDelivery') }
        ];

        $rootScope.CREDIT_DATE_TYPES = [
            { value: 'etd', label: $translate.instant('autopsApp.opsClient.etd') },
            { value: 'eta', label: $translate.instant('autopsApp.opsClient.eta') },
            { value: 'ddate', label: $translate.instant('autopsApp.opsClient.ddate') },
            { value: 'biz', label: $translate.instant('autopsApp.opsClient.biz') },
            { value: 'bdate', label: $translate.instant('autopsApp.opsClient.bdate') },
            { value: 'bill', label: $translate.instant('autopsApp.opsClient.bill') },
            { value: 'wmsIn', label: $translate.instant('autopsApp.opsClient.wmsIn')},
            { value: 'inv', label: $translate.instant('autopsApp.opsClient.inv')}
        ];
        $rootScope.CREDIT_RATINGS = [
            { value: '1', label: $translate.instant('autopsApp.opsClient.creditRate.1') },
            { value: '2', label: $translate.instant('autopsApp.opsClient.creditRate.2') },
            { value: '3', label: $translate.instant('autopsApp.opsClient.creditRate.3') },
        ];
        $rootScope.VIP_LEVELS = [
            { value: null, label: '' },
            { value: 1, label: 'VIP 1' },
            { value: 2, label: 'VIP 2' },
            { value: 3, label: 'VIP 3' },
        ];

        $rootScope.CHECK_STATUS = [
            { value: "", label: "" },
            { value: 'notChecked', label: $translate.instant('autopsApp.guide.sts.notChecked') },
            { value: 'approved', label: $translate.instant('autopsApp.guide.sts.approved') },
            { value: 'unapproved', label: $translate.instant('autopsApp.guide.sts.unapproved') }
        ];

        $rootScope.WMS_CHARGE_TYPES = [
            { value: "", label: "" },
            { value: 'cbm', label: $translate.instant('autopsApp.wmsIn.chargeTypes.cbm') },
            { value: 'ton', label: $translate.instant('autopsApp.wmsIn.chargeTypes.ton') },
            { value: 'pkgs', label: $translate.instant('autopsApp.wmsIn.chargeTypes.pkgs') },
            { value: 'pallet', label: $translate.instant('autopsApp.wmsIn.chargeTypes.pallet') },
            { value: 'mt', label: $translate.instant('autopsApp.wmsIn.chargeTypes.mt') },
            { value: 'vw', label: $translate.instant('autopsApp.wmsIn.chargeTypes.vw') },
            { value: 'ctn', label: $translate.instant('autopsApp.wmsIn.chargeTypes.ctn') }
        ];

        $rootScope.COUNTRYNAME = null;
        $rootScope.getCountryName = function(code) {
            if (!code) {
                $rootScope.COUNTRYNAME = null;
            }
            angular.forEach($rootScope.countryCodes, function(data) {
                if (data.code == code) {
                    $rootScope.COUNTRYNAME = (lang == "en") ? data.enName : data.cnName;
                }
            })
            return $rootScope.COUNTRYNAME;
        }

        $rootScope.selectTxt = function(id) {
            // $timeout(function (){
            $("#" + id).select();
            // })
        }
        $rootScope.ISBETA = function() {
            if ($rootScope.absUrl.indexOf("beta.ai-ops.vip") != -1 ||
                $rootScope.absUrl.indexOf("47.107.193.198") != -1) {
                return true;
            } else {
                return false;
            }
        }
        $rootScope.ISFBA = function(type) {
            return $rootScope.fbaTypes.indexOf(type) !== -1;
        }

        $rootScope.ISFBAPARCEL = function(type) {
            return $rootScope.fbaTypes.indexOf(type) !== -1 || $rootScope.parcelTmsTypes.indexOf(type) !== -1;
        }

        $rootScope.ISWMS = function(type) {
            return $rootScope.wmsTypes.indexOf(type) !== -1;
        }



        $rootScope.onShipperSelected = function(data, obj, fd) {
            obj[fd] = data.blShow;
        }

        $rootScope.uppercaseObjFd = function(obj, fd) {
            if (!obj) {
                return;
            }
            if (!obj[fd]) {
                if (fd == 'deliveryTo') {
                    obj.deliverytoId = null;
                } else if (fd == 'destName') {
                    obj.destId = null;
                } else if (fd == 'podName') {
                    obj.podId = null;
                } else if (fd == 'polName') {
                    obj.polId = null;
                } else if (fd == 'porName') {
                    obj.porId = null;
                }
                $translate.refresh();
            } else {
                var outStr = "";
                for (var i = 0; i < obj[fd].length; i++) {
                    if (/[a-z]/.test(obj[fd].charAt(i))) {
                        outStr += obj[fd].charAt(i).toUpperCase();
                    } else {
                        outStr += obj[fd].charAt(i);
                    }
                }
                obj[fd] = outStr;
            }
        }

        $rootScope.OWN_IMPORTER_CHECK = function(code, guide) {
            if (code == "DE" || code == "ES" || code == "FR" || code == "IT" || code == "PL" || code == "GB") {
                guide.ownImporter = true;
            }
        }

        $rootScope.appsCount = {};
        $rootScope.getAppsCount = function(data, obj, fd) {
            if (!$rootScope.account || !$rootScope.account.id || !$rootScope.account.companyId) {
                return;
            }
            OaApplication.getAppsCount({
                userId: $rootScope.account.id,
                companyId: $rootScope.account.companyId
            }, function(result) {
                $rootScope.appsCount = result;
            })
        }

        $rootScope.MAX_LEN_CHECK = function(obj, fd, maxLen) {
            if (!obj || !obj[fd] || obj[fd].length < maxLen) {
                return;
            }
            obj[fd] = obj[fd].substring(0, maxLen);
        }

        var mblLockFd = ["guideShipperBl", "deliveryBl", "guideNotifyBl", "pkgsAndType", "goodsDiscription", "gwVol", "siRemarks", "mark", "blAttachment"];
        var hblLockFd = ["shipperBl", "cneeBl", "notifyBl", "deliveryBl", "alsoNotify", "mark", "pkgsAndType", "goodsDiscription", "gwVol", "blAttachment"];
        var mblLocked = function(obj, fd) {
            if(obj.bookingId && mblLockFd.indexOf(fd) !== -1 && obj.mblConfirmedTime){
                return true;
            }
            return false;
        }

        $rootScope.obfFdTextPop = function(obj, fd, notBlInfo, hblConfirmedTime) {
            if (!obj || mblLocked(obj, fd)) {
                return;
            }
            if(hblConfirmedTime && hblLockFd.indexOf(fd) !== -1){
                return;
            }

            $rootScope.notBlInfo = notBlInfo;
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return obj[fd];
                    }]
                }
            }).result.then(function(result) {
                obj[fd] = result;
            }, function() {});
        }

        $rootScope.ISSEA = function(shipmentType) {
            if (shipmentType == 'AIR' || shipmentType == 'FBAAIR' || shipmentType == 'EXPRESS') {
                return false;
            }
            return true;
        }

        $rootScope.BIZPORTS = [];
        var getBizPorts = function(shipmentType) {
            if (!shipmentType) {
                return;
            }
            if (shipmentType == "WMS" || shipmentType == "LOCALKG" || shipmentType == "LOCAL") {
                $rootScope.BIZPORTS = $rootScope.OPSPORTS.allports;
                return;
            }
            if ($rootScope.ISSEA(shipmentType)) {
                $rootScope.BIZPORTS = $rootScope.OPSPORTS.seaports;
            } else {
                $rootScope.BIZPORTS = $rootScope.OPSPORTS.airports;
            }

            $rootScope.OPSPORTS.allports = angular.copy($rootScope.BIZPORTS);
            angular.forEach($rootScope.OPSPORTS.fbaports, function(data) {
                $rootScope.OPSPORTS.allports.push(data);
            })
        }

        $rootScope.LOADPORTS = function(shipmentType) {
            if ($rootScope.OPSPORTS.allports && $rootScope.OPSPORTS.allports.length > 0) {
                getBizPorts(shipmentType);
                return;
            }
            OpsPorts.getAll(function(result) {
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                $rootScope.OPSPORTS.allports = [];
                $rootScope.OPSPORTS.fbaports = [];
                angular.forEach(result, function(data) {
                    $rootScope.OPSPORTS.allports.push(data);
                    if (data.type == 1) {
                        $rootScope.OPSPORTS.seaports.push(data);
                    } else if (data.type == 3) {
                        $rootScope.OPSPORTS.airports.push(data);
                    } else {
                        $rootScope.OPSPORTS.fbaports.push(data);
                    }
                })
                getBizPorts(shipmentType);
            })
        };

        $rootScope.LOADALLVENDERS = function() {
            if (!$rootScope.OPSVENDERS || $rootScope.OPSVENDERS.length == 0) {
                OpsVender.query(function(result) {
                    angular.forEach(result, function(data) {
                        data.branch = null;
                        data.team = null;
                        data.branch = null;
                        data.team = null;
                    })
                    $rootScope.OPSVENDERS = result;
                    initVendersData();
                });
            } else {
                initVendersData();
            }
        }

        var initVendersData = function() {
            $rootScope.opsClients = [];
            $rootScope.opsAgents = [];
            $rootScope.opsColoaders = [];
            $rootScope.traders = [];
            $rootScope.truckers = [];
            $rootScope.brokers = [];
            $rootScope.warehouses = [];
            $rootScope.fumigations = [];
            $rootScope.insurances = [];
            angular.forEach($rootScope.OPSVENDERS, function(data) {
                if (!data.blacklist) {
                    if (data.isClient) {
                        $rootScope.opsClients.push(data);
                    }
                    if (data.isColoader) {
                        $rootScope.opsColoaders.push(data);
                    }
                    if (data.isAgent) {
                        $rootScope.opsAgents.push(data);
                    }
                    if (data.isTrucker) {
                        $rootScope.truckers.push(data);
                    };
                    if (data.isCustom) {
                        $rootScope.brokers.push(data);
                    };
                    if (data.isWarehouse) {
                        $rootScope.warehouses.push(data);
                    };
                    if (data.isTrader) {
                        $rootScope.traders.push(data);
                    };
                    if (data.isInsurance) {
                        $rootScope.insurances.push(data);
                    };
                    if (data.isFumigation) {
                        $rootScope.fumigations.push(data);
                    };
                }
            })
        };

        var getContactId = function(type, guide) {
            if (type == "customer") {
                return guide.customerContactId;
            } else if (type == "client") {
                return guide.shipperContactId;
            } else if (type == "coloader") {
                return guide.bookingToContactId;
            } else if (type == "agent") {
                return guide.deliveryContactId;
            } else if (type == "trucker" || type == "popTrucker") {
                return;
            } else if (type == "broker") {
                return guide.brokerContactId;
            } else if (type == "warehouse") {
                return guide.warehouseContactId;
            } else if (type == "fumigation") {
                return guide.fumigationContactId;
            } else if (type == "insurance") {
                return guide.insuranceContactId;
            } else if (type == "trader") {
                return guide.traderContactId;
            } else if (type == "clearanceAgent") {
                return guide.clearanceAgentContactId;
            }
        }
        var updateContact = function(type, contact, guide) {
            if (type == "customer") {
                guide.customerContactId = contact.id;
                if (contact.userId) {
                    guide.customerUserId = contact.userId;
                }
                if (guide.customerId == guide.shipperId && !guide.shipperContactId) {
                    guide.shipperContactId = guide.customerContactId;
                    guide.shipperUserId = guide.customerUserId;
                }
            } else if (type == "client") {
                guide.shipperContactId = contact.id;
                if (contact.userId) {
                    guide.shipperUserId = contact.userId;
                }
                if (guide.customerId == guide.shipperId && !guide.customerContactId) {
                    guide.customerContactId = guide.shipperContactId;
                    guide.customerUserId = guide.shipperUserId;
                }
            } else if (type == "coloader") {
                guide.bookingToContactId = contact.id;
                guide.bookingToEmail = contact.email;
            } else if (type == "agent") {
                guide.deliveryContactId = contact.id;
                if (contact.userId) {
                    guide.agentUserId = contact.userId;
                }
            } else if (type == "clearanceAgent") {
                guide.clearanceAgentContactId = contact.id;
            } else if (type == "trucker") {
                guide.booking.bookingTrucks[0].truckerAttn = contact.attn;
                guide.booking.bookingTrucks[0].truckerTel = contact.tel;
                guide.booking.bookingTrucks[0].truckerFax = contact.fax;
                guide.booking.bookingTrucks[0].truckerMob = contact.mob;
                guide.booking.bookingTrucks[0].truckerEmail = contact.email;
                if (contact.userId) {
                    guide.booking.bookingTrucks[0].userId = contact.userId;
                }
            } else if (type == "popTrucker") {
                guide.truckerAttn = contact.attn;
                guide.truckerTel = contact.tel;
                guide.truckerFax = contact.fax;
                guide.truckerMob = contact.mob;
                guide.truckerEmail = contact.email;
                if (contact.userId) {
                    guide.userId = contact.userId;
                }
            } else if (type == "broker") {
                guide.brokerContactId = contact.id;
            } else if (type == "warehouse") {
                guide.warehouseContactId = contact.id;
            } else if (type == "fumigation") {
                guide.fumigationContactId = contact.id;
            } else if (type == "insurance") {
                guide.insuranceContactId = contact.id;
            } else if (type == "trader") {
                guide.traderContactId = contact.id;
            }
        }

        var choosedContact = function(type, contactList, guide) {
            for (var i = contactList.length - 1; i >= 0; i--) {
                if (contactList[i].defaultPic) {
                    updateContact(type, contactList[i], guide);
                    return;
                }
            }
            $rootScope.chooseContactVenderType = type;
            $uibModal.open({
                templateUrl: 'app/entities/guide/contact-list.html',
                controller: 'ContactListController',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return contactList;
                    }]
                }
            }).result.then(function(contact) {
                updateContact(type, contact, guide);
                $translate.refresh();
            }, function() {});
        }

        $rootScope.SHOWCONTACTLIST = function(id, type, guide) {
            if (guide.companyId != $rootScope.account.companyId && type != "broker" && type != "popTrucker") {
                return;
            }
            if (!type || !id) {
                return;
            }
            if ((type == "customer" && $rootScope.HAVENOAUTH('FUN_VIEW_CLIENT')) ||
                (type == "client" && $rootScope.HAVENOAUTH('FUN_VIEW_SHIPPER')) ||
                (type == "coloader" && $rootScope.HAVENOAUTH('FUN_VIEW_COLOADER')) ||
                (type == "agent" && $rootScope.HAVENOAUTH('FUN_VIEW_AGENT')) ||
                (type == "clearanceAgent" && $rootScope.HAVENOAUTH('FUN_VIEW_AGENT'))) {
                layer.msg($translate.instant('global.forbidden'));
                return;
            }
            if (!id) {
                return;
            }
            var contact = {};
            contact.venderId = id;
            contact.contactId = getContactId(type, guide);
            $rootScope.chooseContactVenderType = type;

            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-detail.html',
                controller: 'OpsChangeLogDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return contact;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                updateContact(type, result, guide);
            }, function() {});
        }

        $rootScope.SELECT_FILL_ID = function(item, obj, fd) {
            obj[fd] = item.id;
        }

        $rootScope._onColoaderSelected = function(data, guide) {
            guide.bookingToId = data.id;
            $rootScope.opsShippers = [];
            updateContact("coloader", {}, guide);
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.coloaderMustAudit)) {
                    guide.bookingToName = null;
                    return;
                }
                guide.bookingToId = result.venderId;
                guide.bookingToName = result.companyName;
                guide.bookingToEmail = result.email;
                guide.bookingToCode = result.code;
                if (result.contactList.length == 1 || ($rootScope.HAVENOAUTH('FUN_VIEW_COLOADER') && result.contactList.length > 1)) {
                    updateContact("coloader", result.contactList[0], guide);
                } else {
                    choosedContact("coloader", result.contactList, guide);
                }
                updateCheckBy(guide, result);
            });
        }
        var updateCheckBy= function(guide, result) {
            if (!guide.checkById) {
                guide.checkById = result.checkById;
                guide.checkByName = result.checkByName;
                updateImporter(guide, result);
            } else if (result.checkById && result.checkByName && result.checkById != guide.checkById) {
                layer.confirm($translate.instant('autopsApp.guide.changeAuditorAlert') + result.checkByName + "?", {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    guide.checkById = result.checkById;
                    guide.checkByName = result.checkByName;
                    updateImporter(guide, result);
                    $translate.refresh();
                }, function() {
                    updateImporter(guide, result);
                    return;
                });
            }
        }
        var updateImporter= function(guide, result) {
            if(!result.ownImporter || guide.booking.importerName == result.importerName){
                return;
            }
            if (!guide.ownImporter || !guide.booking.importerName) {
                guide.booking.importerTaxId = result.importerTaxId;
                guide.booking.importerEori = result.importerEori;
                guide.booking.importerName = result.importerName;
                guide.booking.importerAddress = result.importerAddress;
                guide.ownImporter = result.ownImporter;
            }
            else {
                layer.confirm($translate.instant('autopsApp.guide.changeImporterAlert') + result.importerName + "?", {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    guide.booking.importerTaxId = result.importerTaxId;
                    guide.booking.importerEori = result.importerEori;
                    guide.booking.importerName = result.importerName;
                    guide.booking.importerAddress = result.importerAddress;
                    guide.ownImporter = result.ownImporter;
                    $translate.refresh();
                }, function() {
                    return;
                });
            }
        }


        var updateClientSource = function(guide, result) {
            if(guide.clientSource){
                layer.confirm("客户来源信息发生变更，是否需要覆盖更新?", {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    guide.clientSource = result.clientSource;
                    $translate.refresh();
                }, function() {
                    return;
                });
            }
            else {
                guide.clientSource = result.clientSource;
            }
        }
        var changeGuideCustomer = function(data, guide) {
            guide.customerId = data.id;
            guide.customerUserId = data.userId;
            updateContact("customer", {}, guide);
            $rootScope.opsShippers = [];
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.clientMustAudit)) {
                    guide.customerName = null;
                    return;
                }
                guide.customerId = result.venderId;
                guide.customerName = result.companyName;
                guide.customerCode = result.code;
                guide.customerUserId = result.userId;
                guide.clientCredit = result.clientCredit;
                if (result.contactList.length == 1) {
                    updateContact("customer", result.contactList[0], guide);
                } else {
                    choosedContact("customer", result.contactList, guide);
                }
                if (!guide.shipperId) {
                    guide.shipperId = result.venderId;
                    guide.shipperName = result.companyName;
                    guide.shipperUserId = result.userId;
                    guide.shipperContactId = guide.customerContactId;
                    if (!guide.booking.shipperBl) {
                        guide.booking.shipperBl = result.shipperBl;
                    }
                    if (!guide.booking.cneeBl) {
                        guide.booking.cneeBl = result.cneeBl;
                    }
                    if (!guide.booking.notifyBl) {
                        guide.booking.notifyBl = result.notifyBl;
                    }
                    if (result.agentVid && !guide.isVirtual) {
                        var data = {};
                        data.id = result.agentVid;
                        $rootScope._onAgentSelected(data, guide);
                    }
                }
                if (result.cusId && result.cusName) {
                    guide.cusId = result.cusId;
                    guide.cusName = result.cusName;
                }
                if (result.creater && result.createdBy) {
                    guide.salesId = result.createdBy;
                    guide.salesName = result.creater;
                    guide.team = result.team;
                    guide.branch = result.branch;
                } else {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.pubClientAlert'));
                }

                if($rootScope.isZb(guide.companyId)){
                    updateClientSource(guide, result);
                }
                updateCheckBy(guide, result);
            })
        }
        $rootScope._onCustomerSelected = function(data, guide) {
            // W24051121422 / W24074303570
            if(guide.submited && ($rootScope.isYuyue(guide.companyId) || guide.companyId == 270)){
                var guideCopy = angular.copy(guide);
                guideCopy.customerId = data.id;
                layer.load(1, { shade: 0.3 });
                Guide.changeCustomerCheck(guideCopy, function(){
                    layer.closeAll();
                    changeGuideCustomer(data, guide);
                }, function(result){
                    guide.customerName = null;
                    $rootScope.ALERT_ERROR(result);
                });
                return;
            }
            else {
                changeGuideCustomer(data, guide);
            }
        }

        function venderCheckFailed(venderDto, mustAudit) {
            if (!venderDto.audited && mustAudit) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                return true;
            }
            if (venderDto.blacklist) {
                $rootScope.OPS_ALERT($translate.instant('global.blacklistAlert'));
                return true;
            }
            return false;
        }

        function payerCheckFailed(result) {
            if (result.blacklist) {
                $rootScope.OPS_ALERT($translate.instant('global.blacklistAlert'));
                return true;
            }
            if (result.audited) {
                return false;
            }
            if (!result.isClient && !result.isVender && !result.isAgent && !result.isColoader) {
                return false;
            }
            if (result.isClient && !result.clientMustAudit) {
                return false;
            }
            if (result.isVender && !result.truckerMustAudit) {
                return false;
            }
            if (result.isAgent && !result.agentMustAudit) {
                return false;
            }
            if (result.isColoader && !result.coloaderMustAudit) {
                return false;
            }
            $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.notAuditedAlert'));
            return true;
        }


        $rootScope._onPayerSelected = function(data, rate) {
            rate.venderId = data.id;
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (payerCheckFailed(result)) {
                    rate.payer = null;
                    rate.venderId = null;
                    return;
                }
            })
        }

        $rootScope._onClientSelected = function(data, guide) {
            guide.shipperId = data.id;
            guide.shipperUserId = data.userId;
            updateContact("client", {}, guide);
            $rootScope.opsShippers = [];
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.clientMustAudit)) {
                    guide.shipperName = null;
                    return;
                }
                guide.shipperId = result.venderId;
                guide.shipperName = result.companyName;
                guide.shipperUserId = result.userId;
                if (result.contactList.length == 1) {
                    updateContact("client", result.contactList[0], guide);
                } else {
                    choosedContact("client", result.contactList, guide);
                }
                if (!guide.booking.shipperBl) {
                    guide.booking.shipperBl = result.shipperBl;
                }
                if (!guide.booking.cneeBl) {
                    guide.booking.cneeBl = result.cneeBl;
                }
                if (!guide.booking.notifyBl) {
                    guide.booking.notifyBl = result.notifyBl;
                }
                if (result.agentVid && !guide.isVirtual) {
                    var data = {};
                    data.id = result.agentVid;
                    $rootScope._onAgentSelected(data, guide);
                }

                if (!guide.cusId) {
                    guide.cusId = result.cusId;
                    guide.cusName = result.cusName;
                }
                if (!guide.salesName) {
                    if (result.creater && result.createdBy) {
                        guide.salesId = result.createdBy;
                        guide.salesName = result.creater;
                        guide.team = result.team;
                        guide.branch = result.branch;
                    } else {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.pubClientAlert'));
                    }
                }
                if (!guide.customerId) {
                    guide.customerId = result.venderId;
                    guide.customerUserId = result.userId;
                    guide.customerContactId = guide.shipperContactId;
                    guide.customerName = result.companyName;
                    guide.clientCredit = result.clientCredit;
                    if (result.creater && result.createdBy) {
                        guide.salesId = result.createdBy;
                        guide.salesName = result.creater;
                        guide.team = result.team;
                        guide.branch = result.branch;
                    } else {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.pubClientAlert'));
                    }
                    if($rootScope.isZb(guide.companyId)){
                        updateClientSource(guide, result);
                    }
                }
                updateCheckBy(guide, result);
            });
            $rootScope.WMSGOODS = [];
        }

        $rootScope._onAgentSelected = function(data, guide) {
            guide.deliveryId = data.id;
            guide.agentUserId = data.userId;
            updateContact("agent", {}, guide);
            $rootScope.opsShippers = [];
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.agentMustAudit)) {
                    guide.deliveryName = null;
                    return;
                }
                guide.deliveryId = result.venderId;
                guide.agentUserId = result.userId;
                guide.deliveryName = result.companyName;
                guide.deliveryEmail = result.email;
                guide.deliverySecret = result.secret;
                if (!guide.guideShipperBl) {
                    guide.guideShipperBl = result.shipperBl;
                }
                if (!guide.mblOnly && result.cneeBl) {
                    if (!guide.deliveryBl) {
                        guide.deliveryBl = result.cneeBl;
                        guide.booking.deliveryBl = result.cneeBl;
                    } else {
                        layer.confirm($translate.instant('autopsApp.guide.amendMblCneeAlert'), {
                            title: guide.jobNum,
                            btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                        }, function(index) {
                            layer.close(index);
                            guide.deliveryBl = result.cneeBl;
                            guide.booking.deliveryBl = result.cneeBl;
                        }, function() {
                            return;
                        });
                    }
                }
                if (!guide.mblOnly && result.notifyBl) {
                    guide.guideNotifyBl = result.notifyBl;
                }
                if (result.contactList.length == 1 || ($rootScope.HAVENOAUTH('FUN_VIEW_AGENT') && result.contactList.length > 1)) {
                    updateContact("agent", result.contactList[0], guide);
                } else {
                    choosedContact("agent", result.contactList, guide);
                }
                updateCheckBy(guide, result);
            });
        }
        $rootScope._onClearanceAgentSelected = function(data, guide) {
            guide.clearanceAgentId = data.id;
            $rootScope.opsShippers = [];
            updateContact("clearanceAgent", {}, guide);
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.agentMustAudit)) {
                    guide.clearanceAgent = null;
                    return;
                }
                guide.clearanceAgentId = result.venderId;
                guide.clearanceAgent = result.companyName;
                if (!guide.mblOnly && result.notifyBl) {
                    guide.guideNotifyBl = result.notifyBl;
                }
                if (result.contactList.length == 1 || ($rootScope.HAVENOAUTH('FUN_VIEW_AGENT') && result.contactList.length > 1)) {
                    updateContact("clearanceAgent", result.contactList[0], guide);
                } else {
                    choosedContact("clearanceAgent", result.contactList, guide);
                }
                updateCheckBy(guide, result);
            });
        }
        $rootScope._onBrokerSelected = function(data, guide) {
            guide.brokerVid = data.id;
            updateContact("broker", {}, guide);
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.truckerMustAudit)) {
                    guide.brokerName = null;
                    return;
                }
                guide.brokerVid = result.venderId;
                guide.brokerName = result.companyName;
                if (result.contactList.length == 1) {
                    updateContact("broker", result.contactList[0], guide);
                } else {
                    choosedContact("broker", result.contactList, guide);
                }
                updateCheckBy(guide, result);
            });
        }

        $rootScope._onWarehouseSelected = function(data, guide) {
            guide.warehouseVid = data.id;
            updateContact("warehouse", {}, guide);
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.truckerMustAudit)) {
                    guide.warehouseName = null;
                    return;
                }
                guide.warehouseVid = result.venderId;
                guide.warehouseName = result.companyName;
                if (result.contactList.length == 1) {
                    updateContact("warehouse", result.contactList[0], guide);
                } else {
                    choosedContact("warehouse", result.contactList, guide);
                }
                updateCheckBy(guide, result);
            });
        }

        $rootScope._onTruckerSelected = function(data, truck) {
            guide.truckerVid = data.id;
            truck.userId = data.userId;
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.truckerMustAudit)) {
                    guide.truckerName = null;
                    return;
                }
                truck.truckerVid = result.venderId;
                truck.userId = result.userId;
                truck.truckerName = result.companyName;
                truck.truckerAddress = result.address;
                if (result.contactList && result.contactList.length > 0) {
                    truck.truckerAttn = result.contactList[0].attn;
                    truck.truckerTel = result.contactList[0].tel;
                    truck.truckerFax = result.contactList[0].fax;
                    truck.truckerMob = result.contactList[0].mob;
                    truck.truckerEmail = result.contactList[0].email;
                    truck.truckerCc = result.contactList[0].cc;
                    if (result.contactList[0].userId) {
                        truck.userId = result.contactList[0].userId;
                    }
                }
                updateCheckBy(guide, result);
            });
        }
        $rootScope._onFumigationSelected = function(data, guide) {
            guide.fumigationVid = data.id;
            updateContact("fumigation", {}, guide);
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.truckerMustAudit)) {
                    guide.fumigationName = null;
                    return;
                }
                guide.fumigationVid = result.venderId;
                guide.fumigationName = result.companyName;
                if (result.contactList.length == 1) {
                    updateContact("fumigation", result.contactList[0], guide);
                } else {
                    choosedContact("fumigation", result.contactList, guide);
                }
                updateCheckBy(guide, result);
            });
        }
        $rootScope._onInsuranceSelected = function(data, guide) {
            guide.insuranceVid = data.id;
            updateContact("insurance", {}, guide);
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.truckerMustAudit)) {
                    guide.insuranceName = null;
                    return;
                }
                guide.insuranceVid = result.venderId;
                guide.insuranceName = result.companyName;
                if (result.contactList.length == 1) {
                    updateContact("insurance", result.contactList[0], guide);
                } else {
                    choosedContact("insurance", result.contactList, guide);
                }
                updateCheckBy(guide, result);
            });
        }
        $rootScope._onTraderSelected = function(data, guide) {
            guide.traderVid = data.id;
            updateContact("trader", {}, guide);
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (venderCheckFailed(result, result.truckerMustAudit)) {
                    guide.traderName = null;
                    return;
                }
                guide.traderVid = result.venderId;
                guide.traderName = result.companyName;
                if (result.contactList.length == 1) {
                    updateContact("trader", result.contactList[0], guide);
                } else {
                    choosedContact("trader", result.contactList, guide);
                }
                updateCheckBy(guide, result);
            });
        }
        $rootScope.opsItems = [];
        $rootScope.validItems = [];
        $rootScope.loadItems = function() {
            if ($rootScope.opsItems.length == 0) {
                OpsItem.getAll({
                    companyId: $rootScope.account.companyId
                }, function(result) {
                    $rootScope.opsItems = result;
                    $rootScope.validItems = [];
                    angular.forEach(result, function(data) {
                        if (!data.disabled) {
                            $rootScope.validItems.push(data);
                        }
                    })
                });
            };
        };

        $rootScope.OPSCURRENCYS = null;
        $rootScope.loadCurrencys = function(date) {
            if ($rootScope.OPSCURRENCYS && $rootScope.OPSCURRENCYS.length > 1) {
                return;
            }
            OpsExchangeRate.getAllCurrencys(function(result) {
                $rootScope.OPSCURRENCYS = result;
                $rootScope.OPSCURRENCYS.splice(0, 0, "");
            })
        }
        $rootScope.GET_LINES = function(shipmentType) {
            var outLines = [];
            if (shipmentType == "AIR" || shipmentType == "FBAAIR" || shipmentType == "EXPRESS") {
                angular.copy($rootScope.airlanes, outLines);
                angular.forEach($rootScope.sealanes, function(line) {
                    outLines.push(line);
                })
            } else {
                angular.copy($rootScope.sealanes, outLines);
                angular.forEach($rootScope.airlanes, function(line) {
                    outLines.push(line);
                })
            }
            return outLines;
        }

        $rootScope.DELIVERYTYPES = [{
            value: "",
            label: ""
        }, {
            value: 'express',
            label: $translate.instant('autopsApp.booking.deliveryType.express')
        }, {
            value: 'truck',
            label: $translate.instant('autopsApp.booking.deliveryType.truck')
        }, {
            value: 'ltl',
            label: $translate.instant('autopsApp.booking.deliveryType.ltl')
        }, {
            value: 'ftl',
            label: $translate.instant('autopsApp.booking.deliveryType.ftl')
        }, {
            value: 'fcl',
            label: $translate.instant('autopsApp.booking.deliveryType.fcl')
        }, {
            value: 'dd',
            label: $translate.instant('autopsApp.booking.deliveryType.dd')
        }, {
            value: 'pickup',
            label: $translate.instant('autopsApp.booking.deliveryType.pickup')
        }, {
            value: 'truckPickup',
            label: $translate.instant('autopsApp.booking.deliveryType.truckPickup')
        }, {
            value: 'expressPickup',
            label: $translate.instant('autopsApp.booking.deliveryType.expressPickup')
        }, {
            value: 'owmsShelf',
            label: $translate.instant('autopsApp.booking.deliveryType.owmsShelf')
        }, {
            value: 'owmsHold',
            label: $translate.instant('autopsApp.booking.deliveryType.owmsHold')
        }, {
            value: 'storageTemp',
            label: $translate.instant('autopsApp.booking.deliveryType.storageTemp')
        }, {
            value: 'portToPort',
            label: $translate.instant('autopsApp.booking.deliveryType.portToPort')
        }];

        $rootScope.DC_TYPES = [{
            value: "",
            label: ""
        }, {
            value: "1",
            label: "Signature"
        }, {
            value: "2",
            label: "Adult Signature"
        }];
        $rootScope.CHARGE_TYPES = [{
            value: "",
            label: ""
        }, {
            value: "kg",
            label: $translate.instant('autopsApp.opsCarrier.chargeTypeOptions.kg')
        }, {
            value: "cbm",
            label: $translate.instant('autopsApp.opsCarrier.chargeTypeOptions.cbm')
        }, {
            value: "plt",
            label: $translate.instant('autopsApp.opsCarrier.chargeTypeOptions.plt')
        }];
        $rootScope.airlanes = [{
            id: "",
            name: ""
        }, {
            id: 'AF',
            name: $translate.instant('global.lanes.AF')
        }, {
            id: 'AS',
            name: $translate.instant('global.lanes.AS')
        }, {
            id: 'CC',
            name: $translate.instant('global.lanes.CC')
        }, {
            id: 'EC',
            name: $translate.instant('global.lanes.EC')
        }, {
            id: 'EU',
            name: $translate.instant('global.lanes.EU')
        }, {
            id: 'NA',
            name: $translate.instant('global.lanes.NA')
        }, {
            id: 'NC',
            name: $translate.instant('global.lanes.NC')
        }, {
            id: 'NE',
            name: $translate.instant('global.lanes.NE')
        }, {
            id: 'NW',
            name: $translate.instant('global.lanes.NW')
        }, {
            id: 'OC',
            name: $translate.instant('global.lanes.OC')
        }, {
            id: 'SA',
            name: $translate.instant('global.lanes.SA')
        }, {
            id: 'SC',
            name: $translate.instant('global.lanes.SC')
        }, {
            id: 'SW',
            name: $translate.instant('global.lanes.SW')
        }, {
            id: 'Zone1',
            name: 'Zone1'
        }, {
            id: 'Zone2',
            name: 'Zone2'
        }, {
            id: 'Zone3',
            name: 'Zone3'
        }, {
            id: 'Zone4',
            name: 'Zone4'
        }, {
            id: 'Zone5',
            name: 'Zone5'
        }, {
            id: 'Zone6',
            name: 'Zone6'
        }, {
            id: 'Zone7',
            name: 'Zone7'
        }, {
            id: 'Zone8',
            name: 'Zone8'
        }];

        $rootScope.sealanes = [{
            id: "",
            name: ""
        }, {
            id: 'AME',
            name: $translate.instant('global.lanes.AME')
        }, {
            id: 'AML',
            name: $translate.instant('global.lanes.AML')
        }, {
            id: 'AMW',
            name: $translate.instant('global.lanes.AMW')
        }, {
            id: 'ANZ',
            name: $translate.instant('global.lanes.ANZ')
        }, {
            id: 'ASL',
            name: $translate.instant('global.lanes.ASL')
        }, {
            id: 'BRS',
            name: $translate.instant('global.lanes.BRS')
        }, {
            id: 'CND',
            name: $translate.instant('global.lanes.CND')
        }, {
            id: 'CSJ',
            name: $translate.instant('global.lanes.CSJ')
        }, {
            id: 'DLI',
            name: $translate.instant('global.lanes.DLI')
        }, {
            id: 'EAF',
            name: $translate.instant('global.lanes.EAF')
        }, {
            id: 'EBP',
            name: $translate.instant('global.lanes.EBP')
        }, {
            id: 'EIL',
            name: $translate.instant('global.lanes.EIL')
        }, {
            id: 'IPL',
            name: $translate.instant('global.lanes.IPL')
        }, {
            id: 'MAM',
            name: $translate.instant('global.lanes.MAM')
        }, {
            id: 'MDE',
            name: $translate.instant('global.lanes.MDE')
        }, {
            id: 'MRL',
            name: $translate.instant('global.lanes.MRL')
        }, {
            id: 'NAF',
            name: $translate.instant('global.lanes.NAF')
        }, {
            id: 'PCI',
            name: $translate.instant('global.lanes.PCI')
        }, {
            id: 'QDI',
            name: $translate.instant('global.lanes.QDI')
        }, {
            id: 'RMA',
            name: $translate.instant('global.lanes.RMA')
        }, {
            id: 'RFE',
            name: $translate.instant('global.lanes.RFE')
        }, {
            id: 'SAF',
            name: $translate.instant('global.lanes.SAF')
        }, {
            id: 'SAM',
            name: $translate.instant('global.lanes.SAM')
        }, {
            id: 'TJI',
            name: $translate.instant('global.lanes.TJI')
        }, {
            id: 'WAF',
            name: $translate.instant('global.lanes.WAF')
        }, {
            id: 'XMI',
            name: $translate.instant('global.lanes.XMI')
        }, {
            id: 'ZSJ',
            name: $translate.instant('global.lanes.ZSJ')
        }, {
            id: 'NAM',
            name: $translate.instant('global.lanes.NAM')
        }, {
            id: 'EUR',
            name: $translate.instant('global.lanes.EUR')
        }, {
            id: 'SEA',
            name: $translate.instant('global.lanes.SEA')
        }, {
            id: 'NEA',
            name: $translate.instant('global.lanes.NEA')
        }, {
            id: 'MEX',
            name: $translate.instant('global.lanes.MEX')
        }, {
            id: 'SAW',
            name: $translate.instant('global.lanes.SAW')
        }, {
            id: 'SAE',
            name: $translate.instant('global.lanes.SAE')
        }, {
            id: 'PNM',
            name: $translate.instant('global.lanes.PNM')
        }, {
            id: 'CRB',
            name: $translate.instant('global.lanes.CRB')
        }, {
            id: 'CAM',
            name: $translate.instant('global.lanes.CAM')
        }, {
            id: 'BSE',
            name: $translate.instant('global.lanes.BSE')
        }, {
            id: 'RSE',
            name: $translate.instant('global.lanes.RSE')
        }, {
            id: 'SBR',
            name: $translate.instant('global.lanes.SBR')
        }, {
            id: 'BBG',
            name: $translate.instant('global.lanes.BBG')
        }, {
            id: 'Zone1',
            name: 'Zone1'
        }, {
            id: 'Zone2',
            name: 'Zone2'
        }, {
            id: 'Zone3',
            name: 'Zone3'
        }, {
            id: 'Zone4',
            name: 'Zone4'
        }, {
            id: 'Zone5',
            name: 'Zone5'
        }, {
            id: 'Zone6',
            name: 'Zone6'
        }, {
            id: 'Zone7',
            name: 'Zone7'
        }, {
            id: 'Zone8',
            name: 'Zone8'
        }];

        //java constants
        $rootScope.importCountrys = [{
            code: "",
            name: ""
        }, {
            code: "US",
            name: $translate.instant('autopsApp.booking.bookingCtns.US')
        }, {
            code: "CA",
            name: $translate.instant('autopsApp.booking.bookingCtns.CA')
        }, {
            code: "DE",
            name: $translate.instant('autopsApp.booking.bookingCtns.DE')
        }, {
            code: "GB",
            name: $translate.instant('autopsApp.booking.bookingCtns.GB')
        }, {
            code: "AU",
            name: $translate.instant('autopsApp.booking.bookingCtns.AU')
        }, {
            code: "JP",
            name: $translate.instant('autopsApp.booking.bookingCtns.JP')
        }, {
            code: "LK",
            name: $translate.instant('autopsApp.booking.bookingCtns.LK')
        }];

        function hfBills() {
            var entity = {};
            entity.vender = {};
            $uibModal.open({
                templateUrl: 'app/entities/hf-bill/hf-bill-delete-dialog.html',
                controller: 'HfBillDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCompany');
                        $translatePartialLoader.addPart('hfBill');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            ImChatService.logout();
            layer.closeAll();
            $scope.recentContacts = new Array();
            $scope.title = $translate.instant("global.menu.Message");
            $scope.messageCount = 0;
            collapseNavbar();
            Auth.logout();
            $rootScope.account = {};
            $state.go('home', null, { reload: true });
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        $rootScope.shipperId = null;
        $rootScope.salesId = null;
        $rootScope.opId = null;
        $rootScope.docId = null;
        $rootScope.accountId = null;
        // 技术支持
        $rootScope.techId = null;
        var imLoginSuccess = false;
        $scope.messageCount = 0;
        $scope.recentContacts = new Array();
        $scope.useridPrefix = 'demo_'; //后面被本人请求返回的聊天信息后缀所覆盖

        //------------------------------------------Init Recent Contact----------------------------------------------------------------------
        var initRecentContact = function() {
            var recentResponsed = new Array();
            WKIT.Conn.sdk.Base.getRecentContact({
                count: 99,
                success: function(recentRes) {
                    if (recentRes.code == '1000') {
                        var recentResult = recentRes.data.cnts;
                        if (recentResult.length > 0) {
                            var requestParamImUsers = "";
                            for (var i = 0; i < recentResult.length; i++) {
                                var uid = recentResult[i]['uid'].substring(8, recentResult[i]['uid'].length);
                                requestParamImUsers = requestParamImUsers + "," + uid;
                            }

                            requestParamImUsers = requestParamImUsers.substring(1, requestParamImUsers.length);

                            ImChatFactory.getRecentContancts({
                                userids: requestParamImUsers
                            }, function(data) {

                                if (data.length > 0) {
                                    for (var j = 0; j < data.length; j++) {
                                        var responedObj = new Object();
                                        responedObj['userid'] = data[j]['userid'];
                                        responedObj['nick'] = data[j]['nick'];
                                        responedObj['unRmsgNum'] = 0;
                                        recentResponsed.push(responedObj);
                                    }
                                }

                                $scope.recentContacts = new Array();
                                angular.forEach(recentResponsed, function(user, index) {
                                    $scope.recentContacts.push(user);
                                });
                                initUnReadMsg();

                            });
                        } else {
                            console.log('No Recent Contact');
                        }
                    } else {
                        console.log('No Recent Contact');
                    }
                },
                error: function(error) {
                    console.log('GET Recent Contact Failed');
                }
            });
        }
        //------------------------------------------Init unRead Msg---------------------------------------------------------------------------
        var initUnReadMsg = function() {
            WKIT.Conn.sdk.Base.getUnreadMsgCount({
                count: 10,
                success: function(unReadRes) {
                    console.log('dealWithUnReadMsg');
                    if (unReadRes.code == '1000') {
                        var unReadResponsed = new Array();
                        var unReadContactList = unReadRes.data;
                        if (unReadContactList.length > 0) {
                            for (var i = 0; i < unReadContactList.length; i++) {
                                var responsedObj = new Object();
                                var userid = unReadContactList[i]['contact'].substring(8, unReadContactList[i]['contact'].length);
                                responsedObj['userid'] = userid;
                                responsedObj['unRmsgNum'] = unReadContactList[i]['msgCount'];
                                unReadResponsed.push(responsedObj);
                            }
                            console.log('Has unRead Msg Success');
                            var notInContacts = false;
                            angular.forEach(unReadResponsed, function(msg, index) {
                                for (var i = 0; i < $scope.recentContacts.length; i++) {
                                    if (msg['userid'] == $scope.recentContacts[i]['userid']) {
                                        notInContacts = true;
                                        $scope.messageCount = $scope.messageCount + msg['unRmsgNum'];
                                        $scope.recentContacts[i]['unRmsgNum'] = $scope.recentContacts[i]['unRmsgNum'] + msg['unRmsgNum'];
                                        break;
                                    }
                                }
                                // if someone which not in contacts send new msg to me, add to Contacts
                                if (!notInContacts) {
                                    ImChatFactory.getUserInfo({
                                        userid: msg['userid']
                                    }, function(result) {
                                        if (angular.isDefined(result['userid']) && null != result['userid'] && "" != result['userid']) {
                                            var newUser = new Object();
                                            newUser['userid'] = result['userid'];
                                            newUser['nick'] = result['nick'];
                                            newUser['unRmsgNum'] = msg['unRmsgNum'];
                                            $scope.recentContacts.push(newUser);
                                        }

                                    });
                                }
                            });

                        }
                    } else {
                        console.log('No Un Read Msg');
                    }
                },
            });
        }
        //------------------------------------------Init unRead Msg---------------------------------------------------------------------------
        var startListenMsg = function() {
            WKIT.Conn.sdk.Event.on('START_RECEIVE_ALL_MSG', function(eventRes) {
                if (eventRes.code == '1000') {
                    var result = new Object();
                    var resultMsgs = eventRes.data['msgs'];
                    result['userid'] = resultMsgs[0]['from'];
                    result['msg'] = resultMsgs[0]['msg'];
                    result['userid'] = result['userid'].substring(8, result['userid'].length);
                    //                    newMsg.notify(result);
                    if (result['userid'] != $rootScope.touid) {
                        if ($scope.recentContacts.length > 0) {
                            for (var i = 0; i < $scope.recentContacts.length; i++) {
                                if ($scope.recentContacts[i]['userid'] == result['userid']) {
                                    $scope.messageCount = $scope.messageCount + 1;
                                    $scope.recentContacts[i]['unRmsgNum'] = $scope.recentContacts[i]['unRmsgNum'] + 1;
                                    break;
                                }
                            } //for
                            $rootScope.$apply();
                            $scope.$apply();
                        }
                    }
                }
            });
        }
        //------------------------------------------change Chat User---------------------------------------------------------------------------
        //click contactsList to change User id ops_id
        $scope.swtichUser = function(userid) {
            if (angular.isDefined(userid)) {
                ImChatService.switchTouid(userid, function() {});
                controllerCurrentUserReleated(userid);
            }
            $("#J_wkitTextarea").focus();
        }

        //Control chat box title
        $scope.title = $translate.instant("global.menu.Message");
        var controllerCurrentUserReleated = function(userid) {
            var needtofind = true;
            angular.forEach($scope.recentContacts, function(user, index) {
                if (user['userid'] == userid) {
                    needtofind = false;
                    $scope.messageCount = $scope.messageCount - user['unRmsgNum'];
                    user['unRmsgNum'] = 0;
                    $scope.title = $translate.instant("global.menu.chatWith") + user['nick'];
                    $(".layui-layer-title").html($scope.title);
                }
            });
            if (needtofind) {
                ImChatFactory.getUserInfo({
                    userid: userid
                }, function(result) {
                    if (angular.isDefined(result['userid']) && (null != result['userid'])) {
                        $scope.title = $translate.instant("global.menu.chatWith") + result['nick'];
                        $(".layui-layer-title").html($scope.title);
                    }
                    var newUser = new Object();
                    newUser['userid'] = result['userid'];
                    newUser['nick'] = result['nick'];
                    newUser['unRmsgNum'] = 0;
                    $scope.recentContacts.push(newUser);
                    sortContacts(result['userid']);
                });
            } else {
                sortContacts(userid);
            }
        }
        var sortContacts = function(userid) {
            $scope.contsTemp = new Array();
            if ($scope.recentContacts.length > 1) {
                //delete current user from contacts
                for (var i = $scope.recentContacts.length - 1; i >= 0; i--) {
                    if ($scope.recentContacts[i]['userid'] == $rootScope.touid) {
                        $scope.contsTemp.push($scope.recentContacts[i]);
                        $scope.recentContacts.splice(i, 1);
                        break;
                    }
                }

                //put rest
                for (var i = 0; i < $scope.recentContacts.length; i++) {
                    $scope.contsTemp.push($scope.recentContacts[i]);
                }

                $scope.recentContacts = $scope.contsTemp;
            }
        }

        $rootScope.qqChat = function(userid, guide) {
            if (guide && guide.jobNum) {
                var copyTxt = "Job#: " + guide.jobNum + "; ";
                if (guide.soNum) {
                    copyTxt = copyTxt + "SO#: " + guide.soNum + "; ";
                }
                if (guide.polName || guide.podName) {
                    copyTxt = copyTxt + "POL-POD: " + (guide.polName ? guide.polName : "") + " - " + (guide.podName ? guide.podName : "");
                }
                var aux = document.createElement("input");
                aux.setAttribute("value", copyTxt);
                document.body.appendChild(aux);
                aux.select();
                document.execCommand("copy");
                document.body.removeChild(aux);
                layer.msg($translate.instant("global.qqChatMsg"));
            }
            Account.getQq({ id: userid }, function(result) {
                window.open('tencent://message/?uin=' + result.qq + '&Site=au-ops.com&Menu=yes;javascript(0);');
            }, $rootScope.ALERT_ERROR);
        }

        //chat with someone by userid
        $rootScope.liveChat = function(userid, guide) {
            if (!imLoginSuccess) {
                $rootScope.initLiveChat();
                // layer.msg($translate.instant("global.menu.liveChatLoginFailed"));
                return;
            }

            $scope.chatTo = '';
            if (angular.isUndefined(userid) || null == userid) {
                console.log('liveChat id is Undefined');
                return;
            }
            var jobNum = null;
            $scope.chatTo = $scope.useridPrefix + userid;
            if (guide && guide.jobNum) {
                jobNum = guide.jobNum;
            }
            $scope.swtichUser($scope.chatTo);
            if (jobNum) {
                ImChatService.sendMsg($scope.chatTo, jobNum);
            }
            $scope.popupChatDialog();
        }
        //pop up the box
        $scope.popupChatDialog = function() {
            if (!imLoginSuccess) {
                $rootScope.initLiveChat();
                // layer.msg($translate.instant("global.menu.liveChatLoginFailed"));
                return;
            } else {
                chatDialogShow();
            }

        }
        var chatDialogShow = function() {
            layer.open({
                type: 1,
                title: $scope.title,
                shadeClose: true,
                shade: false,
                maxmin: true,
                area: ['800px', '562px'],
                content: $("#chatDialog")
            });
        }

        $rootScope.GROUP_COMPANYS = null;
        $rootScope.LOAD_GROUP_COMPANYS = function() {
            if ($rootScope.GROUP_COMPANYS && $rootScope.GROUP_COMPANYS.length > 0) { return; }
            if ($rootScope.account && $rootScope.account.companyId && $rootScope.account.authorities.length > 0) {
                OpsCompany.getGroupCompanys(function(result) {
                    $rootScope.GROUP_COMPANYS = result;
                })
            }
        };
        $rootScope.SWITCH_JOB_COMPANY = function(guideId, companyId) {
            if ($rootScope.HAVENOAUTH('AU_ORDER_TRANSFER')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_ORDER_TRANSFER');
                return;
            }
            if (!guideId) {
                return;
            }
            Guide.switchJobCompany({
                guideId: guideId,
                newComId: companyId
            }, function(result) {
                layer.msg($translate.instant('global.messages.switchSuccess'));
            }, $rootScope.ALERT_ERROR)
        };
        $rootScope.initLiveChat = function() {
            // return;
            if (!Principal.isAuthenticated()) {
                return;
            }
            //----------------------------------------- Init live Chat -----------------------------------------------------
            ImChatFactory.getMe(function(result) {
                if (null == result.userid) {
                    return;
                }
                var userid = result.userid;
                var appkey = result.appkey;
                var credential = result.credential;
                var cutPrefix = result.userid.split('_');
                if (cutPrefix.length > 1) {
                    if (angular.isDefined(cutPrefix[0])) {
                        //                console.log('prefix:'+cutPrefix[0]+'_');
                        $scope.useridPrefix = cutPrefix[0] + '_';
                    }
                }
                var prefix = $scope.useridPrefix;
                WKIT.init({
                    container: document.getElementById('webimco'),
                    width: 520,
                    height: 500,
                    uid: userid,
                    appkey: appkey,
                    credential: credential,
                    touid: prefix + "1",
                    sendBtn: true,
                    avatar: 'https://hforwarder.oss-cn-shenzhen.aliyuncs.com/HeadShot/default.jpg',
                    toAvatar: 'https://hforwarder.oss-cn-shenzhen.aliyuncs.com/HeadShot/default.jpg',
                    onLoginSuccess: function(success) {
                        console.log('wkit登录成功');
                        $rootScope.touid = $scope.useridPrefix + "1";
                        initRecentContact();
                        startListenMsg();
                        $("#J_wkitTextarea").attr("placeholder", "");
                        $(".layui-layer-title").html($translate.instant("global.menu.Message"));
                        imLoginSuccess = true;
                    },
                    onLoginError: function(error) {
                        imLoginSuccess = false;
                        console.log('登录失败:' + error.resultText);
                    }
                }); //-----------------------------WKIT.init
            });
        }

        $rootScope.initLiveChat();

        if ($rootScope.ISDEMO) {
            $("#floatTools").css('display', 'block');
            $("#aFloatTools_Show").click(function() {
                $('#divFloatToolsView').animate({
                    width: 'show',
                    opacity: 'show'
                }, 100, function() {
                    $('#divFloatToolsView').show();
                });
                $('#aFloatTools_Show').hide();
                $('#aFloatTools_Hide').show();
            });

            $("#aFloatTools_Hide").click(function() {
                $('#divFloatToolsView').animate({
                    width: 'hide',
                    opacity: 'hide'
                }, 100, function() {
                    $('#divFloatToolsView').hide();
                });
                $('#aFloatTools_Show').show();
                $('#aFloatTools_Hide').hide();
            });
            $('#divFloatToolsView').animate({
                width: 'show',
                opacity: 'show'
            }, 100, function() {
                $('#divFloatToolsView').show();
            });
            $('#aFloatTools_Show').hide();
            $('#aFloatTools_Hide').show();
        } else {
            $("#aFloatTools_Hide").trigger('click');
            $("#floatTools").css('display', 'none');
        }


        $rootScope.COPYALL = function(text) {
            var aux = document.createElement("input");
            aux.setAttribute("value", text);
            document.body.appendChild(aux);
            aux.select();
            document.execCommand("copy");
            document.body.removeChild(aux);
            layer.msg($translate.instant("global.copyedText"), { time: 600 });
        }
        $rootScope.COMPANYFILTER = function(item) {
            return $rootScope.account && $rootScope.account.authorities && $rootScope.account.authorities.indexOf(item.id + '') !== -1;
        };

        $rootScope.HAVEAUTH = function(authority) {
            if (!$rootScope.account || !$rootScope.account.authorities) {
                return false;
            }
            return $rootScope.account.authorities.indexOf(authority) !== -1;
        };

        $rootScope.HAVENOAUTH = function(authority) {
            if (!$rootScope.account || !$rootScope.account.authorities) {
                return true;
            }
            return $rootScope.account.authorities.indexOf(authority) === -1;
        };

        $rootScope.HAVEBOTHAUTH = function(authority1, authority2) {
            if (!$rootScope.account || !$rootScope.account.authorities) {
                return false;
            }
            return $rootScope.account.authorities.indexOf(authority1) !== -1 && $rootScope.account.authorities.indexOf(authority2) !== -1;
        };

        $rootScope.HAVEANYAUTH = function() {
            if (!$rootScope.account || !$rootScope.account.authorities) {
                return false;
            }
            var result = false;
            angular.forEach(Array.from(arguments), function(authority){
                if($rootScope.account.authorities.indexOf(authority) !== -1){
                    result = true;
                }
            })
            return result;
        };

        $rootScope.HAVEALLAUTH = function() {
            if (!$rootScope.account || !$rootScope.account.authorities) {
                return false;
            }
            var result = true;
            angular.forEach(Array.from(arguments), function(authority){
                if($rootScope.account.authorities.indexOf(authority) == -1){
                    result = false;
                }
            })
            return result;
        };
        $scope.showWms = function() {
            var authorities = ["ROLE_WAREHOUSE", "ROLE_BROKER", "ROLE_ACCOUNT", "ROLE_OPERATOR", "FUN_WMS_WAREHOUSE", "FUN_WMS_GOODS"];
            if (!$rootScope.account || !$rootScope.account.authorities) {
                return false;
            }
            var isWmsClerk = false;
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                    isWmsClerk = true;
                    break;
                }
            }
            if (isWmsClerk && $rootScope.account.authorities.indexOf("WMS") !== -1) {
                return true;
            } else {
                return false;
            }
        }

        $scope.showCashier = function() {
            if (!$rootScope.account || angular.equals($rootScope.account, {}) || !$rootScope.account.authorities) {
                return false;
            }
            if ($rootScope.account.authorities.indexOf("CMIS") !== -1) {
                var authorities = ["ROLE_CASHIER", "ROLE_ACCOUNT"];
                for (var i = 0; i < authorities.length; i++) {
                    if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                        return true;
                    }
                }
            } else {
                return false;
            }
        }

        $rootScope.VIEW_ALL_CLIENTS = function() {
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return false;
            }
            if ($rootScope.account.authorities.indexOf("ROLE_OPERATOR") === -1 &&
                $rootScope.account.authorities.indexOf("ROLE_DOCUMENT") === -1 &&
                $rootScope.account.authorities.indexOf("ROLE_BOSS") === -1 &&
                $rootScope.account.authorities.indexOf("FUN_VIEW_ALL_CLIENTS") === -1 &&
                $rootScope.account.authorities.indexOf("ROLE_ACCOUNT") === -1 &&
                $rootScope.account.authorities.indexOf("ROLE_CASHIER") === -1) {
                return true;
            }
            return false;
        };

        $rootScope.VIEWGE = function(content, shipmentList) {
            if (content.guideId) {
                var obj = { id: content.guideId };
                $rootScope.RATESPOP(obj, "guide", shipmentList);
            } else if (content.parcelId) {
                var obj = { id: content.parcelId };
                $rootScope.RATESPOP(obj, "expressParcel", shipmentList);
            } else if (content.expressParcelId) {
                var obj = { id: content.expressParcelId };
                $rootScope.RATESPOP(obj, "expressParcel", shipmentList);
            } else if (content.wmsInId) {
                var obj = { id: content.wmsInId };
                $rootScope.RATESPOP(obj, "wms", shipmentList);
            }
        }

        $scope.combineVender = function() {
            if ($rootScope.HAVENOAUTH('ROLE_BRANCH')) {
                layer.msg($translate.instant('global.forbiddenBranch'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/opsVender/opsVender-dialog.html',
                controller: 'OpsVenderDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsVender');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        $rootScope.PICKINGTICKETPOP = function(entity) {
            if (!entity) {
                entity = {};
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-cargo/wms-cargo-picking.html',
                controller: 'WmsCargoPickingController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }
        // $rootScope.PICKINGTICKETPOP();

        function rateFinder(type, isCostFinder) {
            var entity = {};
            entity.isNew = type;
            entity.isCostFinder = isCostFinder;
            $uibModal.open({
                templateUrl: 'app/entities/ops-cost/ops-cost-detail.html',
                controller: 'OpsCostDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }


        $rootScope.EXPORTEXCEL = (function() {
            var uri = 'data:application/vnd.ms-excel;base64,',
                template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64 = function(s) {
                    return window.btoa(unescape(encodeURIComponent(s)))
                },
                format = function(s, c) {
                    return s.replace(/{(\w+)}/g,
                        function(m, p) {
                            return c[p];
                        })
                }
            return function(table, name) {
                if (!table.nodeType) table = document.getElementById(table)
                var ctx = {
                    worksheet: name || 'Worksheet',
                    table: table.innerHTML
                }
                window.location.href = uri + base64(format(template, ctx))
            }
        })()


        $scope.switchCompany = function(company) {
            OpsCompany.changeCompany({ companyId: company.id }, function(result) {
                Principal.identity(true).then(function(account) {
                    $rootScope.account = account;
                    $rootScope.OPSCURRENCYS = null;
                    $rootScope.OPSPORTS = {};
                    $rootScope.OPSVENDERS = [];
                    $rootScope.dnTemplates = null;
                    $rootScope.cnTemplates = null;
                    $rootScope.WMSGOODS = null;
                    $rootScope.choosedRole = null;
                    $rootScope.CLIENT_DEPOSITE = null;
                    $rootScope.CARGOTYPES = null;
                    $rootScope.HANDLETYPETITLES = null;
                    $rootScope.OATYPES = null;
                    $rootScope.CLIENT_SOURCES = null;
                    $rootScope.CLIENT_TYPES = null;
                    $rootScope.CUSTOMS_TYPES = null;
                    loadInit();
                    // $state.go('home');
                    $state.go('home', null, { reload: true });
                    $translate.refresh();
                });
            }, function(error) {
                if (error.status == 403) {
                    layer.msg($translate.instant('global.userOverLimit'));
                }
                else if (error.status == 401) {
                    layer.msg($translate.instant('global.forbidden'));
                }
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            })
        };

        function pubSearch() {
            $uibModal.open({
                templateUrl: 'app/entities/guide/pub-search.html',
                controller: 'PubSearchController',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return null;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        function writeOff() {
            var entt = {};
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountant/com-write-off.html',
                controller: 'ComWriteOffController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entt;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        function fastWriteOff(byDn) {
            $rootScope.WRITE_OFF_POP({ byDn: byDn });
        }
        $rootScope.WRITE_OFF_POP = function(entt) {
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountant/fast-write-off.html',
                controller: 'FastWriteOffController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entt;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('autoCheck');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }
        // if($rootScope.absUrl.indexOf("8080") !== -1){
        //     fastWriteOff(true);
        // }
        $(document).keydown(function(event) {
            if (!$rootScope.account || !$rootScope.account.companyId) { return }
            if (event.keyCode == 112) {
                event.preventDefault();
                fastWriteOff(true);
            } else if (event.keyCode == 115) {
                event.preventDefault();
                $rootScope.PICKINGTICKETPOP();
            } else if (event.keyCode == 117) {
                event.preventDefault();
                $state.go('all-export');
            } else if (event.keyCode == 118) {
                event.preventDefault();
                $state.go('all-import');
            } else if (event.keyCode == 119) {
                event.preventDefault();
                $state.go('sea-export');
            } else if (event.keyCode == 120) {
                event.preventDefault();
                $state.go('air-export');
            } else if (event.keyCode == 121) {
                event.preventDefault();
                $state.go('air-import');
            }
        });
        $rootScope.TD_POP_BY_JOB = function(jobNum) {
            WmsIn.getByWarehousingNumber({warehousingNumber: jobNum}, function(result){
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-distribution-dialog.html',
                    controller: 'WmsDistributionDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', function(WmsIn) {
                            return result;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                }, function() {
                });
            }, function(){
                layer.msg($translate.instant('global.noRecord'));
            })

        }




        $rootScope.WMSPOP = function(wmsId) {
            if (isNaN(wmsId)) {
                wmsId = WinAES.decode(wmsId);
            }
            WmsIn.get({ id: wmsId }, function(wmsIn) {
                var htmlPath = 'app/entities/wms-in/wms-in-detail.html';
                var controllerName = 'WmsInDetailController';
                if (wmsIn.isOut) {
                    htmlPath = 'app/entities/wms-in/wms-in-out-detail.html';
                    controllerName = 'WmsInOutDetailController';
                }
                $uibModal.open({
                    templateUrl: htmlPath,
                    controller: controllerName,
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return wmsIn;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {}, function() {});
            });
        }
        $rootScope.ROLE_BIZPOP = function(guideId) {
            $uibModal.open({
                templateUrl: ($rootScope.HAVEAUTH && $rootScope.HAVEAUTH("FB_WF_VIEW")) ? 'app/entities/guide/fba-role-dialog.html' : 'app/entities/guide/fba-dialog.html',
                controller: 'FbaDialogController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: ['Guide', function(Guide) {
                        return Guide.get({ id: guideId }).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                $state.go('^');
            }, function() {
                $state.go('^');
            });
        }
        $rootScope.BIZPOP = function(guideId) {
            if (isNaN(guideId)) {
                guideId = WinAES.decode(guideId);
            }
            Guide.get({
                id: guideId
            }, function(guide) {
                bizPopShow(guide);
            }, function(argument) {
                layer.msg($translate.instant('global.forbidden'));
            });
        };
        $rootScope.BIZPOP_BY_JOB = function(jobNum) {
            if(!jobNum){
                return;
            }
            if(DataUtils.isTkJobNum(jobNum)){
                $rootScope.VIEWEXPRESS_BY_JOB(jobNum);
            }
            else if(DataUtils.isTdJobNum(jobNum)){
                $rootScope.TD_POP_BY_JOB(jobNum);
            }
            else {
                Guide.getByJobNum({
                    jobNum: jobNum
                }, function(guide) {
                    bizPopShow(guide);
                }, function(argument) {
                    layer.msg($translate.instant('global.noRecord'));
                });
            }
        };

        function bizPopShow(guide) {
            $rootScope.id = "view";
            var htmlPath = 'app/entities/import/import-dialog.html';
            var controllerName = 'ImportDialogController';
            if (guide.shipmentType == "EXPRESS" || guide.shipmentType == "FBAAIR" || guide.shipmentType == "FBASEA" || guide.shipmentType == "FBARAIL" ||
                guide.shipmentType == "WMS" || guide.shipmentType == "LOCAL" || guide.shipmentType == "LOCALKG") {
                controllerName = 'FbaDialogController';
                htmlPath = ($rootScope.HAVEAUTH && $rootScope.HAVEAUTH("FB_WF_VIEW")) ? 'app/entities/guide/fba-role-dialog.html' : 'app/entities/guide/fba-dialog.html';
            } else if (guide.isExport) {
                if (guide.shipmentType == "AIR") {
                    htmlPath = 'app/entities/guide/air-dialog.html';
                    controllerName = 'AirDialogController';
                } else {
                    htmlPath = 'app/entities/guide/sea-dialog.html';
                    controllerName = 'SeaDialogController';
                }
            }
            $uibModal.open({
                templateUrl: htmlPath,
                controller: controllerName,
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }


        $rootScope.VIEWEXPRESS = function(id) {
            if (!id) {
                return;
            }
            if (isNaN(id)) {
                id = WinAES.decode(id);
            }
            ExpressParcel.get({
                id: id
            }, function(result) {
                expressParcelPop(result);
            }, function(argument) {
                layer.msg($translate.instant('global.forbidden'));
            });
        }
        $rootScope.VIEWEXPRESS_BY_JOB = function(jobNum) {
            if (!jobNum) {
                return;
            }
            ExpressParcel.getRapidInParcel({
                jobNum: jobNum
            }, function(result) {
                expressParcelPop(result);
            }, function(result) {
                layer.msg($translate.instant('global.noRecord'));
            })
        }
        var expressParcelPop = function(result) {
            var htmlPath = 'app/entities/express-parcel/express-parcel-dialog.html';
            var controllerName = 'ExpressParcelDialogController';
            var popSize = 'xxxl';
            if (result.shipmentType == "PACKAGE") {
                htmlPath = 'app/entities/express-packing/express-packing-dialog.html';
                controllerName = 'ExpressPackingDialogController';
                popSize = 'xxl';
            }
            $uibModal.open({
                templateUrl: htmlPath,
                controller: controllerName,
                controllerAs: 'vm',
                backdrop: false,
                size: popSize,
                resolve: {
                    entity: [function(ExpressParcel) {
                        return result;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressParcel');
                        $translatePartialLoader.addPart('expressPacking');
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        }


        $rootScope.PRINT_BYTES_DIR = function(bytes) {
            var file = new File([bytes], "3EREWREW.pdf", {
                type: 'application/pdf'
            });
            // console.log(file);
            if (file.size == 0) {
                layer.msg($translate.instant('global.loadFileFailed'));
                return;
            }
            var fileUrl = URL.createObjectURL(file);
            PRINT_PDF_DIR(fileUrl);
        }

        function PRINT_PDF_DIR(fileUrl) {
            var iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.src = fileUrl;
            iframe.onload = function() {
                setTimeout(function() {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 0);
            };
        }

        function previewPdf(fileUrl, sourceUrl) {
            var pdfInfo = {
                type: 2,
                title: 'PDF Preview',
                maxmin: true,
                shadeClose: true,
                shade: 0.3,
                area: ['60%', '90%'],
                content: fileUrl
            }
            if (sourceUrl) {
                pdfInfo.btn = [$translate.instant('global.downloadSourceFile')];
                pdfInfo.yes = function (index) {
                    $rootScope.DOWNLOAD_URL(sourceUrl);
                    layer.close(index);
                };
            }
            layer.open(pdfInfo);
        }

        $rootScope.PRINTBYTES = function(bytes, sourceUrl) {
            var file = new File([bytes], "3EREWREW.pdf", {
                type: 'application/pdf'
            });
            if (file.size == 0) {
                layer.msg($translate.instant('global.loadFileFailed'));
                return;
            }
            var fileUrl = URL.createObjectURL(file);
            previewPdf(fileUrl, sourceUrl);
        }
        $rootScope.EXPORTDOWNLOAD = function(result, format, fileName) {
            var file;
            if (format == 2) {
                file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                fileName = fileName + ".xlsx";
            } else {
                file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                fileName = fileName + ".pdf";
            }

            if (file.size == 0) {
                layer.msg($translate.instant('global.loadFileFailed'));
                return;
            }
            var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
            var aTag = document.createElement("a");
            aTag.download = fileName;
            aTag.href = blobURL;
            aTag.click();
        }
        $rootScope.PDFPRINTEXCELDOWNLOAD = function(result, format, fileName) {
            if (format == 2 || format == "excel") {
                $rootScope.DOWNLOADEXCEL(result, fileName + ".xlsx");
            } else {
                $rootScope.PRINTBYTES(result.data);
            }
        }
        $rootScope.DOWNLOADEXCEL = function(result, fileName) {
            var file = new Blob([result.data], {
                type: 'application/vnd.ms-excel'
            });
            if (file.size == 0) {
                layer.msg($translate.instant('global.loadFileFailed'));
                return;
            }
            var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
            var aTag = document.createElement("a");
            aTag.download = fileName;
            aTag.href = blobURL;
            aTag.click();
        }

        $rootScope.PDFPOP = function(fileUrl, filename) {
            if(filename && filename.indexOf("Guide_") == 0 && $rootScope.VIEW_GUIDE_FORBIDDEN()){
                return;
            }
            if (!fileUrl) {
                layer.msg($translate.instant('global.urlNullAlert'));
                return;
            }
            if (fileUrl.indexOf("http:") !== -1 && fileUrl.indexOf("aliyuncs.com") !== -1) {
                fileUrl = fileUrl.replace("http:", "https:");
            }
            if (DataUtils.isPic(filename)) {
                previewImg(fileUrl);
                return;
            }

            if ((angular.lowercase(fileUrl).indexOf(".pdf") == -1 && (!filename || filename.indexOf(".pdf") == -1)) ||
                filename == ".PDF" || ($rootScope.account && $rootScope.account.companyId == 273 && !$rootScope.ISDEMO)) {
                if (fileUrl.indexOf("%2C") !== -1 || fileUrl.indexOf("?path=") !== -1) {
                    if(fileUrl.indexOf("?path=") !== -1){//http://127.0.0.1:8080/oss/2342342342.pdf?path=REPORTS/GUIDE/1544823/2342342342.pdf&signature=MTcwNjUyMjExNzgyMw==
                        filename = fileUrl.substring(fileUrl.indexOf("?path=") + 6, fileUrl.indexOf("&signature="));
                        filename = filename.substring(filename.lastIndexOf("/") + 1);
                    }
                    download_oss_file(fileUrl, filename);
                }
                else {
                    var aTag = document.createElement("a");
                    aTag.href = fileUrl;
                    aTag.click();
                }
                return;
            }
            if (fileUrl.indexOf("?") !== -1 && fileUrl.indexOf("?path=") == -1) {
                OpsFiles.previewPdf({ filePath: fileUrl }, function(result) {
                    $rootScope.PRINTBYTES(result.data, fileUrl);
                });
            } else {
                previewPdf(fileUrl);
            }
        }

        var download_oss_file = function(fileUrl, filename) {
            OpsFiles.previewPdf({ filePath: fileUrl }, function(result) {
                var blob = new Blob([result.data], {
                    type: "application/vnd.ms-excel"
                });
                var anchor = document.createElement("a");
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = filename;
                anchor.click();
                window.URL.revokeObjectURL(blob);
            });
        }


        $rootScope.DOWNLOAD_URL = function(fileUrl) {
            if (!fileUrl) {
                layer.msg($translate.instant('global.urlNullAlert'));
                return;
            }
            if (fileUrl.indexOf("http:") !== -1 && fileUrl.indexOf("aliyuncs.com") !== -1) {
                fileUrl = fileUrl.replace("http:", "https:");
            }
            var aTag = document.createElement("a");
            aTag.href = fileUrl;
            aTag.click();
        }

        $rootScope.PDF_PIC_POP = function(fileUrl, filename) {
            if (!fileUrl) {
                layer.msg($translate.instant('global.urlNullAlert'));
                return;
            }
            if (fileUrl.indexOf("http:") !== -1 && fileUrl.indexOf("aliyuncs.com") !== -1) {
                fileUrl = fileUrl.replace("http:", "https:");
            }
            if (DataUtils.isPic(filename)) {
                previewImg(fileUrl);
                return;
            }
            if (!DataUtils.isPdf(filename)) {
                var aTag = document.createElement("a");
                aTag.href = fileUrl;
                aTag.click();
                return;
            }
            if (fileUrl.indexOf("?") !== -1 && fileUrl.indexOf("?path=") == -1) {
                OpsFiles.previewPdf({ filePath: fileUrl }, function(result) {
                    $rootScope.PRINTBYTES(result.data, fileUrl);
                });
            } else {
                previewPdf(fileUrl);
            }
        }
        $rootScope.PREVIEW_IMG = function(fileUrl) {
            previewImg(fileUrl);
        }
        function previewImg(url) {
            var imgHtml = "<img src='" + url + "' />";
            layer.open({
                type: 1,
                title: 'Image Preview',
                maxmin: true,
                shadeClose: true,
                shade: 0.3,
                area: ['60%', '90%'],
                content: imgHtml
            });
        }

        $rootScope.PRINT_PDF = function(fileUrl, filename) {
            if (!fileUrl) {
                return;
            }
            if (fileUrl.indexOf("http:") !== -1 && fileUrl.indexOf("aliyuncs.com") !== -1) {
                fileUrl = fileUrl.replace("http:", "https:");
            }
            if (fileUrl.indexOf(".pdf") == -1 && (!filename || filename.indexOf(".pdf") == -1)) {
                var aTag = document.createElement("a");
                aTag.href = fileUrl;
                aTag.click();
                return;
            }
            if (fileUrl.indexOf("?") !== -1 && fileUrl.indexOf("?path=") == -1) {
                OpsFiles.previewPdf({ filePath: fileUrl }, function(result) {
                    $rootScope.PRINTBYTES(result.data, fileUrl);
                });
            } else {
                previewPdf(fileUrl);
            }
        }

        $rootScope.DOWNLOADTXT = function(result, fileName) {
            var file = new Blob([result.data], {
                type: 'application/csv;charset=GBK'
            });
            if (file.size == 0) {
                layer.msg($translate.instant('global.loadFileFailed'));
                return;
            }
            var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
            var aTag = document.createElement("a");
            aTag.download = fileName;
            aTag.href = blobURL;
            aTag.click();
        }

        function goWmsGoods() {
            ManageEnterprise.clientGet(function(retult) {
                if (retult && retult.id) {
                    $rootScope.manageEnterprise = retult;
                    $state.go('wms-goods', null, {
                        reload: false
                    });
                }
            }, function() {
                layer.msg($translate.instant('global.noManageEnterpriseAlert'));
            })
        }

        var clientChargesPop = function(id, type) {
            if($rootScope.HAVEAUTH("SS_FB_CHARGES")){
                return;
            }
            var entity = {
                id: id,
                type: type
            }
            $uibModal.open({
                templateUrl: 'app/entities/write-off-records/write-off-records-detail.html',
                controller: 'WriteOffRecordsDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {}, function() {});
        }
        $rootScope.RATESPOP = function(guide, type, guides) {
            if (type == "wms" && !guide.id && guide.guideId) {
                guide = angular.copy(guide);
                guide.id = guide.guideId;
                type = "guide";

                guides = angular.copy(guides);
                angular.forEach(guides, function(data) {
                    data.id = data.guideId;
                })
            }
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!$rootScope.account.companyId) {
                clientChargesPop(guide.id, type);
                return;
            }
            var entity = {};
            entity.guide = guide;
            entity.shipmentType = type;
            entity.guidesList = guides;
            $uibModal.open({
                templateUrl: $rootScope.HAVEAUTH('OLD_RATES_VIEW')?'app/entities/guideRates/guideRates-detail-old.html':'app/entities/guideRates/guideRates-detail.html',
                controller: 'GuideRatesDetailController',
                controllerAs: 'vm',
                size: 'xxxl',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        }
        $rootScope.GUIDE_RATES_POP = function(guide) {
            var entity = {};
            entity.guide = angular.copy(guide);
            entity.shipmentType = 'guide';
            entity.guidesList = null;
            guideRatesPop(entity, 'autopsApp:loadGuide', guide);
        }
        $rootScope.PARCEL_RATES_POP = function(guide) {
            var entity = {};
            entity.guide = angular.copy(guide);
            entity.shipmentType = 'expressParcel';
            entity.guidesList = null;
            guideRatesPop(entity, 'autopsApp:loadParcel', guide);
        }

        var guideRatesPop = function(entity, loadTag, shipment){
            $uibModal.open({
                templateUrl: $rootScope.HAVEAUTH('OLD_RATES_VIEW')?'app/entities/guideRates/guideRates-detail-old.html':'app/entities/guideRates/guideRates-detail.html',
                controller: 'GuideRatesDetailController',
                controllerAs: 'vm',
                size: 'xxxl',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                $scope.$emit(loadTag, shipment);
            }, function() {
                $scope.$emit(loadTag, shipment);
            });
        }

        $rootScope.PRINTSKU = function(sku, name, manageEnterpriseId, clientId, nameEn) {
            if (!sku) {
                layer.msg("Pls input Code/SKU!");
                return;
            }
            var wmsGoodsList = [];
            var wmsGood = {};
            wmsGood.sku = sku;
            wmsGood.name = name;
            wmsGood.nameEn = nameEn;
            wmsGood.manageEnterpriseId = manageEnterpriseId;
            wmsGood.clientId = clientId;
            wmsGoodsList.push(wmsGood);
            WmsGoods.skuBarcode({}, wmsGoodsList, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }
        $rootScope.PRINT_WMSIN_SKU = function(wmsIn) {
            if (!wmsIn.cargoList || wmsIn.cargoList.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            WmsIn.skuBarcode({}, wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $rootScope.OPREQUIREMENT = function(wmsIn, type) {
            var entity = angular.copy(wmsIn);
            entity.type = type;
            $uibModal.open({
                templateUrl: 'app/entities/wms-work-log/wms-operation-requirment-dialog.html',
                controller: 'WmsOperationRequirmentDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsWorkLog');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.WORKLOG_EDIT = function(workLog) {
            $uibModal.open({
                templateUrl: 'app/entities/wms-work-log/wms-work-log-dialog.html',
                controller: 'WmsWorkLogDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: ['WmsWorkLog', function(WmsWorkLog) {
                        return WmsWorkLog.get({id : workLog.id}).$promise;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        $rootScope.WORKLOGPOP = function(wmsIn, cargo, opsItem) {
            if ((wmsIn && !wmsIn.id && !wmsIn.guideId) || (cargo && !cargo.id)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var workLog = {
                companyId: $rootScope.account.companyId,
                createdBy: $rootScope.account.id,
                creater: $rootScope.account.firstName + " " + $rootScope.account.lastName
            };
            if (cargo) {
                if (wmsIn && wmsIn.id) {
                    cargo.wmsInId = wmsIn.id;
                    workLog.warehouseId = wmsIn.warehouseId;
                    workLog.warehouse = wmsIn.warehouseName;
                }
                workLog.wmsInId = cargo.wmsInId;
                workLog.wmsCargoId = cargo.id;
                workLog.count = cargo.pkgs;
                workLog.vender = cargo.clientName;
                workLog.venderId = cargo.clientId;
                workLog.wmsInNum = cargo.wmsInNum;
                workLog.jobNum = cargo.wmsInNum;
            } else if (wmsIn) {
                workLog.wmsInId = wmsIn.id;
                workLog.count = wmsIn.applicationNum;
                workLog.vender = wmsIn.clientName;
                workLog.venderId = wmsIn.venderId;
                workLog.wmsInNum = wmsIn.warehousingNumber;
                workLog.jobNum = wmsIn.warehousingNumber;
                workLog.warehouseId = wmsIn.warehouseId;
                workLog.warehouse = wmsIn.warehouseName;
            }
            if (opsItem) {
                DataUtils.updateWmsWorkLog(workLog, wmsIn, cargo, opsItem);
            }

            $uibModal.open({
                templateUrl: 'app/entities/wms-work-log/wms-work-log-dialog.html',
                controller: 'WmsWorkLogDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return workLog;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsWorkLog');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $scope.fbaRapidIn = function(type, guideId) {
            var entity = {
                guideId: guideId,
                type: type
            }
            $uibModal.open({
                templateUrl: 'app/entities/guide/fba-wmsIn-dialog.html',
                controller: 'FbaWmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            });
        }
        $rootScope.FMS_RAPID_IN = function(type, guideId) {
            var entity = {
                id: guideId,
                guideId: guideId,
                type: type
            }
            $uibModal.open({
                templateUrl: 'app/entities/warehousing-data/warehousing-data-dialog.html',
                controller: 'WarehousingDataDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                $scope.$emit('autopsApp:loadGuide', entity);
            }, function() {
                $scope.$emit('autopsApp:loadGuide', entity);
            });
        }

        $scope.preIn = function(isWmsIn) {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-pre-in.html',
                controller: 'WmsPreInController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return isWmsIn;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        $scope.returnIn = function() {
            $uibModal.open({
                templateUrl: 'app/entities/wms-log/wms-log-delete-dialog.html',
                controller: 'WmsLogDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        $scope.rapidShelve = function() {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-shelve.html',
                controller: 'WmsShelveController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        $scope.claimCargo = function() {
            $uibModal.open({
                templateUrl: 'app/entities/wms-cargo/wms-unclaimed-cargo-dialog.html',
                controller: 'WmsUnclaimedCargoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        $rootScope.BATCHSTATUS = function(guides) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            if(amendGuideForbidden(guides)){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/guide/batch-status.html',
                controller: 'BatchStatusController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return guides;
                    }],
                }
            }).result.then(function(results) {}, function() {});
        }

        $rootScope.portRemarkPop = function(companyId, remarkCode) {
            if (!companyId || !remarkCode) {
                return;
            }
            var entity = {
                companyId: companyId,
                code: remarkCode
            }
            $uibModal.open({
                templateUrl: 'app/entities/jhiSignature/jhiSignatures.html',
                controller: 'JhiSignatureController',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                }
            }).result.then(function(results) {}, function() {});
        }

        $rootScope.dutyCalculation = function(bCtn) {
            $uibModal.open({
                templateUrl: 'app/entities/custom-declaration/custom-declaration-dialog.html',
                controller: 'CustomDeclarationDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return angular.copy(bCtn);
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                // bCtn.dutyAmt = result.dutyAmt;
            }, function() {});
        }
        $rootScope.isZb = function(companyId) {
            if (companyId == 131 || companyId == 343 || companyId == 446 || companyId == 466 || companyId == 720 || companyId == 971 || (companyId >= 138 && companyId <= 141)) {
                return true;
            }
            return false;
        }
        $rootScope.IS_YF = function(companyId) {
            if (companyId == 372 ||
                companyId == 54 || companyId == 373) {
                return true;
            }
            return false;
        }
        $rootScope.IS_XJ = function(companyId) {
            if (companyId == 434 || companyId == 435 ||companyId == 440 || companyId == 453 || companyId == 635) {
                return true;
            }
            return false;
        }


        // 是否为“拓普达”
        $rootScope.isTPD = function(companyId) {
            if (companyId == 263 || companyId == 54) {
                return true;
            }
            return false;
        }
        // 是否为“景华峰”
        $rootScope.isJHF = function(){
            if($rootScope.account.companyId == 54 || $rootScope.account.companyId == 643 || $rootScope.HOST_COMPANY_ID == 643){
                return true;
            }else{
                return false;
            }
        }

        $rootScope.isThunder = function(companyId) {
            if (companyId == 129 || companyId == 54) {
                return true;
            }
            return false;
        }
        $rootScope.IS_HH = function() {
            if ($rootScope.account.companyId == 116 ||
                $rootScope.account.companyId == 286 ||
                $rootScope.account.companyId == 296 ||
                $rootScope.account.companyId == 543) {
                return true;
            }
            return false;
        }

        $rootScope.IS_YHSP = function() {
            if ($rootScope.account.companyId == 320 ||
                $rootScope.account.companyId == 337 ||
                $rootScope.account.companyId == 338) {
                return true;
            }
            return false;
        }

        $rootScope.IS_ZJSC = function() {
            if ($rootScope.account.companyId == 330 ||
                $rootScope.account.companyId == 355 ||
                $rootScope.account.companyId == 402 ||
                $rootScope.account.companyId == 408 ||
                $rootScope.account.companyId == 54) {
                return true;
            }
            return false;
        }

        $rootScope.IS_HAITIE = function() {
            if ($rootScope.account.companyId >= 84 && $rootScope.account.companyId <= 86) {
                return true;
            }
            if ($rootScope.account.companyId == 156) {
                return true;
            }
            return false;
        }

        $rootScope.isYuyue = function(companyId) {
            if (companyId == 223 || companyId == 240 || companyId == 315 || companyId == 346 ||
                (companyId >= 394 && companyId <= 397) || companyId == 567 || companyId == 777 || companyId == 781) {
                return true;
            }
            return false;
        }

        // 是否为“德立迅”
        $rootScope.isDLX = function(){
            if($rootScope.account.companyId == 647 || $rootScope.account.companyId == 5){
                return true;
            }else{
                return false;
            }
        }
        $rootScope.isYuyueExHaShen = function(companyId) {
            if (companyId == 394 || companyId == 395 || companyId == 315 || companyId == 397) {
                return true;
            }
            return false;
        }
        $rootScope.isYuyueHaShen = function(companyId) {
            if (companyId == 567 || companyId == 396 || companyId == 777 || companyId == 781) {
                return true;
            }
            return false;
        }
        $rootScope.isHaiyuan = function(companyId) {
            if (companyId == 302 || companyId == 303 || companyId == 304) {
                return true;
            }
            return false;
        }

        $rootScope.CLOUD_INVOICE_SET = function(companyId) {
            if ($rootScope.isYuyue(companyId)) {
                return true;
            }
            return false;
        }

        $rootScope.VIEWVOUCHER = function(voucherId, accountVouchers, accountVoucher) {
            if ($rootScope.HAVENOAUTH('ROLE_ACCOUNT')) {
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
                return;
            }
            var vouchersEntity = {};
            vouchersEntity.voucherId = voucherId;
            vouchersEntity.accountVouchers = accountVouchers;
            vouchersEntity.accountVoucher = accountVoucher;
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountVoucher/accountVoucher-dialog.html',
                controller: 'AccountVoucherDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return vouchersEntity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountVoucher');
                        $translatePartialLoader.addPart('writeOff');
                        $translatePartialLoader.addPart('accountInvoice');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            });
        }
        $rootScope.VIEWCONTENTVOUCHER = function(contentId, voucherContents, accountVoucher) {
            if ($rootScope.HAVENOAUTH('ROLE_ACCOUNT')) {
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
                return;
            }
            var vouchersEntity = {};
            vouchersEntity.accountVoucher = accountVoucher;
            vouchersEntity.contentIdTemp = contentId;
            vouchersEntity.voucherContents = voucherContents;
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountVoucher/accountVoucher-dialog.html',
                controller: 'AccountVoucherDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return vouchersEntity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountVoucher');
                        $translatePartialLoader.addPart('writeOff');
                        $translatePartialLoader.addPart('accountInvoice');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        $rootScope.guideJasonPop = function(guide) {
            $uibModal.open({
                templateUrl: 'app/entities/crm-price/crm-price-detail.html',
                controller: 'CrmPriceDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return angular.copy(guide);
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                // bCtn.dutyAmt = result.dutyAmt;
            }, function() {});
        }

        $rootScope.preBookingPop = function(guide) {
            $uibModal.open({
                templateUrl: 'app/entities/prebooking/prebooking-detail.html',
                controller: 'PrebookingDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: ['Guide', function(Guide) {
                        if (!guide.booking) {
                            return Guide.get({ id: guide.id }).$promise;
                        }
                        return angular.copy(guide);
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                angular.copy(result, guide);
                guide.encodeId = WinAES.encode(guide.id.toString());
                $translate.refresh();
            }, function() {});
        }

        $rootScope.viewDocs = function(guide) {
            var guides = [];
            guides.push(guide);
            $uibModal.open({
                templateUrl: 'app/entities/guideDoc/guideDoc-dialog.html',
                controller: 'GuideDocDialogController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return guides;
                    }]
                }
            }).result.then(function(result) {}, function() {});
        }
        $rootScope.customsMgmt = function(guide) {
            $uibModal.open({
                templateUrl: 'app/entities/bookingCustom/bookingCustom-detail.html',
                controller: 'BookingCustomDetailController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(result) {}, function() {});
        }

        $rootScope.truckingMgmt = function(guide) {
            $uibModal.open({
                templateUrl: 'app/entities/bookingCustom/bookingCustom-delete-dialog.html',
                controller: 'BookingCustomDeleteController',
                size: 'xxl',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        if (!guide.booking) {
                            return Guide.getGuideBooking({ id: guide.id }).$promise;
                        }
                        return guide;
                    }]
                }
            }).result.then(function(result) {}, function() {});
        }

        $rootScope.toEbooking = function(carrier) {
            if (!carrier) {
                layer.msg($translate.instant('global.carrierMiss'));
                return;
            }
            OpsCarrier.getOneByName({
                companyId: $rootScope.account.companyId,
                name: carrier
            }, function(result) {
                if (result.url) {
                    window.open(result.url);
                } else {
                    layer.msg($translate.instant('global.carrierUnsupported'));
                }
            })
        }
        $rootScope.exportDbfFile = function(result, fileName) {
            var file = new Blob([result.data], {
                type: 'application/dbf'
            });
            if (file.size == 0) {
                layer.msg($translate.instant('global.loadFileFailed'));
                return;
            }
            var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
            var aTag = document.createElement("a");
            aTag.download = "vouchers_" + new Date().getTime() + ".dbf";
            aTag.href = blobURL;
            aTag.click();
        }

        $rootScope.deleOssFile = function(file, files) {
            if ($rootScope.HAVENOAUTH('AU_DEL_FILE')) {
                layer.msg($translate.instant('global.forbiddenDelFile'));
                return;
            }
            $rootScope.DEL_OSS_FILE_DO(file, files);
        }
        $rootScope.DEL_OSS_FILE_DO = function(file, files) {
            if (!file.filepath) {
                return;
            }
            layer.confirm($translate.instant('global.confirmToDelete'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                OpsFiles.delOssFile({
                    filepath: file.filepath
                }, function(result) {
                    var index = files.indexOf(file);
                    if (index > -1) {
                        files.splice(index, 1);
                    };
                    layer.msg($translate.instant('global.delete_success'));
                }, function(reject) {
                    layer.msg($translate.instant('global.delete_fail'));
                }, function(error) {});
            }, function() {});
        }

        //邮件发送后 自动更新节点
        $rootScope.SETSTATUS = function(guideId, status) {
            Guide.setStatusOne({
                guideId: guideId,
                status: status
            }, function(result) {
                $scope.$emit('autopsApp:guideUpdate', result);
                layer.msg($translate.instant('global.statusUpdated'));
            }, function(result) {});
        }
        $rootScope.IS_OP_CHECK = function() {
            return isOp();
        }
        var isOp = function() {
            var authorities = ["ROLE_BOSS", "ROLE_DOCUMENT", "ROLE_OPERATOR", "ROLE_CS"];
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return false;
            }
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };

        $rootScope.VIEW_DELIVERY_GUIDE = function(guide) {
            if($rootScope.VIEW_GUIDE_FORBIDDEN()){
                return;
            }
            OpsFiles.viewDeliveryGuideFile({}, guide, function(result) {
                $rootScope.PRINTBYTES(result.data);
            });
        }


        $rootScope.VIEW_GUIDE_FORBIDDEN = function(code) {
            if(!code || (code >= 20 && code < 40)){
                if ($rootScope.HAVENOAUTH('AU_VIEW_GUIDE')) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_GUIDE');
                    return true;
                }
            }
            return false;
        }

        $rootScope.FORBIDDEN_FB_BY_ALERT = function(authority) {
            $rootScope.OPS_ALERT($translate.instant('global.forbiddenByFB', { param: $translate.instant('user-management.' + authority) }));
        }
        $rootScope.FORBIDDEN_BY_ALERT = function(authority) {
            $rootScope.OPS_ALERT($translate.instant('global.forbiddenBy', { param: $translate.instant('user-management.' + authority) }));
        }
        $rootScope.NO_MODULE_ALERT = function(authority) {
            $rootScope.OPS_ALERT($translate.instant('global.noModule', { param: $translate.instant('user-management.' + authority) }));
        }

        $rootScope.FORBIDDEN_BY_MSG = function(authority) {
            layer.msg($translate.instant('global.forbiddenByMsg', { param: $translate.instant('user-management.' + authority) }));
        }
        $rootScope.NOT_ENABLED_ALERT = function(authority) {
            $rootScope.OPS_ALERT($translate.instant('global.notEnabledAlert', { param: $translate.instant('autopsApp.opsCompany.functionSettings.item.' + authority) }));
        }
        $rootScope.PICKING_TICKET_ALERT = function(result) {
            if(!result.key){
                return;
            }
            $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.generatePickingTicketFailed', {param: result.key}))
        }


        $rootScope.SETGUIDESTATUS = function(guide, guides, status) {
            if (guide) {
                var checkGuides = [];
                checkGuides.push(guide);
                if(amendGuideForbidden(checkGuides)){
                    return;
                }
                if (status == "voided") {
                    if ($rootScope.HAVENOAUTH('AU_VOID_ORDER')) {
                        $rootScope.FORBIDDEN_BY_ALERT('AU_VOID_ORDER');
                        return;
                    }
                    Guide.setVoid({ id: guide.id }, function(result) {
                        guide.isVoid = result.isVoid;
                        guide.status = result.status;
                        layer.msg($translate.instant('global.invalid_success'));
                    }, $rootScope.ALERT_ERROR);
                    return;
                } else if (guide.isVoid) {
                    return;
                }
                var fd = DataUtils.getGuideNodeName(status);
                var fdValue = guide[fd];
                if (status == "SiReceived" && guide.booking) {
                    fdValue = guide.booking[fd];
                }

                if (!fdValue && status == "isClosed" && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                    return;
                }

                if (fdValue) {
                    if ($rootScope.HAVENOAUTH('AU_AMEND_TIMESTAMP') && status != 'voided' && status != 'Cancelled') {
                        $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_TIMESTAMP');
                        return;
                    }
                    if (status == "isClosed" && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                        $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                        return;
                    }
                    layer.confirm($translate.instant('autopsApp.guide.clearNodeAlert'), {
                        icon: 3,
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        Guide.setStatusOne({
                            guideId: guide.id,
                            status: status
                        }, function(result) {
                            layer.msg($translate.instant('global.save_success'));
                            angular.copy(result, guide);
                            DataUtils.addGuideTag(guide);
                        }, function(result) {
                            layer.closeAll();
                            $scope.isSaving = false;
                            $rootScope.ALERT_ERROR(result);
                        });
                        layer.close(index);
                    }, function() {});
                    return;
                }
                if (!status) {
                    layer.msg($translate.instant('global.systemMgmt'));
                    return;
                }
                if ("plConfirmed" == status || "MblConfirmed" == status) {
                    layer.msg($translate.instant('autopsApp.guide.amendInBizTips'));
                    return;
                }
                if (!isOp() && $rootScope.HAVENOAUTH("ROLE_AGENT")) {
                    layer.msg($translate.instant('autopsApp.guide.opDocEnterAlert'));
                    return;
                }
                if ($rootScope.choosedRole == "ROLE_CS") {
                    if ((status == "Booked" || status == "SoReleased") && $rootScope.account.authorities.indexOf("BOOKING_BY") == -1) {
                        layer.msg($translate.instant('autopsApp.guide.opDocEnterAlert'));
                        return;
                    } else if ((status == "TruckArranged" || status == "CtnStuffed") && $rootScope.account.authorities.indexOf("TRUCK_BY") == -1) {
                        layer.msg($translate.instant('autopsApp.guide.opDocEnterAlert'));
                        return;
                    } else if (status == "CustomArranged" && $rootScope.account.authorities.indexOf("CUSTOM_BY") == -1) {
                        layer.msg($translate.instant('autopsApp.guide.opDocEnterAlert'));
                        return;
                    }
                }

            }
            else if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            if (status == "isClosed" && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            if(amendGuideForbidden(guides)){
                return;
            }

            var entity = {};
            entity.guides = guides;
            entity.guide = guide;
            entity.status = status;
            entity.nodeAction = "markNode";
            $uibModal.open({
                templateUrl: 'app/entities/ops-track-status/ops-track-status-detail.html',
                controller: 'OpsTrackStatusDetailController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                layer.msg($translate.instant('global.save_success'));
                if (guide) {
                    angular.copy(result, guide);
                    DataUtils.addGuideTag(guide);
                } else {
                    angular.forEach(guides, function(guide) {
                        angular.forEach(result, function(data) {
                            if (data.id == guide.id) {
                                angular.copy(data, guide);
                                DataUtils.addGuideTag(guide);
                            }
                        })
                    })
                }
                $translate.refresh();
            }, function() {});
        }

        $rootScope.FMS_CLEAR_NODE = function(guides) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            if (status == "isClosed" && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            if(amendGuideForbidden(guides)){
                return;
            }
            var entity = {};
            entity.guides = guides;
            entity.nodeAction = "clearNode";
            $uibModal.open({
                templateUrl: 'app/entities/ops-track-status/ops-track-status-detail.html',
                controller: 'OpsTrackStatusDetailController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                layer.msg($translate.instant('global.save_success'));
                angular.forEach(guides, function(guide) {
                    angular.forEach(result, function(data) {
                        if (data.id == guide.id) {
                            angular.copy(data, guide);
                            DataUtils.addGuideTag(guide);
                        }
                    })
                })
                $translate.refresh();
            }, function() {});
        }

        $rootScope.wmsInExcel = function(file) {
            if ($rootScope.HAVENOAUTH('WMS')) {
                layer.msg($translate.instant('global.forbiddenWMS'));
                return;
            }
            if (!file) { return; }
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/wms-ins/wmsInExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                layer.msg($translate.instant('global.importSuccess'));
            }).error(function(error) {
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });
        };

        $rootScope.plPop = function(guide, isDTO) {
            if (!guide || !guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (isDTO) {
                guide = { id: guide.id };
            }
            $uibModal.open({
                templateUrl: 'app/entities/bookingCtns/bookingCtns-dialog.html',
                controller: 'BookingCtnsDialogController',
                size: 'xxxl',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(result) {}, function() {})
        }


        $rootScope.parcelPop = function(guide, byWmsIn) {
            if ($rootScope.HAVENOAUTH('PARCEL')) {
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                controller: 'ExpressParcelDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return Guide.createParcel({ byWmsIn: byWmsIn }, guide).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressParcel');
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                $scope.$emit('autopsApp:loadGuide', guide);
            }, function(result) {
                $scope.$emit('autopsApp:loadGuide', guide);
            });
        }

        $rootScope.consoleListPop = function(masterId, isFms) {
            if (!isOp()) {
                layer.msg($translate.instant('global.forbidden'));
                return;
            }
            var entity = {
                masterId: masterId,
                isFms: isFms
            };
            $uibModal.open({
                templateUrl: 'app/entities/guide/hguides-dialog.html',
                controller: 'HGuidesDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        };


        $rootScope.importCharges = function(file, isPay, isExportTms, isParcel, isWms) {
            if (!file) { return; }
            var fd = new FormData();
            fd.append('file', file);
            fd.append('isPay', isPay);
            fd.append('isExportTms', isExportTms);//出口 或 TMS
            fd.append('isParcel', isParcel);
            fd.append('isWms', angular.isUndefined(isWms) ? false : isWms);
            fd.append('companyId', $rootScope.account.companyId);
            layer.load(1, { shade: 0.3 });
            $http.post(
                'api/guideRates/excelImport', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                layer.closeAll();
                if (data.successCount) {
                    $rootScope.OPS_ALERT($translate.instant('global.importSuccessAmt', { param: data.successCount }));
                } else if (data.errorStr) {
                    $rootScope.OPS_ALERT(data.errorStr);
                }
            }).error(function(error) {
                layer.closeAll();
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });
        };

        $rootScope.importChargesRow = function(file, isPay, isExportTms, isParcel, isWms) {
            if (!file) { return; }
            var fd = new FormData();
            fd.append('file', file);
            fd.append('isPay', isPay);
            fd.append('isExportTms', isExportTms);//出口 或 TMS
            fd.append('isParcel', isParcel);
            fd.append('isWms', angular.isUndefined(isWms) ? false : isWms);
            fd.append('companyId', $rootScope.account.companyId);
            layer.load(1, { shade: 0.3 });
            $http.post(
                'api/guideRates/excelImportRow', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                layer.closeAll();
                if (data.successCount) {
                    $rootScope.OPS_ALERT($translate.instant('global.importSuccessAmt', { param: data.successCount }));
                } else if (data.errorStr) {
                    $rootScope.OPS_ALERT(data.errorStr);
                }
            }).error(function(error) {
                layer.closeAll();
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });
        };

        $rootScope.importOrdersByExcel = function(type, isImport) {
            $rootScope.isImportExcel = isImport;
            var entity = {};
            entity.type = angular.uppercase(type);
            $uibModal.open({
                templateUrl: 'app/entities/wms-map/wms-map-detail.html',
                controller: 'WmsMapDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountBind');
                        $translatePartialLoader.addPart('wmsMap');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        };

        $rootScope.salesDepBranch = function(entity, type) {
            if(entity.isLocked){
                layer.msg($translate.instant('autopsApp.opsClient.isLocked'));
                return;
            }
            entity.popType = type;
            $uibModal.open({
                templateUrl: 'app/entities/guideDoc/guideDoc-delete-dialog.html',
                controller: 'GuideDocDeleteController',
                size: 'sm',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {}, function() {});
        }

        $rootScope.viewBooking = function(guideId) {
            $rootScope.BOOKINGACTIVETAB = 0;
            $rootScope.id = "view";
            $uibModal.open({
                templateUrl: 'app/entities/booking/booking-detail.html', //detail
                controller: 'BookingDetailController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return Guide.getGuideBookingDTO({ id: guideId }).$promise;
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }

        $rootScope.CLIENTVIEWGE = function(content) {
            if (content.guideId) {
                $rootScope.viewBooking(content.guideId);
            } else if (content.expressParcelId) {
                $rootScope.VIEWEXPRESS(content.expressParcelId);
            } else if (content.wmsInId) {
                $rootScope.WMSPOP(content.wmsInId);
            }
        }

        $rootScope.CLIENT_INFO = function(activeTab) {
            $rootScope.activeTab = activeTab;
            $uibModal.open({
                templateUrl: 'app/entities/opsVender/opsVender-detail.html',
                controller: 'OpsVenderDetailController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return OpsVender.getSubsysClient().$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }

        $rootScope.exportIFCSUM = function(guide, guides, isMbl) {
            layer.msg("Generating...", {
                shade: 0.3,
                time: 2000000
            });
            if (guide) {
                guides = [];
                guides.push(guide)
            }
            Guide.IFCSUM({
                isMbl: isMbl
            }, guides, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADTXT(result, "IFCSUM.txt");
            }, function(result) {
                layer.closeAll();
                layer.msg($translate.instant('global.requestFail'));
            });

        };


        $rootScope.opsShippers = [];
        $rootScope.loadOpsShipper = function(guide) {
            if ($rootScope.opsShippers && $rootScope.opsShippers.length > 0) {
                return;
            }
            var venderIds = [];
            if (guide.shipperId) {
                venderIds.push(guide.shipperId);
            }
            if (guide.customerId) {
                venderIds.push(guide.customerId);
            }
            if (guide.deliveryId) {
                venderIds.push(guide.deliveryId);
            }
            if (guide.bookingToId) {
                venderIds.push(guide.bookingToId);
            }
            OpsShipper.getAllByClientOrAgentId(venderIds, function(result) {
                $rootScope.opsShippers = result;
                for (var i = $rootScope.opsShippers.length - 1; i >= 0; i--) {
                    if (!$rootScope.opsShippers[i].companyName) {
                        $rootScope.opsShippers.splice(i, 1);
                    }
                }
            })
        };

        $rootScope.refreshHblsEtd = function(masterGuideId, isFms) {
            var entity = {
                masterId: masterGuideId,
                isFms: isFms,
                vgm: true,
                cls: true,
                siCutTime: true,
                customClearTime: true,
                etd: true,
                atd: true,
                eta: true,
                customArrangeTime: true,
                customReleaseTime: true,
                otherTimeThree: true,
                customsInspection: true,
                customsInspectionExp: true,
                vslVoy: true,
                ata: true,
                carrier: true,
                overseasCs: true
            };
            OpsCompany.getOpsCompanyJson({ id: $rootScope.account.companyId }, function(result) {
                var autoRefreshs;
                if(isFms){
                   autoRefreshs = angular.fromJson(result.configurationJson).AUTOREFRESH;
                }
                else{
                    autoRefreshs = angular.fromJson(result.configurationJson).AUTOREFRESHPC;
                }
                if (autoRefreshs) {
                    entity = {};
                    angular.forEach(autoRefreshs, function(fd) {
                        entity[fd] = true;
                    });
                    entity.isFms = isFms;
                    entity.masterId = masterGuideId;
                }
                $uibModal.open({
                    templateUrl: 'app/entities/bookingCustom/bookingCustom-dialog.html',
                    controller: 'BookingCustomDialogController',
                    size: 'sm',
                    resolve: {
                        entity: [function() {
                            return entity;
                        }]
                    }
                }).result.then(function(result) {}, function() {})
            });
        }


        $rootScope.saveAsTruckTempalte = function(ctn) {
            if (ctn.companyId) {
                ctn.companyId = null;
                ctn.isTemplate = false;
            } else {
                ctn.companyId = $rootScope.account.companyId;
                ctn.isTemplate = true;
            }

            GuideCtns.saveAsTemplate({}, ctn, function(result) {
                angular.copy(result, ctn);
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(error) {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }
        $rootScope.templates = [];
        $rootScope.loadTempaltes = function(truckId) {
            if (!$rootScope.templates || $rootScope.templates.length == 0 || truckId) {
                GuideCtns.getTempaltes({
                    truckId: truckId
                }, function(result) {
                    $rootScope.templates = result;
                }, function(error) {
                    // layer.msg($translate.instant('global.messages.submittedfailed'));
                });
            }
        }


        $rootScope._ctnselected = function($item, ctn) {
            ctn.truckNum = $item.truckNum;
            ctn.driverMob = $item.driverMob;
            ctn.driverName = $item.driverName;
            if (!ctn.ctnNum) {
                ctn.ctnNum = $item.ctnNum;
                ctn.ctnType = $item.ctnType;
            }
        }

        $rootScope.viewTracking = function(guide, guides, isExpress) {
            var entity = {};
            entity.guide = angular.copy(guide);
            entity.guides = angular.copy(guides);
            entity.isExpress = isExpress;
            $uibModal.open({
                templateUrl: 'app/entities/express-tracking/express-trackings.html',
                controller: 'ExpressTrackingController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        $rootScope.fillNull = function(obj, fd) {
            if (!obj[fd]) {
                obj[fd] = "";
                $translate.refresh();
            }
        }
        $rootScope.VIEW_HANDBOOK = function(url) {
            previewPdf(url);
        }
        $rootScope.AUTO_TRACKING = function(guide) {
            if ($rootScope.HAVENOAUTH('AU_TRK') && $rootScope.HAVENOAUTH('AU_FREE')) {
                guide.autoTracking = false;
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                $rootScope.VIEW_HANDBOOK("https://hforwarder.oss-cn-shenzhen.aliyuncs.com/handbook/AU-OPS%E6%89%8B%E5%86%8C%E2%80%94%E4%BA%91%E5%BD%95%E5%8D%95%2B%E8%B7%9F%E8%B8%AA.pdf");
                return;
            }
            if ($rootScope.HAVENOAUTH('AU_DATA_FEED')) {
                guide.autoTracking = false;
                $rootScope.FORBIDDEN_BY_ALERT('AU_DATA_FEED');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/data-subscription/data-subscription-dialog.html',
                controller: 'DataSubscriptionDialogController',
                controllerAs: 'vm',
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(options) {}, function() {});

        }

        function getErrorMsg(error){
            if (error.headers('X-autopsApp-i18n')) {
                return $translate.instant(error.headers('X-autopsApp-i18n'), {
                    param: decodeURIComponent(error.headers('X-autopsApp-i18n-param')),
                    param2: decodeURIComponent(error.headers('X-autopsApp-i18n-param2'))
                });
            } else if (error.headers('X-autopsApp-msg')) {
                return decodeURIComponent(error.headers('X-autopsApp-msg'));
            } else if (error && error.data && error.data.description) {
                return error.data.description;
            } else if (error && error.data && error.data.message) {
                return error.data.message;
            } else if (error && error.status && error.status == 404) {
                return $translate.instant('global.messages.resultNull');
            } else {
                return $translate.instant('global.messages.submittedfailed');
            }
        }

        $rootScope.ALERT_ERROR = function(error) {
            layer.closeAll();
            var errorMsg = getErrorMsg(error);
            if(error.status == 422){
                errorMsg = "(委托方关联订单)" + errorMsg;
            }
            $rootScope.OPS_ALERT(errorMsg);
            //下载文件
            if (error && error.data && error.data.digest) {
                var result = new Object();
                result['data'] = error.data.digest;
                $rootScope.DOWNLOADTXT(result, "importResult.txt");
            }
            $rootScope.ALERT_ALARM();
        }

        $rootScope.ALERT_RESULT_MSG = function(headers) {
            if (headers('X-autopsApp-i18n')) {
                $rootScope.OPS_ALERT($translate.instant(headers('X-autopsApp-i18n'), {
                    param: headers('X-autopsApp-i18n-param'),
                    param2: headers('X-autopsApp-i18n-param2')
                }));
            } else if (headers('X-autopsApp-msg')) {
                $rootScope.OPS_ALERT(headers('X-autopsApp-msg'));
            }
        }

        $rootScope.ALERT_HEADERS = function(headers) {
            if (headers('X-autopsApp-i18n')) {
                $rootScope.OPS_ALERT($translate.instant(headers('X-autopsApp-i18n'), {
                    param: headers('X-autopsApp-i18n-param'),
                    param2: headers('X-autopsApp-i18n-param2')
                }));
            } else if (headers('X-autopsApp-msg-i18n')) {
                layer.msg($translate.instant(headers('X-autopsApp-msg-i18n')));
            }
        }

        $rootScope.remoteCheck = function(country, postcode, carrier) {
            if ($rootScope.HAVENOAUTH('PRINT_LABEL') && $rootScope.account.companyId) {
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if (!country || !postcode) {
                $rootScope.OPS_ALERT($translate.instant('global.remoteCheckAlert'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/sub-tracking/sub-tracking-dialog.html',
                controller: 'SubTrackingDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            country: country,
                            postcode: postcode,
                            carrier: carrier
                        };
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.generateJobNUM = function(guide) {
            if (guide.etdLocked) {
                $rootScope.OPS_ALERT($translate.instant('global.etdLockedAlert'));
                return;
            }
            var jobNumCopy = guide.jobNum;
            $uibModal.open({
                templateUrl: 'app/entities/guide/generate-job-num.html',
                controller: 'GenerateJobNumController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(result) {
                guide.jobNum = result;
                $rootScope.CHECK_JOB_NUM(guide, jobNumCopy);
                $translate.refresh();
            }, function() {});
        }


        $rootScope.subTrackingPop = function(guide, parcel) {
            var consolShipmentDTO = {};
            if (guide) {
                consolShipmentDTO.guideId = guide.id;
                if (guide.parcelMaster) {
                    consolShipmentDTO.isMaster = true;
                }
                consolShipmentDTO.jobNum = guide.jobNum;
                consolShipmentDTO.mblNum = guide.mblNum;
                consolShipmentDTO.shipmentType = guide.shipmentType;
                consolShipmentDTO.carrier = guide.deliveryChannel;
            } else {
                consolShipmentDTO.parcelId = parcel.id;
                if (parcel.masterId == 0 || parcel.isMaster) {
                    consolShipmentDTO.isMaster = true;
                }
                consolShipmentDTO.jobNum = parcel.jobNum;
                consolShipmentDTO.mblNum = parcel.mblNum;
                consolShipmentDTO.shipmentType = parcel.shipmentType;
                consolShipmentDTO.carrier = parcel.deliveryChannel;
            }
            $uibModal.open({
                templateUrl: 'app/entities/sub-tracking/sub-tracking-detail.html',
                controller: 'SubTrackingDetailController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return consolShipmentDTO;
                    }]
                }
            }).result.then(function(result) {}, function() {});
        }
        $rootScope.generateHBLNUM = function(guide) {
            if (guide.hblNum) {
                layer.msg($translate.instant('autopsApp.guide.hblExistAlert'));
                return;
            }
            if ($rootScope.account.companyId == 380) {
                if (!$rootScope.account.blPrefix) {
                    $rootScope.account.blPrefix = "PRLXM";
                } else if ($rootScope.account.blPrefix.indexOf("PRLXM") == -1) {
                    $rootScope.account.blPrefix = "PRLXM;" + $rootScope.account.blPrefix;
                }
            }
            if (!$rootScope.account.blPrefix) {
                layer.msg($translate.instant('autopsApp.guide.prefixNull'));
                return;
            }
            var hblCopy = guide.hblNum;
            $uibModal.open({
                templateUrl: 'app/entities/guide/generate-hbl-num.html',
                controller: 'GenerateHblNumController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(result) {
                guide.hblNum = result;
                $translate.refresh();
            }, function() {});
        }

        $rootScope.getHistory = function(id, type) {
            if (!id) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            if ($rootScope.account.companyId && $rootScope.HAVENOAUTH("AU_VIEW_CHANGELOG") && type != "vender") {
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_CHANGELOG');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-dialog.html',
                controller: 'OpsChangeLogDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: ['OpsChangeLog', function(OpsChangeLog) {
                        return OpsChangeLog.getByTypeId({ id: id, type: type }).$promise;
                    }]
                }
            }).result.then(function() {}, function() {
                layer.msg($translate.instant('global.messages.resultNull'));
            });
        }

        $rootScope.saveAsGuideTempalte = function(guide) {
            if (!guide.id) {
                layer.msg($translate.instant('autopsApp.guide.home.save_guide_firstly'));
                return;
            }
            var guideTemplate = {};
            guideTemplate.createdBy = $rootScope.account.id;
            guideTemplate.guideId = guide.id;
            guideTemplate.isExport = guide.isExport;
            guideTemplate.shipmentType = guide.shipmentType;
            $uibModal.open({
                templateUrl: 'app/entities/guide/template-guide.html',
                controller: 'TemplateGuideController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return guideTemplate;
                    }]
                }
            }).result.then(function(result) {
                guide.isTemplate = true;
                $translate.refresh();
            }, function() {});
        }

        $rootScope.setAsMaster = function(guide) {
            if (guide.masterId) {
                layer.msg($translate.instant('autopsApp.guide.consolidatedAlert'));
                return;
            }
            if (guide.isClosed) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
                return;
            }
            if (guide.masterId == 0 || guide.parcelMaster) {
                layer.confirm($translate.instant('global.setGuideAsMasterAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    layer.close(index);
                    setGuideAsMaster(guide);
                }, function() {});
            } else {
                setGuideAsMaster(guide);
            }
        }

        function setGuideAsMaster(guide) {
            Guide.setAsMaster({
                id: guide.id
            }, function(result) {
                guide.jobNum = result.jobNum;
                guide.masterId = result.masterId;
                $scope.$emit('autopsApp:guideUpdate', result);
                $scope.$emit('autopsApp:loadGuide', result);
                layer.msg($translate.instant('global.save_success'));
            }, function(result) {
                if (result.status == 409) {
                    layer.msg($translate.instant('autopsApp.guide.consolidatedAlert'));
                } else if (result.status == 403) {
                    layer.msg($translate.instant('global.lockedAlert'));
                } else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            })
        }

        $rootScope.updateCargoValue = function(guide) {
            if($rootScope.IS_MASTER(guide)){
                Guide.updateCargoValue({masterId: guide.id}, function(result){
                    guide.cargoValue = DataUtils.round(result.value);
                })
                return;
            }
            var ttlAmt = 0;
            angular.forEach(guide.booking.bookingCtns, function(bcn) {
                if(bcn.mixedPls && bcn.mixedPls.length > 0){
                    angular.forEach(bcn.mixedPls, function(mixPl){
                        if (mixPl.totalPrice) {
                            ttlAmt += mixPl.totalPrice;
                        }
                    })
                }
                else if (bcn.totalPrice) {
                    ttlAmt += bcn.totalPrice;
                }
            })
            if (ttlAmt) {
                guide.cargoValue = DataUtils.round(ttlAmt);
            }
        };

        $rootScope.UPDATE_CARGONAME_HSCODE_BY_PL = function(guide, hsOnly) {
            if (!guide.booking.bookingCtns || guide.booking.bookingCtns.length == 0) {
                return;
            }
            var cargoName = ""; //255
            var hsCode = ""; //250
            angular.forEach(guide.booking.bookingCtns, function(ctn) {
                if (ctn.cargoName && cargoName.indexOf(ctn.cargoName) == -1) {
                    cargoName += ctn.cargoName + "; "
                }
                if (ctn.hsCode && hsCode.indexOf(ctn.hsCode) == -1) {
                    hsCode += ctn.hsCode + "; "
                }
            })
            if (!hsOnly) {
                guide.cargoName = DataUtils.maxLen(cargoName, 255);
            }
            if (hsOnly != false) {
                guide.booking.hsCode = DataUtils.maxLen(hsCode, 250);
            }
        }

        $rootScope.UPDATE_PCS = function(guide) {
            guide.pieces = 0;
            angular.forEach(guide.booking.bookingCtns, function(bcn) {
                if (bcn.pieces) {
                    guide.pieces += bcn.pieces;
                }
            })
        };

        $rootScope.consoleOnHoldShipmentPop = function(consoleShipment) {
            if (consoleShipment.guideId) {
                $rootScope.problemShipmentHandle({ id: consoleShipment.guideId }, null);
            } else if (consoleShipment.parcelId) {
                $rootScope.problemShipmentHandle(null, { id: consoleShipment.parcelId });
            }
        };

        $rootScope.problemShipmentHandle = function(guide, parcel) {
            var entity = {
                guide: guide ? guide : {},
                parcel: parcel ? parcel : {}
            }
            $uibModal.open({
                templateUrl: 'app/entities/oa-application/oa-application-detail.html',
                controller: 'OaApplicationDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('oaApplication');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        };

        $rootScope.checkPoNum = function(obj, type) {
            if (type == "PARCELUNI") {
                if (!obj.poNumber) {
                    return;
                }
                ExpressParcel.checkPoNum({
                    type: "unique",
                    venderId: obj.shipperId,
                    parcelId: obj.id,
                    poNum: obj.poNumber
                }, function(result) {
                    if (result.isRepeated) {
                        obj.poNumber = null;
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.poNumRepeated'));
                    }
                });
                return;
            }
            if (!obj.poNum) {
                return;
            }
            if (type == "PARCEL") {
                ExpressParcel.checkPoNum({
                    companyId: $rootScope.account.companyId,
                    parcelId: obj.id,
                    poNum: obj.poNum
                }, function(result) {
                    if (result.isRepeated) {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.poNumRepeated'));
                    }
                });
            } else if (type == "FMS") {
                if (obj.guideId) {
                    obj.id = obj.guideId;
                }
                Guide.checkPoNum({
                    companyId: $rootScope.account.companyId,
                    guideId: obj.id,
                    poNum: obj.poNum
                }, function(result) {
                    if (result.isRepeated) {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.poNumRepeated'));
                    }
                });
            }
        };
        $rootScope.editAddress = function(guide, fd) {
            $rootScope.FROMJOB = true;
            $uibModal.open({
                templateUrl: 'app/entities/address-book/address-book-dialog.html',
                controller: 'AddressBookDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: ['AddressBook', function(AddressBook) {
                        if (guide[fd]) {
                            return AddressBook.get({ id: guide[fd] }).$promise;
                        } else {
                            return {};
                        }
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('addressBook');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(data) {
                guide[fd] = data.id;
                if ("shipFromId" == fd) {
                    guide.shipfromCode = data.code;
                } else if ("ownImporterId" == fd) {
                    if (data.countryCode != guide.shiptoCountryCode) {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.importerCountryDiffAlert'));
                        return;
                    }
                    guide.ownImporterCode = data.code;
                } else if ("dutyPayPartyId" == fd) {
                    guide.dutyPayPartyCode = data.code;
                } else if ("guideDeliveryToChoose" == fd) {
                    DataUtils.onGuideAddressChoosed(guide, data);
                    $rootScope.OWN_IMPORTER_CHECK(data.countryCode, guide);
                } else {
                    guide.shiptoCode = data.code;
                }
            }, function() {});
        }

        $rootScope.guidesPlExport = function(id, isFms, fileType, reportType, fileName, customsId) {
            if (920 == reportType && $rootScope.account.authorities.indexOf('FBA') === -1) {
                layer.msg($translate.instant('global.forbiddenFBA'));
                return;
            }
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsReport.guidesPlExport({
                id: id,
                isFms: isFms,
                fileType: fileType,
                customsId: customsId,
                reportType: reportType,
                opType: 1
            }, function(result, headers) {
                layer.closeAll();
                if (reportType == 900 && $rootScope.account.companyId == 285) {
                    fileName = headers("fileName");
                }
                $rootScope.EXPORTDOWNLOAD(result, fileType, fileName);
            }, function() {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            });
        }

        $rootScope.warehousingPlan = function(id, isFms) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsFiles.consolWarehousingPlan({
                id: id,
                isFms: isFms
            }, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.guide.warehousingPlan') + ".xlsx");
            }, function() {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }

        $rootScope.EXCEL_CONSOL_LIST = function(id) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsFiles.consolList({
                masterId: id
            }, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.guide.warehousingPlan') + ".xlsx");
            }, function() {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }


        $rootScope.doList = function(id, isFms) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsFiles.excelOntaskBizs({
                id: id,
                isFms: isFms
            }, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('entity.action.doList') + ".xlsx");
            }, function() {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }

        $rootScope.excelBizs = function(id, isFms) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsFiles.excelBizs({
                id: id,
                isFms: isFms,
                enCn: $translate.instant('global.enCn')
            }, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('entity.action.excelBizs') + ".xlsx");
            }, function() {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }
        $rootScope.exportConsolMasterPackingList = function(masterId, isFms, withPic) {
            if (!masterId) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsFiles.exportConsolMasterPackingList({
                id: masterId,
                isFms: isFms,
                withPic: withPic,
                enCn: $translate.instant('global.enCn')
            }, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('entity.action.excelPl') + ".xlsx");
            }, function() {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }

        $rootScope.exportPackingListInvoice = function(id, isFms, fileName) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsFiles.exportPackingListInvoice({
                id: id,
                isFms: isFms
            }, function(result, headers) {
                layer.closeAll();
                console.log(angular.toJson(headers, true))
                $rootScope.DOWNLOADEXCEL(result, fileName + ".xlsx");
            }, function() {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }

        $scope.parcelRapidIn = function(type, parcelId) {
            var entity = {
                parcelId: parcelId,
                type: type
            }
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/parcel-wmsIn-dialog.html',
                controller: 'ParcelWmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }
        $rootScope.parcelRapidInByPackage = function(type, parcelId) {
            var entity = {
                parcelId: parcelId,
                type: type
            }
            $uibModal.open({
                templateUrl: 'app/entities/express-package/express-package-dialog.html',
                controller: 'ExpressPackageDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }


        // if($rootScope.absUrl.indexOf("127.0.0.1:8080") !== -1){
        //     $scope.parcelRapidInByPackage("PARCEL", null);
        // }


        var reloadFiles = function(file, files) {
            if (!angular.isDefined(file.filepath)) {
                return;
            }
            if (!files) {
                files = [];
            }
            var exist = false;
            if (files.length > 0) {
                for (var i = 0; i < files.length; i++) {
                    if (files[i].filename == file.filename) {
                        files[i].filepath = file.filepath;
                        files[i].filename = file.filename;
                        files[i].fileurl = file.fileurl;
                        exist = true;
                        break;
                    }
                }
            }
            if (!exist) {
                var temp = new Object();
                temp['filepath'] = file['filepath'];
                temp['filename'] = file['filename'];
                temp['fileurl'] = file['fileurl'];
                temp['fileist'] = true;
                files.push(temp);
            }
        }
        $rootScope.UPLOAD_PIC_FILE = function(file, filePath, files, companyId) {
            if (!file) {
                return;
            }
            if (!DataUtils.isPic(file.name)) {
                $rootScope.OPS_ALERT($translate.instant('global.logo_warn1'));
                return;
            }
            $rootScope.UPLOADFILE(file, filePath, files, companyId);
        }
        $rootScope.IS_PIC_FILE = function(fileName) {
            return DataUtils.isPic(fileName);
        }
        $rootScope.IS_PDF_FILE = function(fileName) {
            return DataUtils.isPdf(fileName);
        }
        $rootScope.IS_PDF_PIC_FILE = function(fileName) {
            return DataUtils.isPdf(fileName) || DataUtils.isPic(fileName);
        }
        $rootScope.UPLOAD_FILE_WITH_NAME = function(file, filePath, files, companyId, fileName) {
            if (!file) {
                return;
            }
            var fd = new FormData();
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', companyId);
            fd.append('fileName', fileName);
            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        reloadFiles(data, files);
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                }).error(function(error) {
                if (error && error.filename) {
                    $rootScope.OPS_ALERT(error.filename);
                } else {
                    layer.msg($translate.instant('global.uploadFail'));
                }
            });
        }

        $rootScope.UPLOADFILE = function(file, filePath, files, companyId) {
            $rootScope.UPLOAD_FILE_WITH_NAME(file, filePath, files, companyId, null);
        };

        $rootScope.UPLOADFILES = function(multipleFiles, filePath, files, companyId) {
            angular.forEach(multipleFiles, function(file) {
                $rootScope.UPLOAD_FILE_WITH_NAME(file, filePath, files, companyId, null);
            })
        };

        $rootScope.UPLOAD_FILE_TO_GUIDES = function(file, path, prefix, guides) {
            if (!file) {
                layer.msg($translate.instant('global.noSelectedFile'));
                return;
            }
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var filepaths = [];
            angular.forEach(guides, function(guide) {
                if (path.indexOf("GUIDE") != -1 && guide.id) {
                    var filePath = path + guide.id + prefix;
                    filepaths.push(filePath);
                } else if (path.indexOf("BOOKING") != -1 && guide.bookingId) {
                    var filePath = path + guide.bookingId + prefix;
                    filepaths.push(filePath);
                }
            })
            var fd = new FormData();
            fd.append('filepaths', filepaths);
            fd.append('file', file);
            uploadFileToGuides(fd);
        };

        function uploadFileToGuides(fd) {
            $http.post(
                'api/ops-files/uploadFileToGuides', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(
                function(data) {
                    layer.msg($translate.instant('global.uploadSuccess'));
                }).error(function(error) {
                    if (error.description) {
                        layer.msg(error.description);
                    } else {
                        layer.msg($translate.instant('global.uploadFail'));
                    }
            });
        }

        $rootScope.LOADFILES = function(files, filePath, companyId) {
            if (files && files.length > 0) {
                return;
            }
            OpsFiles.getFilesList({
                filepath: filePath,
                companyId: companyId
            }, function(data) {
                if (!files) {
                    files = [];
                }
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        reloadFiles(data[i], files);
                    }
                }
            });
        }

        function onEdiSuccess(result) {
            layer.msg($translate.instant('global.send_success'));
        }

        $rootScope.AMASS_SEND_FILE = function(file, refNo, masterId, isParcel) {
            ApiErp.sendFile({
                fileUrl: file.fileurl,
                fileName: file.filename,
                masterId: masterId,
                isParcel: isParcel,
                refNo: refNo
            }, onEdiSuccess, $rootScope.ALERT_ERROR);
        }

        $rootScope.AMASS_SEND_WMSIN = function(id) {
            ApiErp.sendWmsIn({
                id: id
            }, onEdiSuccess, $rootScope.ALERT_ERROR);
        }

        $rootScope.AMASS_SEND_CHARGES = function(guide, guides) {
            if (guide && !guide.refNum) {
                $rootScope.OPS_ALERT("没有AMASS订单号（不是从AMASS系统推送的订单）");
                return;
            }
            if (!guides) {
                guides = [];
            }
            if (guide) {
                guides.push(guide);
            }
            ApiErp.sendCharges(guides, onEdiSuccess, $rootScope.ALERT_ERROR);
        }

        $rootScope.AMASS_SEND_STATUS = function(guide, guides) {
            if (guide && !guide.refNum) {
                $rootScope.OPS_ALERT("没有AMASS订单号（不是从AMASS系统推送的订单）");
                return;
            }
            if (!guides) {
                guides = [];
            }
            if (guide) {
                guides.push(guide);
            }
            ApiErp.sendStatus(guides, onEdiSuccess, $rootScope.ALERT_ERROR);
        }
        $rootScope.AMASS_SEND_SHIPMENT = function(guide) {
            if (!guide.id) {
                $rootScope.OPS_ALERT("请先保存订单！");
                return;
            }
            // if(guide.refNum){
            //     $rootScope.OPS_ALERT("已有AMASS订单号（已推送或者是抓取订单）");
            //     return;
            // }
            ApiErp.sendShipment(guide, onEdiSuccess, $rootScope.ALERT_ERROR);
        }

        $rootScope.AMASS_UPDATE_CHARGES = function(guideRates, crud) {
            ApiErp.updateCharges({ crud: crud }, guideRates, onEdiSuccess, $rootScope.ALERT_ERROR);
        }

        $rootScope.AMASS_SEND_CONSOL = function(guide) {
            if (0 != guide.masterId) {
                layer.msg("请从主单发送配载信息！");
                return;
            }
            ApiErp.sendConsolList({ masterId: guide.id }, onEdiSuccess, $rootScope.ALERT_ERROR);
        }

        $rootScope.EAS_IMPORT_VOUCHER = function(date) {
            ApiErp.importVoucher({
                monthDate: DateUtils.convertLocalDateToServer(date),
                companyId: $rootScope.account.companyId
            }, {}, onEdiSuccess, $rootScope.ALERT_ERROR);
        }

        $rootScope.AMASS_EXPORT_BI = function(guide) {
            ApiErp.exportCsvBi(function() {
                layer.msg("CSV文件已上传至FTP服务器，请核查!");
            });
        }

        $rootScope.PLACE_PARCEL_ORDER = function(shipmentType) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-concern-lane/ops-concern-lane-delete-dialog.html',
                controller: 'OpsConcernLaneDeleteController',
                backdrop: false,
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return {
                            type: 24,
                            shipmentType: shipmentType
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.PLACE_FBA_ORDER = function(type) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-concern-lane/ops-concern-lanes.html',
                controller: 'OpsConcernLaneController',
                backdrop: false,
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return { type: type };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function(guide) {
                if (guide.id && !guide.jobNum) {
                    Guide.delete({
                        id: guide.id
                    });
                }
            });
        }

        $rootScope.JS_DEBUG = function(type, jsStr) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-concern-lane/ops-concern-lane-detail.html',
                controller: 'OpsConcernLaneDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        var jsEntity = {};
                        jsEntity.jsType = type;
                        jsEntity.jsExpression = jsStr;
                        return jsEntity;
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.REPORT_TEMPLATES = null;
        $rootScope.LOAD_REPORT_TEMPLATES = function() {
            if ($rootScope.REPORT_TEMPLATES) {
                return;
            }
            ReportTemplate.getAll(function(result) {
                $rootScope.REPORT_TEMPLATES = result;
            }, function(response) {});
        }

        $rootScope.REPORT_COMPARATOR = function(actual, expected) {
            return actual >= expected && actual < (expected + 10);
        }
        $rootScope.DEL_FILE_FILTER = function(actual, expected) {
            return expected && actual && actual.indexOf(expected) !== -1;
        }
        $rootScope.PL_UNCHOOSED = function(actual, expected) {
            return expected == null;
        }

        $rootScope.IS_JINLING = function() {
            return $rootScope.account.companyId == 189
                // || $rootScope.account.companyId == 54
                ||
                $rootScope.account.companyId == 243 ||
                $rootScope.account.companyId == 235 ||
                $rootScope.account.companyId == 191;
        }

        $rootScope.COPY_FILE = function(file, toPath, expressParcelId, targetFiles) {
            OpsFiles.copyFile({
                expressParcelId: expressParcelId,
                fromPath: file.filepath,
                toPath: toPath
            }, function() {
                if (targetFiles) {
                    angular.copy([], targetFiles)
                }
                layer.msg($translate.instant('global.messages.submitted'));
            }, function() {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        }

        $rootScope.COPY_TO_WMS = function(file, guideId) {
            OpsFiles.copyToWms({
                filename: file.filename,
                fromPath: file.filepath,
                guideId: guideId
            }, function() {
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        }

        $rootScope.PARCEL_SERVICES = null;
        $rootScope.GET_PARCEL_SERVICE = function(obj) {
            if (obj.labelBindType && $rootScope.PARCEL_SERVICES && $rootScope.PARCEL_SERVICES.length > 0) {
                return;
            }
            $rootScope.PARCEL_SERVICES = [];
            if (obj.bindType) {
                if (obj.targetCode || obj.bindId || obj.accountBindId) { //wmsMap
                    obj.shipFromId = obj.bindId;
                } else { //Addressbook
                    obj.shipFromId = obj.id;
                }
            }
            if (obj.shipFromId) {
                ExpressParcel.getServicesList({
                    addressId: obj.shipFromId,
                    labelChannelCode: obj.labelChannelCode
                }, function(results) {
                    $rootScope.PARCEL_SERVICES = results;
                })
            } else if (obj.carrierCode) {
                ExpressParcel.getServicesList({ carrierCode: obj.carrierCode }, function(results) {
                    $rootScope.PARCEL_SERVICES = results;
                })
            }
            if (!obj.serviceDescription) {
                obj.serviceDescription = "";
                $translate.refresh();
            }
        }

        $rootScope.CHANGE_PASSWORD = function(userId, login) {
            var pswEntity = {};
            pswEntity.userId = userId;
            pswEntity.login = login;
            $uibModal.open({
                templateUrl: 'app/entities/company/user-management/user-management-detail.html',
                controller: 'ClerkManagementDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return pswEntity;
                    }]
                }
            }).result.then(function() {}, function() {});
        }
        $rootScope.IS_MASTER = function(guide) {
            if (guide.parcelMaster || guide.masterId == 0 || guide.isMaster) {
                return true;
            }
            return false;
        }
        $rootScope.IS_SUBSHIPMENT = function(guide) {
            if (guide.masterId || guide.parcelId || guide.guideId) {
                return true;
            }
            return false;
        }

        $rootScope.WAREHOUSING_DATA_IMPORT = function(file, importNew) {
            if (!file) { return; }
            layer.load(1, { shade: 0.3 });
            var fd = new FormData();
            fd.append('file', file);
            fd.append('importNew', importNew);
            $http.post(
                'api/warehousing-data/importByExcel', fd, {
                    transformRequest: angular.identity,
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                layer.closeAll();
                var result = new Object();
                result['data'] = data;
                $rootScope.DOWNLOADTXT(result, "importResult.txt");
            }).error(function(error) {
                layer.closeAll();
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });
        };

        $rootScope.SEND_PL_CHECK = function(guide, reportCode) {
            var email = {};
            email.attachments = [];
            email.head = guide.shipperName;
            email.subject = guide.polName + " - " + guide.destName + "(" + guide.soNum + ": " + $translate.instant('autopsApp.guide.home.plCheck') + ")";
            email.content = $translate.instant('entity.email.plCheckContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            var guideReportDTO = angular.copy(guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            entity.guide = guideReportDTO;
            entity.emailType = "plCheck";
            entity.code = reportCode;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = guide.shipperId;
            entity.contactId = guide.shipperContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }


        $rootScope.EXCEL_GUIDES = function(guides, fileName) {
            if (!guides || guides.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            OpsFiles.excelGuide({
                title: fileName,
                enCn: $translate.instant('global.enCn')
            }, guides, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName + ".xlsx");
            })
        }
        $rootScope.EXCEL_GUIDES_EXAM = function(guides, fileName) {
            if ($rootScope.isDLX($rootScope.account.companyId)) {
                layer.msg($translate.instant('global.notAuditedAlert'));
                return;
            }
            if (!guides || guides.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            OpsFiles.examReport({}, guides, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName + ".xlsx");
            })
        }
        $rootScope.EXCEL_GUIDES_AGING = function(guides, fileName) {
            if (!guides || guides.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            OpsFiles.agingReport({}, guides, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName + ".xlsx");
            })
        }
        $rootScope.WMS_MAP_LABEL = function(guide) {
            $uibModal.open({
                templateUrl: 'app/entities/prediction/prediction-detail.html',
                controller: 'PredictionDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reportTemplate');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                var reportTypeArray = new Array();
                reportTypeArray.push(result.warehousingPaper);
                reportTypeArray.push(result.wmsLabel);
                reportTypeArray.push(result.wmsLabelA4);

                var email = {};
                email.attachments = [];

                email.head = guide.shipperName;
                email.subject = "Job#: " + guide.jobNum + "--" + $translate.instant('autopsApp.guide.warehousingPaper');

                var cargotype = "";
                if (guide.cargoType) {
                    cargotype = $translate.instant('global.' + guide.cargoType);
                }
                email.content = $translate.instant('entity.email.warehousingPaper', {
                    pkgType: guide.pkgNum + guide.booking.pkgType,
                    gw: guide.gw,
                    vol: guide.vol,
                    polName: guide.booking.polName,
                    deliveryToPlace: guide.booking.deliveryTo,
                    productName: guide.productName,
                    cargoType: cargotype,
                    etd: DateUtils.convertLocalDateToServer(guide.etd),
                    cargoReadyDate: DateUtils.convertLocalDateToServer(guide.cargoReadyDate)
                });

                if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                    email.content = email.content + $rootScope.account.signatures[0].signature;
                }

                var entity = {};
                entity.email = email;
                entity.guide = guide;
                entity.emailType = "warehousingPaper";
                entity.code = reportTypeArray;
                entity.files = null;
                entity.bookingTruck = null;
                entity.venderId = guide.shipperId;
                entity.contactId = guide.shipperContactId;
                entity.fileResult = null;
                sendEmailPop(entity);
            }, function(result) {

            });
        }
        $rootScope.PRINT_GUIDE_LABEL = function(guide, guides, reportType) {
            if (guide) {
                guides = [];
                guides.push(guide);
            }
            else if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.printLabel({
                reportType: reportType
            }, guides, function(result) {
                layer.closeAll();
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.closeAll();
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $rootScope.CREATE_IMP_WMSIN = function(guide, bySku, usePieces, saveAlert) {
            if (saveAlert) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!guide.deliveryId) {
                layer.msg($translate.instant('autopsApp.guide.home.choose_agent'));
                return;
            }
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                WmsIn.createImportWmsIn({
                    bySku: bySku,
                    usePieces: usePieces
                }, guide, function() {
                    layer.closeAll();
                    $scope.$emit('autopsApp:loadGuide', guide);
                    layer.msg($translate.instant('global.createdSuccess'));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $rootScope.CREATE_WMSIN_BY_GUIDE = function(guide, withPl, byWarehouse) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (byWarehouse && !guide.whId) {
                layer.msg($translate.instant('autopsApp.guide.warehouseNullAlert'));
                return;
            }
            if (!byWarehouse && !guide.warehouseVid) {
                layer.msg($translate.instant('autopsApp.guide.warehouseVenderNullAlert'));
                return;
            }
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                WmsIn.createWmsInByGuide({
                    withPl: withPl,
                    byWarehouse: byWarehouse
                }, guide, function(result) {
                    layer.closeAll();
                    if (!guide.soNum) {
                        guide.soNum = result.warehousingNumber;
                    }
                    $rootScope.OPS_ALERT($translate.instant('global.createdSuccessWithRef', { "ref": result.warehousingNumber }));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $rootScope.CREATE_WMSOUT_BY_GUIDE = function(guide, byWarehouse) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (byWarehouse && !guide.whId) {
                layer.msg($translate.instant('autopsApp.guide.warehouseNullAlert'));
                return;
            }
            if (!byWarehouse && !guide.warehouseVid) {
                layer.msg($translate.instant('autopsApp.guide.warehouseVenderNullAlert'));
                return;
            }
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                WmsIn.createWmsOutByGuide({
                    byWarehouse: byWarehouse
                }, guide, function(result) {
                    $scope.$emit('autopsApp:loadGuide', guide);
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.createdSuccessWithRef', { "ref": result.warehousingNumber }));
                }, function(result) {
                    layer.closeAll();
                    if (result.status == 404) {
                        layer.msg($translate.instant('global.messages.companyNotBindedAlert'));
                    } else if (result.status == 403) {
                        layer.msg("该业务单已生成出仓单！");
                    } else if (result.status == 409) {
                        layer.msg("请刷新后重试！");
                    } else {
                        layer.msg($translate.instant('global.save_fail'));
                    }
                });
            }, function() {});
        }


        $rootScope.OPS_CHANNELS = null;
        $rootScope.GET_CHANNELS = function(warehouseId) {
            OpsCarrier.getChannels({ whId: warehouseId }, function(result) {
                $rootScope.OPS_CHANNELS = result;
            })
        }

        var copyRatesChoose = function(guide) {
            if (guide.guideRates && guide.guideRates.length > 0) {
                layer.open({
                    icon: 3,
                    content: $translate.instant('autopsApp.booking.copyChoose', {
                        amt: guide.guideRates.length
                    }),
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('autopsApp.booking.copyAll'),
                        $translate.instant('autopsApp.booking.copyRev'),
                        $translate.instant('autopsApp.booking.copyExp'),
                        $translate.instant('autopsApp.booking.copyNone')],
                    yes: function(index, layero) {
                        for (var i = guide.guideRates.length - 1; i >= 0; i--) {
                            if (guide.guideRates[i].isHidden || guide.guideRates[i].sharedOn || guide.guideRates[i].hiddenRate) {
                                guide.guideRates.splice(i, 1);
                                continue;
                            }
                            DataUtils.clearCopyRate(guide.guideRates[i]);
                        }
                        layer.close(index);
                    },
                    btn2: function(index, layero) {
                        for (var i = guide.guideRates.length - 1; i >= 0; i--) {
                            if (guide.guideRates[i].isPay || guide.guideRates[i].isHidden || guide.guideRates[i].sharedOn || guide.guideRates[i].hiddenRate) {
                                guide.guideRates.splice(i, 1);
                                continue;
                            }
                            DataUtils.clearCopyRate(guide.guideRates[i]);
                        }
                        layer.close(index);
                    },
                    btn3: function(index, layero) {
                        for (var i = guide.guideRates.length - 1; i >= 0; i--) {
                            if (!guide.guideRates[i].isPay || guide.guideRates[i].isHidden || guide.guideRates[i].sharedOn || guide.guideRates[i].hiddenRate) {
                                guide.guideRates.splice(i, 1);
                                continue;
                            }
                            DataUtils.clearCopyRate(guide.guideRates[i]);
                        }
                        layer.close(index);
                    },
                    btn4: function(index, layero) {
                        guide.guideRates = [];
                        layer.close(index);
                    },
                    cancel: function() {
                        guide.guideRates = [];
                    }
                });
            }
        }
        var copyBlChoose = function(guide) {
            layer.confirm($translate.instant('autopsApp.booking.copyBlChoose'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                guide.guideShipperBl = null;
                guide.deliveryBl = null;
                guide.guideNotifyBl = null;
                guide.importer = null;
                if(guide.booking){
                    guide.booking.shipperBl = null;
                    guide.booking.cneeBl = null;
                    guide.booking.notifyBl = null;
                    guide.booking.deliveryBl = null;
                    guide.booking.alsoNotify = null;
                }
                copyRatesChoose(guide);
                $translate.refresh();
            }, function() {
                copyRatesChoose(guide);
            });
        }
        $rootScope.COPY_GUIDE_CHOOSE = function(guide) {
            layer.confirm($translate.instant('autopsApp.booking.copyScheduleChoose'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                guide.siCutTime = null;
                guide.cls = null;
                guide.vsl = null;
                guide.voy = null;
                guide.etd = null;
                guide.eta = null;
                copyBlChoose(guide);
                $translate.refresh();
            }, function() {
                copyBlChoose(guide);
            });
        }
        $rootScope.PL_WMS_LOGS_POP = function(companyId, cargoId, bookingCtnId) {
            if (cargoId) {
                $rootScope.WMS_LOGS_POP(companyId, cargoId);
            }
            WmsCargo.getByBookingCtnid({ bookingCtnId: bookingCtnId }, function(result) {
                if (result.id) {
                    $rootScope.WMS_LOGS_POP(companyId, result.id);
                }
            });
        }

        $rootScope.WMS_LOGS_POP = function(companyId, cargoId) {
            if (!cargoId) {
                return;
            }
            var logEntity = {
                companyId: companyId,
                wmsCargoId: cargoId
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-log/wms-log-dialog.html',
                controller: 'WmsLogDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return logEntity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsLog');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.WMS_LOGS_SEARCH = function() {
            if ($rootScope.account.companyId && $rootScope.HAVENOAUTH("FUN_WMS_LOGS")) {
                $rootScope.FORBIDDEN_BY_ALERT("FUN_WMS_LOGS");
                return;
            }
            var logEntity = {
                companyId: $rootScope.account.companyId
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-log/wms-log-detail.html',
                controller: 'WmsLogDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return logEntity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsLog');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('wmsCargo');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.clerks = [];
        $rootScope.loadClerks = function(id) {
            if ($rootScope.clerks.length > 0 || !$rootScope.account.companyId) {
                return;
            }
            Clerks.query({ companyId: id }, function(result) {
                $rootScope.clerks = result;
            });
        }
        $scope.CLERKS_TEAM_FILTER = function(clerk, expected) {
            return clerk == expected;
        }
        $rootScope.DEL_ALL_GUIDES = function(guides) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.confirm($translate.instant('global.delAllAlert2', { ttlAmt: guides.length }), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.load(1, { shade: 0.3 });
                Guide.delAll(guides, function(result) {
                    $scope.$emit('autopsApp:loadAllRequred');
                    layer.closeAll();
                    layer.msg($translate.instant('global.delete_success'));
                }, function(error) {
                    layer.closeAll();
                    layer.msg($translate.instant('global.delete_fail'));
                });
                layer.close(index);
            }, function() {});
        }


        $rootScope.CREATE_TMS_BY_GUIDE = function(guide) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!guide.deliveryId) {
                layer.msg($translate.instant('autopsApp.guide.home.choose_agent'));
                return;
            }
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                ExpressParcel.createTmsByGuide(guide, function() {
                    layer.closeAll();
                    layer.msg($translate.instant('global.createdSuccess'));
                }, function(result) {
                    layer.closeAll();
                    if (result.status == 404) {
                        layer.msg($translate.instant('global.messages.companyNotBindedAlert'));
                    } else if (result.status == 409) {
                        layer.msg($translate.instant('global.messages.createRepeated'));
                    } else {
                        layer.msg($translate.instant('global.save_fail'));
                    }
                });
            }, function() {});
        }

        $rootScope.CREATE_TMS_BY_GUIDES = function(guides) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                ExpressParcel.createTmsByGuideDTOs(guides, function(result) {
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.createResultMsg', {
                        successJob: result.successJob,
                        failedJob: result.failedJob
                    }));
                }, function(result) {
                    layer.closeAll();
                    if (result.status == 404) {
                        layer.msg($translate.instant('global.messages.companyNotBindedAlert'));
                    } else if (result.status == 409) {
                        layer.msg($translate.instant('global.messages.createRepeated'));
                    } else {
                        layer.msg($translate.instant('global.save_fail'));
                    }
                });
            }, function() {});
        }

        $rootScope.TRIAL_COST_POP = function(expressParcel, fd) {
            var parcelCopy;
            if(expressParcel){
                parcelCopy = angular.copy(expressParcel);
                parcelCopy.expressPackages = null;
                parcelCopy.pkgNum = null;
                parcelCopy.gw = null;
                parcelCopy.vol = null;
            }
            else {
                parcelCopy = {};
            }

            if (fd == "costChannel") {
                parcelCopy.channel = parcelCopy.costChannel;
            }
            $uibModal.open({
                templateUrl: 'app/entities/edi-data/edi-data.html',
                controller: 'EdiDataController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return parcelCopy;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressParcel');
                        $translatePartialLoader.addPart('opsCost');
                        $translatePartialLoader.addPart('opsCarrier');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                if (expressParcel && result) {
                    expressParcel[fd] = result.channelName;
                    if (fd == "channel") {
                        expressParcel.carrier = result.carrier;
                    }
                }
            }, function(result) {});
        }
        // $rootScope.TRIAL_COST_POP();

        $rootScope.FBA_TRIAL_COST_POP = function(guide) {
            $uibModal.open({
                templateUrl: 'app/entities/edi-insurance/edi-insurance-detail.html',
                controller: 'EdiInsuranceDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return angular.copy(guide);
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressParcel');
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                DataUtils.updateGuideByProduct(guide, result, true);
            }, function(result) {});
        }

        $scope.parcelPackingPop = function(expressPacking) {
            $uibModal.open({
                templateUrl: 'app/entities/express-packing/express-packing-detail.html',
                controller: 'ExpressPackingDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return expressPacking;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressPacking');
                        $translatePartialLoader.addPart('expressParcel');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        $rootScope.BATCH_UPDATE_POP = function(isExpress, guide, guides) {
            if (guide) {
                guides = [];
                guides.push(guide);
            }
            else if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            if(amendGuideForbidden(guides)){
                return;
            }
            if (!guide && guides.length > 0) {
                guide = guides[0];
            }
            var entity = {
                isExpress: isExpress,
                guide: guide,
                guides: guides
            }
            $uibModal.open({
                templateUrl: 'app/entities/express-tracking/express-tracking-dialog.html',
                controller: 'ExpressTrackingDialogController',
                controllerAs: 'vm',
                size: 'xxxl',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressPacking');
                        $translatePartialLoader.addPart('expressParcel');
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {}, function() {});
        }


        $rootScope.DOWNLOAD_TEMPLATE = function(fileType) {
            OpsFiles.getClientFileUrl({
                fileType: fileType
            }, function(result) {
                if (result.fileurl) {
                    var fileUrl = result.fileurl;
                    if (fileUrl.indexOf("http:") !== -1 && fileUrl.indexOf("aliyuncs.com") !== -1) {
                        fileUrl = fileUrl.replace("http:", "https:");
                    }
                    var aTag = document.createElement("a");
                    aTag.href = fileUrl;
                    aTag.click();
                } else {
                    layer.msg($translate.instant('global.loadFileFailed'));
                }
            });
        }


        $rootScope.DOWNLOAD_EXCEL_TEMPLATE = function(templateCode) {
            OpsFiles.getExcelTemplate({
                templateCode: templateCode
            }, function(result) {
                if (result.fileurl) {
                    var fileUrl = result.fileurl;
                    if (fileUrl.indexOf("http:") !== -1 && fileUrl.indexOf("aliyuncs.com") !== -1) {
                        fileUrl = fileUrl.replace("http:", "https:");
                    }
                    var aTag = document.createElement("a");
                    aTag.href = fileUrl;
                    aTag.click();
                } else {
                    layer.msg($translate.instant('global.loadFileFailed'));
                }
            });
        }


        $rootScope.SEARCH_WMS_GOODS = function(code, sku, cargoName, venderId) {
            return ManageEnterprise.searchWmsGoods({
                code: code,
                sku: sku,
                cargoName: cargoName,
                venderId: venderId
            }).$promise;
        }

        $rootScope.SEARCH_COMMODITY_DATABASE = function(queryString) {
            if (!queryString) {
                return;
            }
            return CommodityDatabase.searchCommodityDatabase({
                query: queryString,
                page: 0,
                size: 25,
                sort: ['_score,desc']
            }).$promise;
        }

        $rootScope.SEARCH_COMMODITY_FOR_PL = function(queryString, countryCode) {
            if (!queryString) {
                return;
            }
            return CommodityDatabase.searchForPackingList({
                query: queryString,
                countryCode: countryCode,
                companyId: $rootScope.account.companyId,
                page: 0,
                size: 20,
                sort: ['id,desc']
            }).$promise;
        }


        $rootScope.HSCODE_FINDER = function(queryString) {
            if (!queryString) {
                return;
            }
            return CommodityDatabase.hscodeFinder({
                query: queryString
            }).$promise;
        }

        $rootScope.CARGO_MOVE_SHELF = function(cargo, wmsCargos, locations) {
            if ($rootScope.HAVENOAUTH("FUN_WMS_MOVING")) {
                $rootScope.FORBIDDEN_BY_ALERT("FUN_WMS_MOVING");
                return;
            }

            var entity = {};
            if (cargo) {
                entity.wmsCargos = [];
                entity.wmsCargos.push(cargo);
            } else if (wmsCargos) {
                entity.wmsCargos = wmsCargos;
            }
            entity.locations = locations;
            $uibModal.open({
                templateUrl: 'app/entities/wms-cargo/wms-cargo-movement-dialog.html',
                controller: 'WmsCargoMovementController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }

        $rootScope.CARGO_UNPACKING = function(cargo, locations) {
            // if ($rootScope.HAVENOAUTH("FUN_WMS_MOVING")) {
            //     $rootScope.FORBIDDEN_BY_ALERT("FUN_WMS_MOVING");
            //     return;
            // }
            var entity = {};
            entity.cargo = cargo;
            entity.locations = locations;
            $uibModal.open({
                templateUrl: 'app/entities/wms-cargo/wms-cargo-unpacking-dialog.html',
                controller: 'WmsCargoUnpackingController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }
        // if($rootScope.absUrl.indexOf("8080") !== -1){
        //     $rootScope.CARGO_UNPACKING();
        // }
        $rootScope.datePickerOpenStatus = {};
        $rootScope.openCalendar = function(date) {
            $rootScope.datePickerOpenStatus[date] = true;
        }

        var isDiffShipmentType = function(viewType, newShipmentType) {
            if (viewType == "ALL") {
                return false;
            } else if (viewType == "AIR") {
                return newShipmentType != "AIR";
            } else if (viewType == "FBA") {
                return $rootScope.fbaTypes.indexOf(newShipmentType) == -1;
            } else if (viewType == "WMS") {
                return $rootScope.wmsTypes.indexOf(newShipmentType) == -1;
            } else if (viewType == "SEA") {
                return $rootScope.seaTypes.indexOf(newShipmentType) == -1;
            }
        }

        $rootScope.amendShipmentTypePop = function(guide, guides, viewType) {
            if (guide.etdLocked || guide.isClosed) {
                $rootScope.OPS_ALERT($translate.instant('global.lockedAlert'));
                return;
            }
            $scope.newShipmentType = null;
            $translate.refresh();
            layer.open({
                type: 1,
                area: ['380px', '260px'],
                maxmin: false,
                title: $translate.instant('autopsApp.guide.shipmentType'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#amendShipmentTypePop"),
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.cancel')],
                yes: function(index, layero) {
                    Guide.amendShipmentType({ id: guide.id, shipmentType: $scope.newShipmentType }, function(result) {
                        layer.close(index);
                        if (isDiffShipmentType(viewType, result.shipmentType)) {
                            var idx = guides.indexOf(guide);
                            guides.splice(idx, 1);
                        } else {
                            guide.shipmentType = result.shipmentType;
                        }

                    })
                },
                cancel: function(index) {
                    layer.close(index);
                }
            });
        }
        $rootScope.WAREHOUSE_CHARGE = function(id, type, name) {
            var entity = { id: id, type: type, name: name };
            $uibModal.open({
                templateUrl: 'app/entities/js-expression/js-expression-dialog.html',
                controller: 'JsExpressionDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('jsExpression');
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }

        $rootScope.EXPORT_GRID_DATA = function(jsonObj, fileName) {
            if ($rootScope.HAVEAUTH("FB_EXPORT_GRID_EXCEL")) {
                $rootScope.FORBIDDEN_FB_BY_ALERT("FB_EXPORT_GRID_EXCEL");
                return;
            }
            if (jsonObj.dataType == "vender" && $rootScope.HAVENOAUTH("FUN_CLIENT_EXPORT")) {
                $rootScope.FORBIDDEN_BY_ALERT("FUN_CLIENT_EXPORT");
                return;
            }

            var loadIndex = layer.load(1, { shade: 0.3 });
            // var jsonStr = angular.toJson(jsonObj);
            if (fileName.indexOf(".xlsx") == -1) {
                fileName += ".xlsx";
            }
            OpsFiles.exportGridData({
                timezoneOffset: new Date().getTimezoneOffset() / 60
            }, jsonObj, function(result) {
                layer.close(loadIndex);
                $rootScope.DOWNLOADEXCEL(result, fileName);
            }, function(error) {
                layer.close(loadIndex);
            })

            // var blob = new Blob([jsonStr]);
            // var file = new File([blob], "23232-1.txt",
            //     { type: 'txt'}
            // );
            // var fd = new FormData();
            // fd.append('file', file);
            // $http.post(
            //     'api/excel/exportGridData', fd, {
            //         transformRequest : angular.identity,
            //         responseType: 'arraybuffer',
            //         headers : {
            //             'Content-Type' : undefined
            //         },
            //     }).success(
            //     function(data) {
            //         var result = new Object();
            //         result['data'] = data;
            //         $rootScope.DOWNLOADEXCEL(result, fileName + ".xlsx");
            //     }).error(function(error) {

            // });
        }

        $rootScope.SEND_API_INBOUND_REQUEST = function(guide) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                Guide.setFmsInBoundRequest({}, guide, onEdiSuccess, $rootScope.ALERT_ERROR);
            }, function() {});
        }
        $rootScope.ORMESANI_SEND = function(guide) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                Guide.ormesaniSftpUpload({}, guide, onEdiSuccess, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $rootScope.CHECK_TRACKING_NO = function(guide) {
            if (!guide.mblNum || guide.etdLocked) {
                return;
            }
            if (guide.shipmentType == "AIR") {
                var mawb = guide.mblNum;
                mawb = mawb.replace("/\s+/g", "");
                mawb = mawb.replace("-", "");
                if (mawb.length != 11) {
                    layer.msg($translate.instant('autopsApp.guide.home.mawbInvalid'));
                    return;
                } else {
                    var serialNum = mawb.substring(3, 10) - 0;
                    var checkNum = mawb.substring(10, 11) - 0;
                    if (serialNum % 7 != checkNum) {
                        layer.msg($translate.instant('autopsApp.guide.home.mawbInvalid'));
                        return;
                    }
                }
            } else if (guide.mblNum.length > 50) {
                guide.mblNum = guide.mblNum.substring(0, 50);
            }
            Guide.checkMbl({
                companyId: $rootScope.account.companyId,
                guideId: guide.id,
                isFbaWms: ($rootScope.ISFBA(guide.shipmentType) || $rootScope.ISWMS(guide.shipmentType)),
                mblNum: guide.mblNum
            }, function(result) {
                if (result.trackingChannel && $rootScope.ISFBA(guide.shipmentType)) {
                    if (!guide.deliveryWay) {
                        guide.deliveryWay = "express";
                    }
                    guide.booking.carrierCode = angular.lowercase(result.trackingChannel);
                    guide.booking.carrierFullName = result.trackingChannel;
                }
                if (result.isRepeated) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.mblNumRepeated'));
                    return;
                }
            });
        }

        $rootScope.GUIDES_REPORT_PRINT = function(guides, code) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var guideReportDTOs = [];
            angular.forEach(guides, function(data) {
                var guideReportDTO = angular.copy(data);
                if (guideReportDTO.booking) {
                    guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
                }
                guideReportDTOs.push(guideReportDTO);
            });
            layer.load(1, { shade: 0.3 });
            OpsReport.combineAndPrint({ fileType: 1, reportType: code }, guideReportDTOs, function(result) {
                layer.closeAll();
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.closeAll();
            });
        }

        $rootScope.EDI_FILLING_POP = function(guideId, manifestType, isSame) {
            if ($rootScope.HAVENOAUTH(manifestType)) {
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if (manifestType != "SINOLINES" && $rootScope.HAVENOAUTH("AU_EDI_" + manifestType)) {
                $rootScope.FORBIDDEN_BY_ALERT("AU_EDI_" + manifestType);
                return;
            }
            if (!isSame) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var entity = {};
            entity.guideId = guideId;
            entity.manifestType = manifestType;

            var guide = { id: guideId };
            $uibModal.open({
                templateUrl: 'app/entities/ams-edi-data/ams-edi-data-dialog.html',
                controller: 'AmsEdiDataDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('amsEdiData');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                $scope.$emit('autopsApp:loadGuide', guide);
            }, function(result) {
                $scope.$emit('autopsApp:loadGuide', guide);
            });
        }

        $rootScope.PLTHD_EDI_FILLING_POP = function(guideId, manifestType, isSame) {
            if ($rootScope.HAVENOAUTH(manifestType)) {
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            // if (manifestType != "SINOLINES" && $rootScope.HAVENOAUTH("AU_EDI_" + manifestType)) {
            //     $rootScope.FORBIDDEN_BY_ALERT("AU_EDI_" + manifestType);
            //     return;
            // }
            if (!isSame) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var entity = {};
            entity.guideId = guideId;
            entity.manifestType = manifestType;

            var guide = { id: guideId };
            $uibModal.open({
                templateUrl: 'app/entities/ams-edi-data/plt-edi-data-dialog.html',
                controller: 'PltEdiDataDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('amsEdiData');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                $scope.$emit('autopsApp:loadGuide', guide);
            }, function(result) {
                $scope.$emit('autopsApp:loadGuide', guide);
            });
        }

        $rootScope.BUY_INSURANCE_POP = function(guide, insuranceType) {
            if ($rootScope.HAVENOAUTH("INSUR") && $rootScope.HAVENOAUTH("WYB")) {
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if ($rootScope.HAVENOAUTH("AU_EDI_INSURANCE")) {
                $rootScope.FORBIDDEN_BY_ALERT("AU_EDI_INSURANCE");
                return;
            }
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (insuranceType == 'FBA') {
                if ($rootScope.HAVENOAUTH("INSUR") && $rootScope.HAVEAUTH("WYB")) {
                    insuranceType = "WYB"
                }
            }
            var entity = {
                guideId: guide.id,
                insuranceType: insuranceType
            }
            $uibModal.open({
                templateUrl: 'app/entities/edi-insurance/edi-insurance-dialog.html',
                controller: 'EdiInsuranceDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('ediInsurance');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        $rootScope.DOWNLOAD_TXT = function(str, fileName) {
            var blob = new Blob([str]);
            var blobURL = (window.URL || window.webkitURL).createObjectURL(blob);
            var aTag = document.createElement("a");
            aTag.download = fileName;
            aTag.href = blobURL;
            aTag.click();
        }
        $rootScope.EXPORT_BOOKING_CTNS = function(guides){
            if(guides == null || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.exportBookingCtns(guides, function(result){
                $rootScope.DOWNLOADEXCEL(result, "Cargo_Information.xlsx");
                layer.closeAll();
            }, $rootScope.ALERT_ERROR)
        }
        $rootScope.SHIPMENT_MANIFEST = function(guides) {
            if (!guides || guides.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsFiles.shipmentManifest(guides, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, "manifest.xlsx");
            })
        }
        $rootScope.RE_SHARE_EXPORT = function(guides) {
            if (!guides || guides.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsFiles.amassRateShareSheet(guides, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, "reshare.xlsx");
            }, onExportFailed);
        }

        function onExportFailed() {
            layer.closeAll();
            layer.msg($translate.instant('global.exportFailed'));
        }


        $rootScope.WMS_CODE_CHECK = function(code) {
            if (code && code.indexOf("-") !== -1) {
                $rootScope.OPS_ALERT($translate.instant('global.specialCharacterAlert', { param: "\"-\"" }));
            }
        }
        $rootScope.PICS_POP = function(files) {
            $uibModal.open({
                templateUrl: 'app/entities/wo-body/wo-body-detail.html',
                controller: 'WoBodyDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return files;
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.SEND_FMS_SHIPMENT = function(guide, type) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                Guide.shipmentSend({ type: type }, angular.copy(guide), onEdiSuccess, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $rootScope.FREIGHT_OS = function(guide) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            Guide.freightOsTest(angular.copy(guide), onEdiSuccess, $rootScope.ALERT_ERROR);
        }

        $rootScope.GET_FMS_REQUEST_BODY = function(guide, type) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            Guide.getRequestBody({ type: type }, angular.copy(guide), function(result) {
                $rootScope.DOWNLOAD_TXT(result.value, guide.jobNum + "_" + type + ".txt");
            }, $rootScope.ALERT_ERROR);
        }

        $rootScope.GET_FBA_REQUEST_BODY = function(guide, accountBind) {
            var pushOrdersDTO = {};
            pushOrdersDTO.accountBind = accountBind;
            pushOrdersDTO.guide = guide;
            Guide.getFbaRequestBody({}, pushOrdersDTO, function(result) {
                $rootScope.DOWNLOAD_TXT(result.value, guide.jobNum + "_request_body.txt");
            }, $rootScope.ALERT_ERROR);
        }

        $rootScope.IMPORT_ONE_FBA_ORDER_POP = function() {
            $uibModal.open({
                templateUrl: 'app/entities/wms-map/wms-maps.html',
                controller: 'WmsMapController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return { type: "FBASEA" };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountBind');
                        $translatePartialLoader.addPart('wmsMap');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        };

        $rootScope.SEND_WHS_DATA = function(id, branchGuideId) {
            layer.msg($translate.instant('global.serviceNotSupported'));
            return;
            // layer.confirm($translate.instant('autopsApp.guide.sendWarehouseDataTips'), {
            //     icon: 3,
            //     title: "Msg",
            //     btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            // }, function(index) {
            //     layer.close(index);
            //     Guide.synWarehousingData({
            //         id: id,
            //         branchGuideId: branchGuideId
            //     }, function(result) {
            //         layer.msg($translate.instant('global.send_success'));
            //     }, function(reject) {
            //         layer.msg($translate.instant('global.send_fail'));
            //     }, function(error) {
            //     });
            // }, function() {
            // });
        }
        $rootScope.CHECK_REF_NUM = function(guide) {
            if (!guide.refNum) {
                return;
            }
            Guide.checkRefNum({
                companyId: $rootScope.account.companyId,
                guideId: guide.id,
                refNum: guide.refNum
            }, function(result) {
                if (result.isRepeated) {
                    guide.refNum = null;
                    $rootScope.OPS_ALERT($translate.instant('global.codeRepeat'));
                }
            });
        }


        $rootScope.CHECK_SO_NUM = function(guide) {
            if (!guide.soNum) {
                return;
            }
            Guide.checkSo({
                companyId: $rootScope.account.companyId,
                guideId: guide.id,
                soNum: guide.soNum
            }, function(result) {
                if (result.isRepeated) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.soNumRepeated'));
                }
            });
        }


        $rootScope.CHECK_JOB_NUM = function(guide, oldJobNum) {
            if (!guide.jobNum) {
                return;
            }
            Guide.checkJobNum({
                companyId: $rootScope.account.companyId,
                guideId: guide.id,
                jobNum: guide.jobNum
            }, function(result) {
                if (result.isRepeated) {
                    $rootScope.OPS_ALERT(guide.jobNum + ": " + $translate.instant('autopsApp.guide.home.jobNumRepeated'));
                    guide.jobNum = oldJobNum;
                }
            });
        }
        $rootScope.CHECK_HBL_NUM = function(guide, numCopy) {
            if (!guide.hblNum) {
                return;
            }
            Guide.checkHbl({
                companyId: $rootScope.account.companyId,
                guideId: guide.id,
                hblNum: guide.hblNum
            }, function(result) {
                if (result.isRepeated) {
                    $rootScope.OPS_ALERT(guide.hblNum + ": " + $translate.instant('autopsApp.guide.home.hblNumRepeated'));
                    guide.hblNum = (numCopy == guide.hblNum ? null : numCopy);
                    return;
                }
            });

        };

        $rootScope.AI_CLASSIFICATION_POP = function() {
            var entity = {};
            $uibModal.open({
                templateUrl: 'app/entities/commodity-database/commodity-database-detail.html',
                controller: 'CommodityDatabaseDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodityDatabase');
                        $translatePartialLoader.addPart('commodityCountry');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        $rootScope.FMS_RATES_SUBMIT = function(guideId, isPay) {
            if (!isPay) {
                if ($rootScope.HAVENOAUTH('FUN_VIEW_PAYER')) {
                    $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYER');
                    return;
                }
                if ($rootScope.HAVENOAUTH('AU_VIEW_REV')) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_REV');
                    return;
                }
            }
            if (isPay || isPay == null) {
                if ($rootScope.HAVENOAUTH('FUN_VIEW_PAYEE')) {
                    $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYEE');
                    return;
                }
                if ($rootScope.HAVENOAUTH('AU_VIEW_EXP')) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_EXP');
                    return;
                }
            }
            Guide.getGuideReview({
                id: guideId
            }, function(result) {
                if (!result.guideRates || result.guideRates.length == 0) {
                    layer.msg($translate.instant('global.noRecord'));
                    return;
                }
                if (!result.etd) {
                    layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                    return;
                };
                if (!result.submited) {
                    layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                    return;
                }
                if ($rootScope.account.mustAudit && result.checkStatus != 'approved') {
                    layer.msg($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                    return;
                }
                if ($rootScope.HAVENOAUTH('FUN_VIEW_ONE_HIDDEN')) {
                    for (var i = result.guideRates.length - 1; i >= 0; i--) {
                        if (result.guideRates[i].hiddenRate &&
                            result.guideRates[i].venderId &&
                            result.guideRates[i].payAmount &&
                            result.guideRates[i].payCurrency &&
                            result.guideRates[i].payer &&
                            !result.guideRates[i].dnNum) {
                            $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_ONE_HIDDEN');
                            return;
                        }
                    }
                }
                result.isGuide = true;
                result.isPay = isPay;
                $uibModal.open({
                    templateUrl: 'app/entities/guideRates/batchRatesSubmit.html',
                    controller: 'BatchRatesSubmitController',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return result;
                        }]
                    }
                }).result.then(function(result) {}, function(result) {});
            });
        }

        $rootScope.GUIDE_BATCH_UPLOAD = function(file, fileType) {
            if (!file) { return; }
            if(!file.name || angular.lowercase(file.name).indexOf(".zip") == -1){
                $rootScope.OPS_ALERT($translate.instant('global.zipAlert'));
                return;
            }
            $scope.isSaving = true;
            var fd = new FormData();
            fd.append('file', file);
            fd.append('fileType', fileType);
            if ($rootScope.account.companyId) {
                fd.append('companyId', $rootScope.account.companyId);
            }
            var loadIndex = layer.load(1, { shade: 0.3 });
            $http.post(
                'api/guides/uploadLabelFiles', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                layer.close(loadIndex);
                $scope.isSaving = false;
                if (data) {
                    data = angular.fromJson(data);
                    if (!data.failedStr) {
                        $rootScope.OPS_ALERT("全部更新成功: " + data.uploadedStr);
                    } else {
                        $rootScope.OPS_ALERT("部分/全部未更新成功，请检查: " + data.failedStr);
                    }
                }
            }).error(function(error) {
                layer.close(loadIndex);
                $scope.isSaving = false;
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });
        }
        $rootScope.ZIP_OSS_FILES = function(masterId, includingSub, isFms, jobNum) {
            if (!masterId) {
                return;
            }
            OpsFiles.zipGuideOssFiles({
                masterId: masterId,
                incSub: includingSub,
                isFms: isFms
            }, function(result) {
                $rootScope.DOWNLOADEXCEL(result, jobNum + ".zip");
            })
        };
        $rootScope.ZIP_OSS_FILES_SUB = function(masterId, includingSub, jobNum) {
            if (!masterId) {
                return;
            }
            OpsFiles.zipGuideSubOssFiles({
                masterId: masterId,
                incSub: includingSub,
            }, function(result) {
                $rootScope.DOWNLOADEXCEL(result, jobNum + ".zip");
            })
        };
        $rootScope.ZIP_GUIDE_FILES_BY_TYPE = function(guides, fileType) {
            if (!guides || guides.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            OpsFiles.zipGuidesFilesByType({
                fileType: fileType
            }, guides, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileType + "_files.zip");
            })
        };

        $rootScope.FMS_STATUS_TYPES = [
            { 'label': $translate.instant("autopsApp.guide.sts.new"), 'value': 'new' },
            { 'label': $translate.instant("autopsApp.guide.sts.Requested"), 'value': 'Requested' },
            { 'label': $translate.instant("autopsApp.guide.sts.Booked"), 'value': 'Booked' },
            { 'label': $translate.instant("autopsApp.guide.sts.SoReleased"), 'value': 'SoReleased' },
            { 'label': $translate.instant("autopsApp.guide.sts.stowageDone"), 'value': 'stowageDone' },
            { 'label': $translate.instant("autopsApp.guide.sts.TruckArranged"), 'value': 'TruckArranged' },
            { 'label': $translate.instant("autopsApp.guide.sts.vgmSent"), 'value': 'vgmSent' },
            { 'label': $translate.instant("autopsApp.guide.sts.wmsIn"), 'value': 'wmsIn' },
            { 'label': $translate.instant("autopsApp.guide.sts.wmsOut"), 'value': 'wmsOut' },
            { 'label': $translate.instant("autopsApp.guide.sts.CtnStuffed"), 'value': 'CtnStuffed' },
            { 'label': $translate.instant("autopsApp.guide.sts.CustomArranged"), 'value': 'CustomArranged' },
            { 'label': $translate.instant("autopsApp.guide.sts.customsInspectionExp"), 'value': 'customsInspectionExp' },
            { 'label': $translate.instant("autopsApp.guide.sts.CustomReleased"), 'value': 'CustomReleased' },
            { 'label': $translate.instant("autopsApp.guide.sts.plConfirmed"), 'value': 'plConfirmed' },
            { 'label': $translate.instant("autopsApp.guide.sts.SiSubmitted"), 'value': 'SiSubmitted' },
            { 'label': $translate.instant("autopsApp.guide.sts.SiReceived"), 'value': 'SiReceived' },
            { 'label': $translate.instant("autopsApp.guide.sts.atd"), 'value': 'atd' },
            { 'label': $translate.instant("autopsApp.guide.sts.HblConfirmed"), 'value': 'HblConfirmed' },
            { 'label': $translate.instant("autopsApp.guide.sts.MblConfirmed"), 'value': 'MblConfirmed' },
            { 'label': $translate.instant("autopsApp.guide.sts.DnSent"), 'value': 'DnSent' },
            { 'label': $translate.instant("autopsApp.guide.sts.SaSent"), 'value': 'SaSent' },
            { 'label': $translate.instant("autopsApp.guide.sts.arrivedPod"), 'value': 'arrivedPod' },
            { 'label': $translate.instant("autopsApp.guide.sts.customsInspection"), 'value': 'customsInspection' },
            { 'label': $translate.instant("autopsApp.guide.sts.customCleared"), 'value': 'customCleared' },
            { 'label': $translate.instant("autopsApp.guide.sts.ctnAvailable"), 'value': 'ctnAvailable' },
            { 'label': $translate.instant("autopsApp.guide.sts.transferOut"), 'value': 'transferOut' },
            { 'label': $translate.instant("autopsApp.guide.sts.deliveryAppointed"), 'value': 'deliveryAppointed' },
            { 'label': $translate.instant("autopsApp.guide.sts.Delivering"), 'value': 'Delivering' },
            { 'label': $translate.instant("autopsApp.guide.sts.Deliveried"), 'value': 'Deliveried' },
            { 'label': $translate.instant("autopsApp.guide.sts.ctnReturned"), 'value': 'ctnReturned' },
            { 'label': $translate.instant("autopsApp.guide.sts.isClosed"), 'value': 'isClosed' },
            { 'label': $translate.instant("autopsApp.guide.sts.Cancelled"), 'value': 'Cancelled' },
            { 'label': $translate.instant("autopsApp.guide.sts.voided"), 'value': 'voided' }
        ]

        $rootScope.TMS_STATUS_TYPES = [
            { 'label': $translate.instant("autopsApp.expressParcel.status.new"), 'value': 'new'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.confirm"), 'value': 'confirm'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.sendDn"), 'value': 'sendDn'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.pickup"), 'value': 'pickup'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.wmsIn"), 'value': 'wmsIn'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.delivery"), 'value': 'delivery'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.wmsOut"), 'value': 'wmsOut'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.costInput"), 'value': 'costInput'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.podConfirm"), 'value': 'podConfirm'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.dnDone"), 'value': 'dnDone'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.isClosed"), 'value': 'isClosed'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.isVoid"), 'value': 'isVoid'}
        ]
        $rootScope.PARCEL_STATUS_TYPES = [
            { 'label': $translate.instant("autopsApp.expressParcel.status.new"), 'value': 'new'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.checkIn"), 'value': 'checkIn'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.docment"), 'value': 'docment'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.offShelf"), 'value': 'offShelf'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.checkOut"), 'value': 'checkOut'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.customRls"), 'value': 'customRls'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.deliveried"), 'value': 'deliveried'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.problem"), 'value': 'problem'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.dnDone"), 'value': 'dnDone'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.isClosed"), 'value': 'isClosed'},
            { 'label': $translate.instant("autopsApp.expressParcel.status.isVoid"), 'value': 'isVoid'}
        ]


        $rootScope.HM_TYPES = [
            { 'label': "", 'value': "" },
            { 'label': "H", 'value': 'H' },
            { 'label': "M", 'value': 'M' },
            { 'label': "M&H", 'value': 'M&H' }
        ]
        $rootScope.ASSIST_CHARGES_POP = function(type, id) {
            if ($rootScope.HAVENOAUTH('AU_VIEW_ASSIST_CHARGE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_ASSIST_CHARGE');
                return;
            }
            var entity = {
                type: type,
                id: id
            }
            $uibModal.open({
                templateUrl: 'app/entities/assist-charge/assist-charge-dialog.html',
                controller: 'AssistChargeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.YY_READOLY = function(guide) {
            if (!guide || !$rootScope.isYuyueExHaShen(guide.companyId)) {
                return false;
            }
            if (guide.masterId || (guide.branchGuideId && guide.branchGuideId > guide.id)) {
                return true;
            }
            return false;
        }
        $rootScope.IS_AUTH_TO = function(guide) {
            if(guide.id && guide.branchGuideId && guide.branchGuideId < guide.id){
                return true;
            }
            if(guide.id && guide.ediResp && guide.ediResp.indexOf("auopsEdi:") == 0){
                return true;
            }
            return false;
        }

        $rootScope.IS_AUTH_FM = function(guide) {
            if(guide.id && guide.branchGuideId && guide.branchGuideId > guide.id){
                return true;
            }
            if(guide.id && guide.thirdpartyPlatformId && guide.thirdpartyPlatformRef){
                return true;
            }
            return false;
        }

        $rootScope.YY_PC_READONLY = function(parcel) {
            if ($rootScope.tmsTypes.indexOf(parcel.shipmentType) == -1 || !$rootScope.isYuyue(parcel.companyId)) {
                return false;
            }
            if (parcel.branchGuideId && parcel.branchGuideId > parcel.id) {
                return true;
            }
            return false;
        }


        $rootScope.ON_PRODUCT_SELECTED = function(productName, guide) {
            if (!productName) {
                return;
            }
            var shipmentType = "FBA";
            if (guide.shipmentType == "FCL") {
                shipmentType = "FCL";
            } else if (guide.shipmentType == "LCL") {
                shipmentType = "LCL";
            } else if (guide.shipmentType == "AIR") {
                shipmentType = "AIR";
            }
            guide.sellingMath = null;
            guide.costMath = null;
            matchCostsByProduct(guide, false);

            // if($rootScope.account.companyId == 54){
            //     matchCostsByProduct(guide, false);
            //     return;
            // }
            // OpsCost.findOneByProduct({
            //     isSelling: true,
            //     productName: productName,
            //     shipmentType: shipmentType,
            //     dest: guide.booking.deliveryTo
            // }, function(result) {
            //     if (result && result.id) {
            //         layer.confirm($translate.instant('autopsApp.guide.updateGuideByProductAlert'), {
            //             icon: 3,
            //             title: "Alert",
            //             btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            //         }, function(index) {
            //             layer.close(index);
            //             DataUtils.updateGuideByProduct(guide, result, false);
            //             $rootScope.CHECK_GUIDE_CHANNEL(guide);
            //             $translate.refresh();
            //         }, function() {
            //             $rootScope.CHECK_GUIDE_CHANNEL(guide);
            //         });
            //     }
            // })
        }

        var matchCostsByProduct = function(guide, isPay){
            $uibModal.open({
                templateUrl: 'app/entities/ops-cost/ops-cost-product.html',
                controller: 'OpsCostProductController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return Guide.matchCostsByProduct({isPay: isPay}, guide).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                DataUtils.updateGuideByProduct(guide, result, result.isMath);
                $rootScope.CHECK_GUIDE_CHANNEL(guide);
            }, function() {
                $rootScope.CHECK_GUIDE_CHANNEL(guide);
            });
        }

        $rootScope.companyConfiguration = function(activeFd, activeBtn) {
            if (!$rootScope.account.companyId) {
                return;
            }
            if ($rootScope.HAVENOAUTH('AU_COMPANY_CONFIGURATION')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_COMPANY_CONFIGURATION');
                return;
            }
            var entity = {};
            entity[activeFd] = true;
            entity.activeBtn = activeBtn;
            $uibModal.open({
                templateUrl: 'app/entities/commodity-country/commodity-country-dialog.html',
                controller: 'CommodityCountryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['OpsCompany', function(OpsCompany) {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCompany');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        }
        // 判断指定customNode的业务类型是否与Guide单的类型匹配，匹配返回true，否则返回false
        function checkGuideNodeType(guide, customNode) {
            if (!guide.shipmentType || !customNode.shipmentType) {
                return false;
            }
            if (customNode.shipmentType == 'FMSALL') {
                return true;
            }
            if (customNode.shipmentType == guide.shipmentType) {
                return true;
            }
            if (customNode.shipmentType == 'SEAALL' && $rootScope.seaTypes.indexOf(guide.shipmentType) != -1) {
                return true;
            }
            if (customNode.shipmentType == 'FBAALL' && $rootScope.fbaTypes.indexOf(guide.shipmentType) != -1) {
                return true;
            }
            if (customNode.shipmentType == 'WMSALL' && $rootScope.wmsTypes.indexOf(guide.shipmentType) != -1) {
                return true;
            }
            return false
        }

        $rootScope.RQ_CHECK = function(guide) {
            if(guide.cbMark && !guide.cbNo){
                return $translate.instant('autopsApp.guide.cbNo');
            }
            var rqFd = null;
            for (var i = 0; i < requiredFields.length; i++) {
                var customNode = requiredFields[i];
                if (customNode.conditionsCombine ||
                    !checkGuideNodeType(guide, customNode)) {
                    continue;
                }
                // 如果相应业务单对应字段已经有值了，那对应字段就不用判断了
                var bindFd = customNode.bindFd;
                if (bindFd && bindFd.indexOf("BK") == 0) {
                    if (guide.booking[bindFd.substring(2)] || guide.booking[bindFd.substring(2)] === false) {
                        continue;
                    }
                } else if (guide[bindFd] || guide[bindFd] == false){
                    continue;
                }
                if (!nodeNotMatch(guide, customNode)) {
                    rqFd = bindFd;
                    break;
                }
            }
            if (rqFd) {
                var data = { name: rqFd };
                if ($rootScope.ISFBA(guide.shipmentType)) {
                    DataUtils.getLangNameFba(data);
                } else if ("AIR" == guide.shipmentType) {
                    DataUtils.getLangNameAir(data);
                } else {
                    DataUtils.getLangNameFms(data);
                }
                return data.langName;
            }
            if (guide.submited) {
                return $rootScope.DELIVERY_RQ_CHECK(guide);
            }
        }
        $rootScope.DELIVERY_RQ_CHECK = function(guide) {
            var rqFd = null;
            if ($rootScope.ISFBA(guide.shipmentType) && $rootScope.COM_CONFG.FBARQ) {
                angular.forEach($rootScope.COM_CONFG.FBARQ, function(fd) {
                    if(fd == "BKetd"){
                        fd = "bookingEtd";
                    }
                    if (!rqFd) {
                        if (guide.booking && fd.indexOf("BK") == 0) {
                            if(fd == "BKbookingCtns" && (!guide.booking.bookingCtns || !guide.booking.bookingCtns.length)){
                                rqFd = fd;
                            }
                            else if (!guide.booking[fd.substring(2)]) {
                                rqFd = fd;
                            }
                        } else if (!guide[fd]) {
                            rqFd = fd;
                        }
                    }
                })
                if (rqFd) {
                    var data = { name: rqFd };
                    DataUtils.getLangNameFba(data);
                    return data.langName;
                }
            } else if ("AIR" == guide.shipmentType && $rootScope.COM_CONFG.AIRRQ) {
                angular.forEach($rootScope.COM_CONFG.AIRRQ, function(fd) {
                    if(fd == "BKetd"){
                        fd = "bookingEtd";
                    }
                    if (!rqFd) {
                        if (fd.indexOf("BK") == 0) {
                            if(fd == "BKbookingCtns" && (!guide.booking.bookingCtns || !guide.booking.bookingCtns.length)){
                                rqFd = fd;
                            }
                            else if (!guide.booking[fd.substring(2)]) {
                                rqFd = fd;
                            }
                        } else if (!guide[fd]) {
                            rqFd = fd;
                        }
                    }
                })
                if (rqFd) {
                    var data = { name: rqFd };
                    DataUtils.getLangNameAir(data);
                    return data.langName;
                }
            } else if ($rootScope.COM_CONFG.FMSRQ) {
                angular.forEach($rootScope.COM_CONFG.FMSRQ, function(fd) {
                    if(fd == "BKetd"){
                        fd = "bookingEtd";
                    }
                    if (!rqFd) {
                        if (fd.indexOf("BK") == 0) {
                            if(fd == "BKbookingCtns" && (!guide.booking.bookingCtns || !guide.booking.bookingCtns.length)){
                                rqFd = fd;
                            }
                            else if (!guide.booking[fd.substring(2)]) {
                                rqFd = fd;
                            }
                        } else if (!guide[fd]) {
                            rqFd = fd;
                        }
                    }
                })
                if (rqFd) {
                    var data = { name: rqFd };
                    DataUtils.getLangNameFms(data);
                    return data.langName;
                }
            }
        }
        var dateArray = ["lfd", "eta", "etd", "feederEtd", "deliveryDate", "transferDate", "billDate", "opDate", "atd", "revDeadLine", "cargoReadyDate", "bookingEtd", "BKetd", "BKissueDate"];
        var timeArray = ["ctnReturnTime", "createdTime", "cls", "lastModifiedTime", "bookingTime", "soReleaseTime", "truckArrangeTime", "customArrangeTime", "siSubmitedTime", "hblConfirmedTime", "mblConfirmedTime", "dnSentTime", "saSentTime", "hblReleasedTime", "mblReleasedTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime", "paymentReceivedTime", "siCutTime", "localTimeOne", "localTimeTwo", "otherTimeOne", "otherTimeTwo", "otherTimeThree", "vgmTime", "vgm", "ctnsLoadedTime", "customReleaseTime", "deliveryTime", "plConfirmTime", "customClearTime", "ataTime", "deliveryAppointmentTime", "soExpectedTime", "applyRlsTime", "deliveryGuideTime", "closeTime", "ctnAvailableTime", "BKcreatedTime", "BKlastModifiedTime", "BKsiReceivedTime", "offShelfTime", "costInputTime"];
        var booleanArray = ["revExist", "expExist", "isWood", "isExport", "isTemplate", "ratesLocked", "isLocked", "isApplied",
            "isClosed", "issueFeederHbl", "etdLocked", "mblOnly", "deliverySecret", "trackingFinished", "hidenCost", "isVoid", "spx",
            "hidenSelling", "allBilled", "truckRequired", "customRequired", "vat", "incomeTax", "isDelivering", "feederEtdConfirmed",
            "salesChecked", "submited", "dontSynTtl", "chargesAsArranged", "priceAsArranged", "attachMark", "attachDescription",
            "attachCtns", "isVirtual", "asWmsIn", "asWmsOut", "plConfirmed", "parcelMaster", "chargeByCbm", "isPallet",
            "ownImporter", "autoTracking", "consolidated", "isRemote", "onHold", "customsInspectionExp", "customsInspection", "labelVirtual", "autoSendPlCheck",
            "isResidential", "headTrackFinished", "orderPushTag", "directDelivery", "assistChargesConfirmed", "insuranceRequired",
            "fumigationRequired", "warehouseRequired", "coRequired", "BKisTemplate", "BKisLocked", "BKetdConfirmed", "BKattachCtns",
            "BKmagneticInspection", "BKidentification", "BKotherService", "BKhblPrinted", "BKattachMark", "BKinclTax", "BKattachDescription",
            "BKplConfirmed", "RTisPay", "consoleMasterShipment", "consoleSubShipment", "RTsharedOnTAG", "cwLoked", "guideCtnsExist", "cargoIsOut",
            "inchOz", "irregularPacking", "isSuperRemote", "addressModify"];
        function nodeNotMatch(guide, customNode) {
            if (!customNode) {
                return true;
            }
            if (!customNode.customConditions || customNode.customConditions.length == 0) {
                if(customNode.type == "WF"){
                    return false;
                }
                return !customNode.bindFdName;
            }
            // 且
            var allTrue = true;
            // 或
            var hasTrue = false;
            angular.forEach(customNode.customConditions, function(customCondition) {
                if (customCondition.conditionType == 'condition') {
                    var subFieldName = customCondition.fieldName;
                    if (customCondition.operator == 'isNull') {
                        if (subFieldName.indexOf("BK") == 0) {
                            if (!guide.booking[subFieldName.substring(2)]) {
                                hasTrue = true;
                            } else {
                                allTrue = false;
                            }
                        } else {
                            if (!guide[subFieldName]) {
                                hasTrue = true;
                            } else {
                                allTrue = false;
                            }
                        }
                    } else if (customCondition.operator == 'notNull') {
                        if (subFieldName.indexOf("BK") == 0) {
                            if (guide.booking[subFieldName.substring(2)]) {
                                hasTrue = true;
                            } else {
                                allTrue = false;
                            }
                        } else {
                            if (guide[subFieldName]) {
                                hasTrue = true;
                            } else {
                                allTrue = false;
                            }
                        }
                    } else if (customCondition.operator == 'equals') {
                        var tgValue = customCondition.targetValue;
                        if(tgValue){
                            if(subFieldName == "deliveryWay"){
                                angular.forEach($rootScope.DELIVERYTYPES, function (data) {
                                    if(data.label == tgValue){
                                        tgValue = data.value;
                                    }
                                })
                            }
                            if(subFieldName == "cargoType"){
                                angular.forEach($rootScope.CARGOTYPES, function (data) {
                                    if(data.label == tgValue){
                                        tgValue = data.value;
                                    }
                                })
                            }

                            if(subFieldName == "bizType"){
                                angular.forEach($rootScope.BIZTYPES, function (data) {
                                    if(data.label == tgValue){
                                        tgValue = data.value;
                                    }
                                })
                            }
                            if(subFieldName == "internalType"){
                                angular.forEach($rootScope.INTERNALTYPES, function (data) {
                                    if(data.label == tgValue){
                                        tgValue = data.value;
                                    }
                                })
                            }
                            if(subFieldName == "tkState"){
                                angular.forEach($rootScope.TK_STATES, function (data) {
                                    if(data.label == tgValue){
                                        tgValue = data.value;
                                    }
                                })
                            }
                        }
                        if(subFieldName == "guideCtnsExist"){
                           var ctnInputed = false;
                            if(guide.guideCtns && guide.guideCtns.length > 0){
                                ctnInputed = true;
                                angular.forEach(guide.guideCtns, function(data){
                                    if(!data.ctnNum){
                                        ctnInputed = false;
                                    }
                                })
                            }
                            if(tgValue == "true"){//分柜资料已录入
                                if (ctnInputed) {
                                    hasTrue = true;
                                } else {
                                    allTrue = false;
                                }
                            }
                            else {
                                if (!ctnInputed) {
                                    hasTrue = true;
                                } else {
                                    allTrue = false;
                                }
                            }
                        }
                        else if (subFieldName.indexOf("BK") == 0) {
                            if (guide.booking[subFieldName.substring(2)] == tgValue) {
                                hasTrue = true;
                            } else {
                                allTrue = false;
                            }
                        } else {
                            if (booleanArray.indexOf(subFieldName) != -1) {
                                if (tgValue === "true" && guide[subFieldName] === true) {
                                    hasTrue = true;
                                }else if (tgValue === "false" && (!guide[subFieldName] || guide[subFieldName] === false)) {
                                    hasTrue = true;
                                } else {
                                    allTrue = false;
                                }
                            } else {
                                if (guide[subFieldName] == tgValue) {
                                    hasTrue = true;
                                } else {
                                    allTrue = false;
                                }
                            }
                        }
                    }
                    else {
                        var tgValue = customCondition.targetValue;
                        if(timeArray.indexOf(subFieldName) != -1 || dateArray.indexOf(subFieldName) != -1){
                            if(tgValue.length == 8 && !isNaN(tgValue)){
                                tgValue =  new Date(tgValue.substring(0, 4),tgValue.substring(4, 6) - 1,tgValue.substring(6));
                            }
                            else {
                                tgValue = DateUtils.getNewDay (new Date(), tgValue);
                            }
                        }
                        if (customCondition.operator == 'greater') {
                            if (subFieldName.indexOf("BK") == 0) {
                                if (guide.booking[subFieldName.substring(2)] >= tgValue) {
                                    hasTrue = true;
                                } else {
                                    allTrue = false;
                                }
                            } else {
                                if (guide[subFieldName] >= tgValue) {
                                    hasTrue = true;
                                } else {
                                    allTrue = false;
                                }
                            }
                        } else if (customCondition.operator == 'less') {
                            if (subFieldName.indexOf("BK") == 0) {
                                if (guide.booking[subFieldName.substring(2)] <= tgValue) {
                                    hasTrue = true;
                                } else {
                                    allTrue = false;
                                }
                            } else {
                                if (guide[subFieldName] <= tgValue) {
                                    hasTrue = true;
                                } else {
                                    allTrue = false;
                                }
                            }
                        }
                    }

                } else if (customCondition.conditionType == 'node') {
                    var subCustomNode;
                    if (customNode.type == 'RQ') {
                        subCustomNode = requiredFieldsMap.get(customCondition.fieldName);
                    } else if (customNode.type == 'HD') {
                        subCustomNode = conditionsCombineHideFieldsMap.get(customCondition.fieldName);
                    } else if (customNode.type == 'AF') {
                        subCustomNode = conditionsCombineAutoFullFieldsMap.get(customCondition.fieldName);
                    } else if (customNode.type == 'WF') {
                        subCustomNode = conditionsCombineWfFieldsMap.get(customCondition.fieldName);
                    }
                    if (nodeNotMatch(guide, subCustomNode)) {
                        allTrue = false;
                    } else {
                        hasTrue = true;
                    }
                }
            })
            // 检查失败，表示条件判断，但指定的字段却没有填
            if (customNode.logicalOperator == 'and' && allTrue) {
                return false;
            } else if (customNode.logicalOperator == 'or' && hasTrue) {
                return false;
            }
            return true;
        }

        var comNodes = ["deliveryGuideTime", "bookingTime", "soReleaseTime", "truckArrangeTime", "otherTimeOne", "otherTimeTwo", "customArrangeTime",
            "customReleaseTime", "atd", "hblReleasedTime", "mblReleasedTime", "ataTime", "customsInspectionExp", "customsInspection", "customClearTime", "ctnAvailableTime", "otherTimeThree", "deliveryAppointmentTime",
            "deliveryTime", "closeTime", "localTimeTwo", "ctnsLoadedTime", "etd", "eta", "isClosed", "consolidated", "BKisLocked"
        ];

        $rootScope.AUTOFULLNODE = function(guide) {
            angular.forEach(autoFullFields, function(autoFullField) {
                if (checkGuideNodeType(guide, autoFullField) && !autoFullField.conditionsCombine && !nodeNotMatch(guide, autoFullField)) {
                    if(comNodes.indexOf(autoFullField.bindFd) != -1){
                        if(autoFullField.bindFd.indexOf("BK") == 0){
                            if(!guide.booking[autoFullField.bindFd.substring(2)]){
                                if(booleanArray.indexOf(autoFullField.bindFd) != -1){
                                    guide.booking[autoFullField.bindFd.substring(2)] = true;
                                }
                                else {
                                    guide.booking[autoFullField.bindFd.substring(2)] = new Date();
                                }
                                $rootScope.OPS_ALERT($translate.instant('global.messages.autoFillAlert', {param: autoFullField.ndName}));
                            }
                        }
                        else if(!guide[autoFullField.bindFd]){
                            if(booleanArray.indexOf(autoFullField.bindFd) != -1){
                                guide[autoFullField.bindFd] = true;
                            }
                            else {
                                guide[autoFullField.bindFd] = new Date();
                                if(autoFullField.bindFd == 'deliveryGuideTime'){
                                    guide.submited = true;
                                    guide.status = "Requested";
                                }
                            }
                            $rootScope.OPS_ALERT($translate.instant('global.messages.autoFillAlert', {param: autoFullField.ndName}));
                        }
                    }
                    else if (guide.orderNodes){
                        angular.forEach(guide.orderNodes, function(orderNode) {
                            if(!orderNode.nodeTime && orderNode.nodeName == autoFullField.ndName && orderNode.guideId){
                                orderNode.nodeTime = new Date();
                                OrderNode.setOrderNode({}, orderNode, function(result) {
                                    $scope.$emit('autopsApp:loadGuide', guide);
                                }, $rootScope.ALERT_ERROR);
                                $rootScope.OPS_ALERT($translate.instant('global.messages.autoFillAlert', {param: orderNode.nodeName}));
                            }
                        })
                    }
                }
            })
        }

        var requiredFields = [];
        var requiredFieldsMap = new Map();

        var hideFieldsMap = new Map(); // 不包含条件组合
        var conditionsCombineHideFieldsMap = new Map(); // 只有条件组合

        var autoFullFields = [];
        var conditionsCombineAutoFullFieldsMap = new Map();

        var wfFieldsMap = new Map(); // 不包含条件组合
        var conditionsCombineWfFieldsMap = new Map(); // 只有条件组合

        $rootScope.LOAD_CUSTOM_NODE_FIELD = function() {
            requiredFields = [];
            requiredFieldsMap = new Map();

            hideFieldsMap = new Map();
            conditionsCombineHideFieldsMap = new Map();

            autoFullFields = [];
            conditionsCombineAutoFullFieldsMap = new Map();

            wfFieldsMap = new Map();
            conditionsCombineWfFieldsMap = new Map();

            CustomNode.getCustomNodesByType({ type: "all" }, function(result) {
                angular.forEach(result, function(data) {
                    if (data.type == "RQ") {
                        requiredFields.push(data);
                        if (data.conditionsCombine) {
                            requiredFieldsMap.set(data.ndName, data);
                        }
                    } else if (data.type == "HD") {
                        if (!data.conditionsCombine) {
                            addToNodeMap(hideFieldsMap, data)
                        } else {
                            conditionsCombineHideFieldsMap.set(data.ndName, data)
                        }
                    } else if (data.type == "AF") {
                        autoFullFields.push(data);
                        if (data.conditionsCombine) {
                            conditionsCombineAutoFullFieldsMap.set(data.ndName, data);
                        }
                    } else if (data.type == "WF") {
                        if (!data.conditionsCombine) {
                            addToNodeMap(wfFieldsMap, data)
                        } else {
                            conditionsCombineWfFieldsMap.set(data.ndName, data)
                        }
                    }
                })
            });
        }

        function addToNodeMap(nodeMap, customNode) {
            var fdKey = customNode.bindFd;
            if (customNode.type == "WF") {
                fdKey = customNode.role + fdKey;
            }
            if (customNode.shipmentType == "FBAALL") {
                angular.forEach($rootScope.fbaTypes, function(shpmtType) {
                    nodeMap.set(shpmtType + fdKey, customNode);
                })
            } else if (customNode.shipmentType == "WMSALL") {
                angular.forEach($rootScope.wmsTypes, function(shpmtType) {
                    nodeMap.set(shpmtType + fdKey, customNode);
                })
            } else if (customNode.shipmentType == "SEAALL") {
                angular.forEach($rootScope.seaTypes, function(shpmtType) {
                    nodeMap.set(shpmtType + fdKey, customNode);
                })
            } else if (customNode.shipmentType == "PARCELALL") {
                angular.forEach($rootScope.customNodeParcelTypes, function(shpmtType) {
                    nodeMap.set(shpmtType + fdKey, customNode);
                })
            } else if (customNode.shipmentType == "TMSALL") {
                angular.forEach($rootScope.customNodeTmsTypes, function(shpmtType) {
                    nodeMap.set(shpmtType + fdKey, customNode);
                })
            } else {
                nodeMap.set(customNode.shipmentType + fdKey, customNode);
            }
        }


        $rootScope.AL_CK = function(guide, fd) {
            if (!fd || !guide) {
                return false;
            }
            // 如果相应业务单对应字段已经有值了，那对应字段就不用判断了
            if (fd.indexOf("BK") == 0) {
                if (guide.booking[fd.substring(2)]) {
                    return false;
                }
            } else if (guide[fd]) {
                return false;
            }

            for (var i = 0; i < requiredFields.length; i++) {
                var customNode = requiredFields[i];
                if (!checkGuideNodeType(guide, customNode) || customNode.conditionsCombine ||
                    !customNode.customConditions || fd != customNode.bindFd) {
                    continue;
                }
                if (!nodeNotMatch(guide, customNode)) {
                    return true;
                }
            }

            if (!$rootScope.COM_CONFG) {
                return false;
            }
            if ($rootScope.ISFBA(guide.shipmentType) && $rootScope.COM_CONFG.FBARQ && $rootScope.COM_CONFG.FBARQ.indexOf(fd) !== -1) {
                if (fd.indexOf("BK") == 0) {
                    return !guide.booking[fd.substring(2)];
                } else {
                    return !guide[fd];
                }
            } else if ("AIR" == guide.shipmentType && $rootScope.COM_CONFG.AIRRQ && $rootScope.COM_CONFG.AIRRQ.indexOf(fd) !== -1) {
                if (fd.indexOf("BK") == 0) {
                    return !guide.booking[fd.substring(2)];
                } else {
                    return !guide[fd];
                }
            } else if ($rootScope.COM_CONFG.FMSRQ && $rootScope.COM_CONFG.FMSRQ.indexOf(fd) !== -1) {
                if (fd.indexOf("BK") == 0) {
                    return !guide.booking[fd.substring(2)];
                } else {
                    return !guide[fd];
                }
            }
            return false;
        }

        $rootScope.isWeiPai = function(companyId) {
            if (companyId == 454 || companyId == 455 || companyId == 456 || companyId == 54) {
                return true;
            }
            return false;
        }
        $rootScope.IS_OPS_RD = function() {
            if(!$rootScope.account){
                return false;
            }
            return $rootScope.account.id == 5 || $rootScope.account.id == 19943 || $rootScope.account.id == 30962;
        }

        $rootScope.vgmPortList = Dictionary.vgmPortList;
        $rootScope.carrierList = Dictionary.carrierList;
        $rootScope.pierList = Dictionary.pierList;

        $rootScope.SEND_VGM = function(guide) {
            if ($rootScope.HAVENOAUTH('VGM')) {
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/vgm-head/vgm-head-dialog.html',
                controller: 'VgmHeadDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return VgmHead.getVgmHeadByGuideId({ guideId: guide.id, mblNum: guide.mblNum }).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('vgmHead');
                        $translatePartialLoader.addPart('vgmCtn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        $rootScope.vgmStatusList = [{
            value: "",
            label: ""
        }, {
            value: '-1',
            label: '草稿'
        }, {
            value: '0',
            label: '发送中'
        }, {
            value: '100',
            label: '发送成功'
        }, {
            value: '102',
            label: '发送失败'
        }];


        // 是否测试环境，由于禺越需要使用测试账号先测试，故相应按钮先只开放给测试账号opsmsyyadmin及乐代自己的账号
        $rootScope.isYYTest = function(companyId) {
            if (companyId == 19 || companyId == 54) {
                return true;
            }
            return false;
        }

        // 判断是否限制修改配柜（适用于禺越）
        $rootScope.yy_isCanChangeContainer = function(companyId, guide) {
            if ($rootScope.isYYTest(companyId)) {
                if (guide.customArrangeTime != null) {
                    return false;
                } else {
                    return true;
                }
            }
            return true;
        }
        $rootScope.batchSaveInsurance = function(data, insuranceType) {
            EdiInsurance.batchSaveInsurance({ 'insuranceType': insuranceType }, data, function() {
                layer.msg($translate.instant('global.messages.batchSaveInsuranceSuccess'));
            }, $rootScope.ALERT_ERROR);
        }

        // 是否管理员
        $rootScope.isAdmin = function() {
            if ($rootScope.account.companyId == 1) {
                return true;
            } else {
                return false;
            }
        }
        var audio = document.getElementById("audioPlay");
        $rootScope.ALERT_ALARM = function() {
            audio.play();
        }
        $rootScope.FORMAT_NUM_DEC = function(value) {
            return $rootScope.FORMAT_NUM(DataUtils.round(value));
        }

        $rootScope.FORMAT_NUM = function(value) {
            if (!value && value != 0) {
                return " ";
            }
            if (isNaN(value)) {
                value = value.toFixed(2);
            }
            var strArray = value.toString().split('.');
            var valueStr = strArray[0].replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');

            if (strArray.length == 1) {
                return valueStr + ".00";
            } else if (strArray[1].length == 1) {
                return valueStr + "." + strArray[1] + "0";
            } else {
                return valueStr + "." + strArray[1];
            }
        }

        // 是否为“深圳柏域斯浩航跨境物流有限公司坂田分公司”
        $rootScope.isPGSBT = function(companyId) {
            if (companyId == 467 || companyId == 270) {
                return true;
            } else {
                return false;
            }
        }
        // 是否为“深圳市柏域斯物流有限公司”或乐代
        $rootScope.isPGS = function(companyId) {
            if (companyId == 297 || companyId == 270 || companyId == 271 || companyId == 276 ||
                companyId == 376 || companyId == 406 || companyId == 412 || companyId == 416 || companyId == 467) {
                return true;
            } else {
                return false;
            }
        }


        $rootScope.RXO_SEND304 = function(guide, purpose) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                Guide.rxo304Edi({ purpose: purpose }, guide, onEdiSuccess, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $rootScope.RXO_SEND990 = function(guide, event) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                Guide.rxo990Edi({ event: event }, guide, onEdiSuccess, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $rootScope.ADD_NODE = function(type) {
            $uibModal.open({
                templateUrl: 'app/entities/custom-node/custom-node-dialog.html',
                controller: 'CustomNodeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        return {
                            shipmentType: type=="RLS"?"FMSALL":type,
                            companyId: null,
                            customConditions: [{conditionType: 'condition'}],
                            conditionsCombine: false,
                            disabled: false,
                            logicalOperator: "and",
                            viewScope: "self",
                            type: "ND",
                            role: type=="RLS"?"ROLE_ACCOUNT":$rootScope.choosedRole,
                            id: null
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customNode');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('customCondition');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.VOID_MARK = function(guide) {
            var arr = document.getElementsByClassName('mask_div');
            if (arr && arr.length > 0) {
                for (var i = arr.length - 1; i >= 0; i--) {
                    if (arr[i] != null) {
                        // arr[i].parentNode.removeChild(arr[i]);
                        arr[i].remove();
                    }
                }
            }
            if (guide.isVoid) {
                watermark({ "watermarl_element": "watermark-wrapper", "watermark_txt": $translate.instant('autopsApp.guide.sts.voided') });
            }
            if (guide.bizType && !guide.isClosed) {
                var isExist = false;
                angular.forEach($rootScope.BIZTYPES, function(data) {
                    if (data.value == guide.bizType) {
                        isExist = true;
                    }
                })
                if (!isExist) {
                    guide.bizType = null;
                }
            }
        }

        $rootScope.SEA_TRACKING_POP = function(guideId) {
            var entity = {};
            entity.guideId = guideId;
            $uibModal.open({
                templateUrl: 'app/entities/express-tracking/express-trackings-sea.html',
                controller: 'ExpressTrackingSeaController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {}, function() {});
        }

        $rootScope.tableHeightStyle = {};
        window.onresize = function() {
            $rootScope.RESIZE();
        }
        $rootScope.RESIZE = function() {
            $timeout(function() {
                if ($("#basicTable") && $("#basicTable").offset()) {
                    var footerHeight = 0;
                    if (document.getElementById("footPager")) {
                        document.getElementById("footPager").style.cssText = "margin: 0px;";
                        footerHeight = $("#" + "footPager").height();
                    }
                    var heightPx = $(window).height() - $("#basicTable").offset().top - footerHeight - 1;
                    $rootScope.tableHeightStyle = {
                        "max-height": heightPx + "px"
                    }
                    setTimeout(function() {
                        $translate.refresh();
                    }, 800);
                }
            })
        }
        $rootScope.JS_EXPRESSION_GUI = function(type, object) {
            var entity = {};
            entity.jsType = type;
            if (type == 'surchargeJs' || type == 'wmsChargeJs') {
                entity.jsJson = object.jsGuiJson;
            }
            $uibModal.open({
                templateUrl: 'app/entities/js-expression/js-expression-gui-dialog.html',
                controller: 'JsExpressionGuiDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('jsExpression');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                if (type == 'surchargeJs' || type == 'wmsChargeJs') {
                    object.jsExpression = result.jsStr;
                    object.jsGuiJson = result.jsGuiJson;
                } else if (type == 'weightJs' || type == 'maxSize' || type == 'dutyJs' || type == 'dgJs' || type == 'customsJs') {
                    object[type] = result.jsStr;
                }
            }, function(result) {});
        }

        $rootScope.UPDATE_BY_CARRIER = function(carrier, bizType, guide) {
            if (!carrier.chargesCode) {
                if($rootScope.ISFBAWMS(guide.shipmentType)){
                    guide.carrier = carrier.code;
                }
                else {
                    guide.carrier = carrier.name;
                    guide.booking.carrierCode = carrier.code;
                    guide.booking.carrierFullName = carrier.carrierFullName;
                    if("AIR" == guide.shipmentType){
                        guide.carrierOne = carrier.code;
                    }

                }
                return;
            }
            layer.confirm($translate.instant('autopsApp.guide.bindedChargesAlert'), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                GuideRates.addByTemplate({
                    guideId: guide.id
                }, carrier, function(result) {
                    if (bizType == "guide") {
                        $scope.$emit('autopsApp:loadGuide', guide);
                    }
                    layer.msg($translate.instant('global.save_success'));
                });
            }, function() {});
        }

        $rootScope.CHECK_GUIDE_CHANNEL = function(guide) {
            Guide.checkChannelValid(angular.copy(guide), function(result) {
                if (result.carrier && result.carrier != guide.carrier) {
                    guide.carrier = result.carrier;
                    $translate.refresh();
                }
                if (result.isRemote != null) {
                    guide.isRemote = result.isRemote;
                    $translate.refresh();
                }
                if (result.chargeWeight && result.chargeWeight != guide.chargeWeight) {
                    layer.confirm($translate.instant('autopsApp.guide.channelChargeWeightAlert', { chargeWeight: result.chargeWeight, cw: guide.chargeWeight }), {
                        icon: 3,
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        layer.close(index)
                        guide.chargeWeight = result.chargeWeight;
                        if(result.chargeByCbm != null){
                            guide.chargeByCbm = result.chargeByCbm;
                        }
                        if(result.booking.divVolBy){
                            guide.booking.divVolBy = result.booking.divVolBy;
                        }
                        // guide.cwLoked = true;
                        $translate.refresh();
                        channelOpAlert(guide, result);
                    }, function() {
                        channelOpAlert(guide, result);
                    });
                }
                else {
                    channelOpAlert(guide, result);
                }
            }, $rootScope.ALERT_ERROR);
        }

        $rootScope.GET_CHARGE_WEIGHT= function(guide) {
            Guide.getChargeWeight(angular.copy(guide), function(result) {
                if(result){
                    if(null != result.chargeByCbm){
                        guide.chargeByCbm = result.chargeByCbm;
                    }
                    if(result.chargeWeight && guide.chargeWeight != result.chargeWeight){
                        guide.chargeWeight = result.chargeWeight;
                        guide.booking.divVolBy = result.booking.divVolBy;
                        layer.msg($translate.instant('autopsApp.guide.cwUpdatedByChannel'));
                        $translate.refresh();
                    }
                }
            });
        }

        var channelOpAlert = function(guide, result) {
            if(!result.opId || result.opId == guide.opId){
                return;
            }
            layer.confirm($translate.instant('autopsApp.guide.channelOpAlert', { param: result.opName}), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index)
                guide.opId = result.opId;
                guide.opName = result.opName;
                $translate.refresh();
            }, function() {});
        }
        $rootScope.CHECK_PARCEL_CHANNEL = function(expressParcel) {
            var parcelCopy = angular.copy(expressParcel);
            parcelCopy.labelBindType = null;
            ExpressParcel.checkChannelValid(parcelCopy, function(result) {
                if (result.carrier && result.carrier != expressParcel.carrier) {
                    expressParcel.carrier = result.carrier;
                    expressParcel.carrierCode = result.carrierCode;
                }
                expressParcel.isRemote = result.isRemote;
                expressParcel.isSuperRemote = result.isSuperRemote;
                expressParcel.remotePostalCode = result.remotePostalCode;
                if(result.labelBindType){
                    expressParcel.labelBindType = result.labelBindType;
                    expressParcel.shipFromId = result.shipFromId;
                    expressParcel.shipfromCode = result.shipfromCode;
                    expressParcel.serviceType = result.serviceType;
                    expressParcel.serviceDescription = result.serviceDescription;
                }
                if (result.divVolBy || result.vw) {
                    expressParcel.divVolBy = result.divVolBy;
                    expressParcel.vw = result.vw;
                }
                $translate.refresh();
                channelOpAlert(expressParcel, result);
            }, $rootScope.ALERT_ERROR);
        }
        $rootScope.CHECK_WMSIN_CHANNEL = function(wmsIn) {
            WmsIn.checkChannelValid(angular.copy(wmsIn), function(result) {

            }, $rootScope.ALERT_ERROR);
        }
        $rootScope.AUWMS_EDI = [];
        $rootScope.EDI_LOAD = function(type) {
            if ($rootScope.AUWMS_EDI && $rootScope.AUWMS_EDI.length > 0) {
                return;
            }
            AccountBind.getEdiAccounts({
                companyId: $rootScope.account.companyId,
                type: type
            }, function(result) {
                $rootScope.AUWMS_EDI = result;
            })
        }

        $rootScope.CREATE_EDI_WMSIN = function(accountBind, guide, bySku, usePieces) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            // if(!accountBind.payeeCode){
            //     layer.msg($translate.instant('autopsApp.guide.ediWhNullAlert'));
            //     return;
            // }
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                WmsIn.createEdiWmsIn({
                    accountBindId: accountBind.id,
                    bySku: bySku,
                    usePieces: usePieces
                }, guide, function() {
                    layer.closeAll();
                    layer.msg($translate.instant('global.createdSuccess'));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        // 柏域斯定制，导出出货报表 工单W23027429738
        $rootScope.EXPORT_PGS_SHIPPING_REPORT = function(rows) {
            if (rows == null || rows.length < 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsFiles.exportShippingReport(rows, function(result) {
                $rootScope.DOWNLOADEXCEL(result, "Shipping_Report.xlsx");

            }, $rootScope.ALERT_ERROR)
        }

        // 是否为“先达国际货运（上海）有限公司深圳分公司”
        $rootScope.isOTEL_SZ = function() {
            if ($rootScope.account.companyId == 54 || $rootScope.account.companyId == 595) {
                return true;
            } else {
                return false;
            }
        }
        $rootScope.SEND_TRANSFAR_SHIPPING_EDI = function(guide, type, action) {
            if (!guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                Guide.shipmentSend2({ type: type, action: action }, angular.copy(guide), function(result) {
                    layer.msg($translate.instant('global.send_success'));
                    $rootScope.DOWNLOADTXT(result, guide.mblNum + ".txt");
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $rootScope.LOAD_PIC_LOGO = function(pubTracking) {
            $rootScope.LOADING_PIC_LOGO = true;
            OpsFiles.getCompanyByUrl({
                pubTracking: pubTracking,
                host: $location.host()
            }, function(result) {
                $rootScope.LOADING_PIC_LOGO = false;
                if (!$rootScope.account) {
                    $rootScope.account = {};
                }
                if (result.logo) {
                    $rootScope.account.companyLogo = result.logo;
                }
                $rootScope.account.companyPic = result.companyPic;
                $rootScope.account.techEmail = result.email
                if (result.companyAbb) {
                    $rootScope.account.companyAbb = result.companyAbb;
                    $scope.systemName = result.companyAbb;
                    document.title = $translate.instant('home.docTitle', { systemName: result.companyAbb });
                } else if (!$rootScope.account.companyLogo) {
                    if ($rootScope.absUrl.indexOf("transwinner") !== -1) {
                        $scope.systemName = "瀚斯威";
                        document.title = "瀚斯威云智能操作系统";
                        getLogo(42);
                    } else if ($rootScope.absUrl.indexOf("t-ocean") !== -1) {
                        $scope.systemName = "海铁";
                        document.title = "海铁云智能操作系统";
                        getLogo(84);
                    } else if ($rootScope.absUrl.indexOf("elitecargo") !== -1) {
                        $scope.systemName = "亿俐缇";
                        document.title = "亿俐缇云智能操作系统";
                        getLogo(79);
                    } else if ($rootScope.absUrl.indexOf("hengda") !== -1) {
                        $scope.systemName = "亨达";
                        document.title = "亨达云云智能操作系统";
                        getLogo(88);
                    } else if ($rootScope.absUrl.indexOf("eslogistic") !== -1) {
                        $scope.systemName = "东方海航";
                        document.title = "东方海航云智能操作系统";
                        getLogo(91);
                    } else if ($rootScope.absUrl.indexOf("tempo") !== -1) {
                        $scope.systemName = "藤波";
                        document.title = "藤波云智能操作系统";
                        getLogo(81);
                    } else if ($rootScope.absUrl.indexOf("hubologistics") !== -1) {
                        $scope.systemName = "恒通达";
                        document.title = "恒通达云智能操作系统";
                        getLogo(110);
                    } else if ($rootScope.absUrl.indexOf("fulin") !== -1) {
                        $scope.systemName = "富临";
                        document.title = "富临云智能操作系统";
                        getLogo(114);
                    } else if ($rootScope.absUrl.indexOf("tensent") !== -1) {
                        $scope.systemName = "腾欣";
                        document.title = "腾欣云智能操作系统";
                        getLogo(118);
                    } else if ($rootScope.absUrl.indexOf("sgscm") !== -1) {
                        $scope.systemName = "环海";
                        document.title = "环海云智能操作系统";
                        getLogo(116);
                    } else if ($rootScope.absUrl.indexOf("nbc") !== -1) {
                        $scope.systemName = "南沙保畅";
                        document.title = "南沙保畅云智能操作系统";
                        getLogo(133);
                    } else if ($rootScope.absUrl.indexOf("conquestlogistics") !== -1) {
                        if ($translate.instant('global.enCn') == 'en') {
                            $scope.systemName = "Conquest";
                            document.title = "Conquest Cloud Intelligence";
                        } else {
                            $scope.systemName = "嘉丰云智能物流系统";
                            document.title = "嘉丰云智能物流系统";
                        }
                        getLogo(74);
                    } else if ($rootScope.absUrl.indexOf("anmei") !== -1) {
                        $scope.systemName = "Anmei";
                        document.title = "Anmei Cloud Intelligence";
                        getLogo(104);
                    } else if ($rootScope.absUrl.indexOf("thunder") !== -1) {
                        $scope.systemName = "Thunder";
                        document.title = "Thunder Cloud Intelligence";
                        getLogo(129);
                    }
                }
                if (!$rootScope.account.companyLogo) {
                    $rootScope.account.companyLogo = "auops";
                }
            });
        }

        $rootScope.FBA_BOOKING = function() {
            $rootScope.id = "new";
            $rootScope.bookingType = "fba";
            $uibModal.open({
                templateUrl: 'app/entities/booking/booking-fba.html',
                controller: 'BookingDetailController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return null;
                    }]
                }
            }).result.then(function(result) {
                $state.go('booking-fba');
            }, function(result) {
                $state.go('booking-fba');
            });
        }
        // $scope.fbaBooking();

        function contains(file) {
            file = angular.lowercase(file);
            var regx = new Array('jpg', 'png', 'jpeg', 'bmp', 'tiff');
            for (var m = 0; m < regx.length; m++) {
                if (file == regx[m]) {
                    return true;
                }
            }
            return false;
        }

        $rootScope.uploadPic = function(file, plMixObj, filePath, companyId) {
            if (!plMixObj.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!file) {
                return;
            }
            var fileSplit = file.name.split(".");
            if (!contains(fileSplit[fileSplit.length - 1])) {
                layer.msg($translate.instant('global.logo_warn1'));
                return;
            }
            if (file.size > 2040960) {
                layer.msg($translate.instant('global.logo_warn2m'));
                return;
            }
            var fd = new FormData();
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', companyId);
            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                if (!plMixObj.cargoPics) {
                    plMixObj.cargoPics = [];
                }
                plMixObj.cargoPics.push(data);
                layer.msg($translate.instant('global.uploadSuccess'));
            }).error(function(error) {
                layer.msg($translate.instant('global.uploadFail'));
            });
        };

        $rootScope.STATE_GO_BLANK = function(sref) {
            window.open($state.href(sref, {}), "_blank");
        }

        $scope.epBooking = function(shipmentType) {
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-booking.html',
                controller: 'ExpressParcelDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        var newBiz = {};
                        newBiz.status = 'new';
                        newBiz.divVolBy = 6000;
                        newBiz.payParty = 'shipfrom';
                        newBiz.chargeType = 'prepay';
                        newBiz.shipmentType = shipmentType;
                        newBiz.expressCargos = [];
                        return newBiz;
                    }]
                }
            }).result.then(function(result) {
                $state.go('express-parcel');
            }, function(result) {
                $state.go('express-parcel');
            });
        }
        $rootScope.REMOVE_COL_BY_AUTH = function(cols) {
            for (var i = cols.length - 1; i >= 0; i--) {
                if (cols[i].field == 'profit' && $rootScope.HAVENOAUTH('AU_VIEW_PROFIT')) {
                    cols.splice(i, 1);
                }
            }
        }

        $rootScope.TD_COMBINE = function(tdOrder) {
            $uibModal.open({
                templateUrl: 'app/entities/parcel-goods/parcel-goods-dialog.html',
                controller: 'ParcelGoodsDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return tdOrder;
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.CARGO_RECEIVING_SITUATION = function(guideId) {
            $uibModal.open({
                templateUrl: 'app/entities/warehousing-data/warehousing-data-detail.html',
                controller: 'WarehousingDataDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: ['Guide', function(Guide) {
                        return Guide.getFmsRapidInGuide({ id: guideId }).$promise;
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.OTELSZExportVendorInvoiceRegistryInOrder = function(id, isFms, isPay, jobNum) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.OTELSZExportVendorInvoiceRegistry({
                id: id,
                isFms: isFms,
                isPay: isPay
            }, function(result) {
                layer.closeAll();
                var filename;
                if (isPay) {
                    filename = jobNum + "_AP.xlsx";
                } else {
                    filename = jobNum + "_AR.xlsx";
                }
                $rootScope.DOWNLOADEXCEL(result, filename);
            }, function() {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }
        $rootScope.importOrdersByCustomize = function(customExcelImportRecord, file, order, isExport, accountBindId) {
            if (!file) {
                return;
            }
            var fd = new FormData();
            fd.append('file', file);
            fd.append('templateId', customExcelImportRecord.id);
            if (order) {
                fd.append('orderId', order.id);
            }
            if (isExport == true || isExport == false) {
                fd.append('isExport', isExport);
            }
            var url = $location.absUrl();
            if (url) {
                fd.append('shipmentType', url.substring(url.lastIndexOf('/') + 1));
            }
            if (accountBindId) {
                fd.append('accountBindId', accountBindId);
            }
            layer.load(1, { shade: 0.3 });
            $http.post('api/guide-import/customizeImportOrder', fd, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                },
            }).success(function(data) {
                layer.closeAll();
                if (data.description) {
                    $rootScope.OPS_ALERT(data.description);
                } else {
                    layer.msg($translate.instant('global.importSuccess'))
                }
                if (order && order.id) {
                    $scope.$emit('autopsApp:loadGuide', order);
                } else {
                    $scope.$emit('autopsApp:loadAllRequred');
                }
            }).error(function(error) {
                layer.closeAll();
                if (error.description) {
                    $rootScope.OPS_ALERT(error.description);
                } else {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                }
            });
        }

        $rootScope.ALERT_LANG = function(text){
            var speech = new SpeechSynthesisUtterance();
            // 设置播放内容
            speech.text = text;
            // 设置话语的音调(0-2 默认1，值越大越尖锐,越低越低沉)
            speech.pitch = 0.8;
            // 设置说话的速度(0.1-10 默认1，值越大语速越快,越小语速越慢)
            speech.rate = 1.5;
            // 设置说话的音量
            speech.volume = 10;
            // 设置播放语言
            speech.lang = 'zh-CN';

            // 播放结束后调用
            // speech.onend = (event) => {

            // }
            // 加入播放队列
            window.speechSynthesis.speak(speech);
        }
        // $rootScope.ALERT_LANG("2");


        /**
         * 停止播报，停止所有播报队列里面的语音
         */
        var stopSpeech = function(){
            window.speechSynthesis.cancel();
        }

        $rootScope.LOAD_CARRIERS = function(shipmentType) {
            if ($rootScope.OPSPORTS.allLiners && $rootScope.OPSPORTS.allLiners.length > 0) {
                getShipmentCarrier(shipmentType);
            }
            OpsCarrier.getAll(function(result) {
                $rootScope.OPSPORTS.exExpressCarriers = [];
                $rootScope.OPSPORTS.airLiners = [];
                $rootScope.OPSPORTS.seaLiners = [];
                $rootScope.OPSPORTS.expressLiners = [];
                $rootScope.OPSPORTS.allLiners = [];
                angular.forEach(result, function(data) {
                    $rootScope.OPSPORTS.allLiners.push(data);
                    if (data.type == 1) {
                        $rootScope.OPSPORTS.seaLiners.push(data);
                        $rootScope.OPSPORTS.exExpressCarriers.push(data);
                    } else if (data.type == 3) {
                        $rootScope.OPSPORTS.airLiners.push(data);
                        $rootScope.OPSPORTS.exExpressCarriers.push(data);
                    } else {
                        $rootScope.OPSPORTS.expressLiners.push(data);
                    }
                })
                getShipmentCarrier(shipmentType);
            })
        }

        var getShipmentCarrier = function(shipmentType){
            if(!shipmentType){
                $rootScope.OPSPORTS.carriers = $rootScope.OPSPORTS.allLiners;
            }
            if (shipmentType == "AIR" || shipmentType == "FBAAIR"){
                $rootScope.OPSPORTS.carriers = $rootScope.OPSPORTS.airLiners;
            }
            else if (shipmentType == "EXPRESS"){
                $rootScope.OPSPORTS.carriers = $rootScope.OPSPORTS.expressLiners;
            }
            else {
                $rootScope.OPSPORTS.carriers = $rootScope.OPSPORTS.seaLiners;
            }
        }

        $rootScope.BATCH_WMS_LABELS = function(guideList, reportType, fileType) {
            if(!guideList || guideList.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var wmsInDTOList = [];
            angular.forEach(guideList, function(data){
                if(data.customerId && !data.isVoid){
                    var wmsIn = {};
                    wmsIn.guideId = data.id;
                    wmsIn.companyId = data.companyId;
                    wmsIn.warehousingNumber = data.jobNum;
                    wmsInDTOList.push(wmsIn);
                }
            })
            batchWmsLabels(wmsInDTOList, reportType, fileType);
        }
        $rootScope.BATCH_PC_LABELS = function(orderList, reportType, fileType) {
            if(!orderList || orderList.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var wmsInDTOList = [];
            angular.forEach(orderList, function(data){
                if(data.shipperId && !data.isVoid){
                    var wmsIn = {};
                    wmsIn.parcelId = data.id;
                    wmsIn.companyId = data.companyId;
                    wmsIn.warehousingNumber = data.jobNum;
                    wmsInDTOList.push(wmsIn);
                }
            })
            batchWmsLabels(wmsInDTOList, reportType, fileType);
        }
        var batchWmsLabels = function(wmsInList, reportType, fileType){
            WmsIn.batchWmsLabels({
                reportType: reportType,
                fileType: fileType,
                companyId: $rootScope.account.companyId
            }, wmsInList, function(result) {
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, fileType, "BATCH_WMS_LABELS");
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $rootScope.BATCH_WMSIN_LABELS = function(wmsInList, reportType, fileType) {
            if(!wmsInList || wmsInList.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            batchWmsLabels(wmsInList, reportType, fileType);
        }
        $rootScope.BATCH_TD_PLT_LABELS = function(wmsInList, reportType, fileType) {
            if(!wmsInList || wmsInList.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var wmsInDTOList = [];
            angular.forEach(wmsInList, function(data){
                var wmsIn = {};
                wmsIn.tdId = data.id;
                wmsInDTOList.push(wmsIn);
            })
            batchWmsLabels(wmsInDTOList, reportType, fileType);
        }
        $rootScope.ZIP_WMS_LABELS = function(guideList, reportType, fileType) {
            if(!guideList || guideList.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var wmsInDTOList = [];
            angular.forEach(guideList, function(data){
                if(data.customerId && !data.isVoid){
                    var wmsIn = {};
                    wmsIn.guideId = data.id;
                    wmsIn.companyId = data.companyId;
                    wmsIn.warehousingNumber = data.jobNum;
                    wmsIn.deliveryTo = data.deliveryTo;
                    wmsIn.poNum = data.poNum;
                    wmsIn.applicationNum = data.pkgNum;
                    wmsInDTOList.push(wmsIn);
                }
            })
            WmsIn.zipWmsLabels({
                reportType: reportType,
                fileType: fileType,
                companyId: $rootScope.account.companyId
            }, wmsInDTOList, function(result) {
                $rootScope.DOWNLOADEXCEL(result, "label_files.zip");
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $rootScope.BATCH_WMS_IN_PAPER = function(guideList, reportType, fileType) {
            if(!guideList || guideList.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var wmsInDTOList = [];
            angular.forEach(guideList, function(data){
                if(data.customerId && !data.isVoid){
                    var wmsIn = {isOut:false};
                    wmsIn.guideId = data.id;
                    wmsIn.fileType = fileType;
                    wmsIn.companyId = data.companyId;
                    wmsInDTOList.push(wmsIn);
                }
            })
            OpsFiles.generateWmsInPaperBatch({
                reportType: reportType
            }, wmsInDTOList, function(result) {
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, fileType, "REPORT_MULTI");
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $rootScope.OPS_ALERT = function(msg) {
            if(msg == "error.concurrencyFailure" || msg.indexOf("global.") == 0 || msg.indexOf("autopsApp.") == 0){
                msg = $translate.instant(msg);
            }
            layer.alert(msg, {icon: 0,  title: $translate.instant('global.alert_title'), btn: [$translate.instant('global.confirm')]});
        }

        // $rootScope.OPS_ALERT("error.concurrencyFailure");

        $rootScope.LOAD_EXCEL_EXPORT_CUSTOMIZE = function() {
            $rootScope.EXCEL_EXPORT_CUSTOMIZES = [];
            GridState.getCustomExcelExportRecord({types: Array.from(arguments)}, function(result){
                $rootScope.EXCEL_EXPORT_CUSTOMIZES = result;
            })
        }

        $rootScope.CUSTOMIZE_EXCEL_EXPORT = function(exportCustomize, selectedRows) {
            if(!selectedRows || selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var orderIds = [];
            angular.forEach(selectedRows, function(item) {
                if(item && item.id){
                    orderIds.push(item.id);
                }
            })
            layer.load(1, { shade: 0.3 });
            GridState.customizeExportOrder ({templateId: exportCustomize.id}, orderIds, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, exportCustomize.stateName + "_" + new Date().toLocaleString() + ".xlsx");
            }, $rootScope.ALERT_ERROR)
        }


        $rootScope.LOAD_SYNORDER_CONFIG = function(guide) {
            $rootScope.SYN_ORDER_CONFIG = [];
            GridState.getSynOrderConfig({}, guide, function(result) {
                $rootScope.SYN_ORDER_CONFIG = result;
            },$rootScope.ALERT_ERROR);
        }

        $rootScope.SYN_ORDER_DO = function(synOrderConfig, guide) {
            if (!guide.branchGuideId && !guide.bookingToId) {
                layer.msg($translate.instant('autopsApp.guide.authorizeBranchNull'));
                return;
            }
            if(guide.branchGuideId && $rootScope.HAVENOAUTH("SYN_EDI_DATA")){
                $rootScope.FORBIDDEN_BY_ALERT('SYN_EDI_DATA');
                return;
            }
            if(!guide.branchGuideId){
                createBranchGuide(synOrderConfig, guide);
            }
            else {
                synBranchGuideData(synOrderConfig, guide);
            }
        }

        $rootScope.AUTO_SYN_DO = function(guide, synDirection) {
            if($rootScope.HAVENOAUTH("SYN_EDI_DATA")){
                $rootScope.FORBIDDEN_BY_ALERT('SYN_EDI_DATA');
                return;
            }
            Guide.autoSynDo({synDirection: synDirection}, guide, function (result) {
                layer.msg($translate.instant('global.save_success'));
                $scope.$emit('autopsApp:loadGuide', result);
            }, $rootScope.ALERT_ERROR);
        }

        $rootScope.SYN_ORDER_BATCH = function(guides, sendDirection) {
            if($rootScope.HAVENOAUTH("SYN_EDI_DATA")){
                $rootScope.FORBIDDEN_BY_ALERT('SYN_EDI_DATA');
                return;
            }
            if(guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var jobNumStr = "";
            angular.forEach(guides, function(data){
                jobNumStr += data.jobNum + " ";
            });
            var title = $translate.instant('autopsApp.guide.synBranchGuide');
            var content = $translate.instant('autopsApp.guide.synBranchGuideBatchAlert', {param: jobNumStr});
            layer.confirm(content, {
                icon: 3,
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                Guide.synBranchGuideBatch({sendDirection: sendDirection}, guides, function (result) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.updatedShipmentsAlert', {param: result.updatedShipments}));
                }, $rootScope.ALERT_ERROR);
            }, function() {
                return;
            });
        }

        var createBranchGuide = function(synOrderConfig, guide){
            var title = $translate.instant('autopsApp.guide.authorizeBranch');
            var content = guide.bookingToName + $translate.instant('autopsApp.guide.home.auth_send_content');
            layer.confirm(content, {
                icon: 3,
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                if(guide.masterId == 0){
                    var sendSubOrderTip = $translate.instant('autopsApp.guide.home.isSendSubOrder');
                    layer.confirm(sendSubOrderTip, {
                        title: title,
                        btn: [$translate.instant('autopsApp.guide.home.includeSubOrder'), $translate.instant('autopsApp.guide.home.onlyMasterOrder')]
                    }, function(){
                        $scope.isSaving = true;
                        Guide.createBranchGuide({sendSubOrder: true, configurationId: synOrderConfig.id}, guide, onCreateBranchGuideSuccess, $rootScope.ALERT_ERROR);
                    }, function(){
                        $scope.isSaving = true;
                        Guide.createBranchGuide({sendSubOrder: false, configurationId: synOrderConfig.id}, guide, onCreateBranchGuideSuccess, $rootScope.ALERT_ERROR);
                    })
                }else{
                    $scope.isSaving = true;
                    Guide.createBranchGuide({configurationId: synOrderConfig.id}, guide, onCreateBranchGuideSuccess, $rootScope.ALERT_ERROR);
                }
            }, function() {
                return;
            });
        }
        var onCreateBranchGuideSuccess = function(result) {
            $scope.$emit('autopsApp:loadGuide', result);
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var synBranchGuideData = function(synOrderConfig, guide){
            Guide.synBranchGuideData({configurationId: synOrderConfig.id}, guide, function (result) {
                layer.msg($translate.instant('global.save_success'));
                $scope.$emit('autopsApp:loadGuide', result);
            }, $rootScope.ALERT_ERROR);
        }

        $rootScope.COPY_NUM_BATCH = function(orderList, fd) {
            if(!orderList || orderList.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var txt = "";
            angular.forEach(orderList, function(data){
                if(data && data[fd]){
                    txt = txt + data[fd] + " ";
                }
            })
            $rootScope.COPYALL(txt.trim());
        }

        $rootScope.FORECAST_DATA_CHANGE = function(guide, fd) {
            if(!$rootScope.isYuyue(guide.companyId) || guide.cwLoked){
                return;
            }
            if (!guide.otherTimeOne && !guide.masterId && !guide.parcelId) {
                guide[fd] = guide.booking[fd];
            }
        }

        $rootScope.UPDATE_GUIDE_WMSCW = function(guide) {
            DataUtils.updateGuideWmsCw(guide);
        }

        $rootScope.WMS_DATA_CHANGE = function(guide, fd) {
            $rootScope.UPDATE_GUIDE_WMSCW(guide);
            if(!$rootScope.isYuyue(guide.companyId) || guide.cwLoked){
                return;
            }
            if (!guide.masterId && !guide.parcelId) {
                if(fd == 'wmsActAmt'){
                    guide.pkgNum = guide[fd];
                }
                else if(fd == 'wmsGw'){
                    guide.gw = guide[fd];
                }
                else if(fd == 'wmsVol'){
                    guide.vol = guide[fd];
                }
            }
        }

        $rootScope.SET_ORDER_NODE = function(orderNode, guide) {
            if(!orderNode.guideId){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if(orderNode.nodeTime){
                layer.confirm($translate.instant('autopsApp.guide.clearNodeAlert'), {icon: 3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        orderNode.nodeTime = null;
                        OrderNode.setOrderNode({}, orderNode, function(result) {
                            if(guide.booking){
                                $scope.$emit('autopsApp:loadGuide', guide);
                            }
                            else {
                                orderNode.nodeTime = result.nodeTime;
                                DataUtils.addGuideTag(guide);
                                $translate.refresh();
                            }
                        }, $rootScope.ALERT_ERROR);
                        layer.close(index);
                    }, function() {
                });
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/order-node/order-node-dialog.html',
                controller: 'OrderNodeDialogController',
                backdrop: 'static',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return angular.copy(orderNode);
                    }]
                }
            }).result.then(function(result) {
                if(guide.booking){
                    $scope.$emit('autopsApp:loadGuide', guide);
                }
                else {
                    orderNode.nodeTime = result.nodeTime;
                    DataUtils.addGuideTag(guide);
                    $translate.refresh();
                }
            }, function() {
            });
        }

        $rootScope.SHOW_STATUS = function(status) {
            if(!status){
                return "";
            }
            var showStatus = $translate.instant('autopsApp.opsTrackStatus.sts.' + status);
            if(showStatus.startsWith("autopsApp.")){
                return status;
            }
            else {
                return showStatus;
            }
        }
        $rootScope.SHOW_STATUS_GD = function(status) {
            if(!status){
                return "";
            }
            var showStatus = $translate.instant('autopsApp.guide.sts.' + status);
            if(showStatus.startsWith("autopsApp.")){
                return status;
            }
            else {
                return showStatus;
            }
        }



        $rootScope.IS_STANDARD_NODE = function(status) {
            if(!status){
                return false;
            }
            var showStatus = $translate.instant('autopsApp.opsTrackStatus.sts.' + status);
            if(showStatus.startsWith("autopsApp.")){
                return false;
            }
            else {
                return true;
            }
        }

        $rootScope.UPDATETRACKING_NUM = function(isFms) {
            var entity = {isFms: isFms};
            $uibModal.open({
                templateUrl: 'app/entities/order-node/order-node-detail.html',
                controller: 'OrderNodeDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function(){
                        return entity;
                    }]
                }
            }).result.then(function(result) {

            }, function(result) {
            });
        };

        $rootScope.ASYNC_TRACKING_TASKS = function(guides) {
            if (!guides || guides.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var body = {};
            body.taskType = "EP_TRACKING";
            body.guideDTOList = guides;
            AsyncTask.submitAsyncTask(body, function(result){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.asyncTask.tips.taskSubmitSuccess'));
            }, $rootScope.ALERT_ERROR);
        }
        var amendGuideForbidden = function(guides) {
            if(!guides || guides.length == 0){
                return false;
            }
            if ($rootScope.HAVENOAUTH("AU_AMEND_SHIPMENT")) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_SHIPMENT');
                return true;
            }
            var checkOk = true;
            angular.forEach(guides, function(data){
                if (!isOp() && data.isLocked) {
                    checkOk = false;
                }
            })
            if(!checkOk){
                $rootScope.OPS_ALERT($translate.instant('global.opDocAuRequired'));
                return true;
            }
            return false;
        }

        $rootScope.SALES_LOCK = function(guide) {
            if ($rootScope.HAVENOAUTH('AU_SALES_LOCK')) {
                guide.isLocked = !guide.isLocked ;
                $rootScope.FORBIDDEN_BY_ALERT('AU_SALES_LOCK');
                return;
            }
            Guide.salesLock({
                guideId: guide.id,
                isLocked: guide.isLocked
            }, function(result, headers) {
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }
        $rootScope.APPLY_RELEASE = function(guide) {
            if (!guide.submited){
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                return;
            }
            if(!guide.finId){
                layer.msg($translate.instant('autopsApp.guide.home.finNullAlert'));
                return;
            }
            if (guide.hblReleasedTime && guide.isApplied){
                layer.msg($translate.instant('autopsApp.guide.home.blrelased'));
                return;
            }
            if (guide.isApplied) {
                guide.isApplied = false;
            }
            else{
                guide.isApplied = true;
            }
            Guide.applyRelease({
                guideId: guide.id,
                isApplied: guide.isApplied
            }, function(result, headers) {
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(result) {
                guide.isApplied = !guide.isApplied;
                if(result.status == 403){
                    layer.msg($translate.instant('autopsApp.guide.home.blrelased'));
                }
                else if(result.status == 404){
                    layer.msg($translate.instant('autopsApp.guide.home.finNullAlert'));
                }
                else {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                }
            });
        }

        $rootScope.ADD_PARCELS_TO_MASTER = function(parcels, isFms) {
            if(!parcels || parcels.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.prompt({
                formType: 0,
                value: '',
                title: $translate.instant('autopsApp.guide.inputMjobnum') + (isFms?"(FMS/FBA)":"(TK/KD)")
            }, function(value, index, elem) {
                layer.close(index);
                if(!value){
                    return;
                }
                ExpressParcel.addParcelToMaster({
                    isFms: isFms,
                    jobNum: value
                }, parcels, function (results) {
                    layer.msg($translate.instant('global.messages.submitted'));
                    angular.forEach(parcels, function(guide){
                        angular.forEach(results, function(result){
                            if(guide.id == result.id){
                                guide.masterId = result.masterId;
                                guide.guideId = result.guideId;
                            }
                        })
                    })
                    $translate.refresh();
                }, $rootScope.ALERT_ERROR);
            });
        }
        $rootScope.IS_HuanHaiOrLD = function() {
            if ($rootScope.account.companyId == 116 ||
                $rootScope.account.companyId == 286 ||
                $rootScope.account.companyId == 296 ||
                $rootScope.account.companyId == 471 ||
                $rootScope.account.companyId == 611 ||
                $rootScope.account.companyId == 691 ||
                $rootScope.account.companyId == 54) {
                return true;
            }
            return false;
        }
        $rootScope.GUIDEDTO_PREVIEWREPORT = function(code, guide) {
            var guideReportDTO = angular.copy(guide);
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {

            });
        }


        $rootScope.VENDER_AUDIT = function(vender) {
            var venders = [{
                vender: vender.companyName,
                venderId: vender.id
            }];
            var entity = {};
            entity.venders = venders;
            $rootScope.VENDER_AUDIT_POP(entity);
        }

        $rootScope.VENDER_AUDIT_POP = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/opsVender/vender-audit-dialog.html',
                controller: 'VenderAuditDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $rootScope.VENDER_AUDIT_GUIDE = function(guide) {
            var entity = {};
            entity.guide = guide;
            $rootScope.VENDER_AUDIT_POP(entity);
        }

        $rootScope.PREVIEW_DCN = function(rate) {
            if($rootScope.HAVENOAUTH("FUN_VIEW_PAYEE")){
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYEE');
                return;
            }
            if (!rate.dnNum) {
                return;
            };
            if(rate.dnNum.indexOf("DN") == 0 || rate.dnNum.indexOf("DM") == 0){
                $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountInvoice/accountInvoice-delete-dialog.html',
                    controller: 'AccountInvoiceDeleteController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xl',
                    resolve: {
                        entity: [function() {
                            return AccountInvoice.getByDnNum({
                                companyId: $rootScope.account.companyId,
                                dnNum: rate.dnNum}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('accountInvoice');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(result) {
                    if(result && result.id == rate.id){
                        rate.isVoided = result.isVoided;
                        $translate.refresh();
                    }
                }, function() {

                });
            }
            else if(rate.dnNum.indexOf("CN") == 0 || rate.dnNum.indexOf("CM") == 0){
               $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountPayment/accountPayment-delete-dialog.html',
                    controller: 'AccountPaymentDeleteController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xl',
                    resolve: {
                        entity: [function() {
                            return AccountPayment.getByDnNum({
                                companyId: $rootScope.account.companyId,
                                dnNum: rate.dnNum}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('accountPayment');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(result) {
                    if(result && result.id == rate.id){
                        rate.isVoided = result.isVoided;
                        $translate.refresh();
                    }
                }, function() {
                    // $state.go('^');
                });
            }
        };
        $rootScope.excelImportAssistCharges = function(file, isPay, isExportTms, isParcel, isWms) {
            if (!file) { return; }
            var fd = new FormData();
            fd.append('file', file);
            fd.append('isPay', isPay);
            fd.append('isExportTms', isExportTms);//出口 或 TMS
            fd.append('isParcel', isParcel);
            fd.append('isWms', angular.isUndefined(isWms) ? false : isWms);
            layer.load(1, { shade: 0.3 });
            $http.post(
                'api/assist-charges/excelImportAssistCharges', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                layer.closeAll();
                if (data.successCount) {
                    $rootScope.OPS_ALERT($translate.instant('global.importSuccessAmt', { param: data.successCount }));
                } else if (data.errorStr) {
                    $rootScope.OPS_ALERT(data.errorStr);
                }
            }).error(function(error) {
                layer.closeAll();
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });
        };

        $rootScope.EXPRESS_PARCEL_DISPLAY_CHECK = function(guide, fd) {
            if (!guide) {
                return false;
            }
            if("channel" == fd && $rootScope.HAVEAUTH("SS_WMS_VIEW_CHANNEL")){
                return false;
            }
            var cstHdFd;
            if (guide.shipmentType == 'EXPRESS') {
                cstHdFd = "EP" + fd;
            } else if (guide.shipmentType == 'WMS') {
                cstHdFd = "EPWMS" + fd;
            } else {
                cstHdFd = guide.shipmentType + fd;
            }
            if (!hideFieldsMap.has(cstHdFd)) {
                return true;
            }
            return nodeNotMatch(guide, hideFieldsMap.get(cstHdFd));
        }
 // 判断指定customNode的业务类型是否与快递小包订单的类型匹配，匹配返回true，否则返回false
        function checkExpressParcelNodeType(guide, customNode) {
            if (!guide.shipmentType || !customNode.shipmentType) {
                return false;
            }
            if (customNode.shipmentType == 'FMSALL') {
                return true;
            }
            if (customNode.shipmentType == 'EP' && guide.shipmentType == 'EXPRESS') {
                return true;
            }
            if (customNode.shipmentType == 'EPWMS' && guide.shipmentType == 'WMS') {
                return true;
            }
            if (customNode.shipmentType == guide.shipmentType) {
                return true;
            }
            if (customNode.shipmentType == 'PARCELALL' && $rootScope.parcelTypes.indexOf(guide.shipmentType) != -1) {
                return true;
            }
            if (customNode.shipmentType == 'TMSALL' && $rootScope.tmsTypes.indexOf(guide.shipmentType) != -1) {
                return true;
            }
            return false
        }
        var tmsTypes = ["TMSALL", "TMS", "FTL", "LTL", "EXD", "CPU", "EPWMS"];
        $rootScope.EXPRESS_PARCEL_RQ_CHECK = function(guide) {
            var rqFd = null;
            for (var i = 0; i < requiredFields.length; i++) {
                var customNode = requiredFields[i];
                if (customNode.conditionsCombine ||
                    !checkExpressParcelNodeType(guide, customNode)) {
                    continue;
                }
                // 如果相应业务单对应字段已经有值了，那对应字段就不用判断了
                var bindFd = customNode.bindFd;
                if (guide[bindFd]) {
                    continue;
                }

                if (!nodeNotMatch(guide, customNode)) {
                    rqFd = bindFd;
                    break;
                }
            }

            if (rqFd) {
                var data = { name: rqFd };
                DataUtils.getLangNameExpressParel(data, tmsTypes.indexOf(guide.shipmentType) != -1);
                return data.langName;
            }
        }
        $rootScope.EXPRESS_PARCEL_AL_CK = function(guide, fd) {
            if (!fd || !guide) {
                return false;
            }
            // 如果相应业务单对应字段已经有值了，那对应字段就不用判断了
            if (guide[fd]) {
                return false;
            }
            for (var i = 0; i < requiredFields.length; i++) {
                var customNode = requiredFields[i];
                if (!checkExpressParcelNodeType(guide, customNode) || customNode.conditionsCombine ||
                    !customNode.customConditions || fd != customNode.bindFd) {
                    continue;
                }
                if (!nodeNotMatch(guide, customNode)) {
                    return true;
                }
            }
            return false;
        }

        $rootScope.EDIT_DN = function(invoice) {
            if(!invoice.companyId || !invoice.dnNum){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountInvoice/createDn-dialog.html',
                controller: 'CreateDnDialogController',
                size: 'lg',
                backdrop: false,
                resolve: {
                    entity: function () {
                        return AccountInvoice.getByDnNum({
                            companyId: invoice.companyId,
                            dnNum: invoice.dnNum}).$promise;
                    }
                }
            }).result.then(function(result) {
                angular.copy(result, invoice);
            }, function() {
            })
        }

        $rootScope.EDIT_CN = function(payment) {
            if(!payment.companyId || !payment.dnNum){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountPayment/createCn-dialog.html',
                controller: 'CreateCnDialogController',
                size: 'lg',
                backdrop: false,
                resolve: {
                    entity: function () {
                        return AccountPayment.getByDnNum({
                            companyId: payment.companyId,
                            dnNum: payment.dnNum}).$promise;
                    }
                }
            }).result.then(function(result) {
                angular.copy(result, payment);
            }, function() {
            })
        }
        $rootScope.EDIT_DCN = function(request) {
            if (!request.dnNum) {
                return;
            };
            if(request.dnNum.indexOf("DN") == 0 || request.dnNum.indexOf("DM") == 0){
                $rootScope.EDIT_DN(request);
            }
            else {
                $rootScope.EDIT_CN(request);
            }
        }
        $rootScope.PUSH_LABEL_OK = function(type) {
            return type == 'logiwa' || type == 'MfyCang';
        }


        $rootScope.CREATE_OAAPPLICATION = function(autoCheckDTO) {
            if(!autoCheckDTO.billNum){
                layer.msg($translate.instant('autopsApp.autoCheck.createOaApplicationAlert'));
                return;
            }
            autoCheckDTO.currency = $rootScope.account.baseCurrency;
            AutoCheck.createOaApplication(autoCheckDTO, function(result){
                oaApplicationPop(result);
            }, $rootScope.ALERT_ERROR)
        }
        $rootScope.BL_RLS_OAAPPLICATION = function(guides) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var jobNum = "";
            angular.forEach(guides, function(data){
                if(data.jobNum){
                    jobNum = jobNum + " " + data.jobNum;
                }
            })
            if(jobNum.length > 255){
                layer.msg("订单数量过多，请控制在15单以内！");
                return;
            }
            var opApplication = {
                type: $rootScope.isYuyue($rootScope.account.companyId)?"constraint":"blRelease",
                refNum: jobNum.trim(),
                subject: $rootScope.isYuyue($rootScope.account.companyId)?"强制提交申请":"放单申请",
                jobCount: guides.length
            }
            oaApplicationPop(opApplication);
        }
        var oaApplicationPop = function(opApplication) {
            $uibModal.open({
                templateUrl: 'app/entities/oa-application/oa-application-dialog.html',
                controller: 'OaApplicationDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: function () {
                        return opApplication;
                    }
                }
            }).result.then(function() {
            }, function() {
            });
        }


        $rootScope.SET_AUTO_TRACK = function(guide, hideMsg) {
            if(guide.trackingFinished){
                return;
            }
            Guide.autoTrackingCheck(guide, function(result){
                guide.trackingFinished = result.trackingFinished;
                if(guide.trackingFinished == null && !hideMsg){
                    layer.msg($translate.instant('global.serviceNotSupport'));
                }
            }, $rootScope.ALERT_ERROR);
        }
        $rootScope.SET_PUSH_UPDATED = function(guide) {
            if(!guide.thirdpartyPlatformId){
                layer.msg($translate.instant('global.serviceNotSupport'));
                return;
            }
            if(guide.pushUpdated){
                guide.pushUpdated = false;
            }
            else if(guide.pushUpdated == false){
                guide.pushUpdated = null;
            }
            else if(guide.pushUpdated == null){
                guide.pushUpdated = false;
            }
        }

        $rootScope.CLIENT_AUTHORITY_POP = function(opsVender) {
            $uibModal.open({
                templateUrl: 'app/entities/company/user-management/client-authorities-dialog.html',
                controller: 'ClientAuthoritiesController',
                size: 'xxxl',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return opsVender;
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            })
        }
        // $rootScope.CLIENT_AUTHORITY_POP({id: 2305});

        $rootScope.ORDER_NODE_FILTER = function(item) {
            return item.txtTag != true;
        };

        // 可传入前缀和数量，生成托盘号
        $rootScope.GENERAL_PALLET_NO = function(palletNoPrefix, palletNoQuantity) {
            $uibModal.open({
                templateUrl: 'app/entities/job-pallet/general-pallet-no.html',
                controller: 'GeneratePalletNoController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return {
                            palletNoPrefix: palletNoPrefix,
                            palletNoQuantity: palletNoQuantity
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }


        $rootScope.coscoTracking = function(guide) {
            $uibModal.open({
                templateUrl: 'app/entities/express-tracking/cosco-tracking.html',
                controller: 'CoscoTrackingController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return {
                            orderNum: guide.soNum
                        };
                    }]
                }
            }).result.then(function() {}, function() {});
        }
        $rootScope.ADD_GUIDES_TO_MASTER = function(guides, isFms) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var entity = {guides: guides, isFms: isFms};
            $uibModal.open({
                templateUrl: 'app/entities/login-log/login-log-dialog.html',
                controller: 'LoginLogDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }
        $rootScope.DELIVERY_GUIDE_BATCH = function(guides) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.deliveryGuideBatch(guides, function(result) {
                layer.closeAll();
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR)
        }

        $rootScope.AUDIT_GUIDE_BATCH = function(guides) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            if($rootScope.HAVENOAUTH('ROLE_MARKET')){
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_MARKET');
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.auditGuideBatch(guides, function(result) {
                layer.closeAll();
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR)
        }

        $rootScope.UPDATE_PROFIT_BATCH = function(guides) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.updateProfitBatch(guides, function(result) {
                layer.closeAll();
                layer.msg($translate.instant('global.update_success'));
            }, $rootScope.ALERT_ERROR)
        }

        $rootScope.SEND_AMASS_SH_ERP= function(guide, type) {
            ApiErp.sendAmassSHErp({type: type}, guide, onEdiSuccess, $rootScope.ALERT_ERROR);
        }
        $rootScope.testHuoSiFuWxPush = function(guide) {
            Guide.testHuoSiFuWxPush(angular.copy(guide), function(result){
                alert(angular.toJson(result, true));
            })
        };

        $rootScope.SEARCH_IMPORTER = function(searchStr) {
            if(!searchStr){
                return;
            }
            return OpsImporterSearch.query({
                query: searchStr,
                page: 0,
                size: 20,
                sort: ['_score,desc', 'id,desc']
            }).$promise;
        }

        $rootScope.ON_IMPORTER_CHOOSED = function(item, guide) {
            if(!item){
                return;
            }
            guide.booking.importerName = item.importerName;
            guide.booking.importerTaxId = item.importerTaxId;
            guide.booking.importerEori = item.importerEori;
            guide.booking.importerAddress = item.importerAddress;
        }
        $rootScope.AMASS_LOCK_TEST = function(id, isParcel) {
            if(!id){
                return;
            }
            if(isParcel){
                ExpressParcel.parcelAutoLockCloseTest({parcelId: id}, function(result) {
                    layer.msg($translate.instant('global.processingComplete'));
                    $scope.$emit('autopsApp:parcelUpdateByChannel', result);
                })
            }
            else {
                Guide.guideAutoLockCloseTest({guideId: id}, function(result) {
                    layer.msg($translate.instant('global.processingComplete'));
                    $scope.$emit('autopsApp:loadGuide', result);
                })
            }
        }
        $rootScope.PRINT_LABEL_ALL = function(guides, fileName) {
            if (!guides || guides.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.msg("Combining label(s)...", {
              shade: 0.3,
              time: 2000000
            });
            $scope.isSaving = true;
            Guide.printLabels(guides, function(result) {
                layer.closeAll();
                $scope.isSaving = false;
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.closeAll();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.requestFail'));
            });
        }
        var lazadaQueryAppointAbilityPop = function(result, guide) {
            var entity = {};
            entity.result = result;
            entity.guide = angular.copy(guide)
            $uibModal.open({
                templateUrl: 'app/entities/write-off-rates/write-off-rates.html',
                controller: 'WriteOffRatesController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                guide.deliveryAppointmentTime = new Date();
            }, function() {});
        }

        // lazadaQueryAppointAbilityPop();
        $rootScope.LAZADA_API = function(guide, endPoint, status) {
            if (endPoint == 'queryAppointAbility') {
                if(!guide.deliveryDate){
                    $rootScope.OPS_ALERT("请填写预计派送日期！");
                    return;
                }
                Guide.queryAppointAbility(guide, function(result) {
                    lazadaQueryAppointAbilityPop(result, guide);
                }, $rootScope.ALERT_ERROR);
            }
            else if (endPoint == 'orderStatusUpdate') {
                Guide.orderStatusUpdate({status: status}, guide, function(result) {
                    layer.msg("节点回推成功！触发订单节点会自动回推，订单日志可查询自动回推结果。");
                }, $rootScope.ALERT_ERROR);
            }
            else if (endPoint == 'appointDetailPrint') {
                Guide.appointDetailPrint(guide, function(result) {
                    PRINT_PDF_DIR(result.filePath);
                }, $rootScope.ALERT_ERROR);
            }
        }

        // 是否为“博达”
        $rootScope.IS_BD = function() {
            if ($rootScope.account.companyId == 810) {
                return true;
            }
            return false;
        }

        $rootScope.IS_TEST = function() {
            return $rootScope.absUrl.indexOf("127.0.0.1:8080") !== -1;
        }

        $rootScope.exportMSC322 = function(guide, guides, isMbl) {
            layer.msg("Generating...", {
                shade: 0.3,
                time: 2000000
            });
            if (guide) {
                guides = [];
                guides.push(guide)
            }
            Guide.MSC322({
                isMbl: isMbl
            }, guides, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, guide.jobNum + ".zip");
            }, function(result) {
                layer.closeAll();
                layer.msg($translate.instant('global.requestFail'));
            });
        };

        $rootScope.ADD_DEL_AUTH = function(authority) {
            var isAdd = $rootScope.HAVENOAUTH(authority);
            User.addDelUserAuth({
                userId: $rootScope.account.id,
                authStr: authority
            }, function(data){
                if(isAdd){
                    $rootScope.account.authorities.push(authority);
                }
                else {
                    var idx = $rootScope.account.authorities.indexOf(authority);
                    $rootScope.account.authorities.splice(idx, 1);
                }
                $translate.refresh();
            })
        }

        // W24095289519 对于头程订单中分单超尺寸的信息进行提醒
        $rootScope.AMASS_CHECK_OVERSIZE = function(datas, onlyMasterOrder) {
            var guideIds = [];
            angular.forEach(datas, function(data) {
                if (onlyMasterOrder) {
                    // 海运出口页面
                    if (data.masterId == 0 && !data.atd){
                        guideIds.push(data.id);
                    }
                } else {
                    // 分单列表页面
                    if (data.guideId && data.shipmentType == 'FBASEA' && !data.atd){
                        guideIds.push(data.guideId);
                    }
                }
            })
            if (guideIds.length > 0) {
                Guide.checkOversize({checkType: "checkOversize"}, guideIds, function(result) {
                    angular.forEach(datas, function(data){
                        if (result.indexOf(data.id) != -1) {
                            data.oversize = true;
                        }
                    })
                })
            }
        }

        // W24095289519 录入附加费提醒
        $rootScope.AMASS_CHECK_ENTRY_SURCHARGE = function(datas) {
            var guideIds = [];
            angular.forEach(datas, function(data) {
                if (data.id){
                    guideIds.push(data.id);
                }
            })
            if (guideIds.length > 0) {
                Guide.checkOversize({checkType: "checkEntrySurcharge"}, guideIds, function(result) {
                    angular.forEach(datas, function(data){
                        if (result.indexOf(data.id) !== -1) {
                            data.oversize = true;
                        }
                    })
                })
            }
        }

        $rootScope.AMASS_VOID_TRACKING_ID = function(guide) {
            if(!guide || !guide.id){
                return;
            }
            layer.confirm("是否取消工作号" + guide.jobNum + "的shiptrack节点映射", {
                icon: 3,
                title: $translate.instant('global.alert_title'),
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                var loadIndex = layer.load(1, { shade: 0.3 });
                Guide.voidTrackingId({id:guide.id}, function(result) {
                    layer.close(loadIndex);
                    layer.msg($translate.instant('global.send_success'));
                }, $rootScope.ALERT_ERROR);
            }, function() {
            });
        }

        $rootScope.AMASS_UPSERT_TRANSPORTATION_INFO = function(guide) {
            if(!guide || !guide.id){
                return;
            }
            Guide.upsertTransportationInfo({id:guide.id}, function(result) {
                layer.msg($translate.instant('global.send_success'));
            }, $rootScope.ALERT_ERROR);
        }

        // 自动调整指定textarea的高度
        $rootScope.AUTO_ADJUST_TEXTAREA_HEIGHT = function(elementId, defaultRows) {
            var textarea = document.getElementById(elementId);
            textarea.style.height = 'auto'; // 重置高度
            if(textarea.rows != defaultRows){
                textarea.rows = defaultRows;
                return;
            }
            var realRowsByScrollHeight =  Math.ceil(textarea.scrollHeight / 18);
            var realRowsByTextLength =  Math.ceil(textarea.textLength / (textarea.scrollWidth / 20));
            var realRows = Math.max(realRowsByScrollHeight, realRowsByTextLength);
            if (defaultRows) {
                textarea.rows = realRows > defaultRows ? realRows : defaultRows;
            } else {
                textarea.rows = realRows;
            }
        }
        $rootScope.IS_DEFAULT_ROWS = function(elementId, defaultRows) {
            var textarea = document.getElementById(elementId);
            return textarea.rows == defaultRows;
        }

        $rootScope.LOAD_JOB_OWNERSHIP_COMABB = function(guide) {
            if($rootScope.account.companyId || !guide.companyId){
                return;
            }
            OpsCompany.getCompanyAbb({companyId: guide.companyId}, function(result){
                $rootScope.JOB_OWNERSHIP_COMABB = result.companyAbb;
            })
        }

        // 检查 Grid 的权限。因 Grid 加载比权限早加载，所以需要在$timeOut时调用才有用。有新的判断需要改变gridOptions可直接加在这里面
        $rootScope.CHECK_GRID_AUTH = function (gridOptions) {
            if(!gridOptions) {
                return;
            }
            if ($rootScope.HAVEAUTH("FB_EXPORT_GRID_EXCEL")) {
                gridOptions.exporterMenuExcel = false;
            }
        }
    }
})();

